import React from 'react';
import { Link, Panel, Box } from 'rebass';
import { Heading2, NormalText, Section, SizedButton } from '../Common/Common';

const ohjeLinkit = [
  [
    'Katso ajo-ohjeet',
    'https://www.google.com/maps/dir//Villa+Ivan+Falin/data=!4m8!4m7!1m0!1m5!1m1!1s0x46920fa6ab159a69:0xc57e3a571e843a6b!2m2!1d25.17548!2d60.207127',
  ],
  [
    'Katso reitti julkisilla',
    'https://www.google.co.uk/maps/dir/Vuosaari(M),+Helsinki,+Finland/Villa+Ivan+Falin,+It%C3%A4reimarintie,+00980+Helsinki,+Finland/@60.2061809,25.1506761,15z/data=!3m1!4b1!4m14!4m13!1m5!1m1!1s0x46920f05616ea06f:0xc65d9022127200e5!2m2!1d25.1440659!2d60.207775!1m5!1m1!1s0x46920fa6aca5f6d1:0x53d473132f27f780!2m2!1d25.175307!2d60.2072132!3e3',
  ],
];

export default () => (
  <NormalText minHeight="100vh">
    <Heading2>Vihkiminen ja juhlan kulku</Heading2>
    <p>
      Vihkiminen tapahtuu kello 15 huvilan pihapiirissä. Juhla jatkuu ruuan, juoman ja rennon seurustelun merkeissä.
      Kahvin ja kakun jälkeen bändi soittaa pihan juhlateltassa. Iltapalaa on tarjolla ennen kotiinlähtöä. Juhlat
      päättyvät kello 23.30.
    </p>
    <p>Jos haluat pitää puheen tai esiintyä, ilmoitathan siitä meille niin osaamme varata siihen sopivan ajan.</p>
    <Heading2>Pysäköinti ja paikalle pääsy</Heading2>
    <p>Tarkka osoite on Villa Ivan Falin, Itäreimarintie, 00980, Helsinki.</p>
    <p>
      Juhlapaikalla on maksutonta pysäköintitilaa. Auton voi halutessaan jättää yöksi pihaan ja noutaa seuraavana
      päivänä. Vuosaaren metroasema on puolen tunnin kävelymatkan etäisyydellä.
    </p>
    <Section textAlign="center">
      {ohjeLinkit.map(([desc, link], i) => (
        <Link href={link}>
          <SizedButton>{desc}</SizedButton>
        </Link>
      ))}
    </Section>
    <Heading2>Muistaminen</Heading2>
    <p>
      Tärkeintä meille on osallistumisenne tärkeään päiväämme. Halutessanne voitte kartuttaa yhteistä
      kodinrakennustiliämme.
    </p>
    <Section textAlign="center">
      <Panel mx="auto" style={{ maxWidth: '20rem' }}>
        <Box p={3}>
          Susanna Paajanen & Juhani Pelli
          <br />
          FI46 3939 0012 5263 64
        </Box>
      </Panel>
    </Section>
    <Heading2>Pukeutuminen</Heading2>
    <p>
      Suomen kesä on arvaamaton, joten varauduttehan mahdolliseen viileään säähän ottamalla mukaan lämmintä vaatetta.
      Sekä vihkiminen että tanssit tapahtuvat ulkona.
    </p>
    <Heading2>Valokuvaus ja some</Heading2>
    <p>
      Paikalla on ammattikuvaaja ikuistamassa hääjuhlaamme. Kuvia tullaan jakamaan tällä sivustolla häiden jälkeen.
      Toivomme etteivät vieraat jaa kuviamme sosiaaliseen mediaan.
    </p>
    <Heading2>Hyödyllisiä puhelinnumeroita</Heading2>
    <Section textAlign="center">
      <Panel mx="auto" style={{ maxWidth: '20rem' }}>
        <Box p={3}>
          Taksi Helsinki 0100 0700
          <br /> Kovanen 0200 6060
          <br /> Susanna 040-7178331
          <br /> Juhani 050-3006152
        </Box>
      </Panel>
    </Section>
  </NormalText>
);
