import React from 'react';
import styled from 'styled-components';
import {
  space,
  border,
  width,
  textAlign,
  fontSize,
  maxWidth,
  minWidth,
  height,
  maxHeight,
  minHeight,
  position,
} from 'styled-system';
import { Heading, Subhead, Button, Relative, Text, Banner } from 'rebass';

const MAIN_FONT = 'Crushed';

export const colors = { orange: 'rgb(244, 190, 99)', green: 'rgb(145, 156, 94)', red: 'rgb(211, 88, 101)' };

export const Section = styled(Relative)`
  ${space}
  ${width}
  ${border}
  ${fontSize}
  ${maxWidth}
  ${minWidth}
  ${height}
  ${maxHeight}
  ${minHeight}
  ${textAlign}
  ${(props) =>
    props.absolute &&
    `
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
  `};
  ${(props) => props.relative && 'position: relative'};
  overflow: hidden;
`;

export const DisplayText = styled(Text)`
  ${maxWidth};
  font-family: '${({ fontFamily = MAIN_FONT }) => fontFamily}', sans-serif;
  font-weight: 500;
`;

export const NormalText = styled(Text)`
  ${maxWidth};
`;

export const Heading1 = styled(Heading)`
  font-family: '${MAIN_FONT}', sans-serif;
  font-weight: 500;
  color: #fff;
  text-shadow: 0 0 6px #000000, 0 0 12px #000000;
`;
Heading1.defaultProps = {
  fontSize: ['2em', '3em', '4em'],
};

export const Heading2 = styled(Subhead)`
  text-align: center;
  font-family: '${MAIN_FONT}', sans-serif;
  color: #000000;
  font-weight: 500;
`;
Heading2.defaultProps = {
  fontSize: ['1.3em', '1.5em'],
  mt: 5,
  mb: 3,
};

export const SizedButton = styled(Button)`
  display: inline-block;
  cursor: pointer;
  background-color: ${(props) => colors[props.c]};
  ${fontSize};
  ${minWidth};
`;
SizedButton.defaultProps = {
  minWidth: ['100%', '15em'],
  mx: [0, '0.2em'],
  my: '0.2em',
  fontSize: '1em',
  c: 'green',
};

export const PopupButton = styled(SizedButton)`
  text-transform: uppercase;
`;
PopupButton.defaultProps = {
  fontSize: 4,
  my: 2,
  mx: [0, '0.5em'],
  py: 20,
  px: 20,
  c: 'green',
};

export const Strong = styled.strong`
  color: black;
`;

export const Emoji = ({ children, ...props }) => (
  <span role="img" aria-label="sparkles" {...props} style={{ filter: 'grayscale()' }}>
    {children}
  </span>
);

export const CodeBlock = styled(Button)`
  background-color: ${(props) => colors[props.c]};
  ${fontSize};
  ${minWidth};
`;

export const BackgroundCover = ({ color = 'black', otherColor = 'white', y = 100 / 11 }) => (
  <Section absolute bg={color} py="3.7vh">
    <svg xmlns="http://www.w3.org/2000/svg" width="100%" height="100%" viewBox="0 0 100 100">
      {Array.from(Array(11)).map(
        (v, i) => (i % 2 === 1 ? <rect x="-100" y={i * y} width="300" height="10" fill={otherColor} /> : null)
      )}
    </svg>
  </Section>
);

export const AnimatingBanner = styled(Banner)`
  min-height: unset;
  transition: height 0.33s ease-in-out;
  ${height};
  ${position};
`;
