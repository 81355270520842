import React, { Fragment } from 'react';
import { Provider as ThemeProvider, Flex } from 'rebass';
import { Section, DisplayText } from '../Common/Common';
import Flower from '../Common/Flower';

const FLOWER_SIZE = 38;
const FLOWER_SMALL_SIZE = 32;

const Card = ({ children, py = '2vh', first, last, mid }) => (
  <Section w="33.33%" style={{ overflow: 'visible' }}>
    {first || (
      <Flower
        corner="tl"
        size={mid ? FLOWER_SMALL_SIZE : FLOWER_SIZE}
        unit="vh"
        left={0}
        bottom={0}
        x="-23%"
        y="-28%"
      />
    )}
    {last || (
      <Flower corner="tr" size={mid ? FLOWER_SMALL_SIZE : FLOWER_SIZE} unit="vh" left={0} bottom={0} x="23%" y="-28%" />
    )}
    <Section height="100%" width="100%" px="2vh" py={py} textAlign="center">
      {children}
    </Section>
  </Section>
);

const Menu = () => (
  <Fragment>
    <DisplayText fontSize={12} mt={40} py="8vh" fontFamily="Crushed" style={{ color: 'black', lineHeight: 2.2 }}>
      Verso-vihersalaatti
      <br />
      Tomaatti-mozzarellasalaatti
      <br />
      Tryffelimarinoituja paahdettuja kasviksia
      <br />
      Salaatti mansikoilla ja leipäjuustolla
      <br />
      Caesar-salaatti (naturel)
      <br />
      Hunajapaahdettua broileria
      <br />
      Pariloitua lohta
      <br />
      Ruohosipulimajoneesi
      <br />
      Katkarapuskagen
      <br />
      Graavisiikaa
      <br />
      Tillisinappikastike
      <br />
      Pulled pork
      <br />
      Piparjuurikastike
      <br />
      ~<br />
      Pekoniryyditettyä Black Angus härkää
      <br />
      Paahdettuja rosmariiniperunoita
      <br />
      Nyhtökauracrepit
      <br />
      ~<br />
      Kahvi ja kakku
      <br />
      ~<br />
      Kasvis ja savuporopizza
      <br />
    </DisplayText>
  </Fragment>
);

const MenuIt = () => (
  <Fragment>
    <DisplayText fontSize={12} mt={40} py="8vh" fontFamily="Crushed" style={{ color: 'black', lineHeight: 2.2 }}>
      Insalata verde
      <br />
      Mozzarella e pomodori
      <br />
      Verdure sott'olio tartufato al forno
      <br />
      Insalata di anguria, fragole e formaggio tipico finlandese
      <br />
      Insalata Caesar naturale
      <br />
      Pollo al forno in salsa al miele
      <br />
      Salmone alla griglia
      <br />
      Maionese all'erba cipollina
      <br />
      Lavarello ”Gravlax”
      <br />
      Salsa all’aneto e senape
      <br />
      Gamberetti “Skagen”
      <br />
      Maiale arrostito
      <br />
      Salsa al rafano
      <br />
      ~<br />
      Manzo grigliato al barbecue
      <br />
      Patate arrosto all’olio e rosmarino
      <br />
      ~<br />
      Torta Nuziale
      <br />
      Caffé
      <br />
      ~<br />
      Pizzette (verdura/renna affumicato)
      <br />
    </DisplayText>
  </Fragment>
);

const tables = [
  ['Sonja Paajanen', 'Pekka Paajanen', 'Valdemar Ripatti', 'Elina Sarlin', 'Jukka Paajanen'],
  ['Giulia Smarra', 'Maria Gatti', 'Pirkko Jokinen', 'Harri Jokinen', 'Madalina Gatti', 'Stefano Gatti'],
  ['Karo Korpijaakko', 'Arnoud Baatsen', 'Hanna Nylund', 'Nico Nylund', 'Nina Lahnalammi', 'Ilkka Lahnalammi'],
  ['Hilkka Vettenranta', 'Pasi Vettenranta', 'Nina Burmistrova', 'Antti Nyberg', 'Kati Wihersaari'],
  ['Anna Strömlin', 'Jaakko Strömlin', 'Sayaka Hämäläinen', 'Oskari Hämäläinen', 'Heidi Salonen', 'Sami Salonen'],
  ['Ellinoora Siitonen', 'Aarno Pelli', 'Markus Pelli', 'Arja Rekola', 'Timo Rekola'],
  ['Hanna Ripatti', 'Jyrki Ripatti', 'Lisa Gatti', 'Tarja Ripatti', 'Piero Gatti'],
  ['Irja Siitonen', 'Jukka Siitonen', 'Jussi Siitonen', 'Pekka Siitonen', 'Päivi Siitonen', 'Sanna Siitonen'],
  [
    'Mikko Salojää',
    'Leena Kosonen',
    'Henriett Bebesi',
    'Herkko Aromaa',
    'Matti Romppanen',
    'Petra Romppanen',
    'Sanna Tirkkonen',
    'Perttu Tirkkonen',
  ],
  [
    'Arja Paajanen',
    'Ykä Paajanen',
    'Mari Nilsen',
    'Niko Nilsen',
    'Riikka Rautionaho',
    'Matti Paajanen',
    'Aatos Paajanen',
  ],
  ['Veera Siitonen', 'Kaapo Siitonen', 'Jasper Siitonen', 'Oskar Siitonen', 'Leevi Pelli', 'Pihla Pelli'],
  ['Annika Parkkonen', 'Eki Parkkonen', 'Eva Mäenpää', 'Pekka Ruokonen', 'Jenni Niemi', 'Kalle Tuominen'],
];

const Vieraat = ({ id, col }) => (
  <Fragment>
    <DisplayText
      fontSize={18}
      mt={40}
      py="20vh"
      fontFamily="Crushed"
      style={{ color: 'black', lineHeight: 2.2, whiteSpace: 'pre-line' }}
    >
      {tables[Math.min(id * 3 + col, tables.length - 1)].map((person) => (
        <div>{person}</div>
      ))}
    </DisplayText>
  </Fragment>
);

const Page = ({ Content, extraProps }) => (
  <Flex style={{ direction: 'row', height: '100%' }}>
    <Card first>
      <Content {...extraProps} col={0} />
    </Card>
    <Card mid>
      <Content {...extraProps} col={1} />
    </Card>
    <Card last>
      <Content {...extraProps} col={2} />
    </Card>
  </Flex>
);

const contents = {
  menu: Menu,
  menuit: MenuIt,
  vieraat: Vieraat,
};

export default (props) => (
  <ThemeProvider theme={{ fonts: { sans: 'Antic, sans-serif', mono: "'Andale Mono', monospace" } }}>
    <Section textAlign="left" height="100vh" relative>
      <Page Content={contents[props.content]} extraProps={props} />
    </Section>
  </ThemeProvider>
);
