import React, { Fragment } from 'react';
import { Provider as ThemeProvider } from 'rebass';
import { Section, DisplayText, BackgroundCover, Strong, Emoji } from '../Common/Common';
import Flower from '../Common/Flower';

const FLOWER_SIZE = 80;

const Card = ({ children, py = '15.5vh' }) => (
  <Section absolute px="14vh" py="20vh">
    <Section height="100%" width="100%" px="20vh" py={py} textAlign="center" bg="white">
      {children}
    </Section>
  </Section>
);

const InvitationFront = (props) => (
  <Fragment>
    <BackgroundCover />
    <Card>
      <DisplayText fontSize={120} color="black">
        Susanna & Juhani
      </DisplayText>
      <DisplayText fontSize={80} mt={40} fontFamily="Alegreya Sans">
        28.7.2018
      </DisplayText>
      <DisplayText fontSize={38} mt={8} fontFamily="Alegreya Sans">
        Villa Ivan Falin, Helsinki
      </DisplayText>
    </Card>
    <Flower corner="tr" size={FLOWER_SIZE} unit="vh" left={0} bottom={0} x="23%" y="-28%" />
    <Flower corner="bl" flip size={FLOWER_SIZE * 1.33} unit="vh" x="-36%" y="33%" />
  </Fragment>
);

const InvitationBack = (props) => (
  <Fragment>
    <BackgroundCover color="#f8f8f8" />
    <Card py="10vh">
      <DisplayText fontSize={60} color="black">
        <Emoji>✨</Emoji> Tervetuloa Hääjuhlaamme <Emoji>✨</Emoji>
      </DisplayText>
      <DisplayText fontSize={40} mt={60} mx="auto" fontFamily="Alegreya Sans">
        Meidät vihitään klo 15 huvilan pihapiirissä, jonka jälkeen juhla jatkuu ruoan ja musiikin merkeissä.
      </DisplayText>
      <DisplayText fontSize={40} mt={60} fontFamily="Alegreya Sans">
        Tarkemmat tiedot netissä &ndash; <Strong>häät2018.pelli.fi</Strong>
        <br />
      </DisplayText>

      <DisplayText fontSize={30} mt={60} fontFamily="Alegreya Sans">
        <Strong>RSVP</Strong>&nbsp;&nbsp;15.6.2018 mennessä
      </DisplayText>
    </Card>
    <Flower faded corner="tl" size={FLOWER_SIZE} unit="vh" x="-23%" y="-28%" />
    <Flower faded corner="br" flip size={FLOWER_SIZE * 1.33} unit="vh" x="36%" y="33%" />
  </Fragment>
);
export default (props) => (
  <ThemeProvider theme={{ fonts: { sans: 'Antic, sans-serif', mono: "'Andale Mono', monospace" } }}>
    <Section textAlign="left" height="100vh" relative>
      {props.front ? <InvitationFront /> : <InvitationBack />}
    </Section>
  </ThemeProvider>
);
