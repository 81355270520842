import React from 'react';
import styled from 'styled-components';
import { height, width, fontSize } from 'styled-system';
import FlowerSVG from './FlowerSVG';

const cornerStyles = {
  tr: 'top: 0; right: 0;',
  tl: 'top: 0; left: 0;',
  br: 'bottom: 0; right: 0;',
  bl: 'bottom: 0; left: 0;',
};

const ratio = 2418 / 3338;
const Wrapper = styled.div`
  ${width};
  ${height};
  ${fontSize};
  position: absolute;
  ${({ corner }) => cornerStyles[corner] || 'right: 0;'}
  transform: ${({ x = '30%', y = '0%', flipY, flipX }) =>
    `translate(${x}, ${y}) ${flipY ? 'rotateY(180deg)' : ''} ${flipX ? 'rotateX(180deg)' : ''}`};
  transform-origin: 50% 50%;
  ${({ faded }) => (faded ? 'filter: saturate(0.62) opacity(0.62)' : '')};
`;

Wrapper.defaultProps = {
  right: 0,
};

const transformValue = (val, transform = (v) => v) => {
  if (Array.isArray(val)) {
    return val.map(transform);
  }
  return transform(val);
};

export default ({ size, unit = 'rem', ...restOfProps }) => (
  <Wrapper
    {...restOfProps}
    width={transformValue(size, (v) => Math.round(v * ratio) + unit)}
    height={transformValue(size, (v) => v + unit)}
  >
    <FlowerSVG />
  </Wrapper>
);
