import { Component } from 'react';

// Let transition complete before next check
const BLOCK_TIMEOUT = 500;
const INTERVAL = 20;

class WithScroll extends Component {
  state = {
    scrollPosition: this.getWindowScrollTop(),
  };

  componentWillMount() {
    this.intervalID = setInterval(this.handleInterval, INTERVAL);
  }

  componentWillUnmount() {
    // Remove and reset interval/animationFrame
    clearInterval(this.intervalID);
    cancelAnimationFrame(this.requestID);
    this.requestID = null;
    this.intervalID = null;
  }

  getWindowScrollTop() {
    // Get scroll position, with IE fallback
    return window.pageYOffset || document.documentElement.scrollTop;
  }

  handleInterval = () => {
    if (this.isBlocked) return;
    // Interval is only used to throttle animation frame
    cancelAnimationFrame(this.requestID);
    this.requestID = requestAnimationFrame(this.handleRequestAnimationFrame);
  };

  handleRequestAnimationFrame = () => {
    const { scrollPosition: oldScrollPosition, isScrolled: oldIsScrolled } = this.state;
    const scrollPosition = this.getWindowScrollTop();
    const isScrolled = scrollPosition > 0;

    // Update the state only when scroll position is changed
    if (oldScrollPosition !== scrollPosition) {
      this.setState({ scrollPosition });
      if (isScrolled !== oldIsScrolled) {
        this.isBlocked = true;
        this.blockTimeoutID = setTimeout(() => (this.isBlocked = false), BLOCK_TIMEOUT);
        this.setState({ isScrolled });
      }
    }
  };

  render() {
    return this.props.children(this.state);
  }
}

export default WithScroll;
