import React, { Fragment } from 'react';
import { Provider as ThemeProvider, Relative } from 'rebass';

import { Section, Heading1, AnimatingBanner } from '../Common/Common';
import Bg from '../Common/Bg';
import Flower from '../Common/Flower';
import TempInfo from './TempInfo';
import Info from './Info';
import Forms from './Forms';
import WithScroll from './WithScroll';
import BannerImage from './bg.jpg';

const BgWithFlower = ({ y }) => (
  <Fragment>
    <Flower size={[16, 22, 28]} y={y} />
    <Bg width="100%" height="80px" />
  </Fragment>
);
export default (props) => (
  <ThemeProvider theme={{ fonts: { sans: "'Antic', sans-serif", mono: "'Andale Mono', monospace" } }}>
    <Section textAlign="left" minHeight="100vh">
      <Bg absolute c="#fbfbfb" y={64} />
      <Relative style={{ zIndex: 1 }}>
        <BgWithFlower y="-40%" />
        <WithScroll>
          {({ isScrolled }) => (
            <AnimatingBanner
              backgroundImage={BannerImage}
              p={0}
              height={isScrolled ? ['4rem', '5rem', '6rem'] : [('auto', '20vh', '40vh')]}
            >
              <Heading1 m={0}>Susanna & Juhani 2018</Heading1>
            </AnimatingBanner>
          )}
        </WithScroll>
        <Bg width="100%" height="16px" />
        <Section px={20} py={[30, 30, 40]} bg="white" mx="auto" maxWidth={['100%', '30em']} fontSize="1.2em">
          {!props.dev ? (
            <Fragment>
              <TempInfo />
              <Forms />
            </Fragment>
          ) : (
            <Fragment>
              <Info />
              <Forms />
            </Fragment>
          )}
        </Section>
        <BgWithFlower y="-38%" />
      </Relative>
    </Section>
  </ThemeProvider>
);
