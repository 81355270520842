import React, { Component } from 'react';
import { Section, Heading2, PopupButton } from '../Common/Common';

const redirectToGallery = () => window.location.assign('https://galleria.wihersaari.fi/guestlogin/susannaandjuhani');

export default class Forms extends Component {
  render = () => (
    <Section textAlign="center">
      <Heading2>KUVAT</Heading2>
      <PopupButton onClick={redirectToGallery}>Siirry galleriaan</PopupButton>
    </Section>
  );
}
