import React from 'react';
import { Link } from 'rebass';
import { Heading2, NormalText, Section, SizedButton } from '../Common/Common';

const ohjelma = [
  ['15:00', 'Vihkiminen'],
  ['16:00', 'Onnittelut'],
  ['17:00', 'Ruokaa ja puheita'],
  ['19:00', 'Musiikkia, laulua ja tanssia'],
  ['00:00', 'Juhlat huvilalla päättyvät'],
];
const ohjeLinkit = [
  [
    'Katso ajo-ohjeet',
    'https://www.google.com/maps/dir//Villa+Ivan+Falin/data=!4m8!4m7!1m0!1m5!1m1!1s0x46920fa6ab159a69:0xc57e3a571e843a6b!2m2!1d25.17548!2d60.207127',
  ],
  [
    'Katso reitti julkisilla',
    'https://www.google.co.uk/maps/dir/Vuosaari(M),+Helsinki,+Finland/Villa+Ivan+Falin,+It%C3%A4reimarintie,+00980+Helsinki,+Finland/@60.2061809,25.1506761,15z/data=!3m1!4b1!4m14!4m13!1m5!1m1!1s0x46920f05616ea06f:0xc65d9022127200e5!2m2!1d25.1440659!2d60.207775!1m5!1m1!1s0x46920fa6aca5f6d1:0x53d473132f27f780!2m2!1d25.175307!2d60.2072132!3e3',
  ],
  [
    'Katso julkisten aikatalut',
    'https://www.reittiopas.fi/reitti/Rautatientori%2C%20Helsinki%3A%3A60.171283%2C24.942572/Villa%20Ivan%20Falin%2C%20Uutela%2C%20Helsinki%3A%3A60.206765%2C25.175766?arriveBy=true&time=1522497600',
  ],
];

export default () => (
  <NormalText>
    <Heading2>Tervetuloa hääjuhlaamme</Heading2>
    <p>
      Päädyitte tälle sivulle toivottavasti kutsukortissa olevan osoitteen kautta. Kutsu on avoin kuoreen merkityille
      henkilöille. Sivun alalaidassa olevien nappuloiden takaa voitte ilmoittautua, jolloin tiedämme varata tilaa
      pöytään.
    </p>
    <Heading2>Juhlapaikka</Heading2>
    <p>
      Sekä vihkiminen että itse juhla tapahtuvat samassa paikassa. Tarkka osoite on Villa Ivan Falin, Itäreimarintie,
      00980, Helsinki. Huvilan yhteydessä on parkkitilaa ja Vuosaaren metroasema on puolen tunnin kävelymatkan
      etäisyydellä. Metroasemalta lähetevä bussi 90 tuo hieman lähemmäs.
    </p>
    <Section textAlign="center">
      {ohjeLinkit.map(([desc, link], i) => (
        <Link href={link}>
          <SizedButton>{desc}</SizedButton>
        </Link>
      ))}
    </Section>
    <Heading2>Ruokailu ja kahvitarjoilu</Heading2>
    <p>
      Juhlaväelle tullaan tarjoamaan ruokaa ja juomaa. Menu selviää myöhemmin ja sitä voi käydä vakoilemassa täältä.
      Ilmoitattehan ruoka-aineallergioista ja muista huomioitavista seikoista vastauslomakkeessa.
    </p>
    <Heading2>Muuta huomioitavaa</Heading2>
    <p>
      Pukukoodi on tumma puku, mutta ei se niin tarkkaa ole. Illan musiikkitarjonnasta vastaa bändi. Musiikki on kevyen
      puoleista ja tanssiminen on sallittua. Soitto ja tanssi jatkuu iltaa myöten huvilan pihalla olevassa teltassa.
      Näin ollen lämmintä päällysvaatetta on hyvä ottaa mukaan. Suomen kesässähän tunnetusti saattaa tulla viileääkin.
    </p>
    <p>
      Bändin lisäksi vieraiden esitykset ovat myös erittäin tervetulleita. Ilmoitathan vastauslomakkeessa esityksistä,
      jotta tiedämme varata tilaa aikatauluun.
    </p>

    <Heading2>Illan kulku</Heading2>
    <table style={{ width: '100%' }}>
      {ohjelma.map(([time, desc]) => (
        <tr>
          <td style={{ width: '45%', textAlign: 'right' }}>{time}</td>
          <td style={{ width: '5%', textAlign: 'center' }}>-</td>
          <td style={{ width: '45%', textAlign: 'left' }}>{desc}</td>
        </tr>
      ))}
    </table>
  </NormalText>
);
