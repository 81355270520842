import React, { Fragment } from 'react';
import { Provider as ThemeProvider } from 'rebass';
import Flower from '../Common/Flower';
import { Section, BackgroundCover, AnimatingBanner } from '../Common/Common';
import BannerImage from './1.jpg';

const FLOWER_SIZE = 60;

const Card = ({ children, py = '15.5vh' }) => (
  <Section absolute style={{ padding: '12vh 12vh 11.2vh' }}>
    <Section height="100%" px={0} py={0} width="100%" bg="white" textAlign="center">
      {children}
    </Section>
  </Section>
);

const InvitationFront = (props) => (
  <Fragment>
    <BackgroundCover />
    <Card>
      <AnimatingBanner backgroundImage={BannerImage} p={0} height="100%" />
    </Card>
  </Fragment>
);

const InvitationBack = (props) => (
  <Fragment>
    <BackgroundCover color="#f8f8f8" />
    <Card py="10vh" />
    <Flower faded corner="tl" size={FLOWER_SIZE} unit="vh" x="-23%" y="-28%" />
    <Flower faded corner="br" flip size={FLOWER_SIZE * 1.33} unit="vh" x="36%" y="33%" />
  </Fragment>
);
export default (props) => (
  <ThemeProvider theme={{ fonts: { sans: 'Antic, sans-serif', mono: "'Andale Mono', monospace" } }}>
    <Section textAlign="left" height="100vh" relative>
      {props.front ? <InvitationFront /> : <InvitationBack />}
    </Section>
  </ThemeProvider>
);
