import React from 'react';
import styled from 'styled-components';
import { height, width, fontSize, style } from 'styled-system';
import bgSvg from './stripes.svg';

const Wrapper = styled.div`
  ${width};
  ${height};
  ${fontSize};
  ${style}
  background: ${({ c = 'black' }) => c} url(${bgSvg}) top left repeat;
  background-size: auto ${({ y = 32 }) => (typeof y === 'number' ? `${y}px` : y)};
  ${(props) =>
    props.absolute &&
    `
    z-index: 0;
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
  `};
`;

export default (props) => <Wrapper {...props} />;
