import React from 'react';
export default () => (
  <svg version="1.1" xmlns="http://www.w3.org/2000/svg" width="100%" height="100%" viewBox="0 0 864 864">
    <path
      fill="white"
      d="M547.448,834.676c-2.643,0-5.029-0.882-6.9-2.55
	l-0.671-0.598l-0.862-0.258l-0.873-0.249c-0.81-0.227-1.582-0.444-2.387-0.721l-5.357-1.571l-0.174-0.034
	c-0.6-0.12-1.299-0.141-3.097-0.144c-2.755-0.009-6.497-0.019-10.318-1.738c-0.776-0.348-1.81-0.568-3.835-0.931
	c-2.751-0.491-5.866-1.047-9.101-2.834c-0.623-0.346-1.266-0.67-1.912-0.994l-0.22-0.112c-2.968-1.489-6.645-3.336-9.615-6.795
	l-0.532-0.617l-0.729-0.364c-3.464-1.723-6.447-4.367-8.626-7.647l-0.161-0.245l-0.193-0.216c-0.269-0.299-0.599-0.595-1.866-1.675
	c-1.777-1.519-4.202-3.588-5.895-6.65l-0.311-0.562l-0.469-0.437c-1.011-0.943-1.78-2.077-2.287-3.372l-0.07-0.18l-0.087-0.173
	c-1.227-2.423-2.065-4.923-2.739-6.932c-0.621-1.851-1.207-3.593-1.939-4.97l-0.176-0.333l-0.147-0.353
	c-3.088-7.368-5.115-15.325-6.029-23.653l-0.051-0.458l-0.237-0.674c-0.568-1.566-1.354-3.732-1.304-6.508
	c0.037-2.111,0.215-4.306,0.523-6.519l0.914-6.562l-6.246,2.211c-1.958,0.693-3.744,0.831-4.925,0.922
	c-0.383,0.027-0.597,0.044-0.772,0.064l-0.271,0.04l-0.647,0.119l-0.87,0.016l-0.994-0.027l-0.603,0.172
	c-0.801,0.229-1.654,0.344-2.536,0.344c-0.538,0-1.075-0.048-1.598-0.141l-1.434-0.254l-1.265,0.72
	c-1.297,0.739-2.778,1.174-4.284,1.257l-0.186,0.016c-0.404,0.041-0.875,0.081-1.386,0.081c-2.21,0-4.24-0.696-5.871-2.012
	l-1.829-1.476l-2.185,0.866c-1.114,0.442-2.35,0.667-3.673,0.667c-0.152,0-3.771-0.02-7.116-1.753l-0.602-0.313l-0.671-0.097
	c-1.661-0.241-3.221-0.915-4.638-2.002l-0.56-0.431l-0.389-0.122l-0.053-0.027l-0.486-0.145l-0.06-0.011
	c-2.394-0.702-4.481-1.814-6.554-3.491l-0.582-0.472l-0.711-0.23c-0.896-0.289-1.73-0.722-2.479-1.286l-0.246-0.184l-0.782-0.412
	c-1.383-0.709-3.293-1.688-5.023-3.421l-0.679-0.68l-0.912-0.3c-2.022-0.664-3.64-1.859-4.808-3.554l-0.532-0.77l-0.816-0.457
	c-1.727-0.966-3.054-2.088-4.121-2.989c-0.245-0.207-0.592-0.499-0.854-0.712l-0.515-0.415l-0.621-0.229
	c-1.831-0.673-3.383-1.992-4.373-3.715l-1.04-1.812l-2.079-0.193c-0.774-0.071-1.542-0.241-2.281-0.505l-4.208-1.5l-1.055,4.343
	c-0.886,3.646-1.997,6.769-3.397,9.55l-0.105,0.227l-1.899,4.436l-0.425,0.971l-0.069,0.223c-1.704,5.569-3.961,9.772-7.105,13.226
	c-3.319,3.65-6.741,7.179-10.05,10.593c-1.406,1.448-2.812,2.9-4.209,4.357c-1.582,1.614-3.176,2.948-4.582,4.124
	c-1.808,1.516-3.487,2.922-4.576,4.378c-2.41,3.232-5.108,5.566-8.251,7.139c-1.713,0.858-3.408,1.294-5.042,1.294
	c-0.233,0-0.465-0.01-0.699-0.028l-1.794-0.142l-1.302,1.242c-1.742,1.664-3.406,3.105-5.087,4.41l-0.993,0.779
	c-3.01,2.366-6.415,5.045-11.222,6.44l-0.227,0.064l-0.217,0.093c-0.276,0.116-0.593,0.272-0.928,0.442l-1.151,0.571l-1.107,0.751
	c-1.453,0.987-2.941,1.998-4.535,2.968c-1.914,1.164-4.002,1.778-6.038,1.778c-2.47,0-4.843-0.9-6.682-2.536
	c-3.733-3.323-3.581-7.479-2.795-10.38l0.609-2.248l-1.646-1.648c-0.282-0.283-0.549-0.585-0.793-0.898
	c-1.875-2.413-2.406-5.279-1.578-8.52l0.038-0.159c0.458-2.15,1.154-3.896,1.77-5.434c0.552-1.389,0.824-2.106,0.949-2.733
	l0.035-0.177l0.372-3.438l-3.115-1.069l-0.735-0.236c-2.879-0.875-5.119-2.882-6.368-5.675c-1.468-3.28-1.315-7.308,0.419-11.048
	c0.17-0.368,0.354-0.73,0.542-1.091l0.41-0.785l0.042-0.886c0.087-1.805,0.646-3.56,1.71-5.364l0.326-0.552l0.138-0.626
	c0.078-0.353,0.176-0.892,0.248-1.298c0.276-1.553,0.579-3.258,1.307-5.143l0.188-0.507c0.243-0.673,0.564-1.56,1.083-2.554
	l0.334-0.641l0.091-0.716c0.082-0.642,0.176-1.37,0.323-2.162l0.611-3.286L258,708.623c-1.833-0.709-3.329-1.977-4.324-3.666
	l-0.182-0.309l-0.232-0.271c-1.034-1.203-1.76-2.523-2.159-3.923c-0.333-1.172-0.81-2.095-1.416-2.742
	c-1.227-1.355-2.312-2.895-3.271-4.652l-1.608-2.947l-3.19,1.053c-0.563,0.185-1.885,0.606-1.885,0.606l-0.21,0.093
	c-1.529,0.687-3.058,1.255-4.406,1.756c-1.561,0.578-3.039,1.127-4.311,1.751c-2.39,1.171-4.844,1.355-6.482,1.394l-1.653,0.036
	l-1.149,1.189c-1.73,1.789-4.105,2.846-6.516,2.9c-0.666,0.014-1.338,0.022-2.011,0.022c-1.596,0-3.186-0.043-4.729-0.131
	c-1.056-0.06-2.072-0.306-3.011-0.727l-1.977-0.889l-1.831,1.162c-8.539,5.425-18.062,7.064-25.714,8.383
	c-1.396,0.238-2.738,0.392-3.922,0.526c-1.5,0.171-2.919,0.331-4.113,0.617c-0.855,0.205-1.75,0.31-2.664,0.31
	c-0.278,0-0.541-0.011-0.786-0.025l-0.854-0.053l-0.801,0.3c-0.444,0.165-0.829,0.325-1.111,0.46
	c-1.312,0.625-3.098,1.341-5.31,1.341c-1.267,0-7.683-0.386-9.567-7.995c-0.874-3.532-0.513-6.497,1.105-9.063
	c0.525-0.832,1.17-1.58,1.916-2.224l1.148-0.988l0.212-1.499c0.095-0.676,0.261-1.337,0.493-1.966l0.135-0.367l0.062-0.388
	c0.066-0.411,0.144-1.286,0.19-1.887c0.228-2.839,0.573-7.139,3.658-11.113c2.664-3.433,5.897-6.243,9.611-8.35
	c0.369-0.211,0.737-0.394,1.109-0.557l1.09-0.473l0.658-0.99c1.335-2.009,3.203-3.046,4-3.489l0.168-0.095l0.158-0.107
	c2.861-1.969,5.532-3.506,8.166-4.699l0.715-0.322l0.542-0.57c1.062-1.116,2.295-2.073,3.771-2.927l1.993-1.151l0.019-2.303
	c0.01-1.232,0.134-2.503,0.379-3.886l0.875-4.936l-5.007,0.209c-0.146,0.007-0.292,0.011-0.437,0.011
	c-2.406,0-4.748-1.009-6.424-2.77l-0.188-0.196l-4.167-3.327l-1.6,0.139c-0.272,0.024-0.545,0.037-0.821,0.037
	c-4.043,0-6.623-2.511-7.725-3.582l-0.175-0.168c-6.865-6.381-14.745-11.424-24.136-15.46c-1.624-0.698-4.035-1.865-6.204-3.919
	l-0.617-0.584l-0.8-0.286c-0.454-0.162-0.903-0.362-1.334-0.599l-0.167-0.084c-3.216-1.564-5.511-4.712-5.987-8.213
	c-0.521-3.831,1.139-7.523,4.33-9.635c2.147-1.421,4.25-2.994,6.477-4.659l3.637-2.705l0.274-1.589
	c0.385-2.224,1.654-4.337,3.669-6.111c5.363-4.709,11.573-8.283,18.456-10.619l7.713-2.619l-6.765-4.537
	c-2.015-1.353-3.601-3.375-4.585-5.851c-1.127-2.831-1.044-5.83,0.234-8.446c1.16-2.371,3.109-4.103,5.635-5.007l0.687-0.245
	l1.153-0.991c0.457-0.398,0.95-0.772,1.46-1.109l1.671-1.102l0.13-1.998c0.156-2.409,0.921-4.374,1.426-5.676
	c0.104-0.266,0.199-0.508,0.286-0.753c0.415-1.157,0.81-2.318,1.206-3.479l0.57-1.672l0.306-0.981
	c2.269-7.327,4.841-15.632,12.229-21.744c2.021-1.671,4.126-3.168,6.258-4.449l7.02-4.217l-7.626-2.983
	c-0.717-0.281-1.439-0.552-2.162-0.826l-0.202-0.075c-3.444-1.295-7.001-2.633-10.504-4.732c-4.93-2.954-5.474-7.658-4.34-11.076
	l0.737-2.222l-1.565-1.739c-2.141-2.38-3.515-5.246-4.083-8.521l-0.111-0.644l-0.311-0.575c-4.318-8.017-3.966-16.612,1.017-24.859
	c0.994-1.646,1.745-3.463,2.295-5.557c1.593-6.05,4.636-11.221,7.321-15.783c0.596-1.01,1.185-2.011,1.757-3.018l0.164-0.288
	l0.114-0.31c0.414-1.124,0.932-2.204,1.542-3.211l0.175-0.289l0.125-0.314c0.411-1.041,0.939-1.994,1.57-2.836l0.376-0.502
	l0.207-0.594c1.5-4.327,5.263-6.1,6.679-6.768l0.396-0.19c1.171-0.594,2.396-1.093,3.38-1.494c0.436-0.176,0.84-0.341,1.201-0.495
	l1.183-0.505l0.671-1.098c0.482-0.789,1.056-1.487,1.705-2.074l0.473-0.428l0.318-0.552c1.059-1.846,2.652-3.24,4.606-4.033
	l1.105-0.448l0.682-0.977c2.491-3.566,5.866-4.847,7.882-5.612l0.146-0.055c0.271-0.101,0.515-0.196,0.709-0.282l0.218-0.105
	c0.925-0.478,1.927-0.868,2.811-1.211l0.24-0.093c0.387-0.148,0.756-0.294,1.048-0.431l0.179-0.084l0.17-0.101
	c0.801-0.476,1.628-0.887,2.458-1.225l4.723-1.917l-2.96-4.151c-0.706-0.989-1.283-2.058-1.716-3.178
	c-1.047-2.707-1.074-5.233-1.091-6.905l-0.001-0.198c-0.003-0.38-0.008-0.655-0.028-0.926c-0.067-0.939-0.004-1.86,0.188-2.727
	l0.251-1.137l-0.395-1.096c-2.72-7.55,0.873-13.594,2.803-16.84c0.425-0.713,0.729-1.229,1.004-1.753
	c1.824-3.482,5.337-5.643,9.171-5.643c1.276,0,2.536,0.23,3.745,0.684l1.058,0.397l1.476-0.28l5.079-0.91l-2.117-4.705
	c-0.27-0.6-0.473-1.233-0.605-1.885l-0.438-2.167l-2.063-0.794c-0.37-0.143-0.737-0.289-1.099-0.441
	c-2.501-1.056-4.773-2.334-6.778-3.461c-1.664-0.936-3.205-1.801-4.707-2.467c-1.227-0.544-5.297-2.734-6.233-8.119l-0.313-1.804
	l-1.565-0.95c-2.699-1.638-4.506-3.944-5.229-6.67l-0.312-1.18l-0.915-0.808c-3.625-3.201-5.321-7.48-6.282-10.419
	c-0.538-1.645-1.691-3.369-3.026-5.367c-1.112-1.665-2.355-3.526-3.414-5.621c-0.379-0.751-0.701-1.524-0.974-2.191l-0.264-0.637
	c-0.299-0.735-0.528-1.339-0.756-1.943c-0.51-1.35-0.951-2.516-1.469-3.349c-1.275-2.046-1.773-4.399-1.402-6.629l0.163-0.981
	l-0.312-0.944c-0.684-2.066-0.839-3.978-0.953-5.374l-0.191-2.422l-0.074-0.307c-0.155-0.649-0.359-1.649-0.34-2.832l0.014-0.876
	l-0.351-0.802c-1.458-3.335-1.683-6.692-1.847-9.144c-0.062-0.94-0.122-1.817-0.228-2.526c-0.667-4.457-1.172-8.913-1.66-13.223
	c-0.278-2.448-0.54-4.755-0.826-7.059c-0.562-4.525,1.078-8.083,1.617-9.251c1.57-3.416,4.518-5.372,8.09-5.372
	c0.618,0,1.244,0.065,1.86,0.194c4.112,0.854,8.046,2.397,11.696,4.588l9.678,5.806l-3.817-10.621
	c-0.753-2.094-0.726-4.356,0.078-6.543c0.473-1.286,1.18-2.44,2.103-3.43l0.516-0.553l0.377-0.938l0.761-1.762l-0.89-1.701
	c-0.67-1.28-1.049-2.633-1.126-4.019l-0.079-1.412l-0.944-1.053c-0.567-0.632-1.062-1.281-1.515-1.983l-0.155-0.241l-0.663-0.765
	l-0.206-0.28c-0.213-0.294-0.417-0.614-0.595-0.94l-0.097-0.177l-0.114-0.166c-0.189-0.277-0.42-0.634-0.655-1.054
	c-3.131-5.527-0.515-9.592,0.755-11.083l1.49-1.75l-0.749-2.172c-0.256-0.742-0.621-1.88-0.839-3.197l-0.162-0.976l-0.638-0.846
	l-0.239-0.407c-1.787-3.049-2.437-6.074-1.927-8.988l0.249-1.424l-0.713-1.257c-1.192-2.1-2.271-4.208-3.315-6.245l-0.801-1.559
	c-1.487-2.88-1.432-6.141,0.142-8.727c1.44-2.364,3.905-3.844,6.762-4.061l0.33-0.025l0.321-0.078
	c0.749-0.183,1.523-0.275,2.305-0.275c1.116,0,2.027,0.184,2.695,0.345l1.2,0.273l0.792-0.14c0.759-0.134,1.525-0.202,2.279-0.202
	c3.042,0,6.052,1.143,8.704,3.307l0.54,0.44l0.656,0.234c0.375,0.133,0.92,0.308,1.288,0.424c1.578,0.501,3.546,1.128,5.552,2.322
	c1.04,0.619,2.224,1.218,3.477,1.853c2.883,1.459,6.134,3.106,9.016,5.668c1.603,1.425,3.665,3.173,5.402,4.053
	c3.971,2.01,6.301,5.026,8.002,7.229c0.92,1.19,1.406,1.797,1.896,2.231l0.118,0.1c3.517,2.884,5.771,6.358,7.759,9.422l0.691,1.064
	l1.29,0.653c7.019,2.844,11.761,7.849,15.475,12.37c2.772,3.374,4.34,6.984,5.724,10.168c0.823,1.895,1.599,3.684,2.509,5.115
	l1.074,1.69l0.108,1.276l0.271,0.614c0.547,1.242,1.303,3.154,1.543,5.39l0.871,8.09l5.904-5.6
	c3.443-3.266,7.418-7.211,10.803-11.709c0.549-0.729,1.074-1.488,1.598-2.248c1.457-2.109,3.065-4.438,5.121-6.524l0.478-0.484
	l0.292-0.614c0.565-1.189,1.531-2.949,3.385-4.471l0.132-0.108l0.261-0.254c0.497-0.478,1.28-1.229,2.379-1.948l0.722-0.472
	l0.464-0.725c0.218-0.34,0.456-0.669,0.71-0.985c1.05-1.298,2.382-2.332,3.852-2.989l3.471-1.55l-1.348-3.555
	c-0.365-0.961-0.622-2.026-0.765-3.163l-0.098-0.665l-0.019-0.483c-0.195-5.266,2.418-9.445,6.992-11.17l4.384-1.654l-2.294-4.085
	c-0.851-1.515-2.435-4.333-2.578-8.103c-0.239-3.75,1.667-7.248,4.967-9.236c1.709-1.03,3.718-1.552,5.973-1.552
	c2.445,0,5.139,0.652,7.393,1.788l8.185,4.128l-2.498-8.821c-0.188-0.663-0.339-1.328-0.449-1.976l-0.061-0.351l-0.029-0.353
	c-0.154-1.873-0.076-8.184,7.415-10.904c1.127-0.409,2.274-0.617,3.409-0.617c2.069,0,4.013,0.678,5.625,1.958l2.123,1.688
	l2.362-1.334c1.542-0.871,3.366-1.402,5.42-1.578c0.293-0.025,0.583-0.036,0.863-0.036c1.591,0,3.083,0.353,4.438,1.05l3.292,1.692
	l1.963-3.137c2.332-3.728,5.387-6.712,7.973-7.789c1.401-0.583,2.844-0.879,4.286-0.879c2.958,0,5.76,1.279,7.69,3.51
	c1.617,1.869,2.539,4.355,2.529,6.82l-0.029,7.283l6.183-3.847c1.294-0.806,3.305-1.949,5.805-2.576l0.183-0.05
	c0.666-0.2,1.7-0.467,2.993-0.467c2.968,0,5.669,1.397,7.412,3.833c0.597,0.835,2.464,3.87,1.375,7.769l-1.862,6.657l6.709-1.665
	c0.669-0.166,1.355-0.25,2.042-0.25c0.21,0,0.422,0.008,0.633,0.022l2.819,0.201l1.146-2.584c1.846-4.164,4.035-7.71,6.691-10.841
	l0.124-0.148c3.247-3.828,6.579-7.754,10.215-11.502c2.107-2.171,3.247-5.79,4.52-10.294c1.183-4.197,2.565-8.644,5.275-12.83
	c1.376-2.123,3.065-3.434,4.185-4.302c0.327-0.251,0.488-0.377,0.644-0.509c1.724-1.469,3.884-2.278,6.081-2.278
	c2.43,0,4.704,0.956,6.404,2.692c1.771,1.807,2.717,4.265,2.662,6.922c-0.013,0.463-0.006,0.929,0.001,1.392
	c0.031,2.1,0.061,4.185-0.442,6.609c-0.392,1.901-0.223,4.281-0.045,6.801c0.14,1.936,0.295,4.106,0.223,6.362
	c-0.038,1.303-0.118,2.632-0.246,4.07l-1.151,13.046l8.281-10.146c0.295-0.361,0.612-0.697,0.945-1l0.629-0.572l0.344-0.777
	c0.241-0.547,0.52-1.082,0.824-1.588l0.073-0.121l0.062-0.126c0.58-1.137,1.354-2.095,2.3-2.846l0.687-0.545l0.397-0.781
	c0.998-1.96,2.218-3.459,3.199-4.664c0.266-0.326,0.616-0.758,0.847-1.06l0.447-0.591l0.21-0.71c0.595-2.025,1.39-3.816,2.09-5.397
	c0.004-0.009,0.413-0.924,0.563-1.277l0.078-0.183l0.061-0.189c0.542-1.717,1.627-3.24,3.055-4.289l0.735-0.539l0.43-0.802
	c0.61-1.137,1.356-2.459,2.493-3.767l0.244-0.282l0.188-0.321c0.782-1.331,1.656-2.56,2.6-3.651l1.504-1.741l-0.737-2.179
	c-0.806-2.386-0.8-4.63,0.016-6.668l0.436-1.089l-0.218-1.153c-0.78-4.144,1.316-8.25,5.101-9.985
	c1.221-0.56,2.514-0.843,3.845-0.843c3.651,0,6.917,2.159,8.319,5.498l0.157,0.375l0.229,0.336c0.628,0.918,1.507,2.37,1.904,4.369
	l0.187,0.94l0.797,1.02c3.459,4.47,4.678,9.204,5.755,13.381c0.363,1.41,0.699,2.711,1.088,3.874
	c1.518,4.549,2.696,9.582,3.604,15.382l7.86-0.133l-0.041,0.155l0.063-0.156l0.093-0.001l0.041-0.331l0.396-0.995
	c3.744-9.321,9.41-16.882,16.841-22.47c1.654-1.245,3.298-2.507,4.938-3.767l2.56-1.963l0.357-0.22
	c5.551-3.417,10.882-5.66,16.305-6.864c8.004-1.777,15.99-2.766,23.733-2.938l0.27-0.003c0.48,0,0.959,0.029,1.38,0.07l0.778,0.076
	l0.748-0.221c0.917-0.27,1.839-0.535,2.765-0.785l0.223-0.06l0.214-0.086c0.915-0.363,2.175-0.78,3.804-0.78
	c3.634,0,6.822,2.121,8.318,5.536l0.145,0.317c0.775,1.701,2.388,5.248,0.88,9.232c-0.669,1.776-1.849,3.283-3.408,4.36
	l-1.451,1.003l-0.247,1.748c-0.166,1.177-0.525,2.337-1.1,3.548l-0.333,0.699l-0.048,0.773c-0.097,1.529-0.276,4.375-1.991,7.239
	l-0.175,0.292c-0.974,1.634-2.427,4.075-5.102,5.917l-0.303,0.209l-0.262,0.26c-2.02,2.02-4.232,3.263-6.011,4.261
	c-1.053,0.592-1.709,0.967-2.193,1.337c-4.765,3.647-9.556,6.314-14.649,8.156l-0.78,0.282l-0.604,0.567
	c-0.988,0.928-2.371,2.1-4.301,2.934c-1.864,0.807-3.729,1.579-5.538,2.293l-10.126,4.001l10.293,3.55
	c0.86,0.297,1.646,0.598,2.349,0.878l0.719,0.288H625c0.962,0,1.878,0.156,2.761,0.465l0.493,0.173l0.522,0.041
	c2.427,0.191,4.573,1.311,6.208,3.239l0.17,0.201l3.948,3.583l1.735-0.019c4.241,0,6.901,2.793,7.902,3.844l0.186,0.194
	c6.988,7.068,13.241,11.878,19.725,15.187c2.535,1.294,4.79,2.602,6.896,3.996c1.034,0.686,1.979,1.492,2.809,2.396l0.631,0.687
	l0.869,0.34c2.257,0.883,4.019,2.468,5.237,4.71c0.556,1.027,0.945,2.129,1.154,3.272l0.188,1.032l0.668,0.809
	c0.547,0.663,1.02,1.379,1.402,2.128c0.627,1.228,2.402,5.584-1.171,9.987l-0.128,0.166c-0.999,1.372-2.015,2.738-3.028,4.104
	l-0.12,0.163c-1.767,2.381-3.566,4.806-5.22,7.156c-0.439,0.623-0.86,1.257-1.28,1.892c-1.268,1.918-2.697,4.078-4.542,6.098
	l-0.611,0.67l-0.266,0.869c-1.161,3.801-3.347,6.559-5.332,8.756l-3.683,4.074l4.994,2.287c1.566,0.716,2.875,1.712,3.892,2.96
	l1.463,1.798l2.288-0.362c1.031-0.164,2.044-0.243,3.096-0.243l0.515,0.007c4.688,0.13,8.379,2.671,9.811,6.797l0.283,0.819
	l0.597,0.63c0.292,0.308,0.574,0.616,0.84,0.929c3.695,4.349,4.06,9.866,0.957,14.402l-0.184,0.27l-0.139,0.295
	c-0.21,0.447-0.474,0.983-0.84,1.572l-0.466,0.75l-0.111,0.876c-0.411,3.28-1.797,5.943-2.809,7.888l-0.118,0.225
	c-0.286,0.549-0.549,1.062-0.743,1.521c-2.57,6.091-6.025,11.574-9.063,16.235l-0.594,0.914c-2.578,3.972-5.498,8.471-9.462,12.371
	c-2.296,2.263-11.018,10.405-11.104,10.487l-0.175,0.174c-6.532,6.892-13.837,12.65-20.901,18.218l-3.69,2.915l0.609,2.508
	c0.052,0.214,0.096,0.43,0.131,0.646l0.784,4.767l4.547-1.63c0.679-0.244,1.376-0.462,2.072-0.648l0.82-0.22l0.659-0.531
	c1.206-0.97,2.536-1.981,4.356-2.758c1.401-0.596,2.813-1.165,4.226-1.733c3.461-1.39,6.731-2.705,9.671-4.42
	c4.105-2.396,8.925-5.122,14.17-7.127c1.887-0.722,3.507-1.058,5.097-1.058c3.645,0,6.734,1.694,8.934,4.899l0.289,0.42l0.383,0.335
	c2.66,2.321,3.256,5.045,3.286,6.923l0.037,2.106l1.749,1.171c2.304,1.542,3.8,3.81,4.217,6.386
	c0.482,2.962-0.498,5.937-2.758,8.379c-1.755,1.9-3.594,3.74-5.216,5.363l-2.346,2.361l-0.789,0.578l-0.229,0.232
	c-0.519,0.526-0.972,1.231-2.159,3.222c-1.126,1.881-2.391,3.998-4.274,6.002c-2.321,2.471-4.841,4.458-7.436,6.444l-3.661,2.804
	l3.272,3.25c1.361,1.352,2.07,2.93,2.426,4.016l1.064,3.264l3.39-0.536c0.467-0.074,0.944-0.111,1.418-0.111
	c1.423,0,2.843,0.33,4.34,1.007l0.497,0.226l0.54,0.085c0.41,0.064,0.766,0.138,1.053,0.199l0.375,0.079l0.382,0.008
	c4.939,0.098,8.788,3.49,9.358,8.249l0.022,0.161c0.962,6.404-2.913,9.337-4.623,10.301l-0.78,0.44l-0.521,0.73
	c-0.434,0.608-0.861,1.197-1.317,1.75c-1.01,1.228-2.036,2.365-2.94,3.367l-0.104,0.117c-0.468,0.52-0.913,1.015-1.347,1.511
	l-0.231,0.265c0,0-0.561,0.925-0.727,1.19l-0.682,1.083l0.067,1.277c0.244,4.583,0.514,9.45,0.896,14.135
	c0.847,10.349-7.068,20.39-17.646,22.384c-2.019,0.381-3.06,0.847-4.128,1.848c-1.035,0.971-2.059,2.025-3.141,3.143
	c-2.688,2.771-5.438,5.607-8.993,8.01c-0.809,0.548-1.742,1.164-2.782,1.793l-1.979,1.197l0.036,2.312
	c0.004,0.191,0,0.383-0.007,0.575l-0.039,1.049l0.477,0.935c0.378,0.736,0.767,1.469,1.157,2.201l0.086,0.161
	c0.754,1.414,1.48,2.788,2.142,4.158c3.576,7.382,7.031,13.069,12.278,16.839l0.269,0.193l0.297,0.146
	c2.634,1.3,3.944,3.244,4.58,4.646c0.97,2.143,1.081,4.385,0.335,6.854l-0.789,2.617l2.142,1.698
	c1.847,1.467,3.028,3.462,3.412,5.771c0.426,2.548-0.185,5.071-1.766,7.297l-1.668,2.349l1.684,2.336
	c1.033,1.435,1.692,3.074,1.964,4.874l0.354,2.361l2.243,0.821c1.88,0.689,3.602,1.991,5.112,3.871
	c0.848,1.051,3.451,4.834,1.467,9.457l-1.105,2.573l2.027,1.932c1.972,1.877,3.186,4.354,3.51,7.16
	c0.106,0.925,0.077,1.863-0.09,2.788l-0.692,3.831l3.805,0.821c3.426,0.738,6.188,2.236,8.445,4.581l0.149,0.147
	c5.763,5.568,2.632,11.194,1.944,12.266l-0.099,0.157c-1.663,2.615-3.677,4.294-6.151,5.126l-6.496,2.184l5.069,4.611
	c1.898,1.726,3.243,3.424,4.233,5.345l0.081,0.151c3.022,5.291,0.26,9.403-0.66,10.538c-1.753,2.17-4.335,3.414-7.084,3.414
	c-0.223,0-0.44-0.009-0.649-0.024l-9.274-0.719l5.825,7.254c3.314,4.129,2.972,10.021-0.817,14.011
	c-1.951,2.057-4.479,3.189-7.113,3.189c-0.893,0-1.788-0.131-2.66-0.386l-2.67-0.784l-1.678,2.22
	c-1.908,2.527-4.782,4.093-8.094,4.41c-0.321,0.03-0.627,0.045-0.928,0.045c-0.206,0-0.413-0.008-0.616-0.021l-3.837-0.242
	l-0.42,3.821c-0.344,3.13-1.338,5.794-3.041,8.145c-1.123,1.552-2.45,2.573-3.245,3.183c-0.99,0.76-2.104,1.283-3.309,1.555
	l-2.62,0.59l-0.463,2.643c-0.022,0.125-0.047,0.25-0.074,0.374l-0.479,2.098l1.479,1.563c1.53,1.618,3.08,3.238,4.616,4.775
	c1.685,1.688,2.645,3.475,3.347,4.78l0.488,0.906l0.398,0.404c0.729,0.741,1.33,1.649,1.737,2.628l0.306,0.736l0.564,0.564
	c3.71,3.712,5.659,8.299,7.225,11.985c0.315,0.745,0.629,1.483,0.954,2.205c2.051,4.544,3.549,9.403,4.334,14.053
	c0.078,0.463,0.24,1.415,0.184,2.676l-0.026,0.587l0.144,0.571c0.119,0.478,0.212,0.97,0.284,1.504l0.083,0.604l0.256,0.552
	c1.48,3.183,1.71,6.232,0.681,9.065l-0.337,0.931l0.131,0.98c0.556,4.127-0.266,7.896-2.441,11.204
	c-1.196,1.82-2.193,4.08-3.249,6.472l-1.113,2.521l1.949,1.95c1.952,1.955,3.106,4.479,3.332,7.296l0.025,0.31l0.072,0.302
	c0.303,1.269,0.359,2.563,0.165,3.853l-0.197,1.329l0.64,1.183l0.742,1.396c1.271,2.439,2.034,4.839,2.319,7.3l0.107,0.927
	l0.502,0.784c2.008,3.131,2.561,6.458,2.965,8.887l0.099,0.584c0.033,0.201,0.063,0.407,0.087,0.616l0.076,0.644l0.273,0.587
	c2.043,4.394,1.56,8.798,1.328,10.915l-0.044,0.383l-0.075,0.369c-0.872,4.289-4.504,7.279-8.833,7.279
	c-0.432,0-0.863-0.028-1.324-0.087l-0.376-0.048l-0.381,0.022c-0.738,0.038-1.256,0.061-1.771,0.061
	c-1.255,0-2.396-0.128-3.487-0.39l-0.601-0.133c-2.502-0.464-4.196-1.77-4.754-2.197l-0.13-0.1l-1.368-0.886l-0.887-0.121
	c-2.77-0.38-4.959-1.457-6.558-2.243l-0.244-0.121c-0.439-0.217-0.854-0.415-1.212-0.552l-0.203-0.079
	c-0.978-0.407-2.188-0.743-3.356-1.068c-2.213-0.619-4.719-1.32-7.254-2.676l-1.231-0.657l-1.373,0.244
	c-0.457,0.083-0.982,0.161-1.551,0.213l-0.316,0.031l-2.443,0.029c-8.025-0.157-14.757-2.681-20.452-7.718
	c-2.145-1.899-5.369-3.256-8.784-4.692c-1.133-0.476-2.259-0.951-3.367-1.45l-2.461-1.096c-5.638-2.499-11.459-5.08-16.967-8.938
	c-0.438-0.301-1.366-0.934-2.309-1.983l-6.904,3.66c0.072,0.286,0.144,0.568,0.221,0.849c0.392,1.44,0.799,2.872,1.204,4.303
	l0.147,0.517c1.237,4.35,2.511,8.821,3.427,13.446c0.901,4.556,1.042,9.339,0.413,14.213c-0.266,2.061-0.641,4.002-1.002,5.881
	c-0.427,2.205-0.828,4.274-0.987,6.211l-0.048,0.559l-0.129,0.584c0,0-0.052,0.595-0.064,0.786
	c-0.089,1.323-0.209,3.151-0.873,5.107l-0.096,0.277l-0.054,0.288c-0.669,3.639-2.353,6.397-3.704,8.615
	c-0.672,1.104-1.103,1.827-1.372,2.438c-0.443,1.016-0.844,2.248-1.231,3.441c-0.777,2.391-1.66,5.103-3.277,7.828
	C556.231,832.171,551.745,834.676,547.448,834.676z"
    />
    <path
      fill="none"
      stroke="#010101"
      strokeWidth="0.1"
      strokeMiterlimit="10"
      d="M220.707,469.521
	c0.117-0.277,0.228-0.562,0.333-0.854c0.06,0.014,0.119,0.027,0.18,0.04c-0.149,0.276-0.301,0.544-0.456,0.801
	C220.745,469.513,220.726,469.516,220.707,469.521z"
    />
    <g>
      <g>
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          fill="#FBE596"
          d="M181.562,632.335c-1.778-1.314-3.48-2.746-5.352-3.907
			c-2.164-1.344-3.775-4.882-7.244-1.862c-0.795,0.695-1.832-0.613-2.584-1.314c-6.85-6.347-14.422-11.723-23.076-15.188
			c-4.949-1.979-8.153-6.781-13.602-7.678c0-1.048,0-2.098,0-3.144c6.551-3.451,12.026-8.452,18.109-12.578
			c0.085-0.164,0.168-0.326,0.25-0.493c2.875-2.424,6.388-3.301,9.947-4.03c1.364-0.279,2.641,0.002,3.314,1.372
			c0.606,1.236-0.069,2.262-0.85,3.164c-1.902,2.198-4.413,3.546-6.884,4.975c-0.774,0.445-2.721,0.565-1.254,2.336
			c3.117,2.367,6.468,4.329,10.054,5.831c4.315,1.809,7.22,5.38,10.705,8.227c0.929,0.759,1.435,1.837,1.018,3.122
			c-1.395,2.385-3.258,2.281-5.31,0.955c-2.413-1.562-4.771-3.209-7.174-4.782c-6.338-4.156-13.208-4.502-20.267-3.44
			c8.676,3.7,17.983,6.034,26.339,10.746c1.716,0.966,3.249,2.226,5.32-0.304c1.409-1.722,2.946,0.296,3.645,1.94
			c0.517,1.213-0.131,3.035,1.887,3.423c2.878,1.452,5.666,3.091,8.771,4.055c2.379,1.419,5.455,1.829,6.811,4.721
			c0.43,1.974-0.112,3.601-1.851,4.676c-1.656,1.021-2.931,0.081-4.121-0.989C185.937,631.021,183.781,632.933,181.562,632.335z"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          fill="#FDEFAC"
          d="M555.809,42.63c9.132,15.498,13.934,32.286,14.917,50.201
			c0.326,5.917-3.505,10.869-3.925,16.617c-0.639,1.126-0.697,2.489-1.464,3.565c-1.263,1.776-2.729,3.318-5.143,2.509
			c-2.52-0.845-3.229-2.923-2.73-5.318c0.845-4.055,1.749-8.094,2.421-12.184c0.169-1.03,0.286-2.043,0.284-3.074
			c-0.07-0.618-0.065-1.239-0.037-1.857c0.135-1.361,0.087-2.709-0.364-4.019c-0.403-5.546-1.01-11.066-2.469-16.456
			c-0.219-0.806,0.21-2.159-0.798-2.268c-0.57-0.062-0.725,1.502-1.372,2.172c-1.652,1.682-3.536,2.185-5.523,0.772
			c-2.064-1.467-1.836-3.447-0.839-5.469c0.877-1.298,1.482-2.984,2.681-3.827c4.313-3.035,3.553-6.841,3.144-11.35
			c-0.282-3.135,0.771-6.626,0.432-10.015C555.284,42.63,555.546,42.63,555.809,42.63z"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          fill="#D5CDD8"
          d="M662.744,531.646c-1.444,3.178-1.68,5.337,2.587,6.98
			c8.895,3.433,11.529,10.887,7.673,19.69c-0.442,1.01-0.905,1.893,0.373,2.661c1.097,0.659,2.073,1.798,3.485,0.774
			c0.733-0.531,1.401-1.171,2.037-1.82c5.583-5.713,8.668-5.876,15.276-0.786c0,0.523,0,1.05,0,1.569
			c-3.416,3.389-7.807,3.15-12.084,3.147c-2.697-0.002-4.81-0.038-2.384,3.48c2.945,4.269,1.507,7.898-2.047,11.018
			c-2.104,1.844-3.034,3.52-1.104,6.338c3.785,5.519,1.938,11.231-4.299,14.546c-0.883,1.096-1.779,2.168-3.284,2.411
			c-3.33-0.63-5.026,0.625-6.392,3.923c-3.548,8.551-8.761,10.7-17.599,8.082c-0.848-0.248-1.801-0.148-2.706-0.214
			c-1.944,0.762-2.15-0.557-2.315-1.925c0.5-2.722,2.485-3.088,4.741-2.874c1.819,0.173,3.622,0.541,5.466,0.441
			c3.433-0.182,6.346-1.349,8.49-4.186c2.765-3.657,5.851-6.984,9.388-9.963c4.013-3.378,4.702-6.372,2.431-11.177
			c-1.628-3.439-1.074-6.094,0.93-9.092c3.801-5.682,3.571-6.96-1.605-11.561c-1.931-1.714-2.368-3.404-1.564-5.88
			c2.781-8.589,0.524-13.441-7.905-17.242c-2.88-1.296-4.583-2.986-3.316-6.375c0.552-1.476-0.02-2.802-0.688-4.12
			c-0.636-1.255-1.567-2.508-0.552-4.019c2.003-1.561,4.028-1.711,6.083-0.118c0.266,1.062,0.532,2.12,0.799,3.183
			C662.331,529.587,663.22,530.599,662.744,531.646z"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          fill="#FDE6D9"
          d="M277.497,554.408c-5.498,1.657-10.664-0.777-15.966-1.392
			c-1.81-0.212-2.529-2.092-2.844-3.833c-1.252-6.923-3.821-8.271-10.957-5.721c-2.073,0.04-4.114,0.469-6.225,0
			c-9.465-2.094-13.588-7.92-12.873-18.357c1.035-2.504-0.454-4.979-0.127-7.476c1.388-1.327,3.23-0.697,4.845-1.071
			c0.945-0.222,1.678,0.661,1.42,1.394c-0.812,2.314,1.056,4.582,0.103,6.907c1.46-3.529,4.531-6.208,5.192-10.163
			c0.263-1.58,0.671-3.216-1.886-1.526c-1.683,1.111-2.044-0.106-1.921-1.516c0.219-2.496,2.397-3.906,5.48-3.826
			c3.165,0.079,3.814,3.391,6.103,4.663c0.71,0.397-0.354,1.976-1.357,2.38c-2.676,1.086-3.033,2.467-0.981,4.575
			c0.541,0.557,0.194,1.511,0.004,2.489c2.199-2.94,2.008-6.517,3.355-9.556c0.917-2.065,1.772-3.913,4.588-2.654
			c1.021,0.454,2.054-0.032,3.063-0.304c1.443-0.388,2.975-0.7,3.788,0.963c0.805,1.649-0.747,2.245-1.692,3.073
			c-4.336,3.811-7.494,8.101-9.368,12.723c0.635-0.736,0.723-1.657,1.044-2.479c0.56-1.426,0.682-3.324,2.716-3.391
			c1.844-0.058,3.455,1.03,4.03,2.654c0.848,2.384,1.844,1.983,3.306,0.745c0.8-0.675,1.615-1.33,2.412-2.012
			c0.98-0.84,1.937-2.198,3.299-1.8c1.689,0.491,0.904,2.151,0.95,3.383c0.078,2.035,0.909,0.843,1.566,0.346
			c2.345-1.771,3.452-1.062,3.038,1.726c-0.421,2.827,1.035,2.482,2.727,1.836c1.75-0.67,2.949-2.153,4.461-3.178
			c1.126-0.764,2.476-1.417,3.473-0.028c0.851,1.185-0.13,2.202-0.96,3.064c-0.454,0.474-1.044,0.785-1.575,1.165
			c-0.646,0.468-1.299,0.979-1.104,1.897c0.205,0.969,1.032,1.121,1.833,1.091c1.315-0.056,2.508,0.367,2.898,1.576
			c0.932,2.902,2.682,3.98,5.75,4.686c1.965,0.452,3.623,0.795,5.501,0.312c3.544-0.908,4.958-4.281,3.314-7.627
			c-0.384-0.786-0.8-0.412-1.229-0.31c-1.4,0.335-2.591,0.07-3.521-1.087c-0.41-0.514-0.634-0.773-1.509-0.343
			c-2.645,1.299-3.514,0.607-3.112-2.351c0.299-2.198,1.225-4.278,1.323-6.532c0.056-1.325,0.306-2.673-1.604-1.05
			c-2.766,2.352-4.896-0.589-6.915-1.43c-1.523-0.638,0.086-3.056,1.032-4.412c1.796-2.578,2.889-5.24,2.107-8.462
			c-0.247-1.014,0.009-2.075,0.492-3.051c0.528-1.06,2.084-2.163,0.298-3.296c-1.39-0.883-2.673-2.9-4.739-0.972
			c-0.578,0.539-1.411,0.812-2.143,0.208c-0.851-0.704-0.654-1.676-0.37-2.547c0.732-2.229,1.981-3.909,4.511-4.392
			c2.984-0.573,5.387-3.484,5.522-6.511c0.023-0.495-0.095-0.998-0.056-1.5c-0.459,0.354-0.181,0.906-0.35,1.341
			c-0.644,1.673-1.973,2.015-3.516,1.711c-1.726-0.338-2.348-1.496-2.038-3.199c0.507-2.77-0.107-3.423-3.039-2.324
			c-3.104,1.161-5.712,1.849-5.648,6.1c0.04,2.744-1.582,3.077-4.37,1.347c-0.768-0.477-0.815,0.301-1.041,0.573
			c-2.163,2.628-4.158,4.9-8.242,2.875c-1.678-0.828-4.024,1.059-6.408,0.074c-0.564-0.234-2.002,3.489-3.375,5.217
			c-0.761,0.96,0.343,1.916,1.039,2.687c0.876,0.972,1.87,1.837,2.636,2.906c0.972,1.355,1.032,2.708-0.245,3.869
			c-1.182,1.079-2.572,1.606-3.68-0.016c-1.299-1.893-2.097-0.512-3.089,0.263c-3.274,2.562-8.639,2.598-11.945,0.4
			c-3.741-2.492-3.81-5.977,0.354-11.135c1.937-2.4,4.055-4.278,3.907-7.622c-0.04-0.904,0.357-1.924,1.086-2.459
			c4.186-3.077,6.356-8.374,11.604-10.313c2.347-0.866,3.143-3.415,4.597-5.23c0.729-0.907,0.025-1.517-0.914-1.459
			c-2.094,0.129-4.183,0.298-5.544,2.335c-0.953,1.427-2.259,2.546-3.782,3.37c-0.71,0.379-1.499,0.626-2.21,0.057
			c-0.683-0.543-0.968-1.5-0.52-2.117c1.535-2.124,1.76-5.241,4.908-6.174c2.834-0.839,3.943-2.675,2.962-5.728
			c-1.136-3.534,0.987-6.298,2.692-9.055c0.524-0.848,1.53-1.016,2.208,0.124c0.919,2.172-0.427,4.004-1.23,6.254
			c2.262-1.254,3.028-3.354,4.604-4.677c2.492-2.086,4.194-1.551,5.159,1.583c0.956,3.104-0.159,5.951-1.078,8.794
			c-0.644,1.988-0.995,3.801,0.811,5.356c-0.09,3.649-3.073,5.937-4.271,9.062c-0.49,1.28-1.936,2.592-0.734,3.941
			c1.358,1.523,2.903,0.092,4.327-0.326c1.331-0.397,2.047-1.555,2.93-2.515c2.777-3.016,6.063-5.281,10.322-4.443
			c3.527,0.694,6.332-1.077,8.59-2.744c3.469-2.557,8.495-3.047,10.51-7.62c0.288-0.655,1.315-0.815,2.081-0.979
			c2.849-0.598,4.258-2.348,4.301-5.24c0.03-2.009,0.578-3.861,1.671-5.556c0.351-0.547,0.862-1.197,0.71-1.788
			c-1.289-5.044,1.385-8.81,3.922-12.647c1.19-1.803,1.82-3.719,1.158-5.942c-0.381-1.278-0.079-2.632,0.369-3.855
			c0.803-2.188,0.712-4.341,0.094-6.525c-0.339-3.648,0.248-7.006,3.114-9.597c1.689-1.526,0.952-3.32,0.653-5.081
			c-0.001-0.844,0.375-1.474,1.099-1.897c3.838,2.032,4.408,5.039,1.798,8.942c-2.664,3.982-3.025,8.139-0.693,12.43
			c0.313,0.577,0.517,1.322,1.121,1.562c4.039,1.615,3.308,3.5,0.602,5.907c-1.932,1.722-1.732,4.583-3.293,6.835
			c2.256,0.006,3.293-1.518,4.707-2.316c1.546-0.877,2.447-0.49,2.687,1.354c0.117,0.902-0.812,2.821,0.145,2.497
			c1.768-0.598,3.482,1.148,5.189-0.528c3.206-3.15,5.548-2.158,7.775,1.886c2.813,5.108,4.571,10.729,8.036,15.537
			c1.705,2.367,2.873,5.361,2.8,8.394c-0.063,2.768,1.115,3.6,3.527,3.222c2.559-0.4,3.934,0.844,4.782,3.046
			c1.728,4.478,3.897,5.399,8.706,2.397c3.438-2.146,7.281-4.555,9.161-8.806c0.918-2.072,1.817-4.004,1.747-6.311
			c-0.08-2.593,0.794-4.934,2.39-6.92c5.507-6.865,6.289-14.648,4.637-22.94c-0.256-1.283-0.87-2.706-0.205-3.845
			c1.604-2.753,0.481-5.281-0.168-7.921c-0.446-1.812-1.116-3.167-3.275-3.212c-1.084-0.024-2.118-0.499-2.587-1.532
			c-1.564-3.44-4.53-5.025-7.863-6.22c-1.52-0.544-1.272-1.85-0.878-2.976c1.214-3.477-0.905-5.531-3.405-6.878
			c-1.917-1.03-2.302-1.891-1.206-3.573c0.795-1.219,1.316-2.546,1.804-3.906c0.768-2.143,2.543-3.234,4.608-2.875
			c3.282,0.569,6.537,0.792,9.845,0.636c0.851,2.102-1.596,4.492,0.324,6.357c1.872,1.813,4.249-0.067,6.389,0.338
			c1.291,0.247,13.528,6.213,14.437,7.205c1.265,1.382,2.352,2.962,2.288,4.846c-0.122,3.509,0.924,6.091,4.494,7.278
			c0.724,0.239,1.195,0.984,1.507,1.734c0.368,0.999,0.583,2.115,0.153,3.036c-2.27,4.867-2.337,10.588-5.975,14.954
			c-0.916,1.103-2.674,2.434-1.151,4.365c2.124,1.784,5.246,0.861,7.343,2.711c1.862,2.202,0.505,4.184-0.479,6.137
			c-0.645,1.276-1.491,2.454-2.396,3.574c-3.943,5.359-7.874,10.664-13.152,14.932c-3.388,2.741-6.638,5.551-10.805,6.922
			c-5.22,1.722-9.01,5.746-13.733,8.258c-1.671,0.89-3.269,1.236-5.15,1.417c-6.28,0.602-12.509,0.108-18.752-0.275
			c-2.09,0.506-2.289,2.389-2.672,3.961c-0.9,3.683-2.572,6.833-5.606,9.161c-2.408,1.846-3.151,4.126-2.761,7.179
			c0.409,3.217,0.139,6.509,0.718,9.744c0.353,1.978,0.045,3.956-0.957,5.697c-4.75,8.258-8.405,17.111-13.658,25.099
			c-1.169,1.778-2.413,3.415-4.538,4.104c-2.409,0.579-4.816,1.188-7.21,1.844c-1.23,0.334-3.318,0.165-3.442,1.323
			c-0.165,1.52,0.822,3.525,2.153,4.296c2.019,1.168,1.31,2.277,1.321,4.031c0.016,2.962-1.486,6.069,0.375,9.015
			c1.023,1.623,1.765,2.946,3.762,1.03c1.387-1.327,2.741-1.129,2.954,1.018c0.284,2.863,3.66,5.671,0.218,8.637
			c-0.333,0.284-0.267,0.774,0.165,0.919c2.932,0.979,2.002,2.796,1.084,4.666c-0.563,1.146,0.283,1.548,1.105,2.083
			c3.705,2.419,3.69,2.712,0.062,5.16c-0.312,0.207-0.697,0.359-0.747,0.804c5.926-0.032,7.515,1.768,6.428,7.353
			c-0.15,0.77-0.619,1.828-0.058,2.253c2.235,1.692,2.66,3.637,1.89,6.294c-0.322,1.107,1.025,1.646,2.228,1.466
			c0.903-0.133,1.804-0.402,2.721-0.218c2.175,0.437,2.508,1.636,1.297,3.373c-0.535,0.765-1.339,1.225-2.14,1.68
			c-5.234,2.971-5.232,2.974-2.694,8.176c0.036,0.388-0.05,0.746-0.25,1.075c-2.586,0.928-3.379-1.29-4.625-2.665
			c-6.607-7.307-12.289-15.28-17.151-23.843c-1.889-3.843-4.327-7.171-8.477-8.866c-0.888-2.221-3.044-3.871-2.924-6.538
			c0.728-0.513,1.441-0.358,2.208-0.029c3.317,1.417,3.323,1.405,6.887-0.105c-1.075-0.462-2.026,0.032-2.991,0.252
			c-1.132,0.259-2.362,0.871-3.197-0.429c-0.848-1.318-1.031-3.122,0.105-3.997c1.681-1.297,1.135-2.124,0.224-3.385
			c-0.61-0.843-1.033-1.988-0.851-2.973c0.949-5.128,0.102-10.358,0.924-15.462c0.435-2.702,0.387-4.728-2.938-5.107
			c-0.509-0.061-0.03,0.253-0.366-0.087c-4.21-4.263-6.882-0.021-9.914,1.83C280.235,552.444,279.255,553.994,277.497,554.408z"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          fill="#FDF0DD"
          d="M315.246,404.281c-0.262,0.534-0.524,1.071-0.787,1.606
			c-0.43,1.779-1.958,2.396-3.341,3.171c-1.496,0.716-3.054,0.763-4.42-0.132c-2.295-1.507-5.171-1.482-7.445-2.991
			c-0.469-0.765-0.491-1.545-0.08-2.339c0.411-0.499,0.961-0.678,1.579-0.694c3.833,0.791,7.614,0.819,11.242-0.927
			c1.857-0.999,2.889-2.339,2.777-4.654c-0.109-2.234,0.884-4.261,2.231-6.066c0.687-0.419,1.499-0.444,2.23-0.707
			c2.705-0.976,2.894-2.153,0.661-4.111c-0.785-0.688-1.697-1.222-2.503-1.89c-5.635-4.674-6.058-10.791-0.998-16.257
			c2.044-2.207,3.994-4.488,5.957-6.762c1.607-1.866,1.212-3.295-0.627-4.556c-1.827-1.258-3.855-2.179-5.683-3.461
			c-2.773-1.949-4.861-4.329-5.744-7.656c-0.134-0.505-0.16-1.151-0.532-1.455c-3.386-2.764-2.038-5.832-0.51-8.804
			c1.001-1.945-0.007-1.379-1.115-1.142c-1.337,0.281-1.708-0.938-2.333-1.728h0.001c-0.222-0.141-0.615-0.397-0.576-0.477
			c1.446-3.013-1.409-4.536-2.333-6.702c-1.059-2.489-1.846-4.843,0.118-7.31c0.97-1.216,0.736-2.709,0.215-4.111
			c-0.275-2.239,2.997-2.441,2.695-4.703c0.131-0.931,0.54-1.705,1.276-2.293c2.462-0.787,2.815-3.282,4.076-5.045
			c2.688,0.506,3.804-1.324,4.84-3.266c2.465-0.841,4.56,1.737,7.039,0.745c2.792-0.444,5.235-1.483,6.894-3.912
			c1.969-0.186,2.374,2.77,4.517,2.242c0.953,0.164,1.828,0.493,2.549,1.152c5.52,5.627,9.96,12.019,13.167,19.219
			c0.628,1.415,1.024,1.577,2.062,0.477c0.749-0.793,1.782-1.027,2.854-1.107c4.704-0.349,6.953,1.625,6.655,6.321
			c-0.328,5.195-1.06,10.329-3.148,15.211c-1.569,3.669-1.157,4.367,2.553,5.812c2.575,1.008,4.577,3.602,7.803,2.828
			c0.391,0.115,0.735,0.307,1.049,0.566c2.894,4.801,0.598,15.746-3.868,18.506c-0.185,0.125-0.523,0.192-0.578,0.346
			c-0.107,0.305-0.055,0.259,0.146-0.086c0.258-0.451-0.083,0.182,0.322-0.198c1.102-0.464,2.104-0.384,3.035,0.339
			c1.782,1.717,4.042,2.866,5.642,4.82c0.952,1.167,1.966,2.247,0.815,3.775c-1.089,1.446-2.445,1.859-4.099,0.835
			c-0.46-0.286-0.925-1.18-1.552-0.391c-0.301,0.378,0.079,0.999,0.205,1.503c0.923,3.653,1.399,3.918,4.504,2.205
			c1.718-0.947,2.468-0.049,2.839,1.394c0.791,3.068-2.461,6.586-5.655,6.168c-1.035-0.138-2.046-0.42-3.066-0.65
			c-2.793-0.63-4.885,0.368-6.054,2.969c-1.077,2.389-2.132,4.773-3.856,6.788c-0.888,0.771-1.825,1.482-2.842,2.067
			c-0.694,0.399-1.632,0.742-2.164,0.019c-2.625-3.573-6.82-2.332-10.245-3.397c-1.542-0.479-2.199,1.12-2.761,2.281
			c-1.505,3.11-3.989,5.051-7.094,6.414c-1.449,0.637-2.669,0.822-4.013-0.189c-0.595-0.448-1.249-1.88-2.124-0.663
			c-0.651,0.906-0.093,2.224,0.815,2.761c2.354,1.389,4.09,3.868,6.74,4.536c2.95,0.742,2.822,2.188,1.726,4.255
			c-1.713,3.237-3.642,6.337-5.843,9.274c-0.375,0.499-1.567,1.746,0.559,1.458c0.771-0.103,1.643-0.428,1.929,0.639
			c0.224,0.835-0.296,1.491-0.875,2.058c-1.169,1.142-3.052,1.567-3.756,4.034c2.822-1.907,4.892-1.148,6.377,1.062
			c1.364,2.031,0.309,3.698-1.259,5.128c-4.54-0.391-9.305,0.575-13.397-2.499c-1.048-0.787-2.218-1.5-1.231-2.684
			c3.755-4.508-0.197-7.933-1.97-11.103c-1.817-3.249-1.979-5.765-0.132-8.645c1.592-2.485,1.758-5.062,1.08-7.86
			C317.917,403.616,316.812,403.552,315.246,404.281z"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          fill="#FDF6E2"
          d="M255.497,450.657c-2.63,2.146-4.758,5.349-3.383,8.191
			c1.719,3.559,0.369,5.861-2.03,6.738c-2.727,0.997-3.646,3.3-5.513,4.84c-0.635,0.521-1.257,1.595-0.461,2.43
			c0.776,0.812,1.366-0.149,1.945-0.588c2.855-2.172,4.376-6.034,8.994-6.049c1.603-0.004,2.338,0.019,2.959,1.323
			c0.534,1.12,0.604,1.731-0.699,2.822c-5.721,4.789-11.16,9.913-16.695,14.923c-0.093,0.084-0.204,0.233-0.188,0.336
			c0.96,6.628-5.87,9.362-7.39,14.776c-0.656,2.337,3.45,7.575,5.581,6.356c2.771-1.587,6.756,0.732,8.608-3.27
			c0.89-1.923,2.737-2.228,3.281,0.557c0.197,1,0.05,2.021,1.417,1.346c1.006-0.499,2.258-1.266,1.504-2.705
			c-0.467-0.896-1.357-1.574-2.087-2.323c-2.177-2.236-5.808-4.251-0.549-7.209c0.777-0.439,0.614-2.409,1.082-3.594
			c0.261-0.661,0.819-1.449,1.429-1.677c1.008-0.374,1.229,0.576,1.422,1.37c0.088,0.363,0.314,0.697,0.479,1.043
			c3.429-3.282,9.658,3.093,12.194-3.567c0.478-1.255,1.729-3.135,3.018-2.55c1.918,0.873-0.328,2.044-0.539,3.135
			c-0.066,0.35,0.042,0.73,0.068,1.095c0.429-0.147,0.968-0.184,1.262-0.469c0.548-0.539,1.471-1.361,1.345-1.82
			c-2.355-8.601,7.041-5.51,9.387-9.427c0.306-0.507,1.98,2.932,1.689,4.643c-0.181,1.068-2.047,2.352,0.342,3.014
			c1.878,0.519,2.931-0.272,3.178-2.31c0.083-0.676,0.761-1.394,1.35-1.858c0.613-0.482,0.53-1.997,1.916-1.396
			c-0.305,0.653-0.838,1.287-0.881,1.955c-0.22,3.372-0.868,6.513-3.283,9.12c-0.947,1.023-1.179,1.804-3.231,1.321
			c-3.005-0.713-4.055,2.368-4.293,4.836c-0.124,1.287,0.959,0.41,1.569,0.027c0.896-0.564,2.137-3.463,2.388-1.536
			c0.385,2.93,9.885,1.167,3.324,6.752c-0.411,0.353-0.746,1.276-0.581,1.748c1.646,4.725-0.62,8.31-3.268,11.813
			c-0.48,0.635-1.004,1.409-0.279,2.063c0.256,0.23,1.178-0.045,1.645-0.337c0.798-0.492,1.804-0.619,2.025,0.176
			c1.185,4.28,2.122,0.84,2.96-0.099c0.627-0.7,1.134-1.651,2.166-1.276c1.515,0.548,0.609,1.688,0.414,2.644
			c-0.597,2.924-1.209,5.846-1.719,8.786c-0.118,0.675-0.38,1.687,0.697,1.891c0.19,0.039,0.654-0.899,0.848-1.438
			c0.278-0.771,0.559-1.557,1.499-1.561c0.939-0.004,1.269,0.66,1.376,1.549c0.39,3.22,2.142,2.17,3.731,1.073
			c0.691-0.477,1.115-1.832,2.128-0.967c1.023,0.874-0.461,1.525-0.613,2.332c-0.145,0.762-0.315,1.561-0.228,2.313
			c0.232,1.995,2.164,5.003,0.707,5.726c-2.868,1.421-6.981,2.685-9.575,1.6c-2.343-0.975-8.005,0.358-7.125-5.521
			c0.09-0.604-0.435-2.446-2.367-1.264c-1.9,1.165-2.866-0.561-3.419-2.019c-0.63-1.667,0.261-2.846,1.924-3.142
			c1.493-0.265,2.22-1.26,2.856-2.389c0.095-0.169-0.419-0.979-0.704-1.009c-1.372-0.153-1.93,1.136-2.858,1.799
			c-1.478,1.052-2.999,2.048-4.528,3.026c-1.342,0.861-2.57,0.22-3.644-0.53c-1.752-1.227,0.135-1.878,0.515-2.797
			c0.228-0.547,0.52-1.498,0.274-1.733c-0.98-0.936-1.15,0.387-1.67,0.79c-1.07,0.828-1.631,2.847-3.186,2.22
			c-0.489-0.196-0.091-2.648-0.064-4.065c0.015-0.724,0.105-1.444,0.162-2.162c-0.629,0.405-1.297,0.764-1.879,1.231
			c-1.219,0.975-2.343,2.072-3.596,3c-2.936,2.186-2.933,2.146-3.997-0.928c-0.573-1.651-2.227-2.315-3.57-2.693
			c-1.342-0.375-1.595,1.417-2.095,2.414c-0.465,0.929-0.649,2.013-1.19,2.883c-0.75,1.212-1.684,2.816-3.161,2.636
			c-2.032-0.242-0.839-1.306-0.033-1.951c0.502-0.404,1.492-0.537,1.642-0.994c1.528-4.667,5.01-8.011,7.702-11.901
			c1.073-1.554,4.631-1.705,3.485-3.639c-1.126-1.898-3.711,1.327-5.021,0.915c-7.247-2.278-4.449,3.565-5.512,6.33
			c-0.651,1.694-1.444,3.358-2.397,4.897c-0.51,0.822-1.922,2.057-2.196,1.894c-1.263-0.762,0.17-1.741,0.178-2.685
			c0.001-0.251,0.262-0.63,0.165-0.741c-3.648-4.169-1.778-6.111,2.452-8.328c0.915-0.477-1.622-0.729-2.745-0.291
			c-0.323,0.126-0.646,0.251-0.997,0.386c-0.547-1.392,2.991-3.29-0.383-3.978c-2.9-0.594-5.985-0.569-5.885,3.875
			c1.182,0.103,0.984-1.315,1.699-1.681c0.887-0.453,1.851-0.758,2.779-1.125c-0.061,0.865-0.198,1.735-0.171,2.601
			c0.181,5.547-3.992,9.139-6.153,13.628c-0.41,0.849-1.576,1.919-2.567,1.119c-1.313-1.062,0.398-1.676,0.83-2.47
			c1.259-2.313-1.547-4.978,0.632-7.204c-1.794,0.111-3.61,0.738-5.36-0.222c-1.798,0.711-2.941-0.495-4.096-1.479
			c-2.081-1.775-3.667-4.006-5.235-6.214c-1.692-2.389-1.277-4.915,0.056-7.324c1.126-2.035,2.295-4.044,2.951-6.296
			c-0.278-2.365-2.166-2.707-3.958-3.04c-1.267-0.235-2.375-0.787-2.702-1.961c-0.958-3.444,4.793-4.788,2.687-8.925
			c-2.138-4.201,0.765-5.704,4.292-6.771c0.935-0.285,1.702-1.546,2.275-2.518c2.02-3.435,2.756-7.969,8.205-8.162
			c1.458-0.05,2.975-1.655,2.42-3.44c-0.678-2.182-2.493-1.123-3.942-0.781c-1.509,0.362-3.015,0.74-4.521,1.107
			c1.471-2.859,4.993-5.07,7.679-4.674c2.199,0.329,4.847,1.612,4.464,3.607c-0.566,2.957,4.837,7.125-1.839,8.862
			c3.46-0.921,3.596-0.884,4.477-3.846c0.571-1.92,2.127-2.525,3.513-3.444c1.127-0.752,2.302-1.312,0.328-2.65
			c-1.507-1.02-1.019-2.491,0.042-3.742c0.846-0.999,1.691-1.999,2.357-3.129c1.651-2.789,1.424-3.17-1.775-3.921
			c-2.552-0.602-2.589-3.337-3.942-4.968c-0.098-1.341,0.697-2.23,1.645-2.947c5.139-3.898,7.124-9.913,10.038-15.248
			c1.184-2.166,0.417-4.882-0.91-7.125c-1.494-2.517-4.105-2.854-6.638-3.621c-2.204-0.671-3.792-3.051-6.643-0.951
			c-2.097,1.548-4.12-0.886-6.232-1.289c-1.022-0.191-1.536-1.042-1.779,0.971c-0.66,5.425-3.854,9.874-6.564,14.458
			c-0.521,0.885-1.942,2.025-2.516,1.415c-3.229-3.443-4.853,0.665-6.935,1.491c-3.909,1.544-3.687,1.822-4.628-2.318
			c-0.519-2.267-2.188-4.267-0.528-6.849c0.544-0.844-1.155-2.891-1.552-2.724c-1.841,0.78-2.908-0.83-4.395-1.018
			c-3.573-0.448-4.036-1.178-2.767-4.546c0.943-2.508,0.344-3.394-2.255-3.32c-4.95,0.141-8.592-1.836-10.381-6.664
			c0.036-1.155,0.62-1.948,1.661-2.389c4.582-1.939,8.948-4.226,12.49-7.822c0.977,0.591,1.766,1.369,2.356,2.348h0.002
			c1.433,0.684,3.783,0.762,3.179,3.119c-0.603,2.357-2.612,2.614-4.772,2.073c-0.779-0.192-1.911-1.069-2.271,0.31
			c-0.273,1.055,0.841,1.545,1.715,1.813c3.956,1.229,8.282,0.94,12.057,2.998c0.694,0.376,1.523-0.237,2.118-0.765
			c1.942-1.718,3.863-3.257,6.572-1.303c0.758,0.547,1.496-0.013,2.054-0.62c3.214-3.497,7.225-2.856,10.938-1.564
			c3.586,1.25,6.965,0.128,10.432,0.128c1.113-0.003,1.361-1.097,1.697-1.933c0.88-2.191,1.844-4.356,3.007-6.391
			c1.331-2.333,3.989-2.889,5.584-1.431c3.603,3.295,3.721,3.27,6.435-1.35c4.921-1.283,7.069-0.119,8.807,4.098
			c1.047,2.543,1.922,4.978,1.413,7.738c0.146,3.081,1.463,6.629-3.367,7.588c-1.109,0.22-0.598,1.27-0.089,1.836
			c1.714,1.916,2.895,4.325,5.246,5.648c0.821,0.477,1.763,0.481,2.639,0.72c2.154,0.581,3.107,1.871,2.212,4.09
			c-2.791,6.923-5.374,13.975-11.917,18.488c-2.056,1.42-2.996,3.982-5.222,5.239c-1.504,0.342-2.387-0.726-3.428-1.487
			c-2.5-1.829-3.042-1.52-3.648,1.577C257.046,447.8,258.156,450.061,255.497,450.657z"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          fill="#FDE8D1"
          d="M274.396,402.704c-0.827-0.127-1.956-0.682-1.399-1.363
			c1.587-1.936-0.143-3.324-0.211-4.833c-0.271-5.838-4.279-5.157-7.863-5.598c-2.905,1.986-5.721,0.089-8.546-0.282
			c-1.203-0.161-1.076-1.19-0.463-2.102c0.509-0.761,1.598-0.86,1.955-1.788c-1.582-1.526-4.229,1.363-5.499-1.347
			c-4.37-4.747-7.624-10.204-10.398-16c-1.243-2.595-2.497-4.329-6.46-4.018c-4.008,0.313-6.46-3.74-7.896-7.511
			c-1.222-3.206-0.203-5.893,2.972-7.044c2.701-0.982,4.322-3.09,6.382-4.744c0.431-0.349,0.576-1.049,0.853-1.586
			c-0.48,0.058-0.973,0.058-1.437,0.176c-1.751,0.454-3.5,0.921-5.238,1.426c-1.239,0.359-2.161,2.444-3.72,0.905
			c-1.203-1.187-1.187-2.876-0.938-4.452c0.529-3.33,2.61-5.947,4.244-8.756c0.271-0.464,1.17-0.992,1.6-0.87
			c7.237,2.098,15.373-0.653,22.13,4.337c3.078,2.271,6.979,1.884,10.649,1.763c0.429-0.016,0.851-0.272,1.276-0.413
			c-0.38-0.307-0.715-0.767-1.148-0.896c-3.125-0.918-6.276-1.747-9.405-2.658c-0.446-0.131-0.806-0.56-1.205-0.851
			c0.307-0.345,0.58-0.96,0.924-1.001c5.179-0.643,10.345-0.896,15.508-2.246c3.084-0.806,4.598,3.819,7.028,5.771
			c9.207,7.403,17.673,15.486,15.942,29.008c0.363,0.045-0.401,1.392,0.926,0.56c4.017-2.512,9-1.277,11.624,2.591
			c2.841,4.187,4.88,8.583,4.301,13.871c-0.356,3.266,0.151,6.496,1.144,9.648c-1.633,4.808-5.571,5.892-9.166,2.527
			c-0.573-0.538-1.126-1.095-1.723-1.589c-1.441-1.296-2.497-2.928-3.641-4.473c-1.724-2.332-3.592-2.739-5.811-0.736
			c-1.309,3.173-3.02,5.857-6.998,5.793c-2.633,0.134-0.887,2.207-1.439,3.26c-0.712,0.908-1.567,1.734-1.769,2.956
			c-0.59,1.049-0.938,2.576-2.682,1.398c-0.48-0.879-0.258-1.99-0.936-2.901c-0.263,1.113,0.512,2.018,0.318,3.036
			c-0.101,0.614-0.357,1.126-0.893,1.475C276.224,403.625,275.261,403.472,274.396,402.704z"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          fill="#FCEDDD"
          d="M516.589,163.66c1.018,2.498,2.105,4.971,3.038,7.501
			c2.041,5.534,4.159,6.234,8.657,2.754c1.195,0.86,1.922,2.159,2.979,3.154c4.031,5.445,8.456,10.61,11.098,16.989
			c1.603,3.868,2.447,8.369,7.262,10.032c0.716,0.247,1.327,1.306,0.547,2.307c-1.64,0.531-1.348,1.104-0.494,2.447
			c4.852,7.633,9.102,15.544,7.943,25.064c-0.155,1.292,1.079,4.51-2.749,2.998c-1.101-0.432-0.708,1.059-0.728,1.676
			c-0.349,11.705,0.864,23.144,5.701,33.989c0.626,1.401,1.33,2.869,0.96,4.526c-0.958,1.942-2.704,2.39-4.636,2.479
			c-6.271,0.349-11.669,3.075-16.842,6.328c-1.642,1.034-3.129,2.303-5.142,2.543c-1.582-0.041-2.097-1.171-2.562-2.438
			c-0.823-2.255-2.118-2.904-4.441-1.622c-1.896,1.049-4.106,1.897-5.764-0.637c-0.358-0.927-0.344-1.858-0.031-2.786
			c1.528-2.79,4.271-4.574,5.939-7.274c6.482-10.502,10.407-21.942,12.503-34.03c0.514-2.956,1.645-5.829,1.401-8.896
			c-0.034-0.419,0.124-0.886-0.177-1.273c-0.068,0.381,0.053,0.774-0.055,1.142c-1.038,3.586-5.498,6.414-9.087,5.742
			c-1.27-0.237-2.005-1.187-2.613-2.217c-1.011-1.702-1.322-3.685-2.136-5.477c-0.479-1.053,0.022-1.919,0.967-2.7
			c3.351-2.761,3.403-6.757,0.114-9.405c-1.288-1.04-1.368-2.108-0.667-3.429c0.616-1.161,1.59-2.095,2.179-3.263
			c0.973-1.932,0.913-3.401-1.559-4.265c-3.854-1.347-6.55-8.839-4.639-12.45c2.145-4.056,6.28-4.188,8.998-0.144
			c1.884,2.805,2.783,6.104,3.907,9.277c-1.4-4.789-3.707-9.181-5.771-13.682c-0.982-2.14-2.189-3.147-4.542-1.631
			c-2.938,1.894-6.902,0.812-9.753,3.538c-1.208,1.158-4.576-2.239-4.127-4.904c0.54-3.199-0.825-4.948-3.146-6.506
			c-0.648-0.436-1.669-1.008-1.483-1.695c1.244-4.604-3.438-6.9-4.065-10.784c-0.28-1.734-1.516-3.083-3.44-3.346
			c-2.093-0.285-2.059,1.578-2.654,2.79c-0.462,0.938-1.166,1.382-2.236,1.049c-1.713-1.408-2.918-3.077-2.817-5.418
			c0.751-1.92,2.17-3.461,3.405-5.02c1.398-1.766,1.8-3.522,1.65-5.599c0.276-0.252,0.596-0.419,0.963-0.499
			c5.892,0.589,9.486,4.546,12.746,8.871C512.645,161.395,514.089,163.161,516.589,163.66z"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          fill="#FDE6D9"
          d="M242.913,443.577c2.271,1.123,2.509,5.934,6.939,3.525
			c0.894-0.489,0.832,1.992,0.644,3.23c-0.354,2.329-2.192,3.801-3.291,5.701c-0.468,0.809-2.15,0.96-1.428,2.396
			c0.683,1.359,1.582,1.143,2.896,0.575c1.305-0.565,1.238,0.944,0.621,1.203c-4.967,2.104-5.483,8.777-10.814,10.41
			c-1.081,0.33-1.908,2.853-2.916,1.158c-0.65-1.095,0.763-3.174,2.179-4.229c0.764-0.566,2.77,0.63,2.457-1.441
			c-0.213-1.416-1.511-0.856-2.551-0.859c-1.072-0.006-1.252-1.159-0.456-1.596c2.002-1.084,2.397-2.399,0.854-3.998
			c-1.651-1.709-3.653-2.62-5.979-1.187c-1.655,1.02-3.25,2.137-4.872,3.208c-0.688,0.342-1.387,1.001-2.061,0.977
			c-12.666-0.511-15.702,12.657-24.675,17.497c-2.502,1.35-4.018,3.174-7.137,1.646c-4.091-2.005-8.415-3.533-12.646-5.243
			c-2.133-0.864-2.777-2.236-1.601-4.312c1.577-2.779,0.798-5.084-1.035-7.548c-7.14-9.602-10.134-19.309-3.591-31.043
			c4.008-7.191,6.155-15.457,11.066-22.308c0.081,5.425,2.569,10.578,2.455,15.854c-0.094,4.344,2.971,5.462,5.071,7.657
			c1.828,1.914,4.341,2.199,6.467,0.711c3.116-2.178,5.51-1.612,7.759,1.129c1.243,1.514,2.864,1.664,4.627,1.203
			c1.137-0.297,2.515-0.956,3.407-0.192c2.873,2.464,6.126,4.04,9.631,5.382c1.533,0.589,2.619,2.596,1.898,4.305
			c-0.802,1.901,0.753,3.278,0.44,5.023c-0.232,1.291,0.971,1.18,1.925,1.174c3.057-0.027,4.538-2.342,6.177-4.303
			C237.466,446.78,239.769,444.646,242.913,443.577z"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          fill="#C2D9AC"
          d="M297.252,225.828c2.96-10.713,7.399-20.333,16.518-27.585
			c9.437-7.504,15.981-17.738,23.526-27.038c4.108-5.067,9.625-9.213,15.126-12.837c3.303-2.175,5.448-4.629,6.877-8.055
			c1.288-1.619,2.532-3.279,4.476-4.206c2.538-0.474,3.208,1.027,3.348,3.135c0.46,6.913,0.656,13.819-0.535,20.682
			c-1.927,4.59-6.169,7.251-9.256,10.879c-2.781,3.269-5.931,6.203-8.327,9.827c-3.261,4.939-4.41,10.531-5.392,16.2
			c-2.912,4.507-6.993,7.92-11.251,10.966c-2.979,2.131-5.857,4.204-8.008,7.178c-2.007,1.865-3.458-0.758-5.367-0.688
			c-4.007,0.153-8.029-0.183-11.955,0.953c-0.898,0.259-1.826,0.372-2.664-0.047c-2.142-1.066-1.266-0.308-0.796,0.979
			c0.576,1.574-0.877,1.91-2.081,2.006C299.592,228.333,298.385,227.12,297.252,225.828z"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          fill="#D0CE8D"
          d="M311.276,302.087c0.19,2.886,0.051,5.538-3.892,5.493
			c-3.002-1.171-5.046-3.631-7.286-5.73c-1.806-1.692-3.812-3.145-5.56-4.83c-2.669-2.575-4.629-2.156-6.672,0.496
			c-2.029,1.759-3.942,1.666-5.76-0.336c-0.521-0.576-1.21-1.452-1.862-1.27c-4.552,1.276-7.762-3.068-12.096-2.761
			c-1.52,0.109-3.012-0.671-4.25-1.695c-1.318-3.426-4.844-5.297-6.143-8.817c-0.524-1.419-2.046-0.592-3.135-0.265
			c-6.557,1.958-8.142,1.321-10.035-5.195c-1.011-3.48-3.02-5.87-5.536-8.278c-5.8-5.548-12.051-10.372-19.334-13.804
			c-1.427-0.675-2.87-1.449-3.729-2.802c-1.989-3.135-3.977-5.985-8.094-6.824c-1.938-0.393-1.99-2.866-2.15-4.689
			c-0.219-2.466,0.069-4.971-0.745-7.389c-0.413-1.226-1.04-2.77,0.312-3.596c1.317-0.803,2.425,0.349,3.353,1.314
			c0.271,0.281,0.585,0.521,0.914,0.732c2.194,1.814,4.365,3.641,5.722,6.242c1.548,2.965,4.649,2.937,7.335,3.691
			c1.386,0.39,2.833,0.198,4.243,0.409c2.275,0.704,4.487,0.611,6.889,0.365c4.807-0.499,9.142,1.414,13.078,4.136
			c0.458,0.908-0.096,1.459-0.716,1.983c-0.555,0.468-2.524,1.171,0.215,1.014c1.091-0.06,1.644,0.8,1.664,1.817
			c0.053,2.931,2.027,5.432,1.865,8.385c-0.097,1.769,1.197,1.669,2.313,1.993c2.271,0.653,3.28,1.711,1.021,3.698
			c-0.594,0.521-0.377,0.406,0.451,0.931c4.189,2.652,8.484,0.723,12.745,0.486c0.484-0.026,0.262,0.208,0.226-0.285
			c-0.213-2.952,2.078-3.295,4.152-3.372c2.068-0.074,3.952-1.667,6.113-0.684c7.631,3.704,12.471,10.15,16.572,17.204
			C298.3,288.176,304.527,295.328,311.276,302.087z"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          fill="#FDEFCC"
          d="M242.913,443.577c-3.413,3.73-7.664,6.597-10.596,10.901
			c-0.718,1.056-3.925,0.967-5.788,0.539c-1.692-0.391-0.146-2.275-0.397-3.339c-0.928-3.909,1.014-10.129-6.762-9.081
			c-0.843,0.114-1.755-0.557-2.209-1.813c-0.774-2.13-2.052-3.157-4.761-1.927c-1.991,0.906-4.618,1.808-6.2-1.735
			c-0.68-1.522-2.777-3.491-5.498-0.927c-2.133,2.008-5.659,2.691-7.745,0.029c-2.303-2.939-8.842-3.827-5.521-9.958
			c0.275-0.507,0.416-1.604,0.144-1.816c-4.839-3.752-2.038-8.649-2.065-13.111c0.803-2.874,2.121-5.49,3.939-7.854
			c2.94,1.395,3.673,5.928,8.071,5.476c0.835-0.085,1.968,0.02,2.963,0.043c1.28,0.028,2.72-0.315,3.527,1.104
			c0.709,1.245-0.152,2.263-0.701,3.268c-0.543,1-1.714,1.831-0.928,3.15c0.942,1.584,2.001,0.027,2.922,0.094
			c1.638,0.119,3.442,0.72,4.771,1.68c2.278,1.645,3.815,3.758,0.564,6.101c2.654,1.685,1.515,4.51,2.051,6.833
			c2.065-1.152,4.208-2.186,6.16-3.5c0.977-0.662,1.059-2.744,2.678-2.377c1.34,0.305,0.417,1.788,0.664,2.656
			c0.542,1.923,1.774,0.632,2.084-0.09c2.319-5.403,7.7-9.691,6.116-16.554c-0.127-0.558,0.242-1.229,0.381-1.849
			c0.501,0.302,0.973,0.676,1.507,0.895c3.012,1.241,5.728,5.153,9.365,1.075c0.243-0.272,0.65-0.54,0.995-0.563
			c5.755-0.36,15.527,9.678,13.061,14.427c-2.847,5.481-4.99,11.787-10.297,15.816C244.494,441.863,243.74,442.769,242.913,443.577z
			"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          fill="#C4CE9F"
          d="M209.112,232.872c-1.438-0.394-1.933-3.161-3.201-2.361
			c-1.693,1.065,0.68,2.56,0.71,3.989c0.02,0.916,0.07,1.83,0.129,2.742c0.218,3.413-1.319,7.789,4.643,7.984
			c0.835,0.028,2.004,1.059,2.387,1.91c3.471,7.725,11.889,8.852,17.638,13.487c4.592,3.704,8.92,7.796,12.954,12.102
			c1.299,1.385,1.363,3.982,1.858,6.059c1.041,4.351,3.864,4.363,7.148,2.911c4.304-1.903,3.471-1.414,5.876,2.035
			c1.664,2.383,4.159,4.232,4.89,7.328c-0.868,0.835-1.929,0.543-2.949,0.509c-6.782-0.247-10.541,2.421-12.754,8.845
			c-0.514,1.488-0.173,3.513-2.212,4.229c-2.956-1.683-2.407-4.821-2.638-7.354c-0.388-4.267-2.837-6.148-6.463-7.39
			c-7.047-2.412-12.911-6.539-18.667-11.34c-5.692-4.747-7.961-11.817-13.304-16.577c-0.521-3.266-5.63-5.966-1.208-9.84
			c-2.559-2.674-3.204-5.889-3.629-9.539c-1.361-11.632-4.223-23.059-5.014-34.795c-0.188-2.748-1.292-6.014,1.099-7.726
			c2.033-1.452,4.635,0.694,6.889,1.571c4.357,1.692,7.45,5.275,11.319,7.706c3.553,1.926,6.394,4.751,9.284,7.489
			c0.993,0.937,2.057,2.114,0.919,3.701c-1.381,0.688-2.641,0.093-3.914-0.361c-6.035-2.863-11.534-6.625-17.116-10.259
			c-1.019-0.665-2.196-2.332-3.322-1.295c-1.1,1.007,0.517,2.328,0.971,3.471c1.552,3.893,3.934,7.374,5.69,11.164
			C208.526,226.292,210.004,229.35,209.112,232.872z"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          fill="#FDF1B5"
          d="M147.813,586.663c-1.091-0.157-2.546,0.454-3.015-1.107
			c-0.473-1.566,0.771-2.236,1.812-3.094c9.816-8.076,21.587-11.311,33.778-11.966c11.2-0.604,22.183-3.968,33.475-2.614
			c5.517,0.662,11.212,0.055,16.531,2.198c0.131,1.105,1.177,1.289,1.857,1.838c0.886,0.721,1.292,1.629,1.028,2.759
			c-0.288,1.241-1.161,1.603-2.35,1.513c-0.432-0.032-1.079-0.553-1.267,0.215c-0.122,0.505,0.328,0.983,0.755,1.272
			c2.485,1.688,5.128,3.081,7.951,4.121c1.343,0.495,2.745,0.847,4.042,1.448c2.928,1.366,3.157,2.685,0.735,4.817
			c-1.224,1.075-2.501,1.981-1.612,3.868c-0.027,2.384-2.239,2.511-3.63,3.468c-1.638,1.129-3.92,1.688-3.638,4.398
			c-0.088,2.643-1.671,4.004-4.08,4.319c-1.703,0.22-2.837,1.02-3.722,2.363c-1.623,2.191-4.419,3.25-5.625,5.848
			c-2.322,2.633-3.865,6.196-7.981,6.683c-2.421-3.518-3.816-7.607-6.196-11.174c-0.844-1.268-1.127-3.072,0.229-4.379
			c1.556-1.503,2.847-0.356,4.112,0.645c1.903,1.507,2.503-0.396,3.299-1.394c1.129-1.417,0.986-3.183,1.039-4.894
			c0.039-1.306,0.111-2.726,2.103-2.576c0.771,0.061,2.094,0.947,2.152-0.562c0.043-1.118,0.192-2.541-1.504-3.249
			c-2.634-1.101-5.16-1.766-7.94-0.581c-2.045,0.866-4.112,1.727-6.222,2.405c-2.769,0.893-3.21-0.413-3.384-3.142
			c-0.421-6.635,4.681-8.547,8.695-11.525c1.967-1.202,4.126-0.188,5.521-0.538c-2.085,0.118-4.779-0.527-7.513-0.416
			c-1.444,0.058-2.96-0.038-4.032,1.146c-1.751,1.938-3.902,2.045-6.139,1.333c-8.648-2.756-17.046-1.922-25.333,1.486
			c-2.313,0.949-4.729,1.505-7.295,1.055c-6.134-1.078-11.579,0.794-16.676,4.041L147.813,586.663z"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          fill="#FDF9CE"
          d="M561.255,94.484c-0.147,4.971-1.111,9.818-2.385,14.599
			c-0.556,2.085-1.374,4.179,1.132,5.483c2.55,1.329,3.383-0.931,4.553-2.422c0.721-0.919,1.494-1.799,2.246-2.695
			c0.129,2.874,0.114,5.726-1.083,8.434c-0.538,1.216-0.709,2.32,1.088,2.566c-0.303,3.266-3.016,3.031-4.611,2.241
			c-3.916-1.938-6.688-0.724-9.932,1.6c-2.873,2.057-6.812,1.505-10.245,2.179c-2.204,0.434-3.075,0.967-3.582,3.079
			c-1.001,4.159-1.283,4.181-4.688,1.952c-2.447-1.603-5.26-2.367-8.01-3.139c-2.535-0.713-4.707-1.578-5.76-4.409
			c1.186,3.13-1.248,5.117-1.979,7.583c-2.196-9.801-0.056-18.564,6.288-26.33c0.985-1.208,1.846-2.529,2.912-3.657
			c0.432-0.456,0.684-4.056,2.059-0.338c0.277,0.751,1.417,0.396,1.239-0.644c-0.913-5.351,3.528-8.195,5.808-11.764
			c4.389-6.868,6.696-14.963,12.462-20.977c-0.052,1.915-0.328,3.908,1.764,5.078c1.879,1.055,3.106-0.344,4.477-1.193
			c1.358,1.501,0.177,2.901-0.099,4.386c-1.002,5.389-4.282,10.157-4.261,15.812c0.003,1.309,0.021,2.613,0.131,3.92
			c0.212,2.546,0.917,3.253,3.269,1.425C556.094,95.663,558.823,95.348,561.255,94.484z"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          fill="#AFCA9F"
          d="M246.08,304.43c1.931-3.718,1.248-8.266,5.737-11.341
			c4.239-2.901,7.916-3.18,12.327-2.031c5.641-0.243,10.269,4.473,16.15,3.312c0.62-0.122,1.809,0.732,2.151,1.423
			c1.444,2.921,3.207,2.527,5.256,0.791c4.593,1.282,8.718,2.968,11.213,7.648c1.532,2.87,4.744,4.328,7.759,5.63
			c0.032,1.855,0.032,1.855-3.414,3.887c-3.679,2.281-4.933,6.51-7.674,9.545c-0.837,1.568-1.673,3.138-2.51,4.706
			c-0.562,2.825-2.375,2.464-4.491,1.941c-7.627-1.887-13.93-6.174-20.09-10.77c-2.445-1.827-5.156-3.167-8.232-3.481
			c-5.251-0.537-9.29-3.301-12.913-6.855C246.125,307.631,244.882,306.365,246.08,304.43z"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          fill="#A4C28F"
          d="M220.191,133.027c-2.191-4.156-4.382-8.308-6.575-12.463
			c0.955-2.272,2.647-1.151,4.149-0.901l-0.003-0.003c1.896,1.342,3.839,2.583,5.867,3.738c3.821,2.175,5.517,6.059,7.014,9.964
			c1.681,4.379,4.099,8.084,8.642,9.965c0.849,0.515,1.43,1.257,1.823,2.153c-0.058,3.525,1.879,5.652,4.173,8.308
			c3.671,4.246,7.885,8.378,9.909,13.922c0.459,1.257,1.414,2.39,2.784,2.556c4.316,0.525,6.792,3.787,9.541,6.465
			c1.981,1.929,3.969,3.605,6.376,5.026c5.218,3.08,9.88,6.964,14.427,10.985c2.284,2.019,2.183,4.987,3.073,7.416
			c1.516,4.133,2.609,8.755,0.42,13.224c-4.989-0.527-8.194-5.137-6.757-9.984c0.324-1.091,1.154-2.175-0.752-1.647
			c-5.082,1.411-7.134-1.772-8.94-5.493c-0.398-0.819-0.628-1.983-1.673-1.971c-2.204,0.029-3.093-1.615-4.339-2.904
			c-0.71-0.733-1.566-1.676-2.656-1.475c-2.55,0.467-3.233-0.644-3.222-2.979c0.008-1.555-0.98-2.588-2.608-1.737
			c-3.014,1.577-4.008-1.018-4.562-2.643c-0.747-2.194-2.611-3.646-3.311-5.38c-1.678-4.146-4.659-3.564-7.769-3.503
			c-0.808,0.016-1.75,0.009-2.205-0.608c-2.177-2.946-5.34-2.597-8.448-3c-3.861-0.502-6.547-3.644-9.886-5.381
			c-0.573-0.301-1.082-0.726-1.725-0.902c-0.281-0.269-0.611-0.501-0.836-0.812c-0.971-1.331-2.86-2.742-1.808-4.367
			c1.331-2.057,2.916,0.643,4.49,0.512c1.593,0.495,3.386,0.214,4.937,1.193c-1.395-1.292-2.518-2.761-3.291-4.479
			c-1.191-2.566-3.648-4.613-3.226-7.841c1.892-1.497,3.189-0.08,4.396,1.034c2.965,2.735,5.792,5.614,8.321,8.778
			c-1.836-2.185-3.35-4.597-4.939-6.958c-0.807-1.196-1.587-2.406-3.139-2.776c-3.069-0.736-4.858-2.879-6.093-5.618
			c-0.544-0.787-1.089-1.574-1.634-2.361c-0.785-1.487,0-1.858,1.275-1.638c1.639,0.288,2.615,1.548,3.405,2.515
			C223.409,138.465,220.673,136.411,220.191,133.027z"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          fill="#FCE99A"
          d="M498.408,151.088c-0.263,0.041-0.524,0.08-0.787,0.118
			c-2.688,1.18-4.675-0.659-6.838-1.631c-2.865-1.286-4.732-3.999-7.534-5.384c-5.166,0.073-7.517,3.305-5.752,8.106
			c0.851,2.313,1.943,4.526,1.026,7.083c-0.52,1.344-1.187,2.601-2.031,3.752c-2.674,3.647-5.251,3.769-8.544,0.429
			c-0.29-4.434,1.593-8.509,2.151-12.818c0.494-3.8,2.203-7.482,0.874-11.42c-1.309-17.196,4.908-31.494,17.048-43.34
			c5.066-4.943,8.033-10.806,9.735-17.557c1.053-4.168,2.288-8.364,5.329-11.647c0.854-0.923,1.91-2.207,3.277-1.628
			c1.293,0.549,0.788,2.144,0.657,3.206c-1.326,10.732,0.507,21.623-1.64,32.308c-2.555-0.164-2.451-2.416-2.879-3.994
			c-0.588-2.177-1.494-2.212-2.922-0.897c-5.044,4.646-11.037,8.631-11.354,16.558c-0.07,1.774-1.127,3.171-2.462,4.32
			c-3.021,5.091-4.396,10.695-4.775,14.908c0.642-4.309,1.651-10.206,5.152-15.294c2.581-0.139,2.567,1.678,2.478,3.407
			c-0.226,4.403,0.999,8.506,2.464,12.559c1.618,6.021,4.639,11.382,7.895,16.632C499.552,149.789,499.53,150.585,498.408,151.088z"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          fill="#FDF2EA"
          d="M252.368,385.391c1.875,0.986,3.623-0.252,5.411-0.428
			c1.711-0.169,2.214,1.027,2.49,1.881c0.649,2.012-1.142,0.848-1.848,1.171c-0.794,0.365-2.153,0.944-2.13,1.369
			c0.072,1.359,1.37,0.589,2.134,0.707c2.156,0.336,4.332,0.554,6.499,0.819c0.555,3.074-1.508,4.257-3.845,4.99
			c-2.343,0.733-2.875-1.069-3.259-2.806c-0.224-1.014-0.794-1.248-1.711-0.918c-1.083,0.387-2.31,0.482-2.833,1.816
			c-0.61,1.559-0.939,3.561-2.099,4.481c-1.607,1.277,1.193,7.684-4.955,4.293c-0.771-0.425-4.989,1.229-7.262,0.521
			c-4.769-1.481-9.208-3.391-12.657,2.163c-0.37,0.596-1.435,1.452-1.876,0.521c-2.393-5.039-4.046-1.001-6,0.33
			c-1.315,0.897-2.002,2.24-4.381,0.349c-2.372-1.885-6.399-1.705-9.712-2.374c-2.132-0.431-4.594-1.343-4.101-3.618
			c0.595-2.741,2.657-2.303,4.685-0.639c1.098,0.902,2.748,0.297,3.37-0.986c1.067-2.197-1.754-2.111-2.325-3.413
			c1.948-0.426,3.256-1.295,1.028-2.937c-2.537-1.868-1.078-2.022,0.51-3.641c3.918-3.989,8.193-7.06,13.471-8.798
			c0.37-0.121,0.702-0.345,1.061-0.502c4.702-2.082,23.625,1.51,27.093,5.121C250.055,385.836,251.024,386.853,252.368,385.391z"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          fill="#CADBB1"
          d="M239.036,143.989c-5.146-1.062-7.244-5.172-9.032-9.379
			c-2.089-4.911-3.705-9.984-9.803-11.595c-1.037-0.273-1.636-2.194-2.439-3.356c11.699-0.507,20.459,6.444,29.521,12.132
			c10.029,6.295,17.801,15.042,24.229,25.048c1.622,2.523,5.654,1.583,8.107,3.771c11.798,10.515,18.215,23.1,15.888,39.276
			c-3.034,1.488-4.15,1.177-4.758-1.766c-0.505-2.441-1.934-4.922-0.149-7.511c0.613-0.889,0.221-2.025-0.272-3.004
			c-3.154-6.279-7.653-11.731-10.894-18.25c-0.309,0.902-0.083,1.375,0.112,1.84c0.4,0.946,1.193,1.97,0.104,2.837
			c-1.092,0.873-2.479,0.512-3.204-0.413c-2.068-2.639-4.39-2.028-6.861-0.905c-1.819-0.4-2.273-2.038-2.964-3.391
			c-2.196-4.303-4.418-8.596-6.431-12.991c-5.091-11.11-13.892-18.292-25.507-23.816c1.484,2.985,2.761,5.179,3.79,7.482
			C239.033,141.254,240.102,142.489,239.036,143.989z"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          fill="#D1DDB9"
          d="M246.08,304.43c2.305,6.938,8.154,8.966,14.432,10.149
			c3.945,0.746,6.987,2.723,10.225,5.01c5.942,4.2,12.32,7.866,19.451,9.888c1.446,0.41,2.122-0.604,2.892-1.478
			c0.432,4.581-2.263,5.128-5.894,4.687c-11.038-1.337-21.255-5.604-31.287-9.872c-2.316-0.982-7.728-3.845-6.061-9.625
			c0.677-2.344-2.33-3.128-4.138-4.008c-3.759-1.83-7.691-3.32-11.367-5.294c-3.372-1.81-7.773-2.926-7.06-8.445
			c0.105-0.825-1.487-1.993-2.457-2.805c-2.054-1.724-5.63-2.358-4.251-6.401c0.099-0.287-0.427-0.95-0.815-1.218
			c-8.129-5.618-9.447-15.541-14.592-23.036c4.36,3.067,6.873,7.524,9.923,11.733c2.028,2.802,4.974,6.28,8.877,8.017
			c2.86,1.273,5.462,3.228,7.952,5.169c0.769,0.601,1.445,1.059,2.321,1.129c8.3,0.685,11.105,5.835,11.08,13.414
			C245.304,302.437,245.81,303.431,246.08,304.43z"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          fill="#FDF6EC"
          d="M311.31,392.484c-2.657-4.092-1.063-8.692-1.604-13.036
			c-0.713-5.739-2.771-10.751-8.604-13.148c-2.695-1.103-5.08-0.617-6.774,2.006c-0.483,0.749-0.01,2.201-1.564,2.178
			c0.168-0.981,0.283-1.979,0.521-2.943c0.156-0.626,0.49-1.209,0.744-1.81c1.862-1.606,2.108-4.034,2.18-6.069
			c0.179-5.121-1.6-9.933,5.042-13.63c2.844-1.584,6.02-7.806,4.553-13.304c0.798,0.333,1.686,1.065,2.372,0.912
			c1.282-0.294,2.446-1.101,3.659-1.695c0.472,1.83-1.269,2.703-1.82,4.104c-1.591,4.043,1.779,13.806,5.777,16.123
			c1.852,1.071,3.903,1.846,5.632,3.081c2.744,1.96,5.364,3.423,1.46,7.607c-3.18,3.406-7.486,6.148-8.652,11.666
			c-1.086,5.138,1.536,7.38,4.675,9.975c1.661,1.37,4.961,1.743,3.938,4.955c-0.947,2.968-3.789,2.118-6.049,2.251
			C315.131,393.12,313.279,393.217,311.31,392.484z"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          fill="#B6D0A1"
          d="M269.62,172.37c2.513-4.079,4.777-2.709,7.046,0.275
			c0.541,0.71,1.463,1.628,2.298,1.027c1.558-1.124-0.535-1.542-0.66-2.313c-0.259-1.587-0.168-3.231-0.221-4.85
			c1.726,0.474,2.1,2.118,2.895,3.378c3.415,5.409,6.87,10.796,10.146,16.289c0.822,1.376,2.017,3.606,0.852,4.684
			c-3.142,2.9,0.006,5.518-0.012,8.195c-0.016,2.166,2.347,0.377,3.544,0.832c0.843,6.612-1.601,12.712-2.956,18.982
			c-2.155-1.577-2.348-3.471-0.915-5.63c1.131-6.257-1.078-12.054-2.815-17.818c-0.551-1.826-2.144-3.528-4.396-4.696
			c-3.417-1.772-6.002-5.144-9.414-6.929c-5.091-2.662-8.912-6.692-12.657-10.714c-2.097-2.251-5.624-0.012-6.788-2.818
			c-2.181-5.256-6.222-9.146-9.376-13.685c-1.236-1.779-1.818-3.372-4.741-3.983c-3.191-0.666,0.064-4.568-0.926-6.891
			c1.072-1.887-1.086-2.62-1.457-4.012c-0.565-2.117-3.674-4.337-1.492-6.203c2.561-2.188,4.886,0.829,6.518,2.521
			c7.171,7.441,13.208,15.79,18.623,24.584c2.591,4.207,5.368,8.327,6.841,13.157C269.604,174.606,268.54,173.467,269.62,172.37z"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          fill="#E9EBE3"
          d="M220.898,219.475c1.308,0.269,2.618,0.538,3.928,0.806
			c2.8-0.054,4.812,1.756,6.981,3.077c6.218,3.794,12.745,6.84,19.793,8.72c1.053,1.542,2.106,3.083,3.159,4.626
			c-0.33,1.763,0.442,3.311,0.942,4.919c0.266,0.861,0.537,2.112-0.614,2.313c-3.047,0.528-0.021,1.19,0.026,1.226
			c5.374,4.114,11.495,7.115,16.85,11.266c-0.256,0.548-0.513,1.095-0.769,1.641c-1.308,1.011-2.585,0.343-3.648-0.31
			c-4.717-2.901-9.882-4.748-15.003-6.73c-2.17-0.841-4.367-1.836-5.777-3.893c-5.219-1.328-9.88-5.55-15.95-2.722
			c-1.011,0.473-2.868-0.873-4.335-1.379c-5.323-1.718-8.155-6.279-11.375-10.278c-1.683-2.089-2.945-4.597-4.098-7.083
			c-0.777-1.673-0.437-3.205,1.617-2.665c2.707,0.71,4.066-0.166,5.208-2.476C218.361,219.469,219.596,219.027,220.898,219.475z"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          fill="#96BB87"
          d="M291.636,213.24c0.306,1.878,0.609,3.752,0.915,5.63
			c-0.357,1.533-0.715,3.065-1.073,4.597c-0.63,1.241-1.35,1.251-1.799-0.038c-0.384-1.101-0.538-2.278-1.192-3.286
			c-0.694-1.279-1.068-2.667-1.449-4.056c-0.204-0.742-0.328-1.839-1.331-1.609c-4.072,0.94-6.224-2.856-9.574-3.781
			c-2.361-0.65-2.806-3.491-1.436-5.851c0.18-0.313,0.635-0.4,0.832-0.739c-0.372,0.019-0.674,0.227-1.002,0.361
			c-0.363,0.15-0.727,0.278-1.119,0.345c-5.787,1.011-5.819,1.014-6.051-5.192c-0.025-0.684-0.362-0.972-0.725-1.136
			c-2.679-1.206-4.594-3.275-6.659-5.284c-1.419-1.382-3.485,0.176-5.274,0.246c-1.839-0.119-2.185-1.385-1.751-2.76
			c0.659-2.083-0.208-1.737-1.593-1.332c-1.786,0.525-2.922-0.739-4.035-1.849c-1.699-1.696-3.527-2.342-6.014-1.661
			c-3.099,0.845-5.368-0.991-7.385-3.099c0.176-2.083,1.502-2.55,3.293-2.294c1.153,0.163,2.293,0.659,3.467,0.384
			c1.28-0.304,2.974-0.973,2.773-2.211c-0.174-1.075-1.737,0.07-2.691,0.045c-0.384-0.01-0.806,0.192-1.162,0.076
			c-4.26-1.368-5.463,1.239-6.081,4.646c-1.387,0.973-2.648,0.666-3.668-0.506c-0.598-0.688-0.577-1.382,0.653-1.318
			c1.021,0.122,2.226,0.528,2.292-1.151c0.074-1.884-0.127-3.669-2.005-4.779c-1.341-0.796-2.659-1.638-3.896-2.578
			c-0.331-0.25-0.52-0.96-0.459-1.417c0.478-3.567,0.502-3.563-3.477-7.87c2.864,0.208,5.323,1.712,7.445,3.276
			c2.417,1.785,4.795,2.38,7.622,2.169c1.771-0.131,3.556-0.396,4.339,2.044c0.292,0.915,1.496,2.233,2.812,1.446
			c3.067-1.843,5.723,1.781,8.693,0.47c0.131-0.058,0.448,0.311,0.68,0.477c-5.435,3.983,1.349,3.18,1.96,4.798
			c0.341,0.896,1.387,1.328,0.548,2.652c-1.138,1.795,2.024,1.731,1.914,3.628c-0.041,0.691,1.107-0.634,1.581-1.174
			c0.757-0.867,1.7-1.069,2.306-0.157c0.813,1.222,3.142,1.856,1.86,4.025c-0.348,0.592-0.751,1.154-0.104,1.705
			c0.204,0.17,0.776,0.131,1.042-0.035c3.458-2.153,3.946,1.958,5.952,2.847c0.142,0.061,0.029,0.697,0.035,1.068
			c6.574-1.721,4.301,5.647,7.69,7.579c2.721,1.555,4.689-1.167,7.006,0.013c0.737,0.374,2.069-0.086,2.296,1.021
			c0.268,1.308-1.019,1.212-1.849,1.43c-1.655,0.429-1.855,1.804-0.909,2.633c1.384,1.206,1.541,2.197,0.705,3.679
			c-0.197,0.349,0.052,0.95,0.095,1.433C289.667,208.899,289.549,213.311,291.636,213.24z"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          fill="#FBE38B"
          d="M179.956,554.34c-3.915,2.746-6.417-3.311-10.268-1.641
			c-1.094,0.477-2.536,0.179-3.817,0.183c-1.478,0.007-2.461-0.819-2.987-2.111c-0.411-1.014-0.424-2.144,0.593-2.821
			c2.15-1.443,4.294-3.778,6.579-3.955c5.803-0.446,4.651-4.099,4.394-7.594c1.302-0.771,1.33-0.792,1.87-0.183
			c0.998,1.13,1.273,2.763,2.546,3.698c2.767,0.647,4.394-2.141,6.905-2.286c6.639-0.941,12.991-3.587,19.827-3.225
			c4.495,0.173,8.382-0.585,9.618-5.855c0.268-1.142,1.056-2.292,2.182-2.645c3.67-1.137,7.306-2.747,11.31-1.686
			c0.769,1.05,0.407,2.269,0.424,3.41c0.076,5.16,0.054,5.265-5.007,5.218c-2.487-0.023-3.188,1.419-3.445,3.374
			c-0.357,2.701-0.228,5.461-1.766,7.94c-0.673,1.087,0.08,2.241,0.685,3.284c0.713,1.229,2.64,2.717,0.345,3.837
			c-1.942,0.947-4.154,0.475-5.547-1.54c-0.692-1.001-0.966-2.075-2.054-3.048c-3.195-2.856-6.582-3.282-10.465-2.29
			c-0.752,0.19-1.56,0.107-2.346,0.128c-3.515,0.09-7.782-1.152-8.027,4.663c-0.023,0.543-0.719,1.062-1.113,1.588
			C187.624,554.053,183.369,552.961,179.956,554.34z"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          fill="#D6CED8"
          d="M363.226,147.304c-0.867,1.58-2.415,2.271-3.925,3.01
			c2.238-6.075,2.804-13.016,10.691-15.646c1.918-0.641,2.844-4.748,3.896-7.393c2.572-6.463,7.701-8.88,14.149-9.155
			c2.109-0.09,3.588-0.485,4.888-2.414c3.306-4.9,8.412-6.873,14.045-7.557c2.508-0.304,4.408,1.071,6.187,2.787
			c1.505,1.45,3.179,3.755,5.577,1.404c2.276-2.231,0.136-4.25-1-5.891c-2.889-4.181-1.767-10.273,2.767-12.863
			c2.315-1.32,3.877-0.576,3.948,2.164c0.088,3.372,0.385,6.75-1.475,9.933c-1.583,2.709-0.82,3.872,2.532,3.119
			c4.824-1.084,8.451,0.537,10.376,5.346c0.868,2.166,2.162,3.419,4.827,2.455c6.382-2.308,10.822,0.235,13.157,6.199
			c2.642,6.749,5.051,0.581,7.56,0.121c0.952,3.687-1.444,6.103-3.295,8.723c-3.09,0.102-3.178,0.31-1.691,4.066
			c0.065,0.595-0.066,1.122-0.493,1.558c-4.992,0.646-7.03-1.187-6.419-5.765c0.686-5.122-4.085-10.362-9.26-9.517
			c-4.589,0.75-7.663-0.552-10.498-4.158c-2.927-3.725-5.998-4.034-9.972-1.36c-2.683,1.806-4.887,1.626-7.576,0.088
			c-7.909-4.525-12.852-3.482-18.69,3.773c-1.326,1.647-2.892,2.645-4.968,2.72c-7.544,0.275-12.783,3.294-14.011,11.384
			c-0.386,2.543-2.294,4.085-4.415,5.202C366.863,141.36,364.597,143.906,363.226,147.304z"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          fill="#FCF7C7"
          d="M205.964,535.563c-7.335-0.688-14.02,3.129-21.263,3.073
			c-1.224-2.502,0.986-4.296,1.425-6.455c0.389-1.912,0.855-3.011-1.739-1.933c-0.602,0.249-1.265,0.121-1.63-0.588
			c-0.014-2.201-0.13-4.456,2.729-5.119c3.387-0.767,5.409-3.984,6.536-6.212c1.609-3.174,3.816-5.237,6.404-6.968
			c7.199-4.811,13.426-11.112,21.694-14.296c0.203,0.354,0.365,0.828,0.147,1.111c-5.753,7.414-5.243,17.664-11.295,24.938
			c0.528,3.979-4.469,6.932-2.101,11.294C207.093,534.821,206.383,535.255,205.964,535.563z"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          fill="#FDF8C8"
          d="M490.558,133.039c-2.469-4.148-3.293-8.696-3.086-13.44
			c0.062-1.462,0.696-2.974-1.628-3.062l-0.011-0.009c1.135-9.859,6.306-17.154,14.368-22.607c0.715-0.483,1.554-1.356,2.196-1.246
			c1.143,0.195,0.577,1.492,0.755,2.321c0.428,2.014,0.665,4.103,2.229,5.667c-1.011,5.619-2.22,11.21-2.987,16.863
			c-1.224,9.029,2.534,17.173,4.971,25.542c0.471,1.622,0.881,3.263,1.319,4.895c-4.193-0.938-5.425-4.53-6.438-7.875
			c-0.886-2.93-1.575-4.965-5.347-4.466C494.591,135.925,492.356,134.693,490.558,133.039z"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          fill="#FDEEAA"
          d="M517.999,131.532c0.255-2.812,0.524-5.624,0.751-8.439
			c0.058-0.721-0.031-1.455-0.049-2.184c0.562,0.478,1.461,0.857,1.635,1.449c1.491,5.083,6.434,4.811,10.058,6.449
			c1.864,0.844,4.045,0.844,5.231,2.982c0.633,1.142,1.422,0.591,1.307-0.438c-0.826-7.406,5.264-5.677,9.076-6.934
			c0.358-0.119,0.786,0.016,1.173-0.032c5.981-0.764,11.224-6.856,17.969-2.513c0.175,0.112,1.088-0.923,1.656-1.423l0.02,0.022
			c2.552-0.583,2.191,0.775,1.546,2.322c-4.268,3.61-5.333,9.251-8.573,13.513c-1.775-2.111-2.141-4.99-3.871-7.13
			c-0.357-0.445-0.111-1.644-0.728,0.038c-0.896,2.444-3.055,1.19-4.637,0.46c-4.084-1.881-8.501,0.282-8.997,4.671
			c-0.258,2.297-0.56,3.922-3.522,4.043c-2.051,0.086-2.786,2.543-1.594,4.492c1.018,1.663,2.301,3.157,3.366,4.789
			c1.126,1.727,1.064,3.211-1.281,3.871c-4.285-0.074-8.629,0.234-12.557-2.025C522.842,143.73,517.612,138.877,517.999,131.532z"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          fill="#E2DCCC"
          d="M233.456,599.247c-0.743-3.237,2.106-3.531,3.874-4.676
			c1.307-0.849,2.686-1.581,4.03-2.364c0.544-1.171,1.643-1.609,2.738-1.997c3.783-1.34,7.503-2.844,11.129-4.552
			c3.853-1.813,7.887-2.373,12.078-2.191c1.024,0.7,2.058,0.836,3.109,0.059c1.615-1.492,3.965,0.505,5.544-1.184
			c4.3-1.095,8.538-1.543,12.505,1.059c0.558,1.344-0.603,1.464-1.356,1.83c-3.418,1.655-3.79,3.212-1.59,6.234
			c0.843,1.158,3.188,1.698,2.211,3.607c-0.686,1.341-0.062,3.032-1.62,4.145c-2.411,1.71-6.431,1.215-9.074-1.434
			c-0.392-1.532-0.943-2.966-2.855-1.627c-3.552,2.487-7.573,2.541-11.649,2.483c-3.215,0.153-6.411,0.471-9.616,0.623
			c-1.664-0.404-3.332-0.585-4.994,0.021c-4.288,1.564-8.726-0.017-13.064,0.609C234.366,599.963,233.914,599.494,233.456,599.247z"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          fill="#FDEDA7"
          d="M189.481,550.527c0.463-2.041,4.134-3.507,0.884-6.314
			c-0.766-0.663,0.918-0.752,1.497-0.762c4.825-0.089,9.657-0.467,14.449-0.569c3.599-0.077,8.225,0.625,8.663,6.248
			c0.129,1.659,2.02,1.276,3.273,1.417c0.709,0.079,1.69,0.795,2.026-0.259c0.388-1.219-1.268-1.116-1.624-1.85
			c-1.001-2.06-2.721-3.666-0.069-6.413c1.603-1.664,0.955-5.424,1.517-8.199c0.23-1.146,0.488-3.201,2.226-2.521
			c7.916,3.093,6.396-2.504,6.427-6.747c0.276-0.197,0.553-0.188,0.832-0.003c-0.741,11.761,3.946,17.4,16.445,18.12
			c0.581,0.03,1.134,0.515,1.702,0.788c-4.243,3.353-9.197,5.283-14.211,7.087c-5.334,2.871-11.367,2.469-17.061,3.649
			c-0.51,0.106-1.065,0.026-1.539-0.181c-7.379-3.262-15.115-3.373-22.953-2.662C191.04,551.443,190.137,551.321,189.481,550.527z"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          fill="#B4CEA1"
          d="M234.268,182.552c2.853,1.095,5.308,3.938,8.91,1.667
			c0.867-0.551,2.17,0.012,3.132,0.95c2.317,2.259,4.585,5.33,8.131,1.564c0.16-0.169,0.704,0.023,1.07,0.048
			c-0.136,0.425-0.19,0.902-0.422,1.27c-0.966,1.529-2.527,2.978-0.389,4.75c1.8,0.554,3.418,1.405,4.098,3.276
			c0.411,1.125,1.264,1.468,2.191,1.986c2.17,1.209,5.184,1.471,4.821,5.428c-0.11,1.213,2.185,3.324,4.595,2.188
			c2.385-1.12,3.518-0.259,3.511,2.185c-0.011,3.058,3.039,3.17,4.49,4.814c0.888,1.004,2.615,1.264,2.656,3.052
			c-1.21,2.729-3.579,3.446-6.258,3.619c-5.601-3.996-12.16-6.786-16.244-12.72c-0.408-0.595-0.891-1.129-1.545-0.911
			c-2.607,0.866-4.007,0.614-3.241-2.697c0.248-1.072-1.049-0.698-1.73-0.886c-3.744-1.046-7.837-1.065-10.936-4.037
			c-0.812-0.781-2.073-1.2-2.471-2.348c-0.64-1.852-2.104-1.622-3.52-1.622c-3.016-1.839-5.592-4.625-9.559-4.412
			c-1.016-0.492-1.827-1.199-1.312-2.408c0.534-1.251,1.717-0.844,2.719-0.742c0.732,0.077,1.905,0.496,2.106,0.211
			c0.571-0.806-0.299-1.331-0.926-1.756c-0.865-0.589-1.964-1.132-1.166-2.399c0.813-1.295,1.914-2.172,3.527-1.053
			c0.359,2.271,1.458,2.255,2.971,0.96C233.75,182.194,234.014,182.185,234.268,182.552z"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          fill="#99C3A4"
          d="M322.994,300.459c-2.771,1.459-4.592-1.059-6.878-1.634
			c-6.744-13.433-12.65-27.297-20.59-40.119c-0.405-0.652-0.624-1.42-0.93-2.137c0.625-1.369-0.011-2.572-0.609-3.701
			c-2.466-4.657-3.532-9.843-5.611-14.654c-1.396-3.228-0.563-5.624,1.942-7.009c2.265,1.257,3.116,4.296,6.009,4.756
			c0.149,0.125,0.297,0.25,0.446,0.371c2.456,12.809,9.997,23.327,15.653,34.696c0.174,0.352,0.363,0.681,0.699,0.902
			c0.127-0.349-0.24-0.562-0.23-0.896c0.025-0.938,0.043-1.862,1.222-2.144c1.654,0.992,1.594,2.7,1.46,4.197
			c-0.651,7.259,1.894,13.576,5.315,19.747C322.165,295.137,324.124,297.424,322.994,300.459z"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          fill="#FDEFED"
          d="M277.497,554.408c2.602-3.811,6.366-6.123,10.499-7.841
			c0.453-0.188,4.045-3.891,2.493,1.219c-0.266,0.877,0.553,1.104,1.207,1.097c5.944-0.066,3.882,3.146,4.055,7.044
			c0.246,5.57-2.738,11.638,0.06,16.345c1.898,3.196,2.303,3.882-0.84,5.438c-0.328,0.164-0.226,1.812,0.123,2.571
			c0.477,1.037,1.265,0.243,1.938-0.079c2.108-1.01,4.294-0.766,6.46-0.243c0.104,0.021,0.105,1.212,0.008,1.234
			c-2.582,0.615-4.792,2.889-7.687,1.965c-1.458-0.465-2.789-1.321-4.177-2.003c-3.437,0.014-4.375-2.757-5.507-5.138
			c-1.718-3.617-3.027-7.447-5.865-10.429c-0.91-2.322-1.518-4.729-1.955-7.182C278.111,557.059,278.777,555.537,277.497,554.408z"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          fill="#B6D09D"
          d="M297.252,225.828c1.349-0.256,2.076,0.921,3.121,1.302
			c0.726,0.266,2.301,0.096,2.45-0.285c0.469-1.206-0.919-1.225-1.62-1.679c-0.552-0.361-1.903,0.275-1.607-0.928
			c0.107-0.429,1.63-0.896,2.357-0.723c6.182,1.472,12.239-1.004,18.433-0.557c1.695,0.125,1.342,3.791,3.473,1.468
			c5.064-3.685,10.75-6.427,15.659-10.348c0.619-0.493,1.382-0.733,2.21-0.656c1.528,0.316,1.891,1.494,2.089,2.796
			c0.154,2.338-0.278,4.59-0.979,6.804c-1.534,3.356-3.45,6.395-6.876,8.164c-1.577,0.774-3.225,1.369-4.931,1.788
			c-2.665,0.256-4.914,1.151-6.177,3.768c-0.324,0.672-0.947,1.216-1.862,0.966c-2.278-3.349-6.427-4.04-9.36-6.525
			c-1.225-1.036-3.049,0.1-4.235,1.12c-2.336,2.012-5.032,2.821-7.953,2.232c-2.132-0.431-3.266,1.283-4.968,1.661
			c0,0-0.146-0.234-0.147-0.234C296.657,232.587,295.674,229.091,297.252,225.828z"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          fill="#97BA86"
          d="M290.318,231.206c-2.042,2.511-1.794,5.096-0.584,7.933
			c2.096,4.914,3.951,9.933,5.923,14.9c0.483,1.216,0.785,2.31-1.061,2.531c-2.564-5.432-5.116-10.87-7.694-16.295
			c-1.589-3.343-3.682-5.531-7.878-5.985c-9.296-1.004-16.414-6.845-22.997-12.687c-6.02-5.346-12.527-9.847-19.083-14.376
			c-1.234-0.854-3.388-1.318-2.943-3.199c0.495-2.096,2.685-1.616,4.34-1.702c0.883-0.048,1.767-0.051,2.652-0.076
			c-0.469-0.887-0.787-1.907-1.43-2.643c-3.861-4.42-9.345-6.574-14.005-9.888c4.531-2.057,7.041,0.833,9.484,3.878
			c3.882,0.22,5.134,3.983,7.599,6.007c3.085,2.537,5.917,5.58,8.508,8.775c1.539,1.897,3.656,3.516,6.06,4.405
			c2.083,0.771,3.617,2.377,3.397,4.283c-0.274,2.387,0.827,2.643,2.51,3.305c3.597,1.408,7.495,2.31,10.329,5.252
			c3.35,5.935,7.771,7.368,13.461,4.36c1.018-0.537,2.036-1.219,3.176-0.247C290.162,230.227,290.24,230.716,290.318,231.206z"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          fill="#FDF0AF"
          d="M229.583,524.555c-0.279,0.003-0.556,0.003-0.832,0.003
			c-5.487,1.408-12.284-0.114-14.26,8.273c-0.792,3.362-5.359,2.642-8.528,2.732c-1.617-3.234,0.52-5.833,1.017-8.728
			c0.872-5.079,4.043-8.53,6.043-12.719c1.227-2.569-0.07-5.251,1.292-7.554c1.891-3.191,3.864-6.336,5.803-9.497
			c0.772-0.301,1.546-0.602,2.321-0.901c1.473,1.196,0.42,2.363-0.096,3.476c-3.561,7.647-2.1,12.665,5.035,17.119
			c0.44,0.272,0.923,0.479,1.385,0.719C229.399,519.798,231.4,521.954,229.583,524.555z"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          fill="#FCE797"
          d="M267.305,583.467c-9.19,1.302-17.127,6.331-25.945,8.74
			c-4.861-2.122-0.518-3.244,0.564-4.601c0.597-0.741,2.271-0.623,2.16-1.927c-0.131-1.496-1.724-1.46-2.764-1.742
			c-4.073-1.113-7.565-3.56-11.45-4.946c-2.887-1.032-0.494-3.589-2.17-4.613c1.716-2.204,1.879,0.616,2.826,0.902
			c0.829,0.254,1.603,0.436,1.957-0.517c0.35-0.943,0.171-1.878-0.999-2.15c-1.572-0.369-2.622-0.868-1.091-2.533
			c11.132,1.532,21.404,5.405,30.657,11.746C263.092,583.228,265.515,581.89,267.305,583.467z"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          fill="#D8D79D"
          d="M246.766,247.136c8.143,3.644,16.286,7.287,24.429,10.934
			c1.898,1.526,3.797,3.055,5.695,4.582c-2.465,1.254-5.038,1.903-7.845,1.852c-0.643-0.009-1.948,1.321-1.841,1.586
			c0.4,0.992,1.264,0.214,1.978,0.093c0.451-0.077,0.945,0.105,1.42,0.169c-0.437,0.426-0.817,1.129-1.319,1.232
			c-6.116,1.241-12.249,2.056-18.314-0.259c-0.926-0.353-1.534-1.159-1.365-2.134c0.179-1.049,1.15-0.774,1.91-0.749
			c0.764,0.025,1.948,1.136,2.138-0.467c0.158-1.318-1.131-1.216-2-1.19c-2.229,0.064-3.899,0.496-2.812-3.16
			c0.824-2.771-2.537-5.56-1.904-8.922c0.108-0.585-1.049-0.358-1.625-0.048c-1.054,0.566-2.348,2.098-3.009-0.141
			c-0.582-1.974,1.401-1.683,2.502-2.153C245.502,248.061,246.114,247.552,246.766,247.136z"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          fill="#FDF8CC"
          d="M189.481,550.527c6.361-1.937,13.174-1.542,18.989,0.3
			c8.709,2.765,16.732,0.724,25.049-0.277c-9.992,5.058-19.774,11.261-31.74,8.285c-3.798-0.943-7.199,0.611-10.829,0.368
			c-3.929-0.268-3.63-0.239-5.476-3.75c-0.947-1.797-3.624-0.83-5.519-1.113C182.519,551.539,186.794,553.017,189.481,550.527z"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          fill="#FDF3B9"
          d="M455.941,137.009c0.021-0.531,0.045-1.062,0.07-1.593
			c-0.115-1.578,0.26-2.953,1.603-3.938c1.271-2.854,2.541-5.705,3.812-8.557c1.174-0.088,1.211-2.496,2.585-1.583
			c1.045,0.693,0.334,2.123,0.154,3.287c-1.627,10.563-3.085,21.145,0.742,31.57c0.42,1.142,0.665,3.055,2.057,2.979
			c1.44-0.077,0.793-1.948,0.996-2.981c1.096-5.605,2.025-11.244,3.014-16.871c2.139,2.341,1.686,5.077,1.121,7.786
			c-1.148,5.537-2.354,11.064-3.535,16.593c-3.028,0.746-4.222-2.251-6.446-3.147c-1.923-1.088-3.986-1.795-6.085-2.434
			c-1.369-0.417-2.687-0.967-3.226-2.499c-0.415-1.452-0.405-2.93-0.306-4.417c0.026-0.864,0.068-1.728,0.271-2.572
			c0.83-2.345,0.374-4.862,0.996-7.246C454.191,139.754,454.465,138.097,455.941,137.009z"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          fill="#FCF3B5"
          d="M490.558,133.039c3.211,0.157,5.97,2.621,9.651,1.165
			c1.773-0.701,2.348,2.099,2.526,3.682c0.487,4.296,3.116,7.22,5.948,10.077c2.902,5.1,6.098,10.048,7.905,15.698
			c-2.742,1.737-3.955-0.314-5.15-2.16c-3.196-4.923-7.269-8.717-13.03-10.413c-1.625-2.95-2.707-6.158-4.829-8.887
			C491.547,139.584,491.35,136.171,490.558,133.039z"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          fill="#99C3A4"
          d="M290.083,229.737c-1.758,0.228-2.879,1.392-4.209,2.451
			c-3.503,2.793-12.53-1.724-13.074-6.382c-1.875-4.303-1.759-4.824,1.562-7.073c2.401-0.441,4.572-1.343,6.294-3.144
			c5.147-1.971,5.5-1.836,7.192,2.911c0.283,0.79,0.896,1.257,1.454,1.794c2.583-0.214-0.355,3.353,2.175,3.173
			C291.013,225.56,290.547,227.648,290.083,229.737z"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          fill="#D7D7A6"
          d="M251.601,232.079c-4.198,0.761-7.781-1.209-11.239-2.956
			c-5.312-2.681-10.37-5.867-15.536-8.839c-1.845-5.103-7.716-6.439-10.215-10.928c9.081,0.528,16.268,4.511,21.961,11.584
			c2.94,3.653,6.369,6.896,11.197,8.013C249.591,229.375,250.571,230.735,251.601,232.079z"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          fill="#DFDFAF"
          d="M271.964,256.429c-8.058-3.154-14.733-8.512-21.588-13.583
			c-0.269-0.198-0.594-0.832-0.531-0.892c0.728-0.71,1.401-0.09,2.104,0.159c1.041,0.372,2.097,0.701,3.145,1.046
			c-0.38-1.055-0.732-2.124-1.152-3.167c-0.528-1.312-1.55-2.71,0.819-3.289C262.847,241.225,269.266,247.206,271.964,256.429z"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          fill="#FDF5C4"
          d="M178.502,522.211c2.156-10.437,8.561-17.353,18.059-21.638
			c3.91-1.77,8.125-2.864,11.7-5.387c0.421-0.299,1.182-0.113,1.785-0.151c-0.389,0.444-0.705,0.996-1.175,1.321
			c-1.607,1.113-3.175,2.349-4.925,3.186c-8.074,3.867-13.781,9.681-15.97,18.596c-0.229,0.929-1.049,1.712-1.597,2.561
			c-2.626-1.824-4.661-0.98-6.435,1.359C179.649,522.44,179.016,522.44,178.502,522.211z"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          fill="#FDF7CD"
          d="M525.978,149.514c4.127,0.986,8.511-0.025,12.552,1.613
			c0.201,0.176,0.422,0.374,0.455,0.621c0.312,2.37-1.612,5.246,1.718,6.977c0.457,0.237,0.085,1.478-0.319,2.194
			c-3.81,3.602-5,8.596-6.972,13.164c-0.579,1.344-0.538,3.189-2.761,2.982c-2.022-0.122-2.419-1.465-2.366-3.148
			c0.664-1.82,1.051-3.81,2.04-5.436C535.688,159.67,531.603,156.417,525.978,149.514z"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          fill="#FCEBA5"
          d="M178.502,522.211c0.41-0.302,0.864-0.557,1.224-0.911
			c3.425-3.378,3.419-3.382,6.652-0.602c-0.297,1.281-0.595,2.565-0.893,3.844c-1.518,1.526-1.802,3.571-2.32,5.519
			c-0.584,4.069-0.965,8.229-4.629,11.001c-2.704-0.378-1.087-4.543-4.084-4.661C174.19,531.213,177.234,526.963,178.502,522.211z"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          fill="#FDF6EC"
          d="M295.587,323.293c1.871-3.736,2.189-8.717,7.676-9.545
			c3.18,1.19,1.799,3.417,0.784,4.997c-2.655,4.14-1.251,6.631,2.77,8.487c1.137,0.524,0.515,1.506,0.128,2.207
			c-0.577,1.043-2.125,1.712-1.139,3.289c-4.729-0.65-8.798-2.013-8.699-7.956C297.122,323.818,296.448,323.421,295.587,323.293z"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          fill="#D2C2CF"
          d="M360.409,118.823c3.546,0.037,9.263,5.353,8.954,8.084
			c-0.278,2.454-1.406,4.571-4.431,4.53c-2.946-0.042-7.234-6.139-7.279-10.045C357.623,118.836,359.158,118.703,360.409,118.823z"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          fill="#FCF3C2"
          d="M278.309,558.406c1.968,1.951,2.508,4.386,2.381,7.045
			c0.574,0.651,0.636,1.445,0.385,2.191c-1.8,5.367-2.907,10.998-5.943,15.902c-1.583,2.673-3.14-1.033-4.718-0.02
			C274.018,575.457,276.913,567.169,278.309,558.406z"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          fill="#CEBBCD"
          d="M389.865,110.442c0.297,3.125-1.189,4.165-3.037,3.691
			c-5.007-1.282-3.823-6.403-5.471-9.759c-0.582-1.184,0.524-3.166,1.937-2.59C387.375,103.442,388.923,107.239,389.865,110.442z"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          fill="#D1D9B1"
          d="M221.77,142.441c7.134,4.632,13.312,10.15,15.969,19.027
			c-3.412-3.743-6.817-7.495-10.238-11.228c-1.15-1.257-2.316-2.524-4.277-2.259C221.575,146.439,221.584,144.463,221.77,142.441z"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          fill="#D8CCD8"
          d="M441.057,112.64c-1.809,0.086-3.699-0.755-2.986-2.503
			c1.511-3.709,5.178-5.07,8.778-6.148c0.674-0.201,1.617-0.125,1.705,1.009C448.699,106.86,443.028,112.641,441.057,112.64z"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          fill="#D2D9B2"
          d="M220.191,133.027c2.177,3.074,4.384,6.125,6.506,9.235
			c0.422,0.621,1.697,1.277,0.509,2.163c-0.211,0.16-1.377-0.591-1.792-1.155c-1.165-1.574-2.19-3.253-4.082-4.082
			c-0.889-0.39-1.131,0.147-1.196,0.893C218.022,137.713,218.486,135.365,220.191,133.027z"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          fill="#D5CAD5"
          d="M355.015,148.65c-3.736,0.294-4.027-1.497-4.04-2.943
			c-0.018-1.801,0.963-3.17,3.077-3.218c1.815-0.045,2.718,1.106,2.681,2.617C356.687,147.006,355.785,148.573,355.015,148.65z"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          fill="#CFC4D0"
          d="M398.101,107.622c-1.566-0.193-1.062-1.472-1.11-2.375
			c-0.1-1.881,0.822-2.91,2.757-2.898c1.2,0.006,2.131,0.405,1.734,1.822C401.007,105.875,399.944,107.125,398.101,107.622z"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          fill="#CFDAB3"
          d="M226.45,155.822c2.222,2.121,4.444,4.242,6.664,6.362
			c-0.142,0.214-0.286,0.429-0.429,0.644c-2.628-1.241-5.254-2.482-7.881-3.72C226.752,158.714,226.298,157.118,226.45,155.822z"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          fill="#CADBB1"
          d="M217.765,119.663c-1.408,0.184-2.998-0.472-4.147,0.901
			C214.442,117.697,216.146,118.875,217.765,119.663z"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          fill="#FDF2EA"
          d="M205.96,395.622c-0.785-0.784-1.57-1.568-2.356-2.352
			C205.102,393.34,205.871,394.141,205.96,395.622z"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          fill="#FDDBDD"
          d="M324.666,622.841c0.001-0.271,0.004-0.534,0.008-0.804
			c5.734-3.396,7.111-3.153,10.435,2.031c0.761,1.187,1.5,2.125,3.063,1.97c1.42-0.14,2.77,0.407,3.477,1.487
			c3.788,5.791,9.528,3.549,14.632,3.57c2.206,0.011,3.99-0.882,5.403,2.092c0.652,1.372,3.381,2.797,5.806,2.518
			c3.257-0.373,3.951,1.268,3.239,4.028c-1.194,4.632,1.938,8.199,4.367,10.786c1.611,1.716,4.346,2.746,5.931,5.237
			c0.845,1.323,3.113,1.072,4.823,0.665c1.681-0.403,3.162-0.445,3.76,1.619c-0.165,1.48-0.961,3.01,1.906,2.92
			c3.075-0.099,6.293,0.045,8.985,2.104c0.881,0.671,2.018,0.511,3.053,0.374c2.028-0.267,3.812,0.149,5.589,1.24
			c1.265,0.777,2.737,1.603,4.306,0.178c1.967-1.784,3.564-0.837,4.949,0.936c1.566,2.002,3.081,3.993,5.202,0.426
			c0.637-1.069,1.86-0.748,2.833-0.199c2.142,1.217,4.37,1.044,6.42,0.04c3.565-1.747,7.591-2.043,11.151-3.978
			c1.891-1.027,4.908,0.32,6.211-2.624c0.152-0.342,1.098-0.318,1.465,0.015c2.395,2.155,5.506,2.402,8.397,2.885
			c2.208,0.37,1.603,2.8,3.4,3.397c1.985,0.659,1.852,2.252,0.806,3.822c-0.156,0.237-0.575,0.617,0.025,0.819
			c0.207,0.067,0.474-0.174,0.663-0.362c0.376-0.374,0.853-0.827,0.746-1.323c-1.091-5.094,3.362-4.632,5.897-5.369
			c2.181-0.63,4.567-0.809,6.846-1.429c2.73-0.742,3.588,0.358,2.722,3.17c-0.271,0.877-0.604,1.751-1.14,2.495
			c-0.944,1.312-0.393,2.889-0.616,4.012c-0.579,2.937,1.048,5.589,0.277,8.396c-0.259,0.941-1.987,2.408-0.473,2.831
			c1.784,0.496,1.921-1.609,2.132-3.029c0.281-1.928,0.061-3.995,1.177-5.71c0.997-1.535,2.209-1.985,3.15,0.086
			c1.361,2.998,0.639,6.427,1.922,9.489c0.353,0.837-0.779,1.551-1.598,1.999c-2.165,1.181-1.667,3.545-0.639,4.334
			c4.278,3.286,1.726,5.27-1.278,7.519c3.861-0.849,7.398,1.257,11.013-0.255c2.032-0.847,3.291,0.169,3.052,2.534
			c-0.08,0.788,0.007,1.568,0.256,2.78c0.82-4.265,1.53-8.109,1.253-12.078c-0.44-4.472,0.64-8.408,3.177-12.23
			c3.052-4.597,3.366-10.104,3.752-15.474c0.145-2.021-0.887-3.224-2.696-4.312c-8.754-5.266-8.533-5.08-6.807-15.847
			c0.541-3.372,1.495-6.636,0.543-10.053c-0.153-0.604-0.249-1.219-0.295-1.838c0.044-5.729,2.668-8.277,8.902-8.649
			c5.956-0.357,8.29-2.59,8.621-8.299c0.108-1.85,0.704-3.299,2.696-3.769c1.496,3.381,3.066,2.467,4.963,0.235
			c1.605-1.887,3.426-3.611,5.292-5.255c1.085-0.957,2.283-2.019,3.836-1.06c1.586,0.977,1.606,2.603,1.265,4.207
			c-0.42,1.962-1.438,3.66-2.697,5.184c-1.584,1.908-2.622,4.234-4.55,5.875c-2.543,0.432-3.62,2.383-4.431,4.472
			c-2.159,5.567-4.646,10.8-10.961,12.816c-0.58,0.185-1.302,0.659-1.159,1.394c0.188,0.975,1.106,0.905,1.879,0.893
			c0.649-0.012,1.293-0.131,1.94-0.12c2.34,0.389,4.288-1.116,6.499-1.366c2.451,0.415,4.178-1.325,6.228-2.105
			c0.367-0.064,0.713-0.008,1.042,0.163c1.611,4.034-0.643,7.316-2.256,10.628c-0.721,1.479-1.599,2.965-2.719,4.283
			c-1.352,1.589,0.096,1.908,1.287,2.213c1.603,0.988,3.285,1.712,5.191,1.021c3.536-1.285,7.202-1.197,10.855-1.005
			c2.393,0.128,4.229,0.148,3.488-3.201c-0.418-1.891,1.024-2.918,2.689-3.231c1.325-0.25,3.372-2.055,3.668,1.136
			c0.062,0.659,0.274,0.521,0.607,0.288c3.093-2.166,4.082,0.458,5.383,2.36c0.22,0.326,0.357,0.879,0.66,0.947
			c3.774,0.866,2.836,3.732,2.933,6.351c0.145,3.968-1.142,6.372-4.838,7.175c-3.148,0.684-5.952,1.319-5.772,5.438
			c0.034,0.767-0.738,1.957-1.253,1.784c-3.822-1.302-5.906,3.437-9.667,2.565c-2.265-0.527-3.529,1.403-4.409,3.207
			c-1.271,2.607-3.144,4.389-5.931,5.331c-3.157,1.066-4.052,3.664-4.495,6.729c-0.372,2.574-0.943,5.466-4.852,4.23
			c-1.408-0.376-2.876-1.043-3.834,0.781c-0.49-0.111-0.932-0.365-1.413-0.512c-3.239,0.174-3.435,2.737-3.85,5.006
			c-0.844,4.622-1.568,9.268-3.947,13.439c-0.188,0.321-0.398,0.63-0.622,0.927c-3.776,6.14-10.219,8.434-16.275,11.285
			c-2.378-1.245-2.455-2.954-1.354-5.242c1.513-3.135,2.179-6.817,6.159-8.164c-4.173-0.911-5.833,2.569-7.769,4.757
			c-2.318,2.62-5.322,3.698-8.107,5.326c-0.227,0.132-0.569,0.312-0.741,0.205c-4.601-2.74-9.819,1.453-14.414-1.294
			c-0.757-0.452-1.498-0.317-2.164,0.268c-0.295,0.26-0.61,0.495-0.949,0.697c-2.674,1.584-2.248-2.518-3.484-2.121
			c-1.631,0.525,1.352,3.324-1.24,4.015c-1.77,0.474-3.092-0.432-3.935-1.891c-0.743-1.283-1.065-2.76-2.999-1.096
			c-1.774,1.528-2.573-2.64-4.728-1.021c-1.316,0.988-2.726,2.095-4.62,1.88c-1.168-0.133-1.756-0.898-2.444-1.742
			c-2.94-3.613-4.39-3.728-8.179-1.011c-1.276,0.915-2.618,2.892-4.311,1.113c-1.289-1.356-1.889-3.308,0.448-4.604
			c2.634-1.458,5.221-3.003,7.885-4.411c1.377-0.726,2.812-1.545,2.842-3.695c-0.716,1.187-1.036,2.641-2.445,2.755
			c-1.6,0.133-1.601-1.468-2.146-2.412c-2.156-3.724-2.142-3.739,1.732-6.076c0.808-0.486,1.544-1.005,1.641-2.046
			c0.029-0.311-0.131-0.516-0.425-0.576c-1.057-0.211-1.723,0.444-2.354,1.114c-0.715,0.762-1.163,1.764-2.017,2.398
			c-1.078,0.799-2.321,1.609-3.527,0.484c-1.121-1.049-0.755-2.42-0.061-3.623c0.664-1.154,1.641-2.034,2.707-2.81
			c0.745-0.544,1.603-0.9,2.367-1.399c1.654-1.08,3.914-2.612,2.741-4.466c-1-1.584-2.995,0.364-4.415,1.174
			c-0.908,0.52-1.782,1.097-2.623,1.731c-0.928,0.694-1.729,2.13-2.875,1.84c-1.978-0.503-3.909-1.162-5.936-1.562
			c-1.111-0.222-0.625-1.523-0.543-2.405c0.07-0.753,0.531-1.708-0.339-2.134c-0.998-0.489-1.723,0.348-2.295,1.056
			c-1.081,1.333-2.159,2.641-3.879,3.191c-1.919,0.615-2.812-0.05-2.471-2.071c0.344-2.042,1.385-3.658,3.21-4.707
			c1.708-0.981,3.458-1.945,4.606-3.599c0.677-0.975,1.974-1.476,2.144-2.821c-1.489,0.686-3.058-0.697-4.586,0.257
			c-2.925,1.822-5.571,3.931-7.423,6.88c-1.059,1.688-1.718,4.706-4.341,3.062c-2.409-1.514-0.511-3.657,0.7-5.365
			c0.504-0.71,1.424-1.517,0.546-2.377c-0.729-0.713-1.74-0.259-2.58,0.147c-1.414,0.688-2.554,1.852-4.077,2.345
			c-0.904,0.293-1.867,0.448-2.575-0.317c-0.619-0.668-0.213-1.49,0.043-2.221c0.306-0.869,0.579-2.011,1.337-2.351
			c4.791-2.14,3.241-6.948,4.618-10.59c-3.22,2.062-7.852,2.27-8.743,7.147c-0.112,0.617-0.811,1.242-1.405,1.271
			c-3.305,0.153-6.595,0.183-9.869,0.931c-1.033,0.236-1.412-0.935-0.696-1.951c0.304-0.429,0.681-0.794,1.018-1.195
			c0.833-0.992,2.407-1.991,2.079-3.139c-0.354-1.229-2.047-0.113-3.133-0.387c-0.912-0.231-1.933-0.358-2.281-1.306
			c-1.142-3.093-3.336-2.815-5.773-1.964c-0.496,0.173-1.006,0.31-1.528,0.367c-0.891,0.093-1.969,0.457-2.478-0.553
			c-0.491-0.983,0.344-1.689,0.978-2.343c0.737-0.758,1.788-1.084,2.637-1.878c-1.273-0.045-2.208,0.728-3.292,0.979
			c-1.205,0.281-2.648,1.478-3.55,0.008c-0.807-1.319-0.847-3.019,0.144-4.484c0.37-0.545,0.867-0.974,1.349-1.418
			c0.854-0.789,2.052-1.489,1.7-2.865c-0.413-1.607-1.884-1.01-2.979-1.112c-1.714-0.16-3.606-0.224-4.388-2.015
			c-0.731-1.676,0.497-2.96,1.506-4.191c-2.154,2.265-4.366,4.48-6.068,7.146c-0.494,0.774-1.113,1.705-2.155,1.276
			c-0.939-0.388-0.937-1.468-0.968-2.405c-0.078-2.472,2.998-4.465,2.163-6.278c-0.707-1.536-4.38-0.07-5.952-2.034
			c-1.676-2.092-3.359-3.722-6.374-3.48c-1.281,0.102-2.336-1.057-2.975-2.298c-0.63-1.371-0.309-2.958-0.997-4.315
			c-0.979-3.986-2.476-7.526-7.169-8.329C326.913,626.622,324.45,625.688,324.666,622.841z"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          fill="#FDEEED"
          d="M517.279,693.597c3.015,0.099,3.907-1.977,3.869-4.334
			c-0.073-4.538,2.149-6.985,6.314-8.202c1.043-0.304,2.214-1.02,2.8-1.896c2.169-3.25,2.887-8.273,8.981-5.964
			c0.835,0.313,2.558-1.951,3.987-2.83c0.476-0.291,2.221-3.637,1.947,0.433c-0.006,0.099,0.816,0.399,0.858,0.338
			c0.415-0.613,1.183-1.411,1.033-1.914c-1.462-4.905,2.488-5.181,5.3-5.969c3.491-0.979,7.123-1.337,4.491-6.478
			c-0.277-0.537,0.498-1.606,0.768-2.435c0.155-0.479,0.534-1.125,0.354-1.43c-0.573-0.979-1.068-0.041-1.479,0.281
			c-1.424,1.127-1.084-0.266-1.602-0.879c-1.557-1.846-1.397-7.688-6.216-2.973c-0.403,0.395-1.693-0.117-2.571-0.207
			c0.103-0.326,0.105-0.786,0.327-0.947c0.721-0.524,2.13-0.378,1.727-1.916c-0.723,0.221-1.456,0.412-2.165,0.672
			c-1.116,0.405-3.406,0.293-2.615,2.031c2.962,6.503-1.605,4.576-4.704,4.522c-3.648-0.066-7.263-0.169-10.813,1.227
			c-1.952,0.764-4.217,0.729-5.078-2.021c-0.771-1.952,0.683-3.197,1.538-4.572c2.689-4.319,5.896-8.314,8.031-12.975
			c1.479-2.249,2.984-2.363,4.49-0.028c0.584,0.902,0.995,1.73,2.432,0.978c3.78-1.979,3.82-1.903,6.221,2.375
			c-0.225-4.949,2.519-6.044,5.993-6.287c1.61-0.116,2.212-1.278,2.474-2.637c0.853-4.343,2.857-8.409,2.768-13.008
			c-0.053-2.755,1.958-4.812,3.803-6.635c1.19-1.175,4.938,1.879,4.993,4.301c0.046,1.914,0.49,3.623,1.351,5.254
			c0.696,1.318,1.283,3.397,3.072,0.8c0.199-0.288,0.727-0.4,1.098-0.326c6.939,1.42,10.625-5.468,16.812-6.095
			c-2.105-0.976-4.574-0.943-3.854-4.143c0.734-3.26,3.148-5.276,6.107-4.948c0.269,0.259,0.458,0.568,0.569,0.924
			c0.081,0.819-0.873,1.829-0.578,2.115c0.651,0.627,1.201-0.416,1.792-0.735c1.46-0.795,2.831-2.014,4.57-0.581
			c3.629,1.84,7.417,2.007,11.287,1.045c1.385-0.344,2.775-1.498,4.075-1.193c6.158,1.436,11.507-1.414,17.193-2.697
			c4.423-0.998,5.73-4.466,7.656-7.629c3.175,0.492,2.425,3.227,2.993,5.214c0.103,1.363-0.218,2.674-0.551,3.976
			c-0.787,2.285,1.52,3.747,1.553,5.812c0.033,1.141,0.084,2.269,1.595,2.438c-2.849,1.827-4.104,3.649,0.021,5.454
			c0.422,0.542,0.485,1.072-0.055,1.581c-1.149-0.177-2.467-0.768-3.387-0.388c-0.687,0.279-4.942-0.075-1.37,2.809
			c0.886,0.713,1.162,2.021,0.286,3.205c-0.838,1.131-1.68,2.339,0.907,1.718c0.185-0.044,0.43,0.16,0.645,0.254
			c-4.123,0.889,0.841,5.627-2.915,6.838c-1.095,0.357-2.084,1.242-1.407,3.064c1.498,4.052,0.549,7.379-3.602,9.457
			c-1.365,0.686-2.789,1.466-3.416,3.049c1.446-0.114,2.905-0.154,4.335-0.378c0.761-0.115,1.48-0.609,2.069,0.285
			c-0.43,0.981-0.273,2.056-0.464,3.077c-0.328,1.75-0.892,3.026-3.082,2.326c-2.188-0.7-3.851-0.239-4.523,2.242
			c-0.276,1.021-1.08,1.564-2.092,1.289c-2.46-0.661-3.549,0.93-4.682,2.594c-1.477,2.173-3.488,3.868-5.265,5.771
			c-1.474,1.581-2.12,2.723,0.535,4.08c3.27,1.665,3.42,3.333,0.896,5.768c-0.816,0.786-0.627,1.183-0.239,1.899
			c1.207,2.243-0.09,3.075-2.021,3.536c-2.516-0.081-3.616-2.244-5.271-3.596c-1.234-1.016-2.115-2.796-4.209-1.976
			c-5.845,6.079-12.825,10.301-20.825,12.87c-2.06,0.654-2.955,3.154-5.299,3.356c-0.436-2.034,2.816-4.21-0.228-6.148
			c1.236-1.76,3.585-0.457,4.952-1.796c0.7-0.688,2.295-0.172,2.271-1.531c-0.004-0.289-1.741-0.317-2.298-0.86
			c-3.562-3.458-7.164-2.077-10.942-0.467c-1.484,0.63-1.146-0.087-0.731-1.28c0.895-2.588,2.29-5.326,0.318-8.052
			c-0.339-0.467-1.125-1.056-1.529-0.947c-1.512,0.416-0.082,1.091-0.018,1.671c0.303,2.727-0.042,4.929-3.637,4.561
			c-1.897-0.194-2.592,1.549-3.805,2.441c-7.385,5.438-15.517,9.888-21.975,16.552c-1.098,1.133-2.346,1.592-3.89,1.15
			c0.784-0.684,1.677-1.278,2.314-2.079c0.604-0.758,1.716-1.816,0.883-2.683c-0.867-0.909-1.89,0.26-2.694,0.799
			c-3.269,2.195-7.095,3.955-10.726,3.493C523.015,698.063,520.213,695.309,517.279,693.597z"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          fill="#FDE5E8"
          d="M337.303,640.914c1.386,3.72,5.825-1.312,7.359,2.677
			c1.52,3.951,5.299,3.448,8.633,3.646c0.551,0.033,1.078,0.481,1.615,0.736c-0.339,0.502-0.64,1.037-1.029,1.499
			c-0.578,0.684-1.374,1.227-1.796,1.985c-0.873,1.566-2.538,3.917-1.059,4.896c1.523,1.009,0.656-2.111,1.971-2.854
			c2.983-1.682,3.952-6.052,8.128-6.202c0.485-0.017,1.278,0.074,1.413,0.358c0.426,0.908-0.401,0.999-1.02,1.111
			c-2.109,0.376-3.783,2.1-3.318,3.69c0.576,1.977,3.007,2.067,4.992,1.401c1.302-0.435,2.345-0.319,2.945,1.017
			c0.625,1.396,0.841,2.764-0.68,3.81c-0.859,0.589-1.635,1.338-2.56,1.792c-2.18,1.071-0.532,2.962-0.43,4.012
			c0.129,1.334,1.269-0.738,2.167-0.617c0.12,0.016,0.256-0.104,0.376-0.089c2.222,0.256,4.647-3.545,6.125-0.807
			c0.891,1.653-2.992,2.068-4.48,3.371c-0.472,0.415-1.291,1.188-1.185,1.386c0.547,1.01,1.278,0.006,1.937-0.064
			c3.164-0.339,7.24-4,8.375,2.709c0.155,0.911,2.849,0.465,4.341-0.193c0.678-0.302,1.441-0.93,2.137-0.141
			c0.878,0.993-0.035,1.637-0.6,2.324c-0.333,0.402-0.657,0.812-0.982,1.219c-1.08,1.355-2.159,2.713-3.237,4.068
			c2.103,0.8,2.901-1.362,4.396-1.931c1.187-0.454,1.911,0.527,2.83,0.963c1.708,0.805,3.888,0.194,3.829-1.341
			c-0.261-6.687,7.308-4.387,9.261-8.398c0.102-0.207,1.609-0.665,1.556,1.432c-0.077,3.102-4.101,6.014-0.51,9.429
			c0.573,0.544-1.207,1.53-2.199,1.284c-1.19-0.297-2.156-0.186-2.739,0.821c-0.694,1.202-1.852,3.475-1.59,3.673
			c1.419,1.078,2.489-0.458,3.661-1.146c1.226-0.716,2.385-1.615,3.7-2.085c1.078-0.385,2.401-0.34,3.193,0.824
			c0.876,1.293,0,2.195-0.83,3.072c-0.716,0.76-1.516,1.464-2.116,2.312c-0.648,0.906-0.917,2.141,0.203,2.748
			c1.151,0.624,1.959-0.232,2.3-1.381c1.518-5.106,5.927-7.409,9.952-9.942c1.332-0.841,3.834,1.285,4.961-1.454
			c0.049-0.118,0.541,1.104,0.339,1.815c-0.935,3.278-3.233,5.503-5.874,7.415c-0.839,0.608-1.865,0.95-2.761,1.487
			c-1.65,0.988-1.703,2.838-2.234,4.414c-0.027,0.083,0.568,0.552,0.711,0.483c1.393-0.634,3.108-0.915,3.609-2.703
			c0.702-2.508,3.134-3.228,4.789-2.815c1.478,0.372,0.771,2.671,0.211,4.184c-0.198,0.535-0.114,1.178-0.157,1.771
			c0.6-0.166,1.207-0.313,1.798-0.502c0.479-0.151,1.278-0.605,1.363-0.494c4.002,5.32,5.338-1.347,7.876-2.052
			c2.327-0.651,5.137-2.781,6.392-0.522c0.958,1.725-1.173,4.913-3.671,6.026c-1.984,0.883-3.661,1.975-5.053,3.573
			c-0.829,0.951-1.499,2.278-0.34,3.073c1.046,0.718,2.143-0.38,2.967-1.243c0.087-0.091,0.158-0.219,0.188-0.339
			c0.689-2.755,2.885-3.489,5.284-3.756c0.293-0.032,1.132,1.26,1.017,1.784c-0.555,2.508-1.908,4.15-4.834,4.201
			c-1.481,0.024-2.345,0.95-0.612,2.144c0.913,0.627,1.612,1.244,1.718,2.435c0.056,0.626-0.438,1.826,0.844,1.649
			c0.768-0.104,1.267-0.933,0.972-1.842c-0.5-1.542,0.43-2.195,1.629-2.599c0.432-0.145,1.216-0.165,1.422,0.091
			c0.868,1.082-0.273,1.896-0.584,2.812c-1.703,5.006-7.125,5.243-10.493,8.122c-0.627,0.537-2.092,0.121-1.937,1.468
			c0.096,0.835,0.401,2.017,1.002,2.355c1.21,0.684,1.676-0.816,2.401-1.418c4.939-4.114,6.556-3.801,10.365,1.629
			c1.088,1.551,2.474,0.756,4.014-0.074c1.564-0.841,3.785-4.989,5.417,0.135c0.236,0.74,0.871,0.406,1.125-0.061
			c3.25-5.966,3.049-1.41,3.646,1.145c0.301,1.293,1.524,1.85,2.671,2.025c1.066,0.16,1.932-0.346,0.923-1.567
			c-0.295-0.361-0.968-0.381-1.382-0.678c-0.98-0.704-0.329-1.343,0.188-1.92c1.113-1.238,2.591-1.129,4.023-0.927
			c1.145,0.162,1.062,0.898,0.589,1.714c-0.4,0.692-0.76,2.409,0.316,1.549c4.896-3.925,10.324-0.209,15.656-1.479
			c6.932-1.647,9.088-0.601,13.907-7.599c2.079-3.015,5.001-1.714,7.429-1.775c1.553-0.037,0.39,1.745-0.444,1.904
			c-4.978,0.959-5.094,5.871-7.429,8.944c-1.206,1.591,0.695,2.546,1.156,3.797c-0.444,3.687-3.46,4.3-6.203,4.777
			c-3.208,0.556-6.494,0.383-9.597-0.916c-7.025,1.536-14.352,1.548-21.146,4.255c-1.325,0.525-3.507,1.11-3.857-0.064
			c-0.413-1.375,1.772-0.754,2.756-1.181c0.911-0.392,1.312-1.334,0.49-1.727c-1.299-0.617-2.82-1.684-4.319-0.497
			c-0.687,0.545-1.03,1.502-1.615,2.198c-0.639,0.758-0.875,2.463-2.014,2.076c-1.668-0.564,0.031-1.669,0.121-2.533
			c0.107-1.042-0.394-1.516-1.217-1.645c-1.301-0.197-2.635-0.294-3.479,1.107c-0.52,0.863-1.006,2.309-1.673,2.415
			c-1.791,0.275-1.854,3.653-3.935,2.401c-2.3-1.379,1.169-3.176,0.555-3.771c-3.009-2.913,0.955-3.534,1.416-5.239
			c0.148-0.553,0.026-1.689-0.2-1.752c-1.176-0.337-2.721-0.103-3.037,1.018c-1.723,6.096-7.552,5.589-11.824,7.487
			c-1.171,0.521-3.434,0.726-3.763-0.909c-0.414-2.065,2.044-1.272,3.145-1.82c1.479-0.737,3.351-0.181,3.741-2.949
			c0.306-2.158-1.217-1.573-1.861-2.139c-1.307-1.148-2.399,0.047-2.862,0.969c-3.457,6.903-8.946,4.559-13.015,1.869
			c-3.486-2.305-7.497-3.495-10.724-6.075c-0.648-0.519-1.483-1.552-1.349-2.144c0.245-1.079,1.262-0.148,1.951-0.145
			c0.742,0.004,1.375-0.252,1.271-1.075c-0.16-1.286-1.244-1.116-2.135-1.101c-6.345,0.087-14.741-5.796-17.662-11.106
			c-1.482-2.695-3.97-6.464-8.483-3.815c-1.275,0.749-1.171-0.308-1.494-0.896c-2.463-4.515-6.868-7.463-9.891-11.607
			c-0.917-1.259-2.529-8.609-8.124-4.841c-2.166-0.154-2.201-2.563-3.669-3.458c-1.897-2.192-3.415-4.651-4.978-7.083
			c-0.689-1.32-1.562-2.518-2.518-3.662c-1.887-2.803-3.052-5.986-4.739-8.881c-3.005-5.153-3.62-10.115,0.701-14.889
			C333.296,644.433,333.638,641.277,337.303,640.914z"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          fill="#E9DF9F"
          d="M562.072,716.518c0.216-1.154,0.216-1.154-1.286-2.584
			c-5.014-4.782-4.864-5.584,0.963-8.798c4.885-2.692,9.632-5.639,14.44-8.475c1.225-0.063,2.438,0.23,3.246,1.082
			c2.347,2.464,4.414,1.881,7.203,0.393c3.939-2.102,8.44-3.013,12.735-4.317c0.837-0.252,1.92,0.145,1.969-1.21
			c0.179-4.942,4.875-5.981,7.644-8.598c1.72-1.625,3.923,0.108,5.85,0.776c1.588,0.758,2.872,1.816,3.491,3.533
			c-0.181,4.432,3.535,5.396,6.371,7.129c3.032,1.101,3.646,4.642,6.245,6.184c0.912,1.033,2.605,1.229,3.021,2.799
			c1.361,4.061,4.77,6.701,6.869,10.247c0.487,0.297,0.479-0.654,0.221-0.198c-0.062,0.104-0.149,0.022-0.339-0.185
			c-0.876-0.959-2.135-1.862-0.298-3.093c5.561,0.691,7.297,1.911,7.902,5.528c-2.789,2.96-2.312,8.344,1.239,11.824
			c1.14,1.112,1.999,2.385,2.964,3.604c0.254,0.243,0.499,0.497,0.736,0.759c1.384,3.891,7.533,4.125,7.178,9.352
			c-0.49,3.198-0.393,6.052,0.912,9.37c1.39,3.525,3.445,7.48,2.126,11.714c-0.421,1.347,0.156,2.127,1.111,3.011
			c2.377,2.195,3.785,4.917,3.837,8.233c-2.54,1.692-4.399,0.297-5.5-1.73c-1.854-3.413-5.702-4.907-7.467-8.418
			c-0.753-1.492-2.6-1.521-4.212-0.699c-1.395,0.713-2.758,1.514-4.277,1.942c-1.542,0.432-3.144,0.689-4.462-0.489
			c-0.641-0.573-0.586-2.533-1.948-1.359c-0.604,0.52,0.848,1.64,1.34,2.5c0.95,1.661,4.083,1.661,3.461,4.538
			c-0.999,0.672-2.028,0.533-2.953-0.068c-4.702-3.056-9.975-2.674-15.193-2.527c-2.318,0.067-4.419-0.171-6.029-2.092
			c-0.708-0.842-1.757-1.053-2.819-1.21c-2.525-0.366-4.14-2.142-5.67-3.928c-0.973-1.139-1.463-2.604-0.688-4.021
			c0.93-1.695,2.252-0.62,3.428-0.224c1.763,0.592,2.87-0.012,3.919-1.57c3.152-4.684,10.502-5.566,15.159-2.18
			c2.632,1.914,5.747,2.459,8.953,1.689c1.104-0.265,2.199-0.558,3.336-0.745c-4.849,0.982-9.181-0.067-13.566-2.406
			c-3.026-1.611-6.73-1.929-10.027-3.131c-4.335-1.577-5.953-4.987-4.901-9.748c0.434-1.97,1.496-4.164-1.37-5.303
			c-0.236-0.097-0.51-0.103-0.77-0.143c0.175,0.317,0.49,0.04,0.733,0.193c2.051,1.284,1.406,2.845-0.078,3.691
			c-1.586,0.901-3.59,3.101-5.401,0.541c-1.758-2.479-4.555-3.995-5.999-6.602c-0.889-1.606-1.183-0.127-1.436,0.082
			c-1.778,1.463-3.518,2.966-5.711,4.21c3.877-1.584,6.64,0.227,9.212,2.554c0.885,0.802,1.659,1.819,1.221,3.105
			c-0.467,1.356-1.782,1.376-2.956,1.446c-1.477,0.091-2.831-0.388-4.152-1.005c-1.184-0.553-2.372-1.315-3.688-1.268
			c-7.071,0.25-11.233-4.993-16.277-8.551c-0.46-0.322-0.479-1.064-1.404-0.779c-6.729,2.075-10.5-2.212-14.084-6.719
			c-1.056-1.328-1.957-2.763-3.204-3.949C561.885,719.221,560.847,718.104,562.072,716.518z"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          fill="#CFCFA2"
          d="M346.662,720.297c-0.782,1.834-1.564,3.671-2.345,5.512
			c-1.181,1.211-2.629,1.046-3.981,0.556c-3.757-1.359-7.411-1.742-10.929,0.633c-2.925,2.109-3.872,5.454-4.968,8.557
			c-1.815,5.13-3.922,10.139-5.712,15.213c1.344-1.301,2.209-3.427,4.648-3.366c2.708,1.164,1.183,2.89,0.603,4.486
			c-4.013,4.062-7.917,8.238-12.098,12.118c-1.491,1.384-3.457,3.74-5.433-0.197c-1.118-2.226-2.337,0.222-3.081,0.988
			c-6.566,6.802-13.431,13.133-22.77,15.96c-2.935-0.879-4.789-2.271-3.468-5.825c0.934-2.516-1.369-3.229-2.913-4.274
			c-1-0.021-2.002-0.041-3.003-0.062c-0.51-1.002-0.056-1.81,0.52-2.59c1.628-1.389,3.966-1.75,5.255-3.634
			c1.512-4.997,6.218-6.542,9.914-9.156c5.502-3.889,7.194-9.436,7.062-15.806c-0.016-0.776-0.173-1.555-0.081-2.349
			c2.171-6.697,4.629-13.281,7.861-19.539c0.807-1.557,1.309-3.115,1.354-4.845c0.056-2.136,0.409-4.172,1.937-5.813
			c4.419-5.544,9.55-10.734,9.264-18.63c-0.091-2.554,1.48-4.735,1.64-7.236c0.08-0.362,0.204-0.708,0.347-1.046
			c1.462-2.771,4.103-4.551,5.917-7.028c1.846,1.472,5.371,1.948,4.854,4.91c-0.48,2.755,1.23,6.6-2.839,8.192
			c-0.33,0.132-0.605,0.247-0.523,0.774c0.98,6.282,0.91,6.652-0.994,11.981c-0.558,1.562-0.354,3.121,0.87,4.361
			c0.955,0.968,1.695,2.003,1.647,3.419c-0.042,1.293,0.621,1.183,1.703,1.331c3.283,0.457,6.246,2.291,8.823,4.038
			c1.516,1.03,4.307,1.877,4.533,4.684c0.065,0.812,0.881,0.812,1.511,0.322c0.517-0.403,1.023-0.837,1.636-1.067
			c1.32-0.506,2.782-1.433,4.021-0.212c1.125,1.106-0.339,2.111-0.645,3.161C346.654,719.321,346.395,719.771,346.662,720.297z"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          fill="#E7E3AE"
          d="M481.197,794.981c-4.468-4.635-5.041-11.108-7.821-16.542
			c0.866-1.33,0.099-2.415-0.385-3.656c-1.652-4.245-2.596-8.707-3.598-13.138c-0.524-2.307-0.141-4.482,1.95-5.952
			c1.941-1.371,3.937-1.227,5.83,0.148c2.229,1.619,0.215-0.819,0.769-0.79c-0.032-0.521,0.15,0.143,0.09-0.38
			c-0.553-4.773,0.366-5.679,5.229-3.954c3.508,1.241,7.636,1.12,10.38,4.21c0.281,0.316,0.435,0.557,0.833,0.396
			c3.802-1.506,7.495-0.198,11.203,0.444c2.288,0.398,2.667-0.643,2.497-2.664c-0.23-2.731,1.881-4.228,4.088-2.747
			c3.027,2.033,6.345,2.476,9.721,2.367c2.989-0.096,4.334,1.327,5.021,3.875c0.468,1.739,0.494,3.729,2.709,4.384
			c9.819,9.294,13.758,22.014,19.658,33.599c1.457,2.859,0.918,5.564,0.162,8.354c0.868,1.611,2.226,0.873,3.399,0.634
			c1.502-0.306,2.938-0.932,4.516-0.894c1.366,0.139,2.772,0.399,2.778,2.118c0.004,1.826-1.515,1.888-2.84,1.931
			c-1.362,0.049-2.503,0.67-3.7,1.198c-1.88,0.831-4.009,1.183-5.669,0.003c-3.169-2.253-6.72-2.547-10.349-2.774
			c-0.923-0.058-1.975,0.007-2.313-1.043c-0.257-0.799,0.431-1.412,0.926-2.039c1.779-2.26,0.916-5.401-1.847-6.318
			c-2.089-0.694-4.138-0.765-4.428-3.941c-0.188-2.074-9.184-5.012-11.255-3.982c-2.486,1.233-5.269,1.912-6.73,4.906
			c-0.89,1.824-3.119,2.752-5.281,3.035c-1.168,0.155-2.507,0.177-2.999-1.208c-0.446-1.264,0.611-1.91,1.509-2.432
			c1.533-0.887,2.752-1.995,3.211-3.771c0.229-0.89,0.547-1.85-0.213-2.595c-0.864-0.848-1.694-0.241-2.453,0.288
			c-2.289,1.599-5.235,1.591-7.566,3.14c-0.872,0.58-2.021,0.474-3.048,0.074c-0.368-0.144-0.694-0.362-1.017-0.589
			c-2.879,4.185-4.506-0.201-6.585-1.03c-2.587-1.029-3.388,1.515-4.9,2.402c-1.359,0.801,0.502,1.577,1.009,2.301
			c0.563,0.807,1.052,1.562,0.268,2.447C483.123,795.735,482.175,795.775,481.197,794.981z"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          fill="#D5D4AA"
          d="M529.123,761.146c-1.236,0.652-2.953,0.111-2.784-0.812
			c1.418-7.806-4.696-6.171-8.634-6.903c-2.201-0.414-4.432-0.259-5.916-2.346c-0.813-1.145-1.549-0.375-2.298,0.156
			c-2.319,1.643,0.504,1.009,0.617,1.486c0.443,1.864-1.433,2.797-1.945,4.341c-0.565,1.701-1.304-0.09-1.979-0.119
			c-4.287-0.173-8.726-3.5-12.852,0.576c-0.184,0.182-1.268-0.205-1.264-0.226c0.768-5.037-3.388-3.383-5.694-4.219
			c-1.82-0.655-3.596-1.483-5.464-1.947c-1.007-0.252-2.729-0.804-3.069,0.56c-0.31,1.245,1.384,0.548,2.086,0.939
			c1.646,0.919,0.667,1.508-0.272,2.121c-0.403,0.268-1.605,0.029-0.778,1.141c0.599,0.803,2.633-1.421,2.23,1.006
			c-0.046,0.267-2.594,0.587-3.635,0.116c-2.165-0.981-3.94-2.436-6.179,0.04c-2.415,2.669-1.534,5.484-0.333,7.925
			c1.749,3.554,0.842,7.994,3.997,11.079c0.505,0.488-0.567,1.868-1.584,2.379c-6.09-16.564-9.192-33.233-1.722-50.262
			c2.369-1.661,4.689,0.281,7.078,0.127c3.513-0.227,6.53-1.08,8.722-3.988c4.732-4.609,12.335-5.428,15.689-11.861
			c4.386,2.76,4.386,2.76,0.438,5.589c3.562-2.381,7.456-3.22,11.683-2.723c3.11,0.362,6.227,0.294,9.205-0.79
			c1.131-0.408,2.016,0.128,3.002,0.329c0.564,0.487,1.128,0.97,1.691,1.455c-0.027,1.028,0.801,1.59,1.259,2.358
			c0.559,0.94,0.504,2.009-0.688,2.113c-2.118,0.193-1.888,0.968-1.552,2.727c0.346,1.816-1.707,1.368-2.871,1.615
			c-0.255,0.054-0.505,0.126-0.747,0.226c0.335,0.659,0.84-0.11,1.228,0.137c0.981,0.255,1.622,0.882,1.989,1.812
			c-0.821,3.057-2.301,5.944-2.471,9.192c0.215,6.724,1.545,13.202,3.914,19.49C529.845,757.638,530.62,759.461,529.123,761.146z"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          fill="#EBE8BA"
          d="M481.197,794.981c0.902-0.028,2.03,0.192,2.42-0.836
			c0.482-1.274-0.888-1.332-1.561-1.812c-0.902-0.644-2.498-0.809-2.12-2.387c0.294-1.229,2.104-0.784,2.521-1.33
			c3.673-4.802,6.307-2.195,9.113,0.937c0.659,0.736,1.021,0.423,0.874-1.759c-0.055-0.783-0.45-1.648,0.56-1.958
			c1.022-0.316,1.334,0.499,1.496,1.323c0.371,1.908,2.385,2.031,2.974,1.327c2.314-2.764,6.844-0.923,8.663-4.503
			c0.525-1.04,1.604-0.399,2.439,0c1.221,0.582,1.921,1.596,1.511,2.851c-0.784,2.376-0.791,5.346-4.133,6.066
			c-0.656,0.141-1.776,0.453-1.428,1.432c0.141,0.397,1.534,0.812,1.961,0.558c2.008-1.197,5.142-0.628,5.286-4.748
			c0.087-2.512,4.263-1.581,6.342-3.188c2.291-1.771,5.205-2.077,7.561,0.923c0.927,1.181,2.436,2.326,4.456,1.77
			c0.979-0.27,2.215,0.754,1.846,1.475c-2.117,4.12,1.79,2.796,3.183,3.684c0.91,0.579,3.354,0.522,2.856,0.971
			c-2.396,2.154,4.678,5.581-1.08,6.854c-0.378,0.082-0.625,0.762-0.931,1.165c0.27,0.201,0.733,0.614,0.784,0.572
			c2.685-2.426,5.106,1.677,7.785,0.293c0.908-0.469,1.743,0.116,2.338,0.938c2.204,3.043,4.798,2.607,7.402,0.672
			c1.048-0.777,1.963-1.184,3.22-0.623c0.732,0.326,1.731,0.511,1.923-0.526c0.323-1.691-1.125-1.336-2.081-1.479
			c0.239-1.618,1.401-1.734,2.482-1.189c1.6,0.799,1.145,2.252,0.706,3.588c-2.756,6.729-5.259,13.576-8.39,20.124
			c-1.244,2.605-3.6,3.108-6.918,1.417c-4.57-2.324-9.096-5.021-14.724-5.13c-12.62-0.25-24.08-3.364-34.003-12.284
			C491.138,805.318,485.687,800.685,481.197,794.981z"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          fill="#EDF1D0"
          d="M653.26,732.918c-0.228-0.025-0.456-0.051-0.685-0.07
			c-1.188-1.298-1.436-3.058-2.265-4.531c-0.659-2.982-0.229-5.112,2.714-7.188c4.04-2.854,4.831-6.825,4.293-12.343
			c-0.645-6.596-5.176-6.896-8.968-8.854c-1.517-0.78-3.593-1.581-4.939,0.959c-0.534,1.008-2.24,1.462-3.185,0.941
			c-2.83-1.561-5.154-0.712-7.563,0.828c-0.672,0.429-1.522,0.103-2.178-0.426c-3.165-1.027-3.279-5.102-6.286-6.29
			c-2.666-1.858-4.962-4.04-6.268-7.098c-1.027-1.043-2.06-2.089-3.088-3.131c-1.038-0.762-2.252-1.434-1.653-3.075
			c1.648-1.055,3.123-2.131,0.488-3.748c-0.682-0.42-1.422-0.915-0.982-1.834c0.158-0.333,0.86-0.646,1.253-0.583
			c1.138,0.184,2.713,0.881,2.797-0.828c0.073-1.49-0.978-3.068-2.788-3.24c-4.537-0.431-2.984-2.654-1.272-4.561
			c2.699-3.011,5.19-6.369,8.082-8.955c1.794-1.603,5.341-4.204,6.782-4.094c4.037,0.301,5.621-1.28,6.84-4.402
			c6.842,6.532,12.163,14.349,17.468,22.038c5.086,7.373,8.779,15.636,8.8,25.019c0.011,3.55,1.739,6.688,1.707,10.547
			c-0.07,8.761-4.205,15.425-8.397,22.335C653.521,731.071,653.485,732.052,653.26,732.918z"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          fill="#CCC8A4"
          d="M302.01,600.148c-2.679,5.988-5.36,11.977-8.039,17.966
			c-2.479,4.637-4.776,9.36-7.104,14.072c-0.706,1.43-1.141,2.854-1.566,4.349c-0.558,1.95-0.747,4.189-3.107,5.058
			c-0.258,0.805-0.517,1.611-0.774,2.422c-4.472,5.671-7.46,12.305-11.728,18.105c-0.806,1.093-1.408,2.938-3.482,2.233
			c-0.143-0.223-0.235-0.462-0.302-0.712c-0.313-1.154,0.428-2.007,0.833-2.954c-2.546,0.338-5.231-2.129-7.709,0.606
			c-1.471,1.622-2.75,4.127-5.75,2.762c-0.546-0.5-0.724-1.246-0.417-1.783c2.565-4.497,3.767-10.081,9.42-12.162
			c1.122-0.412,1.616-1.238,1.978-2.244c1.232-3.45,2.868-6.594,5.252-9.482c1.55-1.878,3.619-4.044,1.929-7.239
			c-0.779-1.472,0.984-2.438,2.116-3.324c4.709-3.678,4.704-3.689,2.052-8.899c-0.976-1.913-2.047-1.936-2.924,0.009
			c-0.563,1.248-1.39,2.183-2.446,2.977c-4.991,4.145-10.331,7.866-14.99,12.409c-1.03,1-2.14,1.899-3.557,2.315
			c-3.048-0.252-4.907,3.086-8.014,2.681c-4.718-0.378-5.689-1.215-4.718-4.002c0.435-1.253,1.068-1.871,2.536-1.911
			c2.716-0.07,7.361-3.925,8.001-6.372c0.553-2.113-2.787-6.132-5.36-5.85c-3.33,0.36-6.929-2.1-10.052,1.001
			c-0.694,0.691-1.759,0.013-2.213-0.927c-0.895-1.853,1.933-7.235,4.005-7.449c1.98-0.201,3.855-0.665,5.659-1.474
			c0.709-0.317,1.424-0.838,2.22-0.471c6.249,2.886,10.867-0.78,15.583-3.909c1.013-0.668,1.948-0.709,2.951-0.342
			c1.436,0.524,2.548,0.165,3.457-1.018c1.899-1.088,4.152-2.817,5.918-0.752c1.824,2.132-0.5,4.089-1.758,5.732
			c-1.556,2.034-3.163,4.105-5.417,5.477c-1.982,0.507-3.871-0.193-5.75-0.598c-2.684-0.572-4.192,0.738-5.333,2.859
			c-0.499,0.932-1.106,2.07-0.136,2.854c1.103,0.893,2.028-0.234,2.542-1.008c1.504-2.266,3.01-3.206,5.061-0.66
			c2.38,0.104,3.714-1.663,5.158-3.04c3.65-3.495,7.307-6.99,10.44-10.98c3.725-1.846,8.115-2.066,11.527-4.715
			c0.387-0.302,0.072-0.339,0.312-0.125c2.678,2.419,4.461-0.943,6.728-0.963c2.07-0.021,3.849-0.387,4.688-2.661
			c0.259-0.7,1.096-1.367,1.886-1.116c1.017,0.322,1.085,1.351,0.968,2.315C302.473,599.57,302.281,599.884,302.01,600.148z"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          fill="#E0D790"
          d="M562.072,716.518c3.862,5.506,6.766,12.079,14.118,13.916
			c2.808,0.7,6.131-2.687,8.494,2.648c1.049,2.367,6.532,5.8,9.005,5.691c4.813-0.209,8.39,2.896,12.8,2.74
			c0.89-0.031,2.466-0.688,1.389-1.339c-2.195-1.339-3.092-5.677-7.23-3.3c-1.901,1.092-3.681-1.08-5.579-1.548
			c0.475-1.816,2.207-0.503,3.206-1.318c2.567-2.089,5.293-3.982,7.98-5.918c0.383-0.277,0.906-0.393,1.383-0.476
			c0.069-0.013,0.382,0.838,0.348,0.857c-4.152,2.241-0.042,4.209,0.777,4.769c2.154,1.482,3.403,5.494,7.202,3.461
			c0.608-0.326,1.073-1.033,1.402-1.669c0.094-0.18-0.477-0.988-0.836-1.065c-1.09-0.23-2.564,1.119-3.228-0.837
			c0.353-0.081,0.736-0.288,1.055-0.218c1.744,0.402,4.031-1.149,5.18,1.438c0.728,1.642,0.961,3.125-0.225,4.779
			c-1.12,1.562-0.903,3.234,0.76,4.466c0.646,0.478,1.377,0.839,2.071,1.255c-0.877,0.345-1.754,0.693-2.605,1.03
			c6.03,2.229,12.103,4.292,18.021,6.727c3.665,1.507,7.621-0.271,11.161,1.452c0.543,0.266,1.013,0.678,1.517,1.02
			c-0.28,0.186-0.656,0.6-0.822,0.526c-4.021-1.729-7.965,2.261-12.04,0.091c-1.644-0.874-3.52-0.849-4.811-2.865
			c-0.897-1.397-3.13-3.096-5.317-0.458c-0.649,0.787-2.397,0.659-3.642,0.96c-0.865,0.212-2.352,0.246-2.488,0.688
			c-1.354,4.428-3.385,6.086-7.823,3.056c-0.866-0.593-1.033,1.733-0.478,2.322c1.82,1.933,2.91,5.136,6.582,4.328
			c0.458-0.103,1.297-0.003,1.452,0.281c3.041,5.564,8.358,3.529,12.661,3.282c4.589-0.262,7.736,3.094,11.919,3.333
			c2.074-0.031,3.813,0.278,3.115,3.063c-7.877-2.55-15.565-5.804-24.222-4.807c-2.016,0.231-4.655-0.643-6.267-1.932
			c-9.562-7.632-21.274-11.055-31.83-16.71c-6.164-3.306-11.232-7.682-14.931-13.945c-3.747-6.347-9.957-10.668-15.512-15.433
			c-1.863-1.595-3.81-2.898-2.601-5.795c0.342-0.816-0.087-1.401-0.716-2.029c-0.813-0.812-3.282-1.397-1.761-2.802
			c1.506-1.392,3.891-1.455,5.461,0.809C557.729,713.292,559.193,715.68,562.072,716.518z"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          fill="#D4D0AD"
          d="M195.007,655.815c-0.716-6.466,0.638-12.669,2.399-18.817
			c0.096-1.759-0.789-3.525,0.027-5.28c2.718-2.88,4.008-7.229,8.503-8.289c1.059,1.036,0.472,2.114,0.178,3.267
			c-0.713,2.778,0.671,4.198,3.515,3.667c1.027-0.19,1.921-0.885,2.981-0.955c0.902-0.058,2.441,0.147,1.869,1.187
			c-2.001,3.64,1.023,1.334,1.695,1.458c0.732,0.135,1.547-0.175,2.329-0.235c2.196-0.173,3.673,0.423,2.04,2.8
			c-1.241,1.806-0.198,3.889-1.145,5.645c-0.907,0.414-1.787,0.103-2.636-0.171c-2.794-0.903-3.92,0.553-4.822,2.962
			c-1.018,2.711,0.271,3.803,2.508,4.542c-0.136,3.918,4.041,5.371,5.03,8.694c0.165,0.553,0.776-0.489,1.271-0.641
			c0.914-0.281,2.745-1.157,2.539-0.041c-0.723,3.909,3.316,4.693,4.199,7.403c-0.304,3.278-2.971,4.701-5.384,5.549
			c-7.131,2.512-11.898,7.15-14.461,14.178c-0.504,1.379-1.249,2.31-2.913,1.761c-1.065-0.695-1.036-1.779-0.857-2.797
			c0.614-3.477-0.005-6.413-3.257-8.397c-0.534-0.328-0.787-1.017-1.047-1.615c-0.638-1.675-0.907-3.516-1.995-5.003
			c-1.788-2.443-2.497-5.07-1.51-8.014C196.487,657.404,196.358,656.396,195.007,655.815z"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          fill="#E8E3D6"
          d="M251.54,636.174c6.035-4.995,12.068-9.988,18.101-14.979
			c3.159-0.101,5.062,2.908,3.247,6.018c-3.348,5.74-5.35,12.224-9.688,17.404c-0.76,0.907-1.488,1.825-2.457,2.518
			c-3.648,2.594-6.22,5.868-7.146,10.392c-0.88,4.294-4.233,6.564-7.435,9.185c-4.083,3.339-8.293,6.629-11.443,11.065
			c-1.62,2.281-2.039,3.987-0.107,6.088c0.433,0.474,0.493,1.199,0.45,1.871c-4.949,2.56-9.919,5.058-15.741,4.693
			c-0.477-2.105-0.122-4.348-1.342-6.354c-0.552-0.907-0.072-2.033,0.746-2.841c3.698-1.104,4.768-4.922,7.375-7.22
			c8.01-7.049,13.795-15.962,19.435-24.926c0.523-1.359,1.476-2.424,2.534-3.362c1.947-1.729,3.311-3.502,2.087-6.355
			C249.628,638.138,250.082,636.808,251.54,636.174z"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          fill="#E0DEBD"
          d="M294.778,737.579c2.13,6.216-2.199,11.025-3.594,16.47
			c-0.569,2.222-3.098,1.813-4.341,2.318c-2.546,1.034-3.324,4.207-6.499,4.306c-2.358,0.07-0.403,3.833-2.79,4.484
			c-1.964-0.901-1.419-3.974-3.865-4.456c-0.907-0.531-1.676-1.237-2.495-1.883c2.047-4.761-0.408-8.295-3.199-11.741
			c2.767-0.364,3.656-1.569,1.971-4.123c-0.833-1.265-0.909-2.571-0.143-4.057c2.726-5.294,7.166-9.703,8.191-15.89
			c0.123-0.738,0.835-1.313,1.736-1.362c1.448,1.676,3.075,1.827,5.452,0.415c2.665-1.583,3.652,0.631,4.971,2.125
			c1.894,3.318,1.873,6.974,1.798,10.618C291.929,736.821,292.397,738.107,294.778,737.579z"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          fill="#D2D2A4"
          d="M279.858,721.879c-2.145,6.58-5.611,12.441-9.595,18.055
			c-1.032,1.452,1.03,2.383,1.217,3.801c0.13,0.995,2.133,1.309,0.514,3.161c-1.632,1.87-2.667,0.575-3.998,0.182
			c-9.09-0.441-9.453-1.178-5.434-11.043c2.985-1.656,6.434-2.556,8.577-5.56c0.902-1.264,2.175-2.297,1.657-4.095
			c-0.282-0.976-0.029-1.824,0.698-2.472c1.338-1.19,1.407-2.281,0.09-3.587c-0.737-0.732-1.103-1.845-1.009-2.874
			c0.226-2.436-1.15-3.814-2.945-5.001c3.676-8.139,9.874-14.946,12.447-23.664c3.046-4.156,7.941-5.845,11.936-8.711
			c1.867-1.336,4.85-1.701,6.766,0.749c1.833,2.342,0.704,4.453-0.995,6.314c-0.985,1.079-1.914,1.988-1.509,3.733
			c0.493,2.115-1.898,2.576-2.709,3.983c-1.086,1.4-1.077,3.192-1.708,4.744c-0.865,2.121-1.532,4.522-4.709,4.321
			c-1.767-0.108-2.111,1.76-2.981,2.796c-3.153,3.746-5.654,7.79-5.036,13.025C281.248,720.718,280.915,721.544,279.858,721.879z"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          fill="#EBE8BA"
          d="M527.51,727.385c-0.516-0.515-1.029-1.029-1.544-1.544
			c3.2-0.676,6.086-1.866,7.899-4.818c17.741,15.211,27.166,34.675,29.911,57.711c0.93,7.79-1.213,15.09-2.451,22.562
			c-1.639,1.329-3.254,0.486-4.769-0.195c-1.15-0.519-1.612-1.733-0.352-2.408c2.158-1.158,1.603-2.364,0.442-3.704
			c-1.355-1.567-1.839-3.132-0.406-4.913c0.336-0.413,0.368-0.954,0.306-1.495c-2.2-19.043-5.768-37.514-20.019-51.966
			C533.513,733.551,530.862,730.129,527.51,727.385z"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          fill="#DBDAB4"
          d="M294.786,694.354c2.662-3.057,2.725-7.525,5.869-10.424
			c1.252-1.154-0.571-3.316-1.304-3.41c-2.001-0.259-4.09-0.534-6.126,1.014c-3.517,2.679-7.411,4.86-11.147,7.249
			c3.65-9.427,10.386-16.861,16.177-24.897c2.59-3.589,5.5-6.948,8.266-10.412c1.345-1.365,2.564-2.834,3.641-4.424
			c3.066-2.557,4.675-6.513,8.251-8.573c2.411,1.044,3.916,2.521,2.589,5.377c-0.555,1.194-0.285,2.272,0.507,3.271
			c0.835,1.051,1.372,2.21,1.177,3.607c-1.524,3.106-3.312,6.001-6.231,8.02c-0.45,0.264,0.155,0.091-0.336,0.182
			c-7.25,1.393-7.542,7.726-9.24,13.03c-0.315,0.985-0.278,2.13-0.964,2.95c-1.265,1.516-0.842,2.558-0.046,4.376
			c1.425,3.25-0.323,6.677-2.009,9.485C301.919,694,298.762,695.574,294.786,694.354z"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          fill="#F3F3D0"
          d="M527.51,727.385c6.159,1.363,8.361,7.172,12.017,11.154
			c11.539,12.573,15.675,28.192,17.119,44.763c0.15,1.733,0.954,3.413,1.49,5.105c0.114,0.358,0.557,0.954,0.546,0.96
			c-4.9,3.097,0.312,5.346,0.641,7.985c0.079,0.617,0.18,1.377-0.094,1.865c-0.6,1.061-2.865-1.584-2.579,0.886
			c0.064,0.569,3.039,0.803,4.676,1.192c-0.219,1.588,0.543,3.33-0.759,4.744c-0.097-2.085-0.023-4.395-3.188-2.398
			c-3.132-0.554-6.276,3.938-9.4-0.023c4.964-6.925,6.934-14.613,3.741-22.608c-5.382-13.48-10.781-27.029-20.498-38.237
			c-2.231-2.577-4.831-4.799-6.897-7.519C525.901,732.843,524.343,729.157,527.51,727.385z"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          fill="#BCBC8A"
          d="M346.662,720.297c-0.33-0.362-0.994-0.802-0.929-1.066
			c0.259-1.111,2.427-2.256,0.969-3.09c-1.212-0.694-3.74,0.138-4.378,1.677c-1.985,4.791-2.263,0.028-3.225-0.497
			c-0.222-0.121-0.048-0.902-0.282-1.075c-2-1.492-3.874-3.407-6.135-4.238c-2.663-0.977-4.783-4.917-8.417-2.068
			c-0.089,0.07-0.438-0.189-0.667-0.3c3.369-4.535-7.253-8.469-0.507-13.622c1.174-0.896-0.749-5.221,1.414-7.646
			c0.303-0.335-0.592-0.416-1.086-0.253c-1.463,0.479-1.943-0.716-2.01-1.627c-0.074-0.999,0.841-1.559,1.905-1.523
			c2.967,0.096,3.311-0.913,1.278-2.969c-0.174-0.177-0.382-0.634-0.352-0.655c5.954-4.351-1.743-5.407-1.984-8.245
			c-0.183-0.354-0.125-0.675,0.139-0.965c3.846,0.352,7.688-1.462,11.53-0.062c1.543,0.563,1.452-2.888,3.35-1.305
			c1.233,0.732,1.849,1.898,2.266,3.208c-0.211,2.409-1.98,4.345-1.668,7.052c0.206,1.768-2.661,1.333-3.718,2.927
			c-2.536,3.826-4.145,7.616-2.409,12.176c0.778,0.862,1.975,0.59,2.883,1.076c2.451,1.321,4.073,3.225,2.619,5.988
			c-1.007,1.919,0.055,2.115,1.277,2.586c3.776,1.462,7.447,2.705,10.406-1.623c0.739-1.078,2.134-1.548,3.38-0.297
			C350.289,709.288,349.79,715.244,346.662,720.297z"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          fill="#FDF8C8"
          d="M318.413,640.918c-2.479,3.012-4.5,6.449-7.874,8.653
			c-1.886,0.187-1.744-1.479-2.236-2.508c-0.588-1.236-0.389-2.817-1.647-3.764c1.15-9.906,3.904-19.816,0.693-29.77
			c-1.474-4.561-3.542-8.927-5.338-13.382c-0.037-0.29-0.073-0.581-0.11-0.873c0.824-0.911-0.227-2.764,1.597-3.172
			c3.402,5,6.383,10.367,10.334,14.89c3.504,4.016,5.95,9.085,10.835,11.848c1.316,1.541,2.634,3.086,3.949,4.627
			c-0.005,0.935-0.608,1.617-1.006,2.388c-1.278,2.912-2.634,5.777-4.691,8.243C321.69,639.478,320.193,640.424,318.413,640.918z"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          fill="#FBE798"
          d="M200.404,670.771c4.46,3.505,5.397,8.27,4.856,13.562
			c0.594,0.668,2.027,0.758,1.518,2.191c-2.482,2.834-5.29,5.265-8.66,7.006c-1.836-0.441-3.562-0.094-5.373,0.391
			c-2.083,0.553-4.811,1.967-5.186-2.121c-0.094-1.021-1.279-0.634-1.905-1.008c-1.758-1.046-4.096-1.181-5.184-3.282
			c-0.062-1.641-0.47-3.053-2.369-3.439c-1.008-0.375-2.066-0.646-2.858-1.442c-0.925-0.194-2.05-0.523-1.955-1.561
			c0.193-2.096,2.481-1.468,3.618-2.402c5.226-1.643,9.976-4.94,15.844-4.364c1.544,0.151,2.486-1.27,3.615-2.137
			C197.541,671.262,198.523,669.666,200.404,670.771z"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          fill="#D5D0B2"
          d="M235.062,685.734c-4.226-4.82-2.366-8.67,3.639-14.354
			c4.646-4.401,12.417-6.379,13.56-13.687c1.366-8.724,10.254-11.488,13.421-18.648c1.903-4.301,4.579-8.271,6.379-12.608
			c0.79-1.905,1.134-4.777-2.42-5.242c0.756-1.038,2.717-1.265,1.945-3.349c-0.477-1.282,0.528-2.241,1.68-1.701
			c1.127,0.535,2.658-1.107,3.394,0.854c1.899,5.064,3.321,9.981-3.541,12.474c-1.03,0.374-1.142,0.866-1.054,1.686
			c0.505,4.707-0.247,6.531-3.639,10.106c-0.819,0.862-1.337,2.238-1.513,3.451c-0.657,4.514-5.25,5.693-7.215,8.752
			c-2.177,3.388-6.175,5.727-6.562,10.239c0.379,0.976-0.24,1.664-0.964,1.783c-3.471,0.562-4.775,3.88-7.335,5.572
			c-0.745,0.495-1.038,1.146-0.753,1.995c0.793,2.388-0.035,4.266-2.557,6.012c2.327,0.319,3.685-1.965,5.288-0.535
			c0.795,0.708,0.78,1.591,0.056,2.411C243.273,683.383,239.336,684.976,235.062,685.734z"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          fill="#FDF1B2"
          d="M200.404,670.771c-2.064,0.21-2.93,2.043-4.294,3.191
			c-1.592,1.344-2.763,2.476-5.414,1.281c-2.764-1.249-4.968,2.451-8.188,2.529c-1.665,0.037-3.676,0.795-5.6,0.893
			c1.328-2.582-1.902-5.271,0.286-8.094c0.448-0.579-1.947,0.435-3.295-0.489c5.99-3.728,11.798-7.36,17.629-10.954
			c1.409-0.866,3.312-1.161,3.479-3.312c1.33-0.67,3.552-0.309,2.795,0.891C194.199,662.439,199.572,666.125,200.404,670.771z"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          fill="#C4C591"
          d="M246.867,680.944c-0.125-2.184-0.91-3.169-2.759-1.245
			c-0.657,0.684-0.755,2.216-2.13,1.145c-0.776-0.605-1.614-1.522-1.79-2.426c-0.409-2.104,1.564-0.396,2.106-1.107
			c0.357-0.465,0.798-0.934,0.973-1.475c2.46-7.623,2.451-7.627,9.867-12.13c2.815,0.647,3.69-1.855,5.2-3.266
			c1.428-1.338,2.478-2.313,4.968-1.663c3.591,0.938,4.613,2.401,3.2,5.709c-0.522,4.195-4.454,5.633-6.764,8.385
			c-2.109,1.322-3.682,3.261-5.567,4.84c-1.9,1.594-3.956,2.896-6.496,3.199C247.406,680.923,247.137,680.932,246.867,680.944z"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          fill="#E1D27B"
          d="M613.189,682.641c0.552,1.024,1.103,2.048,1.653,3.075
			c-6.156-1.894-11.238,1.595-11.981,8.233c-0.117,1.043-0.744,2.061-1.688,1.641c-4.961-2.21-9.618,1.17-12.559,3.047
			c-5.741,3.658-8.714,1.19-12.426-1.976c0.553-1.011,1.106-2.019,1.66-3.028c1.364-1.564,2.569-3.315,4.703-3.971
			c1.372-0.477,2.146,0.589,3.118,1.182c3.356,2.044,5.562,1.595,7.884-1.601c0.388-0.537,0.729-1.225,1.35-1.372
			c2.182-0.515,4.657-0.643,3.805-4.021c-0.058-0.212,0.209-0.588,0.426-0.612c4.461-0.474,3.949-4.123,4.545-7.063
			c3.009-1.727,3.59,1.514,5.162,2.611C610.418,679.89,611.75,681.343,613.189,682.641z"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          fill="#FDF5C4"
          d="M175.244,682.628c1.125,0.008,2.314-0.316,3.242,0.655
			c1.972,2.681,0.878,4.84-1.108,6.907c-1.451,1.511-3.217,2.643-4.735,4.06c-1.479,1.385-3.194,2.51-5.169,4.44
			c5.617-1.124,10.63-1.696,14.207-5.598c-0.186-0.618-0.37-0.646-0.694-0.522c-1.377,0.516-2.999,2.171-4.168,0.608
			c-1.139-1.526,0.755-2.755,1.717-3.895c0.66-0.783,1.261-1.678,2.315-2.03c2.267,1.251,4.345,2.945,7.213,2.693
			c0.456-0.042,1.825,0.094,1.222,1.251c-0.412,0.792-2.082,1.426-0.275,2.208c1.123,0.486,2.642,0.507,3.677-0.461
			c2.102-1.961,3.774-1.021,5.43,0.585c-8.765,6.66-19.275,7.879-29.656,9.456c-2.679,0.408-5.403,0.522-8.106,0.771
			c0.462-2.207,2.942-3.662,2.338-6.269C167.164,692.777,172.519,688.814,175.244,682.628z"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          fill="#DAD9B5"
          d="M323.978,751.887c-0.557-1.318,1.343-3.229-0.985-4.125
			c-0.909-1.331-0.259-2.624,0.44-3.617c3.862-5.496,5.305-11.623,5.073-18.237c5.243-4.207,10.537-0.426,15.811-0.099
			c-1.767,8.949-8.587,14.328-14.443,20.373C327.971,748.146,325.947,749.988,323.978,751.887z"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          fill="#E8E3D6"
          d="M206.778,686.524c-0.505-0.732-1.012-1.462-1.518-2.195
			c2.123-4.902,5.432-8.893,8.818-13.01c2.062-2.51,4.75-2.14,6.88-3.519c2.26-1.462,4.425-2.882,6.27-4.847
			c2.134-1.611,4.143-3.358,5.848-5.438c1.626-1.42,3.029-2.69,3.495-5.205c0.787-4.244,5.469-5.52,8.896-2.847
			c-2.331,5.064-6.5,8.686-10.195,12.623c-3.207,3.42-7.348,5.835-10.83,9.001c-1.163,1.06-2.784,1.39-4.239,1.972
			c-0.439,0.176-3.249-0.646-0.808,1.627c1.337,1.248,0.416,3.724,0.154,5.653c-3.211,3.774-8.404,5.908-9.432,11.517
			c-0.227,1.231-1.564,1.676-2.476,2.393C203.955,692.055,207.555,689.045,206.778,686.524z"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          fill="#FDF0B1"
          d="M247.675,680.91c3.851-2.866,7.705-5.733,11.556-8.603
			c2.058-1.061,4.109-1.48,5.973,0.34c1.84,1.801,0.174,3.522-0.221,5.265c-0.211,0.612-0.242,1.434-0.664,1.801
			c-4.421,3.884-4.8,8.216-3.08,13.785c0.621,2.002-1.307,5.173,0.045,7.915c0.099,0.201-0.466,0.732-0.722,1.108
			c-0.69-0.732-1.679-1.347-2.026-2.216c-2.464-6.161-7.668-10.918-8.969-17.665C249.389,681.717,248.732,681.019,247.675,680.91z"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          fill="#F0E7AB"
          d="M648.547,773.688c-0.274-1.8-1.995-2.122-3.115-3.063
			c-2.512-2.135-4.983-4.325-7.555-6.388c-1.138-0.919-0.134-1.657,0.271-1.327c1.5,1.223,5.622-4.392,4.971,1.894
			c1.232-0.004,2.516,0.211,3.688-0.058c3.188-0.729,6.429-5.506,9.466-1.046c1.997,2.928,5.429,4.321,6.791,7.613
			c0.958,2.309,2.531,3.596,5.151,3.253c0.217,0.343,0.254,0.707,0.115,1.087c-2.173,2.228-4.864,2.94-7.881,2.707
			C656.974,775.552,652.021,776.501,648.547,773.688z"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          fill="#A7B06E"
          d="M352.309,703.859c-0.758,0.116-1.98-0.021-2.207,0.388
			c-3.991,7.229-9.271,3.212-14.249,1.714c-0.97-0.294-0.953-0.351-0.358-1.537c2.404-4.8,1.161-6.594-4.381-6.29
			c-1.205,0.066-1.902-0.09-0.926-1.404c0.963-0.57,1.692-1.53,2.875-1.729c1.454-0.059,2.67-0.924,4.06-1.219
			c3.689-0.78,7.348-1.752,9.975,2.333c0.51,0.796,1.715,2.047,2.097-0.63c0.245-1.716,1.68-3.427,2.962-4.896
			C355.319,694.979,353.832,699.419,352.309,703.859z"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          fill="#CFCD93"
          d="M527.469,714.86c-6.212,3.736-13.309-0.128-19.676,2.291
			c-0.699,0.266-1.771,0.32-2.039,0.812c-1.309,2.406-4.177,2.157-4.893,1.082c-0.941-1.425,1.881-2.133,2.878-2.607
			c4.864-2.318-3.19-2.322-0.598-3.982l0,0c2.537-0.387,5.079-0.608,7.61-1.238c1.649-0.409,2.663-0.482,2.413-2.423
			c-0.122-0.947,1.15-2.033-0.068-2.839c-1.114-0.74-1.43,0.742-2.189,1.075c-2.419,1.048-4.266-0.068-3.781-2.71
			c0.646-3.545,0.221-7.574,3.536-10.214c0.762-0.608,1.374-1.636,2.627-1.255c0.053,0.51,0.103,1.017,0.155,1.526
			c-0.765,3.743,1.645,5.755,3.519,8.634C519.955,707.607,523.659,711.191,527.469,714.86z"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          fill="#8F9D55"
          d="M352.157,690.59c0.125,1.481-0.025,2.854-1.27,3.912
			c-0.486,0.412-1.075,0.906-1.233,1.472c-0.538,1.906-3.175,2.618-4.069,0.628c-2.724-6.053-6.519-0.539-9.653-1.289
			c-0.763-0.184-1.704,0.384-2.564,0.602c-0.209-0.167-0.481-0.4-0.43-0.605c0.849-3.433,0.126-7.089,1.708-10.444
			c1.611-3.413,6.152-5.366,9.816-3.941c0.986,1.34,1.972,2.682,2.958,4.019C347.517,688.065,350.486,688.785,352.157,690.59z"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          fill="#C9CC94"
          d="M269.63,712.446c3.049-0.711,4.18,1.039,4.143,3.613
			c-0.027,1.872-0.605,3.653,1.887,4.448c0.36,0.114,0.681,1.825,0.504,1.931c-3.412,2.043-0.929,6.558-3.845,8.84
			c-2.939,2.297-5.476,5.268-9.757,4.756C264.113,727.929,266.584,720.1,269.63,712.446z"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          fill="#FDF8CA"
          d="M264.983,677.912c1.662-5.536-1.796-5.824-5.75-5.604
			c2.422-2.607,4.847-5.215,7.27-7.821c4.879-5.1,7.248-11.848,11.467-17.373c0.923-1.206,2.289-2.076,3.45-3.099
			c1.718,4.605-2.348,7.328-4.104,10.356C272.858,662.06,271.023,671.104,264.983,677.912z"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          fill="#FDEDB2"
          d="M169.878,677.077c-3.071,5.807-5.987,11.619-9.232,17.243
			c-0.779,1.351-2.298,1.107-2.625-0.898c-1.033-6.351,3.321-15.04,8.924-17.866c0.702-0.354,1.375-0.982,2.083-0.267
			C169.435,675.705,169.568,676.39,169.878,677.077z"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          fill="#FCF6CC"
          d="M282.193,641.593c1.271-5.634,4.813-10.378,5.961-16.182
			c0.538-2.709,3.794-4.881,5.817-7.297c0.523,9.525-3.931,17.218-9.167,24.571C282.787,645.52,283.303,641.416,282.193,641.593z"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          fill="#CDCCA0"
          d="M207.64,694.25c2.169-6.036,5.484-11.124,11.565-13.909
			c0.616,0.435,0.566,0.925,0.117,1.446c-1.807,1.896,0.179,2.697,0.968,4.04c0.913,1.551-0.373,3.106-0.969,4.601
			C215.631,692.32,212.496,695.916,207.64,694.25z"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          fill="#AAB56D"
          d="M274.214,770.656c2.809-2.197,3.062,1.487,4.623,2.166
			c1.599,0.694-0.451,1.507-0.687,2.335c-0.483,1.698-2.114,3.847,1.692,3.708c0.989-0.034,0.723,1.155,0.752,1.891
			c-2.977,1.528-5.75,6.226-8.802,4.036c-2.747-1.966,1.262-5.701,1.709-8.782C273.762,774.227,273.98,772.438,274.214,770.656z"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          fill="#CDBBCC"
          d="M668.974,601.656c0.194-2.023,2.181-1.615,3.283-2.411
			c1.072,3.342,4.005,5.307,5.962,7.996c0.969,1.332,2.012,2.521,0.81,4.155c-1.45,1.968-2.806,0.472-4.007-0.329
			c-2.044-1.359-4.053-2.806-5.919-4.398C667.328,605.152,667.273,603.373,668.974,601.656z"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          fill="#D3CE8D"
          d="M668.259,775.336c-0.01-0.255-0.023-0.515-0.044-0.771
			c-1.339-3.041-2.227-6.177-5.44-8.233c-2.229-1.425,0.476-4.015,0.057-6.174c-0.282-1.45,1.228-3.698-2.035-3.212
			c-0.272,0.04-0.604-0.308-0.91-0.475c3.153-4.617-3.715-9.625,0.551-14.202c-0.475,6.804,4.435,12.249,4.769,18.852
			c-0.33,3.587,2.85,6.079,2.973,9.522c1.844,2.629,1.994,5.56,1.535,8.603c-0.498,0.55-1.168,0.418-1.782,0.4
			c-0.721-0.027-1.682,0.31-2.017-0.576c-0.252-0.668,0.528-1.069,0.96-1.514C667.499,776.917,668.178,776.315,668.259,775.336z"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          fill="#D5C9D5"
          d="M642.243,620.482c0.018-0.528,0.034-1.056,0.055-1.581
			c1.528,2.253,5.754,1.091,6.157,4.341c0.389,3.137,0.766,6.518-1.276,9.419c-0.424,0.597-1.139,1.586-1.526,1.502
			c-1.135-0.245,0.04-1.243-0.291-1.866C643.39,628.604,641.844,624.802,642.243,620.482z"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          fill="#D0CE94"
          d="M533.865,721.022c-0.55,5.021-3.68,5.812-7.899,4.818
			c-1.405,0.585-2.351,2.162-4.104,1.924c-0.106-0.016-0.239-0.907-0.116-1.321c0.607-2.024,2.2-2.312,4.035-2.226
			c0.587,0.025,1.187-0.243,1.78-0.378c-0.388-0.45-0.718-0.976-1.175-1.336c-1.195-0.954-1.458-1.886,0.037-2.704
			c1.322-0.722,2.67-2.312,2.736-3.484C530.728,717.884,532.296,719.454,533.865,721.022z"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          fill="#FCEAA3"
          d="M162.695,697.488c2.771,3.413-0.336,4.634-2.338,6.269
			c-1.203,0.461-2.392,0.969-3.615,1.372c-1.331,0.439-2.687,0.457-3.237-1.116c-0.424-1.212-0.625-2.595,0.795-3.448
			C156.898,699.004,159.208,696.63,162.695,697.488z"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          fill="#D8CBD8"
          d="M690.116,587.165c-0.084,0.121-0.319,0.812-0.633,0.849
			c-2.728,0.316-4.895-1.363-7.271-2.256c-1.558-0.59-2.36-1.949-2.203-3.732c0.118-1.325,0.729-2.19,2.1-2.381
			C684.049,579.373,690.193,584.843,690.116,587.165z"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          fill="#BCC486"
          d="M271.195,758.818c1.718-0.839,2.239,0.144,2.445,1.605
			c1.607,3.074-0.777,5.311-1.65,7.864c-0.261,0.77-0.52,1.536-0.779,2.307c-1.043,0.462-2.098,1.301-3.215,0.279
			c-0.997-0.911-0.29-1.969,0.018-2.898C269.026,764.907,270.128,761.869,271.195,758.818z"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          fill="#FAF4CB"
          d="M198.137,631.507c-0.282,1.825,0.526,3.797-0.731,5.491
			c-2.532-2.889-7.434-0.935-9.549-4.677c-0.227-0.183-0.299-0.357-0.217-0.53c0.08-0.174,0.16-0.26,0.238-0.26
			c4.051,2.761,5.493,1.037,5.476-3.188C195.465,628.617,196.538,630.461,198.137,631.507z"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          fill="#FAE89A"
          d="M306.655,643.3c4.01,0.407,1.54,4.827,3.884,6.271
			c-0.461,2.203-1.78,3.525-4.017,3.901C306.566,650.082,306.61,646.691,306.655,643.3z"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          fill="#E9DF9F"
          d="M668.259,775.336c1.112,0.676,0.581,1.919,0.235,2.346
			c-2.177,2.666,0.688,1.17,1.219,1.564c-3.264,1.549-6.341,1.142-9.265-0.887C663.051,777.353,665.655,776.344,668.259,775.336z"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          fill="#D1C7D3"
          d="M664.832,614.933c-0.307,1.66-1.213,2.823-2.823,2.863
			c-1.626,0.038-1.678-1.384-1.606-2.59c0.093-1.534,0.667-3.081,2.341-3.118C664.363,612.051,664.389,613.816,664.832,614.933z"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          fill="#E2E3BB"
          d="M668.178,770.644c-1.21-1.887-1.709-4.193-3.601-5.693
			c-1.942-1.535,0.034-2.606,0.626-3.829C667.941,763.75,668.117,767.18,668.178,770.644z"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          fill="#FBF5CB"
          d="M187.878,631.531c-0.006,0.263-0.015,0.528-0.021,0.79
			c-2.097,0.788-4.195,1.344-6.295,0.014C183.635,631.807,185.484,629.522,187.878,631.531z"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          fill="#A4BD91"
          d="M676.843,155.81c2.031-0.032,4.146,0.208,5.169,2.111
			c1.118,2.073-1.019,3.263-1.992,4.67c-7.167,10.339-14.437,20.607-21.567,30.972c-3.89,5.652-3.678,12.7-6.075,18.874
			c-0.315,0.419,0.248,0.096-0.181,0.333c-4.703,2.591-3.311,7.2-3.455,11.116c-0.079,2.137-0.702,3.471-2.313,4.568
			c-2.382,1.623-4.687,3.34-6.609,5.505c-1.773,2-4,1.526-6.209,1.222c-2.164-0.659-4.354-0.394-6.547-0.236
			c-1.23,0.089-2.409-0.026-2.551-1.641c-0.1-1.129-2.636-1.942-3.929-1.068c-0.913,0.617-1.982,0.566-2.812,1.033
			c0.068-0.045-0.059,0.035-0.059,0.035s0.226-0.074,0.284-0.086c2.849,0.816,5.055,2.828,7.832,3.771
			c1.243,0.422,2.406,1.884,1.617,3.478c-0.713,1.433-2.178,1.034-3.464,0.736c-0.466-0.105-0.901-0.46-1.51-0.163
			c1.852,0.88,3.114,2.271,2.535,4.299c-0.546,1.914,1.005,1.968,1.831,2.707c1.492,2.915,1.129,7.217-1.603,8.391
			c-3.47,1.494-4.571,4.332-6.109,7.118c-0.25,0.454-0.604,0.848-0.978,1.219c-2.243,2.175-5.132,1.449-7.693,1.359
			c-2.002-0.067-2.17-2.201-2.158-3.941c0.215-2.175,1.179-4.155,1.693-6.253c0.146-0.595,0.448-1.373-0.252-1.728
			c-0.659-0.329-1.132,0.314-1.57,0.758c-0.788,0.81-1.439,1.839-2.795,1.737c-1.418-1.043-2.713-2.021-4.784-1.526
			c-2.168,0.521-3.009-1.353-3.793-2.988c-0.631-4.465,2.246-7.771,3.789-11.516c2.422-4.789,4.739-9.654,6.81-14.587
			c2.326-5.541,5.426-10.429,9.48-14.779c4.222-5.163,9.31-9.492,13.853-14.344c6.171-8.138,15.037-13.663,21.169-21.836
			c2.502-3.058,5.042-6.081,7.934-8.784c1.569-1.494,3.522-2.527,4.957-4.188c2.423-1.999,4.864-3.983,7.625-5.499
			C673.762,155.915,675.176,155.083,676.843,155.81z"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          fill="#D0D6AD"
          d="M642.28,243.102c2.582-5.268,8.404-8.487,9.086-15.083
			c0.212-2.054,3.263-1.728,5.057-2.044c2.144-0.38,4.41-0.007,6.612-0.138c2.966-0.176,4.177,0.547,2.083,3.401
			c-0.356,0.489-1.041,1.094-0.289,1.605c0.268,0.186,1.224-0.23,1.571-0.627c1.702-1.954,3.305-3.995,4.939-6.007
			c1.188-0.39,1.597,0.621,2.147,1.257c3.771,4.36,4.861,4.673,9.811,2.703c0.092,8.804-5.383,15.362-9.19,22.575
			c-1.397,2.646-3.52,4.917-4.868,7.583c-3.975,7.85-12.538,10.137-18.36,15.572c-9.062,8.455-20.578,13.551-29.132,22.524
			c-4.204-1.042-6.575-3.97-8.042-7.825c1.407-3.81,2.598-7.671,3.287-11.702c0.239-1.389-0.228-2.514-0.457-3.759
			c-0.27-1.468,0.102-2.812,1.034-3.989c2.984-0.188,2.681,2.553,3.617,4.148c0.449,0.768,0.771,1.76,0.692,2.621
			c-0.295,3.346,1.357,2.642,3.221,1.673c1.372,0.979,0.169,3.675,1.359,3.66c1.104-0.013,2.396-1.772,3.576-2.793
			c7.588-6.555,15.479-12.757,22.853-19.558c0.991-0.915,3.035-2.195,2.15-3.272c-1.217-1.488-2.641,0.531-3.879,1.238
			c-2.875,1.638-4.21,5.47-8.085,5.717c-1.887-0.41-3.926-0.218-5.649-1.296c-2.157-1.347-2.813-4.51-1.562-7.313
			c0.522-1.177,1.426-4.536,1.392-5.201c0.538-0.583,1.151-0.276,1.796-0.218c3.065,0.282,3.754-0.566,3.264-3.602
			C642.22,244.382,642.713,243.723,642.28,243.102z"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          fill="#FDC2CB"
          d="M602.273,338.175c2.329,1.613,4.659,3.225,6.99,4.837
			c1.867,0.572,3.642,1.295,4.953,2.85c2.55,3.029,5.895,3.916,9.731,3.838c2.409-0.051,4.937-0.579,7.207,0.806
			c-1.229,3.752-3.053,5.103-5.815,3.867c-5.506-2.463-11.205-1.941-16.885-1.302c-2.537,0.284-5.226,0.122-7.557,1.612
			c-1.146,0.733-2.358,1.299-1.558,2.992c0.61,1.545,0.889,3.131,0.598,4.789c-0.481,2.188-1.795,3.713-3.749,4.734
			c-1.099,0.56-2.239,0.979-3.485,1.027c-1.209-0.215-3.353-0.566-1.785-2.077c4.165-4.014,3.659-8.516,2.388-13.31
			c-0.331-3.167,0.732-5.544,3.74-6.981c1-0.479,2.308-1.241,1.257-2.67c-0.992-1.35-2.339-1.097-3.331,0.025
			c-2.876,3.25-7.02,4.321-10.603,6.369c-1.474,0.24-2.956,0.634-4.37-0.223c-0.346-0.48-0.457-1.021-0.446-1.594
			c0.366-2.041-0.405-3.704-1.608-5.304c-1.135-1.506-2.471-2.968-2.39-5.07c0.048-0.374,0.161-0.726,0.313-1.065
			c1.021-1.637,2.454-2.798,4.391-2.917c2.43-0.15,2.754-1.395,2.23-3.404c-0.299-1.136-0.503-2.297-1.135-3.311
			c-3.866-6.203-3.594-12.274,0.65-18.164c1.067-1.484,0.91-2.722-0.507-3.679c-2.58-1.74-3.072-3.928-2.002-6.769
			c1.179-3.129-2.06-4.485-2.958-6.798c0.005-2.204,1.008-3.89,3.111-4.437c2.058-0.537,2.962,1.171,3.788,2.693
			c0.468,0.864,0.994,1.568,2.136,1.049c1.249-0.566,2.482-2.224,3.84-1.117c1.487,1.209,3.461,2.124,4.264,3.935
			c1.546,3.493,3.604,4.143,6.63,1.795c1.027-0.794,1.997-0.145,2.913,0.326c1.161,0.595,2.285,1.257,3.606,1.461
			c2.786,0.425,5.262,1.04,2.713,4.62c-0.32,0.447-0.29,1.001-0.056,1.493c1.326,2.793-0.029,5.193-1.424,7.335
			c-2.277,3.5-1.458,7.595-2.471,11.043c-1.276,4.344,1.039,9.392-2.704,13.074c-0.453,0.448-0.88,0.973-1.18,1.555
			C601.272,336.956,601.683,337.596,602.273,338.175z"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          fill="#FDD9DD"
          d="M602.273,338.175c-0.259-0.953-5.544,0.163-1.013-3.01
			c2.117-1.484,3.802-4.789,2.305-7.93c-0.979-2.054-1.833-3.557,1.263-4.201c0.273-0.057,0.452-0.553,0.674-0.848
			c-4.08-1.577-0.146-3.989-0.562-5.281c-1.369-4.255,1.968-6.283,3.275-9.165c0.916-2.028,1.621-3.484-0.573-5.022
			c-0.836-0.588-1.139-1.775,0.316-1.903c1.286-0.112,2.149-0.336,1.875-1.708c-0.235-1.184-1.4-0.908-2.281-0.959
			c-0.392-0.026-0.922,0.15-1.154-0.045c-3.423-2.888-6.992-0.803-10.499-0.332c-0.771,0.105-2.161,0.319-1.983-0.292
			c1.268-4.329-4.02-4.146-4.86-7.252c-0.373-1.391-2.025,0.736-2.772,1.507c-1.824,1.881-3,1.308-3.594-0.826
			c-0.438-1.57-0.669-3.445-2.534-3.4c-1.94,0.045-2.36,2.159-3.12,3.637c-2.253,0.323-3.48-1.443-4.99-2.54
			c-4.773-3.467-8.934-7.969-15.28-8.682c-0.654-0.074-1.122-0.611-1.022-1.398c1.854,0.217,3.385-0.515,4.786-1.622
			c0.968-1.117,2.293-1.203,3.565-1.001c3.589,0.562,7.21,0.387,10.805,0.614c4.847-0.278,9.743,0.483,14.56-0.569
			c0.369-0.095,0.756-0.144,1.134-0.153c1.373,0.323,2.029,1.99,3.61,1.948c0.356,0.07,0.702,0.169,1.05,0.281
			c7.429,1.484,13.287,5.704,18.689,10.716c2.603,2.562,5.205,5.125,7.807,7.688c0.312,0.582,0.621,1.167,0.933,1.75
			c0.79,0.592,1.152,1.321,0.744,2.297c-2.664,0.818-3.682,1.362-1.17,4.287c3.457,4.027,2.269,9.583,2.546,14.516
			c0.455,8.097-5.073,11.021-11.792,11.936c-3.797,0.518-5.591,4.763-9.731,4.325C602.299,335.434,602.188,337.1,602.273,338.175z"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          fill="#C8CEA2"
          d="M621.193,341.281c-2.584-6.814,2.248-9.965,6.421-13.788
			c7.886-7.23,17.689-10.934,27.007-15.563c7.145-3.551,14.271-7.14,21.453-10.61c2.129-1.027,4.231-1.77,6.351,0.617
			c1.986,2.236,2.569,3.392-0.72,4.942c-3.544,1.673-4.803,5.762-7.445,8.465c-0.227,0.233-0.029,0.883-0.029,1.341
			c0.479-0.081,1.043-0.023,1.424-0.263c1.655-1.04,3.223-2.224,4.888-3.25c3.556-2.185,6.498-0.067,9.364,1.609
			c1.449,0.847,0.521,2.166-0.285,2.987c-2.101,2.141-4.319,4.163-6.491,6.232c-4.027-0.595-7.964-1.782-12.158-0.572
			c-2.479,0.716-4.971,2.633-4.447,5.096c0.477,2.243-0.401,3.448-1.743,4.754c-1.222,0.975-2.396,1.996-3.424,3.186
			c-0.781,0.909-1.828,1.919-2.982,1.545c-4.775-1.551-10.221,1.017-14.651-2.438c-0.003-0.003-5.991,2.242-5.991,2.242
			c2.849,1.023-0.374,4.357,2.377,5.835c1.746,0.938,2.622,3.273,0.053,4.965c-4.234-3.756-9.815-3.58-14.816-5.086
			C623.796,343.063,621.87,343.345,621.193,341.281z"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          fill="#98BB8C"
          d="M631.988,440.458c0.008,0.593-0.167,1.628,0.048,1.719
			c8.608,3.516,7.819,12.927,11.943,19.247c4.361,6.683,7.828,13.906,14.654,18.596c1.133,0.777,2.052,1.77,1.506,3.277
			c-0.583,1.614-2.049,1.245-3.336,1.319c-1.355,0.081-3.335-0.809-3.893,0.769c-0.659,1.865,1.947,1.08,2.597,2.151
			c2.142,3.549,5.628,5.621,8.621,8.273c2.624,2.332-1.055,3.756-2.838,4.078c-2.694,0.484-3.116,1.347-1.734,3.497
			c1.022,2.618,0.521,4.533-2.439,5.316c-2.146,0.053-3.19-1.494-4.249-2.99c-2.184-3.089-4.56-6.021-7.031-8.872
			c0.314-0.406-0.287,0.193-0.229-0.321c0.047-0.423-0.191-0.507-0.262-0.177c-0.048,0.227,0.093,0.502,0.101,0.762
			c0.048,1.091,1.045,2.405-0.293,3.197c-1.403,0.832-2.386-0.399-3.389-1.174c-0.307-0.239-0.59-0.508-0.877-0.774
			c-1.031-1.017-2.197-1.867-3.572-2.303c-3.601-1.138-6.266-3.527-8.812-6.183c-2.979-3.106-4.789-7.47-9.504-8.756
			c-0.792-0.218-1.396-1.142-1.532-2.131c0.007-0.378,0.107-0.729,0.289-1.058c1.298-1.342,3.138-1.98,4.656-1.348
			c3.443,1.436,7.685-0.012,10.625,3.094c0.824,0.87,1.35,0.006,1.793-0.634c0.747-1.074,2.058-1.94,2.959-1.11
			c2.171,2.003,3.799,2.236,6.424,0.448c1.85-1.265,3.232,0.44,2.76,3.163c-0.152,0.867-0.801,2.087,0.4,2.319
			c1.499,0.291,1.274-1.15,1.416-2.156c0.346-2.491,0.112-4.203-3.157-4.365c-2.18-0.109-4.297-0.725-6.562-0.324
			c-2.288,0.403-6.349-1.733-6.134-3.109c0.41-2.626,2.979-2.315,5.346-3.145c-4.336-0.678-7.68,0.82-11.011,2.109
			c-1.315,0.504-2.382,0.792-3.659,0.021c-2.259-1.365-4.283-1.656-5.506,1.4c-0.215,0.544-0.959,0.855-1.504,1.227
			c-1.015,0.634-2.077,1.093-3.312,0.981c-2.253-0.7-3.891-2.003-4.332-4.462c0.603-1.702,1.796-2.492,3.599-2.327
			c0.341,0.103,0.65,0.275,0.904,0.507c0.24,0.07,0.289,0.853,0.753,0.169c-0.812-2.838-3.153-4.292-5.291-5.856
			c-3.111-2.282-6.181-4.604-9.029-7.215c-1.299-2.492-4.01-3.569-5.677-5.693c0.979-1.383,2.499-1.234,3.901-1.359
			c1.562-0.141,3.083-0.461,4.617-0.771c1.257-0.255,2.472-2.269,3.735-0.631c1.177,1.526,0.483,3.222-0.744,4.603
			c-0.355,0.525-1.603,0.909-0.678,1.696c1.375,1.174,2.6,2.829,4.8,2.454c2.734-0.843,5.337-1.111,7.551,1.302
			c0.461,0.503,1.094,0.915,0.746,1.577c-1.434,2.71-0.242,2.863,2.031,2.266c2.098-0.551,3.64-0.312,3.421,2.529
			c-0.141,1.829,1.214,2.482,2.812,2.524c1.696,0.045,3.394-0.096,5.089-0.197c0.779-0.04,1.731-0.223,1.379-1.343
			c-0.328-1.042-1.216-0.856-1.914-0.404c-1.165,0.75-2.386,1.025-3.733,0.75c-1.031-0.211-2.249-0.374-2.442-1.599
			c-0.21-1.33,0.973-1.799,1.988-1.984c1.401-0.256,2.337-1.08,3.21-2.068c2.207-2.505-0.202-5.398,0.626-8.043
			c0.084-0.269-0.746-0.685-1.3-0.58c-0.579,0.11-0.765,0.618-0.943,1.127c-0.508,1.438,0.505,4.078-2.661,3.427
			c-0.322-0.068-0.466-0.126-0.377,0.166c0.076,0.243,0.322,0.395,0.577,0.482c0.505,0.18,1.031,0.105,1.55,0.131
			c1.223,0.059,2.553,0.244,2.531,1.799c-0.022,1.686-1.246,2.554-2.824,2.696c-1.57,0.142-3.43,0.201-4.564-0.716
			c-1.787-1.44-3.313-2.762-5.807-3.146c-1.491-0.229-1.248-2.31-0.466-3.702c0.451-0.799,1.109-1.447,1.647-2.18
			c0.721-0.989,2.921-1.658,2.583-2.025c-0.991-1.079-2.496-1.785-4.08-2.142c-2.85-0.642-5.72-0.376-8.59-0.42
			c-1.841-0.03-2.95-0.561-1.32-2.502c2.011-0.843,4.145-0.698,6.201-0.538c6.308,0.49,11.923-0.918,16.543-5.46
			C630.521,438.964,631.479,439.179,631.988,440.458z"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          fill="#E0DCBA"
          d="M609.239,471.889c1.324,1.305,2.649,2.61,3.975,3.916
			c1.861,0.623,3.362,1.777,4.672,3.205c6.074,2.096,9.235,7.468,12.999,11.994c2.927,3.524,8.122,2.979,10.617,6.82
			c2.591,3.398,4.925,7.059,8.077,9.934c2.249,2.05,4.607,4.732,8.381,1.774c2.022-1.582,2.945,0.426,3.211,2.262
			c0.37,2.524-0.028,5.267,2.259,7.18c-0.109,0.229-0.243,0.452-0.324,0.691c-0.896,2.696-4.131-2.246-4.273,1.141
			c-0.064,1.52,3.012,2.29,3.026,4.553c-2.125,0.084-4.329-0.433-6.301,0.829c-2.997,0.969-15.168-1.518-16.648-3.725
			c-1.839-2.741-4.387-4.362-7.215-5.731c-2.317-3.064-5.479-5.056-8.814-6.827c-2.367-2.377-3.212-5.64-4.831-8.456
			c-4.31-7.48-8.883-14.799-13.346-22.184c-2.091-3.575,0.708-6.961,0.416-10.477C607.645,468.293,608.579,469.908,609.239,471.889z
			"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          fill="#FADBDA"
          d="M676.871,386.994c-0.145,5.148-0.366,10.311,0.573,15.41
			c1.727,9.376-2.533,16.126-11.818,18.384c-3.136,0.765-5.738,2.21-7.975,4.597c-5.081,5.424-10.295,10.752-17.521,13.369
			c-1.313,0.473-2.756,0.598-4.139,0.885c-2.209-1.269-4.812-0.853-7.13-1.68c-1.11-0.392-2.26-0.684-3.165-1.479
			c-0.384-0.341-0.827-0.743-0.608-1.325c0.197-0.524,0.71-0.709,1.243-0.783c1.07-0.147,2.061,0.227,3.076,0.453
			c1.347,0.298,2.766,1.059,3.351-1.189c0.294-1.129,1.628-1.194,2.646-1.343c2.875-0.425,3.098-1.989,2.42-4.511
			c-0.976-3.631-2.656-6.775-5.141-9.542c-1.667-2.301-0.417-3.348,1.751-3.842c1.839-0.419,3.631,0.096,5.419,0.561
			c5.03,1.301,10.092,1.404,15.17,0.239c0.338-0.08,0.332-0.096-0.444-0.898c-1.315-2.512,0.99-3.97,1.709-5.895
			c0.433-4.64-2.586-7.938-4.531-11.629c-1.002-1.9-3.903-3.445-2.965-5.557c1.079-2.425,4.185-1.328,6.437-1.427
			c4.686-0.201,9.386,0.208,14.066-0.262c0.632-0.064,1.389-0.029,1.783-0.56c0.092-0.125-0.856-0.404-1.282-0.694
			c-1.204-0.826-0.786-1.491,0.258-1.954c1.334-0.599,2.795-0.583,4.23-0.688C675.363,385.683,676.294,386.005,676.871,386.994z"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          fill="#FCE89D"
          d="M540.113,160.515c-0.188-0.822-0.217-2.274-0.587-2.367
			c-2.758-0.688-2.757-0.595-1.678-3.996c0.312-0.982,0.459-2.019,0.681-3.026c3.18-3.986-2.603-4.601-2.889-7.428
			c-0.103-1.001-2.476-3.122-1.375-3.157c2.499-0.083,0.137-6.52,4.531-3.052c0.924,0.729,2.019-0.599,1.664-2.14
			c-0.503-2.185,0.502-5.541,1.648-5.39c3.115,0.406,5.038-7.126,8.684-1.203c0.438,0.71,2.312,1.139,3.224,0.829
			c1.688-0.573-0.078-1.986,0.224-3.029c0.229-0.801-0.433-2.226,0.711-2.298c1.321-0.083,0.666,1.371,0.857,2.151
			c0.862,3.49,4.529,5.845,3.987,9.898c-3.418,4.264-6.903,8.48-10.22,12.825c-1.071,1.401-1.696,3.145-2.525,4.731
			c1.749,0.79,3.523,0.272,5.289,0.096c1.686-0.163,3.368-0.314,5.053-0.471c2.672-1.676,5.615-2.066,8.666-1.673
			c1.942,0.25,3.473,1.309,4.106,3.205c0.638,1.907-0.808,2.87-2.142,3.728c-2.175,1.397-1.518,2.265,0.416,3.167
			c4.012,1.868,8.197,3.423,11.744,6.184c0.663,1.379,0.282,3.074,1.516,4.341c0.734,0.752,0.995,1.951,0.089,2.847
			c-0.802,0.79-1.864,0.384-2.501-0.201c-1.688-1.549-1.018-0.023-1.276,0.771c-0.444,1.363-1.779,1.325-2.967,1.411
			c-9.701-1.392-17.937-6.248-26.08-11.206c-1.996-1.216-3.652-2.844-6.15-3.106C541.477,162.809,540.36,162.054,540.113,160.515z"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          fill="#FDC8CD"
          d="M633.624,417.646c3.494,3.213,4.562,7.69,6.105,11.898
			c0.25,0.676-0.408,1.664-0.551,2.524c-0.463,2.773-6.676-1.094-4.881,4.244c-1.29,0.013-2.615,0.215-3.862-0.005
			c-1.497-0.267-2.934-0.891-5.259-1.635c3.396,3.62,8.411,1.394,10.814,4.967c-1.333,0.272-2.668,0.544-4.003,0.819
			c-0.723-0.255-1.445-0.509-2.169-0.761c-1.443,0.918-2.941,0.771-4.514,0.336c-5.747-1.589-11.444-3.442-17.49-3.583
			c0.019,0.496-1.001-1.362-0.494,0.215c1.136,3.53-0.759,5.082-3.814,5.892c-1.033,0.271-1.832,0.979-2.741,1.494
			c-2.956,1.31-5.592,0.239-8.227-1.047c-1.407-0.684-2.977-0.972-4.408-1.605c-1.231-0.668-2.673-1.018-3.529-2.266
			c-0.07-2.08,0.04-3.711-2.804-4.787c-2.457-0.938-4.981-2.851-6.736-5.228c-0.557-0.751-1.065-1.612-0.541-2.527
			c0.573-1,1.593-0.815,2.454-0.469c5.146,2.051,9.861-1.312,14.859-1.156c0.656,0.02,1.14-0.573,1.495-1.154
			c2.38-3.899,6.007-5.122,10.345-4.811c1.89,0.239,3.688,0.931,4.684,2.53c1.723,2.767,4.761,3.534,7.183,5.23
			c1.22,0.857,2.448,0.69,3.644-0.116c2.354-1.585,4.62-1.404,6.895,0.239c0.945,0.68,2.084,1.364,3.23,0.503
			c0.96-0.722,0.124-1.66,0.015-2.517C628.864,421.352,632.112,420.01,633.624,417.646z"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          fill="#FDEEAE"
          d="M580.167,168.429c-4.749-2.121-9.531-4.168-14.231-6.395
			c-1.922-0.912-2.685-2.044-0.078-3.436c1.414-0.752,4.322-0.78,3.59-3.244c-0.774-2.594-3.389-2.943-5.89-2.652
			c-2.058,0.24-4.111,0.521-6.165,0.784c1.125-4.622,5.02-6.491,8.604-8.672c4.665-1.458,3.583-6.708,6.257-9.481
			c0.526-0.518,1.054-1.04,1.581-1.561c1.122-0.569,2.099-1.593,3.569-0.966c2.11,0.896,4.064,0.214,5.978-0.71
			c3.906-1.484,7.686-3.445,12.041-3.205c2.191,0.354,2.793,1.35,1.853,3.547c-0.909,2.125-1.618,4.412-0.83,6.811
			c0.166,0.502,0.299,1.279-0.018,1.506c-3.252,2.345-2.853,7.339-6.664,9.201c-2.478,1.212-2.659,3.788-3.518,5.857
			c-0.822,1.98,0.902,2.364,2.462,2.402c1.561,0.042,3.348-0.259,3.835,1.794c0.516,2.172-0.469,3.848-2.34,4.914
			c-1.261,0.716-2.338,1.66-3.524,2.457c-0.87,0.582-1.765,1.056-2.695-0.147c-1.675-2.159-2.494-0.39-3.38,0.864
			C580.46,168.208,580.314,168.317,580.167,168.429z"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          fill="#FDF4BE"
          d="M568.371,122.793c0.03-1.137-0.008-2.228-1.546-2.322
			c4.361-6.496,9.992-11.882,15.338-17.511c3.076-3.237,6.539-5.674,5.69-11.447c-0.438-2.993,4.766-5.125,5.732-8.806
			c2.621,0.522,4.394,2.585,6.677,3.756c2.454,1.258,4.048,0.892,5.881-0.907c3.725-3.653,7.128-7.647,11.375-10.754
			c1.978-1.445,3.793-1.99,6.472-1.514c2.918,0.52,5.97-1.01,8.91-1.865c1.885-0.547,3.751-1.329,5.49,0.326
			c-1.443,1.105-1.992,2.225-0.595,3.892c0.957,1.144,2.179,2.7-0.576,3.433c-0.256,0.067-0.391,0.593-0.582,0.907
			c0.49,0.112,1.03,0.416,1.455,0.299c1.194-0.326,2.341-0.825,3.634-0.946c-12.415,7.664-25.054,15.885-39.52,17.547
			c-7.661,0.879-12.166,3.907-16.82,8.589C579.679,111.208,574.041,117.016,568.371,122.793z"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          fill="#FBE48E"
          d="M638.391,71.749c-4.287-0.045-8.255,1.161-12.052,3.057
			c-0.313,0.155-0.786,0.319-1.048,0.195c-6.911-3.333-9.26,3.214-13.25,6.139c-1.038,0.76-1.849,1.828-2.765,2.754
			c-6.156,6.221-10.146,5.918-15.692-1.187c1.64-7.164,5.886-12.776,11.081-17.625c3.325-3.101,7.215-5.596,10.852-8.362
			c2.803-0.761,5.704,0.394,8.525-0.465c0.833-0.254,2.056-0.261,2.466,0.68c0.49,1.126-0.462,1.927-1.272,2.611
			c-0.698,0.588-1.519,0.996-1.586,1.03c3.02-2.598,6.498-0.437,10.018,0.945c1.929,1.03,0.335,3.733,2.272,4.762
			c0.989,0.649,2.408,0.408,3.165,1.531C642.356,69.758,641.292,70.921,638.391,71.749z"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          fill="#A3BE95"
          d="M640.688,348.485c0.173-2.294,0.256-4.475-2.777-5.122
			c-0.561-0.119-1.36-1.584-1.341-1.6c2.644-2.274,0.893-3.141-1.186-3.977c-0.148-0.061-0.104-0.604-0.146-0.921
			c3.66,1.635,5.466-5.057,9.671-1.999c2.653,1.925,5.849,2.863,9.081,0.812c0.217-0.138,0.61-0.38,0.674-0.319
			c4.727,4.616,6.491-1.549,9.639-2.636c2.557-2.204,5.591-2.86,8.883-2.892c1.179-0.013,2.449-0.429,3.086,0.866
			c0.584,1.184-0.448,1.891-1.22,2.566c-0.78,0.682-1.445,1.875-2.306,2.022c-5.266,0.905-6.204,6.056-9.247,9.11
			c-2.791,2.405-4.624,5.508-6.354,8.688c-2.43,1.769-5.052,0.078-7.562,0.426c-3.583,0.07-7.182,0.185-9.105-3.765
			C640.357,349.295,640.428,348.873,640.688,348.485z"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          fill="#FCEAA2"
          d="M624.22,118.087c1.852-0.65,4.466-1.411,5.493-0.113
			c5.433,6.849,15.01,6.899,20.768,13.338c4.885,5.464,11.377,9.082,17.904,12.443c2.927,1.507,5.638,3.309,7.074,6.472
			c-1.237,1.523-3.013,1.446-4.666,1.401c-4.006-0.109-8.06,0.627-11.986-0.618c-1.852-1.705-0.276-2.095,1.022-2.335
			c2.392-0.441,4.834-0.099,7.312-0.233c-8.767-3.941-17.751-7.271-25.786-12.562c-0.875-0.576-1.748-1.491-3.056-0.183
			c-2.209,2.214-3.76,1.696-4.717-1.324c-0.415-1.309-0.013-2.991-1.75-3.659c-3.32-1.67-6.235-4.061-9.761-5.363
			c-2.114-0.918-4.035-2.047-4.938-4.339c-0.367-1.961,0.131-3.661,1.938-4.587C621.223,115.32,622.59,117.242,624.22,118.087z"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          fill="#F8DF8A"
          d="M657.987,149.632c5.776,1.539,11.646,0.422,17.47,0.595
			c1.394,0.103,3.012-0.246,3.66,1.536c0.794,2.185-1.141,2.905-2.273,4.047c-4.412,1.391-7.852,4.351-11.432,7.083
			c-3.955,2.501-7.779,5.262-12.544,6.12c-1.165,0.211-2.315,0.477-3.488,0.25c-2.605-0.508-3.217-2.178-1.422-4.178
			c1.871-2.082,4.393-3.199,6.914-4.321c1.074-0.48,3.298-0.573,1.739-2.815c-2.256-2.259-5.036-3.691-7.806-5.157
			c-4.414-2.335-8.511-5.061-11.351-9.334c-0.887-1.337-1.823-2.588-1.027-4.296c1.152-1.494,2.83-1.843,4.069-0.672
			C645.632,143.334,651.943,146.258,657.987,149.632z"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          fill="#DDDFB5"
          d="M683.298,228.169c-1.326,5.672-6.179,0.294-8.541,2.726
			c-0.663-2.473-1.558-4.825-3.417-6.686c-0.953-2.115-2.3-2.243-4.422-1.417c-4.864,1.894-7.995-0.854-7.068-5.992
			c0.392-2.168,0.771-4.354,1.365-6.472c0.463-1.641,1.44-2.693,3.489-2.079c1.887,0.569,3.055,1.424,2.559,3.599
			c-0.447,1.971-1.756,4.424,0.274,5.746c2.054,1.337,3.755-1.082,5.507-2.038c6.899-3.772,12.334-1.472,13.842,6.18
			C687.446,224.59,685.38,226.509,683.298,228.169z"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          fill="#FDF4BF"
          d="M633.66,62.284c-3.428-0.507-6.761-3.534-10.365-0.195
			c-0.532,0.491-2.795,0.203-2.867-0.079c-0.41-1.61,1.374-1.406,2.176-2.06c1.085-0.885,2.266-1.649,3.408-2.464
			c-1.406-0.289-2.704-0.295-4.152,0.253c-2.185,0.827-4.213-0.696-6.341-1.019c12.047-7.773,25.681-8.241,39.352-8.53
			c-0.454,2.026-2.049,3.906-0.788,6.153c-6.276,3.062-10.6,8.293-14.978,13.469c-1.321-0.178-2.798,0.413-3.916-0.781
			c-0.307-3.361,1.168-5.782,3.936-7.551c2.971-1.901,5.472-4.435,8.778-6.345c-6.963-0.013-10.999,1.251-14.097,4.308
			c-0.058,0.297,0.088,0.458,0.422,0.392c1.418-0.275,3.776-1.68,3.97,0.216C638.427,60.298,636.502,62.268,633.66,62.284z"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          fill="#CFCF9F"
          d="M642.28,243.102c0.604,0.24,1.948-0.627,1.508,1.155
			c-0.374,1.507-1.413,4.594-1.502,4.731c-1.824,2.818-3.199,0.301-4.726-0.372c-1.989,1.088-4.384,0.189-6.445,1.476
			c-1.002,0.627-2.472-0.083-3.208-1.366c-0.239-1.007,0.33-1.724,0.855-2.479c0.602-0.867,2.67-1.151,0.462-2.972
			c-1.38-1.136,1.697-3.685,4.581-3.372c-0.348-0.441-0.85-0.572-1.363-0.662c-0.978-0.169-2.326,0.16-2.63-1.117
			c-0.254-1.065,1.026-1.379,1.687-1.906c0.726-0.583,0.619-2.195,2.144-1.786c6.665,0.704,8.848-6.302,14.027-8.154
			c-1.526-3.65,1.723-7.572-0.618-11.152c-0.438-0.671-1.092-1.264-0.471-2.044c0.569-0.719,1.208-0.163,1.684,0.23
			c1.764,1.446,2.859-0.058,4.112-0.876c1.724,4.363,0.905,8.305-1.482,12.281c-3.358,5.585-6.366,11.378-9.514,17.092
			C640.832,242.805,640.044,244.021,642.28,243.102z"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          fill="#FDF7CA"
          d="M624.22,118.087c-3.205-1.145-6.61-2.693-6.307,3.161
			c-9.166-5.002-17.934-4.002-26.334,2.96c-1.909,1.582-3.871,3.101-5.79,4.669c-1.33,1.087-2.561,2.271-2.436,4.212
			c-2.967,3.104-6.386-0.118-9.519,0.682c4.854-4.936,9.477-10.125,14.616-14.741c7.092-6.372,25.036-7.959,33.239-3.308
			C622.667,116.277,623.383,117.289,624.22,118.087z"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          fill="#FDDADC"
          d="M649.408,352.487c2.578,0.202,5.156,0.4,7.735,0.598
			c9.137,2.585,12.81,9.242,13.509,18.116c-0.165,0.086-0.329,0.173-0.494,0.259c-1.106,0.003-2.051-0.499-2.812-1.19
			c-2.322-2.115-4.565-2.924-7.678-1.177c-2.066,1.16-6.216-1.107-6.558-3.337c-0.648-4.228-1.09-8.458-4.055-11.891
			C648.729,353.485,648.978,352.871,649.408,352.487z"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          fill="#FDD6DB"
          d="M640.688,348.485c0.014,0.246,0.044,0.493,0.09,0.736
			c0.674,1.52-0.309,2.226-1.482,2.473c-2.735,0.572-5.529,1.104-8.012-0.874c-8.605,0.969-16.414-0.321-22.02-7.809
			c2.967-0.026,5.935-0.062,8.9-0.077c1.315-0.006,2.656,0.073,3.027-1.65c4.641,1.084,9.256,2.3,13.932,3.211
			C637.697,344.995,639.744,345.878,640.688,348.485z"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          fill="#B3C69F"
          d="M663.498,344.397c0.58-2.412,3.905-3.996,2.004-7.252
			c-0.369-0.633,1.116-1.203,2.072-1.254c0.915-0.045,2.093,0.281,2.694-0.169c1.894-1.417,3.567-3.123,5.329-4.719
			c-1.784-1.753-3.325,1.484-3.934,0.765c-3.17-3.74-4.598,3.627-7.362,0.957c0.688-1.359,3.022-2.591,0.333-4.22
			c1.852-1.686,0.612-5.61,4.23-5.978c3.953-0.407,7.918-0.829,11.884-0.899c0.779-0.016,1.586,1.542,2.381,2.374
			C677.519,331.7,671.907,339.394,663.498,344.397z"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          fill="#E6E7C7"
          d="M676.871,386.994c-0.777-0.271-1.555-0.54-2.333-0.812
			c-2.453-1.831-1.953-4.39-1.707-6.881c2.29-2.31,1.243-5.333,1.676-8.045c4.262-2.611,8.684-3.468,13.528-1.616
			c1.842,0.704,3.111,1.842,1.429,3.247C684.551,376.994,681.114,382.339,676.871,386.994z"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          fill="#A8C099"
          d="M663.43,518.974c-3.954,0.188-2.819-2.748-3.048-4.898
			c-0.146-1.384,0.027-3.377-0.783-3.998c-1.579-1.211-1.688,1.298-2.713,1.84c-2.667,1.406-4.596-0.638-6.24-1.85
			c-4.202-3.109-7.304-7.26-9.144-12.243c1.061,0.663,2.639,2.117,3.077,1.832c1.076-0.708-1.193-1.973-0.125-3.274
			c0.844-1.029-1.417-2.258,0.025-3.402c0.579,1.093,1.157,2.188,1.735,3.278c2.449,4.758,4.898,9.51,10.199,11.801
			c2.752-0.413,3.324-2.291,3.143-4.674c1.717,2.837,3.448,5.669,5.144,8.52C666.314,514.612,666.226,517.045,663.43,518.974z"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          fill="#FDEEB5"
          d="M656.929,58.078c-0.526,8.37-2.758,14.365-9.426,17.679
			c-0.782,0.389-1.674,0.563-2.515,0.838c-0.063-0.886-0.494-1.974-0.137-2.625c2.935-5.323,5.983-10.585,9.092-15.809
			c0.498-0.838,1.486-1.825,2.574-1.008C657.092,57.586,657.155,58.699,656.929,58.078z"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          fill="#CEBBCC"
          d="M662.744,531.646c-1.074-1.006-1.116-2.039-0.087-3.105
			c3.261-0.416,5.027-3.317,7.737-4.65c1.312-0.644,2.517-1.342,3.911-0.029c1.627,1.533,0.298,2.549-0.475,3.666
			c-0.815,1.178-1.599,2.401-2.571,3.439C668.666,533.733,665.961,536.205,662.744,531.646z"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          fill="#FCEAA4"
          d="M654.082,54.344c-4.759-2.693-0.861-4.28,0.788-6.153
			c2.438-0.183,4.708-2.54,7.234-0.817c0.973,0.664,1.157,2.392,0.354,3.165C660.163,52.746,657.744,55.007,654.082,54.344z"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          fill="#DFE1BF"
          d="M623.425,300.473c-0.247-0.765-0.496-1.53-0.746-2.297
			c7.591-5.435,15.18-10.87,22.769-16.305C638.912,289.039,631.366,294.993,623.425,300.473z"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          fill="#FDF7CA"
          d="M572.254,135.333c-0.57,2.582-1.682,4.971-1.61,7.777
			c0.11,4.325-3.494-0.045-4.645,1.705C567.654,141.373,569.228,137.873,572.254,135.333z"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          fill="#FDDADC"
          d="M674.507,371.256c0.734,2.479,3.966,5.239-0.808,7.092
			c-2.847-1.548-3.801-3.947-3.159-7.061l0.112-0.086C671.921,372.346,673.219,371.364,674.507,371.256z"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          fill="#D0C6D1"
          d="M679.072,540.943c0.101,1.337-0.628,1.766-1.667,1.712
			c-1.73-0.087-3.619-0.453-4.103-2.272c-0.415-1.554,1.346-1.619,2.406-1.839C677.707,538.122,678.316,539.738,679.072,540.943z"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          fill="#FDF4C6"
          d="M209.11,579.596c-0.499,0.596-0.936,1.635-1.508,1.716
			c-6.214,0.875-4.4,6.848-6.465,10.304c-0.654,1.094,1.251,1.583,2.12,0.979c4.921-3.427,10.446-3.593,16.089-3.209
			c0.415,0.028,1.179,0.693,1.141,0.777c-1.12,2.492,1.445,4.618,0.607,6.979c-0.058,0.16-1.769,0.015-2.478-0.396
			c-2.069-1.189-3.054-0.699-2.279,1.57c1.387,4.073-2.161,6.016-3.706,8.668c-0.832,1.43-1.001-0.983-1.654-1.456
			c-1.061-0.771-1.625-2.838-3.399-1.739c-1.486,0.918-0.618,2.465-0.042,3.53c2.039,3.775,4.883,7.157,5.513,11.6
			c0.237,1.333-0.599,1.766-1.667,2.003c-4.957-0.237-9.925-0.101-14.889-0.114c-7.782-0.028-7.782-0.012-13.425-4.843
			c0.153,3.439,4.328,4.912,3.988,8.48c-3.27-0.429-5.96-2.134-8.615-3.91c-1.62-3.19-3.353-6.321-4.779-9.604
			c-2.766-3.903-6.184-7.131-10.37-9.432c-3.958-2.172-8.292-3.69-11.622-6.916c2.523-3.028,5.954-3.183,9.282-2.335
			c4.015,1.024,8.016,0.347,11.946,0.672c7.769,0.643,13.733-1.801,19.345-6.966c3.827-3.517,9.698-3.619,13.989-6.716
			C207.064,578.641,208.245,578.717,209.11,579.596z"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          fill="#FEFEFE"
          d="M209.11,579.596c-6.527,3.562-14.408,4.464-19.891,10.077
			c-3.264,3.346-7.125,3.835-11.767,4.45c-8.677,1.148-17.303-2.926-25.781,0.46c-0.862-0.479-1.724-0.959-2.587-1.438
			c0.71-0.522,1.393-1.09,2.137-1.557c2.738-1.727,5.536-3.364,8.224-5.164c0.792-0.528,1.878-1.307,1.259-2.538
			c-0.676-1.351-1.882-1.082-3.063-0.659c-3.284,1.167-6.577,2.312-9.867,3.465c3.744-4.048,9.181-5.339,13.857-5.181
			c5.35,0.184,10.054-0.945,14.726-2.698c7.15-2.682,14.099-1.595,21.11,0.188c2.015,0.512,4.074,1.669,4.862-1.654
			c0.311-1.308,1.742-0.953,2.713-0.906c4.555,0.222,9.105,0.51,13.653,0.84c0.543,0.037,1.529,0.515,1.51,0.707
			c-0.114,1.131-1.136,0.719-1.819,0.796C215.301,579.126,212.096,578.092,209.11,579.596z"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          fill="#F9FAF3"
          d="M173.663,610.931c2.637,2.681,4.2,5.895,4.779,9.604
			c-1.63-0.151-3.338-0.23-2.658-2.672c0.486-1.739-0.441-3.209-1.852-3.258c-1.73-0.062-0.437,1.504-0.558,2.365
			c-0.266,1.861-1.504,1.029-2.31,0.57c-10.555-6.028-22.233-9.364-33.533-13.881c11.834-3.298,22.121-0.607,31.198,7.209
			C170.356,612.27,171.991,613.055,173.663,610.931z"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          fill="#FBFBF3"
          d="M187.057,624.444c-2.175-3.031-5.026-5.651-5.856-9.502
			c-0.125-0.581,0.006-1.216,0.02-1.824c0.462,0.064,1.186-0.034,1.35,0.222c6.524,10.229,16.828,5.148,25.571,6.334
			c1.127,0.153,2.367-0.272,3.316,0.706c0.505,1.593-0.945,1.787-1.743,2.457c-1.182,0.503-2.279,1.363-3.699,0.943
			c-3.522,1.66-4.567,5.849-7.878,7.727c-2.71,0.632-3.737-1.281-4.783-3.164C191.253,627.043,189.155,625.745,187.057,624.444z"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          fill="#FCFDFB"
          d="M561.255,94.484c-0.945,3.404-4.319,2.084-6.446,3.196
			c-1.582,0.827-2.064,4.179-3.72,2.954c-1.656-1.225-1.385-4.214-1.633-6.549c-0.706-6.656,3.167-12.164,4.342-18.333
			c0.262-1.375,0.8-2.698,1.209-4.043c0.223-1.013,0.35-2.057,0.701-3.021c0.24-0.659,0.795-1.201,1.209-1.796
			c0.322,0.673,0.836,1.318,0.935,2.021c0.941,6.692,3.225,13.202,2.724,20.088c0.811,1.225-0.146,2.756,0.685,3.977
			C561.258,93.479,561.257,93.982,561.255,94.484z"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          fill="#FDF9CE"
          d="M561.26,92.976c-1.153-1.167-0.628-2.622-0.686-3.977
			C561.743,90.164,561.202,91.621,561.26,92.976z"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          fill="#FDFDFD"
          d="M631.271,515.893c3.715,0.569,6.546,2.363,8.39,5.737
			c0.457,0.836,1.564,2.052,2.181,1.949c4.935-0.821,9.04,2.384,13.716,2.608c1.52,2.955,4.69,5.38,1.931,9.487
			c-0.94,1.399,1.173,2.491,2.715,3.101c9.802,3.856,12.393,9.461,8.799,19.436c-0.689,1.913-0.146,2.851,1.251,3.941
			c6.229,4.869,6.587,6.788,1.986,13.271c-1.84,2.591-2.536,4.742-0.776,7.763c3.109,5.335,1.333,9.952-3.353,13.316
			c-3.16,2.272-5.739,5.046-7.811,8.148c-3.741,5.608-8.898,6.314-14.79,5.188c-1.868-0.358-3.623-0.911-4.827,1.17
			c-0.3-2.026-5.07-2.232-2.255-5.53c0.393-0.33,0.794-0.644,1.26-0.874c1.099-0.545,2.902-0.786,2.49-2.392
			c-0.37-1.436-2.122,0.278-3.035-0.611c-1.458-1.552-0.278-4.226-2.353-5.512c-1.949-0.839-2.054-2.651-1.971-4.303
			c0.416-8.278-0.9-16.334-2.892-24.301c0.458-3.459-0.387-6.667-1.96-9.739c-1.902-3.713-1.39-5.018,2.578-6.323
			c3.076-1.014,6.419-1.512,7.502-5.384c0.805-2.879,0.354-3.532-2.7-3.192c-0.903,0.099-1.799,0.467-2.703,0.352
			c-2.283-0.298-5.064-1.158-4.823-3.529c0.202-1.999-0.005-5.63,3.928-5.646c0.637-0.002,1.232-0.396,1.824-0.661
			c1.062-0.48,2.192-0.758,3.291-1.133c0.957-0.319,2.824-0.221,1.998-1.967c-0.614-1.299-2.013-2.055-3.586-1.025
			c-1.39,0.913-2.294,2.494-4.036,2.937c-4.345-1.025-4.812-2.005-2.346-5.088c1.396-1.744-0.832,0.145-0.482-0.503
			c-4.78-1.036-6.608-3.94-5.322-8.45c1.225-1.392,2.814-2.176,4.546-2.73C630.254,515.319,630.814,515.435,631.271,515.893z"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          fill="#FDE3F0"
          d="M406.443,416.069c-4.363-2.134-7.562-4.955-6.577-10.495
			c0.108-0.615-0.458-1.702-1.022-2.021c-5.817-3.311-10.915-8.702-18.521-7.191c-4.681,0.927-4.438-2.096-4.264-4.968
			c0.075-1.231,0.843-2.424,1.297-3.634c-0.321-1.468,0.25-2.821,0.651-4.162c0.491-1.641,0.652-3.029-1.22-3.884
			c-0.698-0.32-1.489-0.685-1.232-1.676c0.27-1.04,1.203-1.238,2.099-1.437c2.256-0.495,4.245,1.038,6.466,0.842
			c5.641,1.279,11.17,1.539,17.059,0.838c8.121-0.959,16.501-1.242,24.496,1.66c2.239,0.81,4.277,0.406,6.474-0.365
			c7.332-2.578,13.927-6.637,20.856-10.041c8.153-0.778,16.253-2.425,24.505-1.536c4.053,0.304,8.174,0.362,11.651,2.943
			c4.577,0.81,8.959,2.323,13.349,3.801c2.467,0.834,4.86,1.846,5.697,4.689c0.059,1.065-0.456,2.019-0.651,3.04
			c-1.243,6.481-4.279,8.493-10.655,6.771c-4.582-1.234-9.344-0.915-14.003-2.626c-2.694-0.988-7.219-0.122-10.091,2.537
			c-2.222,2.057-5.371,2.735-7.317,5.153c-0.808,1.002-2.399,1.629-0.749,3.369c0.794,0.838-0.179,1.99-0.925,2.709
			c-1.688,1.619-2.938,3.603-4.853,5.064c-2.703,2.061-2.428,3.432,0.577,4.959c3.068,1.554,3.33,3.028,1.407,7.945
			c-2.075-0.65-3.56-1.001-4.667,1.92c-1.094,2.882-7.945,4.901-11.042,4.036c-2.359-0.655-2.842-2.142-1.286-4.044
			c1.328-1.619,3.281-2.035,5.419-2.715c-3.9,0.937-6.565,2.54-7.174,6.747c-0.317,2.182-3.044,3.451-3.143,5.972
			c-1.356,1.426-2.958,2.076-4.935,1.529c-0.376-0.103-0.8-0.239-1.154-0.132c-6.12,1.898-11.354-0.919-16.759-3.024
			c-3.034-1.185-4.929-3.471-6.021-6.256C409.263,420.021,407.985,417.999,406.443,416.069z"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          fill="#FDD4CB"
          d="M389.14,658.204c-2.256-2.896-5.144,1.199-7.653-0.696
			c-0.774-0.585-2.516-0.056-2.487-1.741c0.048-2.854-0.792-4.317-3.926-3.131c-0.426,0.161-1.006-0.083-1.517-0.145
			c0.723-5.025-6.875-7.854-3.591-13.596c0.444-0.777,0.161-2.54-0.445-2.217c-3.392,1.813-5.702-1.676-8.679-1.443
			c-0.355,0.027-0.832-0.047-1.072-0.271c-2.998-2.773-3.557-1.954-8.392-2.027c-2.47-0.042-4.6-0.189-6.961,0.658
			c-0.967,0.35-1.101-0.918-1.073-1.961c0.083-3.174-3.765-4.921-4.657-4.497c-3.464,1.623-3.927-0.864-4.466-2.676
			c-1.894-6.359-6.23-2.223-9.547-2.424c-4.536-4.648-4.176-6.347,1.782-8.83c1.402-0.581,3.687-1.267,3.167-2.862
			c-0.593-1.814-2.6-0.724-4.018-0.169c-3.687,1.442-2.736-2.239-3.925-3.562c2.374-1.96,1.099-2.772-0.891-4.017
			c-1.578-0.988-0.683-2.808-0.001-4.274c0.662-1.421,1.581-3.221-0.044-4.232c-1.472-0.917-3.028-2.752-5.304-1.289
			c-0.741,0.473-2.023,1.641-2.642,0.336c-0.501-1.054,0.643-2.349,1.695-2.802c4.362-1.876,4.679-3.216-0.362-4.225
			c-2.361-0.469-1.841-1.479-0.619-2.884c1.213-1.392,4.307-3.78-1.077-3.018c-0.456,0.065-1.238-0.523-1.486-1.007
			c-0.457-0.886,0.226-1.526,0.995-1.781c3.502-1.162,2.951-3.012,1.054-5.269c-0.45-0.539-0.581-1.384-0.725-2.113
			c-0.113-0.571-0.021-1.187-0.019-1.778c-0.477,0.371-0.947,0.757-1.433,1.116c-1.048,0.782-2.901,2.966-3.164,1.703
			c-0.578-2.763-4.867-4.217-2.933-7.367c1.612-2.627-2.118-5.839,1.295-7.949c-4.274,1.327-2.178-2.969-3.642-4.107
			c-0.745-0.579-2.277-0.468-1.841-2.028c0.401-1.442,1.71-1.295,2.86-1.545c3.182-0.688,6.331-1.517,9.493-2.287
			c1.264,2.155,2.997,3.922,4.706,5.722c2.372,2.505,2.121,4.387-1.019,5.644c-2.022,0.81-0.034,0.583,0.099,0.633
			c1.447,0.582,0.937,1.557,0.63,2.588c-1.154,3.903-0.796,4.262,3.248,3.379c0.51-0.109,1.007-0.287,1.529-0.328
			c0.799-0.065,1.606-0.036,2.002,0.824c0.304,0.662,0.026,1.273-0.36,1.824c-0.525,0.746-1.243,1.335-1.736,2.113
			c-0.889,1.404-0.846,2.114,1.063,2.723c4.56,1.449,4.667,1.861,3.706,6.375c-0.272,1.277-0.379,2.582-0.659,3.859
			c-0.141,0.639-0.152,1.261,0.28,1.857c1.262,1.734,4.333-0.275,5.227,2.559c0.113,0.358,1.679-0.816,2.57-1.276
			c0.925-0.474,1.646-1.372,2.644-1.649c2.937-0.823,2.86,1.199,2.821,3.135c-0.803,3.295-1.045,6.644-3.287,9.639
			c-1.264,1.685,0.945,4.702-0.818,6.992c-0.297,0.388,0.782,0.477,1.125,0.288c1.019-0.56,1.15-2.054,2.333-2.371
			c-0.08,0.036,0.032-0.128,0.032-0.128s-0.26-0.062-0.323-0.083c-1.652-1.014-1.747-2.606-1.57-4.191
			c0.229-2.06,1.052-3.349,3.323-1.829c0.546,0.365,1.13,0.988,1.713,0.88c3.989-0.732,5.493,1.261,5.681,4.901
			c0.042,0.806,0.411,1.664,1.393,1.536c0.742-0.101,1.494,0.062,2.03-1.194c0.941-2.211,2.97-2.215,4.369-0.197
			c1.201,1.729,1.507,4.392,4.655,3.694c0.406-0.091,0.126-0.288,0.311,0.124c1.255,2.793,1.118,5.984,2.812,8.747
			c0.76,1.237-1.046,2.468-2.149,3.377c-1.799,1.48-3.503,3.055-5.244,4.832c0.864,0.169,1.11-0.328,1.45-0.625
			c1.126-0.981,1.887-2.924,3.767-2.15c1.513,0.625,0.787,2.307,0.843,3.542c0.086,1.935,1.212,3.097,2.722,4.031
			c1.444,0.896,3.041,1.649,3.065,3.725c0.006,0.609-0.138,1.473,0.967,1.112c0.672-0.223,1.107-0.689,0.973-1.472
			c-0.159-0.932-0.746-1.661-1.186-2.462c-1.451-2.628-3.357-4.999-4.437-7.843c-1.409-3.705,0.354-5.835,4.525-5.451
			c2.913,5.333,5.046,11.087,8.807,15.971c1.449,1.879,2.94,3.789,5.074,4.762c4.5,2.054,5.796,5.608,5.419,10.156
			c-0.685,2.313-2.157,2.36-3.93,1.301c-2.151-1.288-3.989-2.937-5.404-5.036c-0.323-0.4-0.691,0.391-0.318,0.019
			c0.086-0.086,0.224-0.04,0.309,0.171c0.977,2.487,3.503,4.02,3.152,7.495c-0.251,2.497,3.126,3.489,5.411,4.41
			c2.14,0.862,4.112,1.789,4.542,4.372c0.109,0.663,0.098,1.304-0.526,1.757C390.464,658.892,389.751,658.842,389.14,658.204z"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          fill="#FCC1D0"
          d="M338.83,585.872c-0.147-0.879,0.012-2.107-0.508-2.558
			c-0.9-0.787-1.51,0.462-2.214,0.946c-1.695,1.163-3.388,2.337-5.127,3.432c-0.766,0.481-2.515,0.4-1.9-0.429
			c3.506-4.731-1.681-2.001-2.081-2.149c-5.968-2.175-1.564-6.654-0.963-8.649c1.168-3.871-1.375-3.647-3.019-4.684
			c-1.108-0.7-3.581,1.043-3.462-0.662c0.103-1.487,0.521-3.875,2.484-4.892c0.696-0.36,1.771-0.685,1.417-1.532
			c-0.542-1.295-1.548-0.083-2.337-0.017c-1.747,0.151-3.298,2.499-5.267,0.229c-1.802-2.079-0.468-3.523,0.658-5.131
			c0.211-0.305,0.101-0.833,0.14-1.255c-0.877,0.131-1.752,0.34-2.633,0.371c-0.731,0.026-1.882,0.093-2.115-0.298
			c-0.443-0.741,0.54-0.876,1.091-1.157c6.241-3.188,6.312-3.271,1.028-8.209c-1.193-1.116-2.209-1.792-1.134-3.435
			c5.24-2.212,6.295-7.682,8.909-11.837c3.322-5.277,5.923-11.018,8.816-16.566c2.702-5.186,0.304-10.576,0.002-15.724
			c-0.221-3.717,0.811-5.935,3.204-7.569c3.738-2.552,4.938-6.212,5.74-10.211c0.376-1.878,1.196-3.135,3.208-3.345
			c1.848,2.438,3.792,3.942,7.311,2.874c2.443-0.738,7.128,4.233,7.147,6.949c0.008,1.038-0.344,2.134,0.12,3.105
			c0.94,1.977,2.492,3.816,0.508,6.069c-1.416,1.608-1.808,3.954-4.335,4.8c-1.93,0.647-4.55,7.697-3.941,9.761
			c0.763,2.582-0.349,4.242-2.312,5.691c-3.818,2.817-2.895,7.624-4.6,11.344c-0.406,0.885,0.943,1.411,1.546,2.067
			c2.663,2.878,2.894,4.941,0.955,8.443c-0.255,0.461-0.688,0.954-0.491,1.428c1.873,4.532-0.4,8.873-0.609,13.301
			c-0.126,2.688-2.618,4.473-3.27,7.047c-0.344,1.363-1.267,2.631-0.043,4.028c2.171,2.482,0.341,3.995-1.437,5.453
			c-0.393,0.324-0.975,0.358-1.474,0.515c-1.541,0.484-3.629,1.466-3.448,2.739c0.292,2.087,1.654,0.342,2.479-0.121
			c0.555-0.313,0.945-0.898,1.468-1.293c2.618-1.964,5.453-2.862,8.285-0.7c1.602,1.223,2.838,0.527,4.355,0.157
			c3.588-0.883,5.187,0.758,4.734,4.638c-2.257,1.199-3.767,4.511-7.364,1.754c-0.998-0.765-2.97,1.561-3.071,3.604
			c-0.076,1.569,0.504,3.105,0.466,4.667c-0.061,2.503-1.312,3.052-3.53,1.799C340.3,589.578,339.011,588.141,338.83,585.872z"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          fill="#F8ABBB"
          d="M355.333,578.793c-0.417-4.363-1.626-4.932-5.196-2.508
			c-1.337,0.909-3.274,0.119-3.724-0.45c-3.087-3.9-5.797-1.767-8.183,0.461c-1.407,1.311-2.582,2.664-4.591,2.318
			c-1.401-0.239-1.444-1.932-1.004-2.533c1.072-1.469,2.401-3.293,3.975-3.737c4.081-1.15,3.672-0.709,2.425-4.17
			c-1.153-3.199,0.856-6.338,2.621-8.981c2.006-3.003,2.159-6.246,1.734-9.454c-0.174-1.309,0.203-1.848,0.775-2.65
			c0.767-1.075,1.84-2.053-0.566-2.708c-1.56-0.427-0.539-1.824-0.019-2.449c3.306-3.982,2.712-6.915-2.15-8.851
			c-0.835-0.331-0.955-1.039-0.517-1.545c3.386-3.925,0.614-11.001,6.988-13.455c1.288-0.496,1.495-2.241,0.97-3.502
			c-1.563-3.743,1.325-10.128,5.352-11.541c1.967-0.692,3.5-6.52,2.212-8.252c-0.343-0.46-0.826-1.27-0.649-1.592
			c2.644-4.869-2.47-6.005-4.181-8.74c-0.484-0.77-1.275-0.513-2.073-0.103c-4.119,2.105-6.045,0.186-6.766-3.809
			c6.849,0.21,13.74,0.955,20.523,0.398c3.004-0.25,5.825-3.083,8.667-4.839c1.654-1.025,3.032-2.665,4.796-3.299
			c10.909-3.925,18.919-11.225,25.096-20.812c0.544-0.841,1.46-1.441,2.203-2.157c2.913-0.433,4.35,0.744,4.714,3.748
			c0.627,5.198-0.223,9.631-5.14,12.51c-1.124,0.662-2.125,1.549-3.263,2.186c-3.705,2.083-7.054,3.718-4.694,9.446
			c1.708,4.149-0.744,7.457-4.918,9.322c-1.102,0.489-2.07,1.093-2.937,1.91c-0.671,0.636-1.467,1.429-2.382,1.187
			c-6.502-1.713-11.452,2.806-17.128,4.309c-3.657,0.965-4.901,4.605-4.341,9.59c-1.604,4.336-5.531,7.247-7.06,11.67
			c-1.645,4.762-4.619,8.584-8.478,11.895c-1.679,1.436-1.452,4.033-0.056,5.719c2.633,3.184,1.766,6.485,1.229,9.996
			c-0.613,4.011-2.305,7.701-2.993,11.677c-0.297,1.721-0.477,3.104,1.683,3.632c2.455,0.601,1.807,3.27,3.013,4.72
			c1.532,1.844,2.467,4.068,3.166,6.271c0.633,1.995,1.217,3.362,3.466,4.137c2.623,0.897,2.04,3.66,2.105,5.812
			c0.043,1.446-0.058,3.071-2.31,2.661C357.199,580.897,354.483,581.09,355.333,578.793z"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          fill="#FDF0DD"
          d="M383.632,378.322c-2.226,1.289-3.916-1.779-6.105-0.751
			c-0.417,0.195-0.819,0.435-1.2,0.698c-0.024,0.015,0.147,0.515,0.309,0.579c6.514,2.469,1.521,5.861,0.722,8.912
			c-2.376,3.209-5.656,1.51-8.252,0.953c-3.069-0.655-4.8-0.515-5.109,2.873c-0.118,1.318-0.91,2.105-1.69,2.992
			c-0.403,0.457-1.028,1.219-0.884,1.563c0.491,1.177,1.195,0.074,1.851,0.01c1.833-0.182,4.989,5.867,4.126,7.687
			c-0.419,0.886-1.501,1.792-0.63,2.78c0.213,0.239,1.525-0.478,2.326-0.771c0.355-0.131,0.696-0.304,1.043-0.454
			c-1.538,4.659,5.035,3.11,5.001,6.521c-0.014,1.438,0.458,2.232,1.844,2.514c4.388,0.889,6.911,6.753,5.109,11.161
			c-1.638,4.004,0.574,8.064,0.683,12.126c0.154,5.756-1.832,10.275-4.576,14.674c-1.622,2.602-3.132,4.914-3.282,8.143
			c-0.235,5.024-1.889,9.403-6.898,11.86c-2.551,1.253-3.402,4.318-6.918,5.183c-4.341,1.067-6.278-0.194-7.478-3.761
			c-0.832-2.474-1.783-3.555-4.714-2.842c-3.262,0.794-4.785-0.815-4.075-4.386c0.573-2.876-0.847-5.726-2.365-7.682
			c-3.962-5.103-5.673-11.186-8.421-16.804c-0.815-1.665-2.411-2.051-3.647-2.698c-1.472-0.775-1.385,1.35-2.087,2.113
			c-0.869,0.947-1.692,4.291-3.447,0.54c-0.07-0.152-1.118-0.146-1.26,0.077c-1.027,1.597-2.357,1.767-3.584,0.545
			c-1.543-1.542,0.774-2.084,1.074-3.174c0.129-0.472,0.337-1.16,0.115-1.443c-0.655-0.832-1.032,0.004-1.475,0.42
			c-0.472,0.444-1.006,0.818-1.493,1.247c-1.55,1.363-4.032,2.55-5.266,1.705c-1.657-1.136,1.278-2.063,1.666-3.558
			c0.668-2.575,0.715-5.833,4.155-7.047c0.889-0.312,1.628-1.964-0.273-2.06c-2.634-0.131-2.644-1.853-3.549-3.675
			c-2.466-4.969-1.875-9.077,1.601-13.225c2.362-2.821,0.795-5.346-1.329-7.585c0.904-0.976,1.126-3.087,2.877-2.624
			c1.488,0.394,0.821,2.275,1.212,3.465c1.199,3.622,0.541,6.76-1.68,9.933c-1.252,1.792-0.862,3.616,0.582,6.097
			c1.902,3.264,6.45,6.993,2.46,11.856c-0.374,0.453-0.541,1.079-0.801,1.624c2.812-0.408,4.129,3.194,6.955,2.636
			c2.526-0.498,4.893,0.045,7.257,0.807c0.576,0.033,1.04,0.298,1.446,0.685c1.641,2.108,2.364,4.818,4.383,6.684
			c0.213,0.301,0.384,0.621,0.536,0.956c1.943,6.383,6.153,11.76,8.081,18.15c0.424,1.405,1.369,2.157,2.812,2.349
			c1.311,0.173,2.544,0.521,3.488,1.564c1.083,1.197,1.513,2.758,2.345,4.091c1.203-2.031,2.615-0.937,4.021-0.286
			c1.152,0.529,1.994-0.155,2.813-0.823c4.033-3.278,7.507-6.902,7.754-12.533c0.028-0.649,0.349-1.251,0.682-1.815
			c0.905-1.063,1.531-2.229,1.254-3.707c0.043-0.357,0.141-0.7,0.283-1.029c2.801-4.156,4.84-8.544,4.245-13.749
			c0.574-2.912,1.267-5.787-0.051-8.733c-0.528-1.175-0.29-2.544-0.06-3.84c1.101-6.174,0.581-7.291-4.675-10.195
			c-0.952-1.368-1.609-3.093-3.706-3.113c-1.375-0.716-2.615-1.707-4.187-2.043c-1.764-0.992-1.83-2.697-1.879-4.404
			c-0.039-1.05,0.067-2.115-0.328-3.123c-0.291-0.985-0.774-1.82-1.776-2.258c-1.519-0.621-3.307-0.893-3.706-2.921
			c-0.03-0.611,0.179-1.13,0.594-1.567c1.233-2.704,2.232-5.547,3.756-8.074c2.062-3.42,5.396-3.247,8.62-2.012
			c2.585,0.991,4.223,0.572,5.158-2.147c0.411-1.197,1.687-2.616,0.311-3.509c-1.858-1.206-1.294,1.261-1.995,1.904
			c-1.755,1.619-2.942,1.493-3.826-0.682c-0.536-1.315-0.782-2.755-1.387-4.031c-0.602-1.267-1.991-2.54-0.286-3.723
			c1.694-1.174,2.494,0.131,3.143,1.619c0.619,1.417,1.644,0.944,2.535,0.228c1.156-0.935,1.235-1.719-0.093-2.761
			c-2.007-1.577-3.816-3.406-5.71-5.131c0.512-0.582,0.715-0.982,0.403-1.971c-1.264-4.008,0.691-5.988,4.644-4.922
			c1.898,0.511,3.821,0.793,5.772,1.011c1.995,0.223,3.845,1.337,3.554,3.403c-0.363,2.572-2.676,1.808-4.434,1.525
			c-0.755-0.121-1.47-0.509-2.281,0.093c3.213,0.582,6.548,1.139,9.698-0.013c3.856-1.414,6.524-0.061,9.003,2.674
			c1.16,1.283,2.732,2.534,1.285,4.315c-1.316,1.618-2.872,0.508-4.152-0.4c-1.593-1.126-3.242-1.514-5.17-1.055
			C382.702,373.776,382.106,374.698,383.632,378.322z"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          fill="#FDB2C5"
          d="M407.155,441.283c-2.428-1.353-5.696-0.013-7.796-2.422
			c-1.113-1.234-0.642-2.438,0.156-3.551c0.605-0.848,1.334-1.605,1.897-2.488c3.24-5.07,3.3-5.208,8.931-2.789
			c3.582,1.538,7.511,2.217,10.786,4.545c1.614,1.148,3.486,0.592,5.3,0.132c3.719-0.947,7.135,0.93,8.123,4.661
			c0.489,1.851,1.116,3.447,2.662,4.602c1.567,2.487,0.566,4.657-0.869,6.761c-0.864,1.27-2.191,2.192-2.724,3.685
			c0.096,1.347,2.436,1.618,1.761,3.101c-0.641,1.397-2.283,0.78-3.501,0.963c-1.396,0.207-2.823,0.247-4.124,0.892
			c-2.981,0.905-5.107,3.961-8.645,3.462c-1.171-0.7-2.463-2.133-2.066-3.014c1.98-4.402-3.412-7.596-1.69-11.822
			c0.83-2.042,0.935-4.3,2.033-6.284c0.649-1.17,0.696-2.57-0.528-3.554c-1.291-1.034-2.629-0.778-3.994-0.004
			C410.98,439.227,409.612,441.258,407.155,441.283z"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          fill="#F7A6B1"
          d="M407.155,441.283c3.08-1.668,4.753-6.17,9.394-4.688
			c0.988,0.313,2.129,0.63,2.331,1.829c0.634,3.769-2.179,6.523-3.009,9.872c-0.625,0.972-1.692,1.967-0.937,3.176
			c2.167,3.469,0.255,5.996-1.837,8.424c-3.946,4.576-6.994,9.775-10.402,14.724c-0.171,0.33-0.375,0.642-0.593,0.936
			c-2.765,3.149-4.621,6.894-6.796,10.435c-3.293,2.366-4.741,6.977-9.363,7.737c-5.846-1.607-11.291,0.098-16.517,2.391
			c-2.655,1.164-4.633,3.346-4.197,6.736c-0.111,0.605-0.444,1.059-0.948,1.396c-0.387,0.152-0.748,0.107-1.086-0.135
			c-1.511-7.156-0.083-8.441,7.161-11.459c3.154-1.311,6.948-1.507,9.628-4.12c0.533-0.519,1.923-0.822,2.531-0.496
			c5.313,2.835,7.556-2.379,10.815-4.314c3.497-2.074,1.05-5.958,1.208-9.044c0.014-0.279-0.375-0.643-0.658-0.857
			c-0.921-0.695-2.196,2.238-2.476,0.115c-0.131-1,2.027-2.504,3.374-3.462c3.173-2.258,6.442-4.388,9.746-6.455
			c4.961-3.103,2.742-8.001,2.957-12.21c0.031-0.599-2.228-1.315-3.427-1.979C405.107,446.995,408.373,444.952,407.155,441.283z"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          fill="#FDDEA0"
          d="M311.267,420.776c-0.037,6.928,1.452,14.087-3.808,20.117
			c-1.803,2.066-2.572,4.675-1.622,7.634c1.119,3.49-4.666,13.889-8.175,14.701c-2.725,0.634-2.103,3.926-4.6,4.934
			c-4.208,1.692-8.561,3.282-11.662,7.031c-1.104,1.338-2.196-0.832-3.488-0.86c-4.997-0.104-8.815,1.609-11.827,5.885
			c-1.415,2.013-3.981,3.574-7.157,2.174c-1.844-0.814-2.111-1.63-1.196-3.216c2.152-3.732,4.285-7.476,6.425-11.216
			c0.358-0.79,0.888-1.215,1.792-0.835c0.622,0.531,1.033,1.222,1.081,2.017c0.171,2.843,0.974,3.509,2.882,0.933
			c0.702-0.945,1.633-0.619,2.492-0.301c4.592,1.708,8.592-0.639,12.746-1.861c6.077-1.785,9.343-7.165,13.834-10.978
			c0.557-0.475,1.343-1.146,0.897-1.961c-0.352-0.642-1.169-0.132-1.775-0.068c-0.819,0.09-1.662,0.099-1.945-0.832
			c-0.364-1.203,0.371-2.19,1.321-2.552c3.315-1.268,4.589-3.677,5.067-7.075c0.288-2.046,1.025-3.501-0.592-5.659
			c-1.694-2.26,0.101-5.556-0.417-8.381c-0.11-1.112-0.541-2.198-0.256-3.334c0.175-0.561,0.519-0.981,1.038-1.254
			c1.553-0.481,3.223,0.004,4.768-0.572c1.23-0.183,1.59-1.054,1.847-2.13C309.222,421.908,309.412,420.471,311.267,420.776z"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          fill="#D5D0B0"
          d="M303.497,596.104c-0.533,1.059-1.065,2.113-1.598,3.172
			c-0.073-0.501-0.15-1.002-0.226-1.505c-0.533,0.401-1.569,0.886-1.518,1.195c0.494,3.056-1.62,2.821-3.367,2.586
			c-2.893-0.391-4.56,1.317-6.292,3.114c-0.521,0.54-1.169,1.084-2.041,0.557c0.314-0.466,0.704-0.902,0.923-1.41
			c0.172-0.403,0.14-0.896,0.2-1.347c-0.44-0.029-1.092-0.263-1.288-0.058c-3.131,3.275-7.555,3.362-11.457,4.718
			c-1.673-2.332-4.494-1.452-6.671-2.396c-0.477-1.119-0.13-2.22,0.753-2.738c2.265-1.325,3.937-3.545,6.55-4.321
			c2.064,1.826,4.571,1.963,7.003,1.359c1.101-0.271,3.407-1.021,1.124-2.992c-0.19-0.162-0.427-0.284-0.581-0.474
			c-1.704-2.109,4.211,1.086,1.308-1.874c-0.98-1.001-2.457-1.81-2.885-3.004c-1.667-4.646,0.184-6.836,5.107-6.409
			c2.791-0.456,4.384,1.524,6.203,3.051C299.957,587.965,301.871,591.887,303.497,596.104z"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          fill="#FDCAD6"
          d="M437.044,444.367c-2.781,0.321-3.193-1.784-3.597-3.688
			c-0.854-4.024-2.457-6.79-7.189-4.659c-5.288,2.379-8.25-3.488-12.868-3.568c-0.948-0.016-1.858-0.906-2.829-1.28
			c-6.19-2.393-10.617,0.659-11.201,7.69c-3.119-1.34-0.883-3.067-0.029-4.164c4.285-5.511,5.622-12.085,7.113-18.628
			c3.097-0.108,4.435,3.398,4.519,4.505c0.43,5.791,5.198,6.71,8.814,8.572c6.003,3.088,12.518,1.766,18.881,1.057
			c2.762,0.905,2.227,3.58,1.264,4.621c-2.711,2.925-0.312,4.003,1.353,5.524C440.317,442.167,439.618,444.256,437.044,444.367z"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          fill="#FDFBF8"
          d="M265.702,467.147c-0.515,0.269-1.031,0.54-1.546,0.812
			c-2.904,0.129-3.14-1.236-2.399-3.691c1.025-3.393,2.887-6.589,1.812-10.498c-0.836-3.046-1.969-2.62-3.582-0.896
			c-1.324,1.416-2.437,3.026-3.725,4.479c-0.664,0.747-0.995,2.45-2.626,1.212c-1.461-1.11-0.548-1.933,0.271-2.846
			c1.284-1.432,1.735-3.163,1.59-5.062c1.242-1.162,0.729-2.682,0.754-4.054c0.032-1.878,0.071-4.006,1.83-4.831
			c1.973-0.927,2.624,1.57,3.927,2.451c0.746,0.502,1.391,1.152,2.082,1.733c1.873,0.211,3.476,1.482,3.801,2.944
			c1.192,5.354,4.765,10.066,4.274,15.845C271.117,468.494,267.82,466.234,265.702,467.147z"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          fill="#FDFDFC"
          d="M294.744,587.328c-2.577,0.016-3.983-2.349-6.204-3.051
			c-4.364-2.179-8.858-1.942-13.407-0.732c1.85-6.03,3.702-12.063,5.555-18.094c4.058,1.637,4.295,5.896,5.873,9.081
			c1.307,2.646,2.609,5.001,5.075,6.622C292.828,583.134,295.094,584.574,294.744,587.328z"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          fill="#FDD78C"
          d="M311.267,420.776c-2.705,0.807,0.272,5.782-3.994,5.444
			c-0.411-6.373,0.222-12.516,4.029-17.94c1.053-0.8,2.104-1.597,3.157-2.393c1.931,2.323,2.543,4.233-0.641,6.331
			C310.865,414.166,311.204,417.637,311.267,420.776z"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          fill="#FDD2BE"
          d="M336.48,299.688c-0.784-0.259-1.569-0.521-2.354-0.78
			c-0.526-0.364-1.43-0.851-1.115-1.404c1.848-3.237-0.513-5.873-0.896-8.797c-0.437-3.295-0.285-6.785,0.177-10.172
			c2.623-0.381,2.674,2.031,3.501,3.419c1.108,1.855,2.036,2.776,3.644,0.563c0.384-0.528,0.827-1.264,1.382-1.344
			c5.543-0.78,9.775-4.312,14.272-7.073c6.299-3.861,12.647,0.271,18.912-0.838c1.699-0.304,3.534,0.029,5.26-0.636
			c-3.473-1.027-6.816-1.113-10.24,0.112c-1.535,0.55-3.293,0.422-4.989-0.115c-5.611-1.789-11.438-2.054-17.263-2.176
			c-1.433-0.029-2.568-0.505-3.455-1.519c-2.166-2.479-3.616-5.487-4.87-8.414c-1.256-2.927,1.332-4.36,3.803-5.304
			c4.322-0.515,8.036,1.6,11.97,2.732c1.845,0.617,3.765,0.943,5.68,1.215c1.942,0.275,3.066-0.246,2.106-2.584
			c-0.892-2.179-0.986-4.539,1.103-6.014c2.412-1.705,2.191-3.228,0.836-5.512c-2.176-3.666-1.702-5.006,1.636-6.641
			c2.902,3.941,5.562,7.786,2.971,13.151c-1.407,2.917,1.737,4.203,3.296,5.959c1.704,3.247,1.419,3.564-4.386,4.856
			c8.514,0.384,16.896-1.014,25.276-2.412c1.032-0.173,2.055-0.413,3.102-0.48c2.1-0.13,4.1,0.104,4.485,2.668
			c0.377,2.528-1.63,3.014-3.439,3.407c-5.379,1.171-10.918,0.63-16.317,1.657c2.66-0.48,5.355-0.653,8.034-0.691
			c1.659-0.026,4.23,0.029,2.534,3.007c-1.041,1.823,0.523,1.708,1.312,1.919c2.527,0.675,5.189,0.614,7.754,0.739
			c6.102,0.294,10.48,3.574,14.704,7.374c2.035,1.83,1.376,4.338,1.75,6.603c-0.24-1.545,0.054-3.1-0.12-4.639
			c-0.218-1.907,0.629-3.218,2.054-4.373c3.133-2.546,4.717-2.488,7.677,0.438c2.371,1.855,3.778,0.444,4.904-1.529
			c0.688-1.207,0.842-2.722,2.124-3.586c1.886-0.832,3.852-0.707,5.779-0.339c5.083,0.962,5.076,1.001,7.411-3.349
			c0.388-0.678,0.789-1.347,0.977-2.124c0.307-1.277,0.544-2.793,2.299-2.621c1.507,0.154,1.483,1.603,1.696,2.735
			c1.003,5.339,4.109,9.354,8.248,12.579c1.581,1.234,3.238-0.138,3.768-1.421c1.854-4.498,5.419-5.637,9.745-5.662
			c2.689,2.479,4.541,5.707,7.619,7.946c1.302,0.947,0.304,3.449-0.529,5.141c-0.36,0.729-0.656,1.507,0.209,2.069
			c0.85,0.551,1.739,0.599,2.459-0.259c3.491-4.142,7.378-3.735,11.44-0.979c1.164,0.79,2.21,0.461,3.31-0.077
			c1.528-0.749,3.062-1.503,4.86-1.212c0.381,0.103,0.725,0.272,1.044,0.502c1.263,1.174,1.363,2.815,1.756,4.341
			c0.009,1.011-0.022,2.006-1.284,2.31c-2.706-2.848-5.362-2.735-8.508-0.404c-11.363,8.417-24.204,13.88-37.286,19.072
			c-5.953,2.361-11.232,6.574-17.057,9.539c-2.078,1.06-4.03,2.358-5.762,2.882c2.953-1.177,6.689-0.371,10.269-1.097
			c1.236-0.253,2.189,0.266,3.205,1.046c2.869,2.201,5.635,2.182,8.479,0.054c0.763-0.569,1.423-1.007,2.441-0.969
			c5.342,0.208,9.188-2.153,13.478-5.512c5.15-4.037,7.933-9.082,11.01-14.338c2.084-3.557,4.386-3.729,6.925-1.218
			c0.852,0.844,1.322,1.705,0.717,2.76c-1.251,2.176-0.891,4.575-1.134,6.894c-2.118,2.892-5.578,3.64-8.497,5.205
			c-5.65,3.029-8.715,7.511-8.707,13.979c0.527,3.199,3.062,5.916,2.284,9.438c-3.573,3.63-7.369,7.025-11.477,10.028
			c-2.129,1.562-4.173,3.049-5.399,5.518c-0.771,1.555-2.074,2.469-3.739,0.854c-1.109-1.075-2.392-0.729-3.692-0.56
			c-3.86,0.506-7.669,1.737-11.634,0.793c-0.806-0.192-1.553,0.108-2.138,0.714c-3.575,3.697-8.262,4.747-13.076,5.473
			c-2.662,0.4-4.711,2.191-7.162,3.1c-3.623,1.343-8.149,0.883-10.388,5.167c-0.384,0.732-2.046,1.065-2.482,0.46
			c-2.255-3.119-7.125-2.44-8.824-6.254c-0.529-1.187-1.897-1.289-2.791-1.967c-4.754-3.605-10.029-5.285-16-5.678
			c-2.738-0.179-5.996-1.187-5.547-5.486c0.127-1.216-0.876-2.428-2.119-3.106v0.003c-1.708-1.401-0.971-1.904,0.745-1.936
			c3.011-0.058,6.037-0.224,8.959,0.739c2.021,0.666,3.872,0.221,5.666-0.746c0.393-0.211,0.576-0.563,0.141-0.829
			c-1.813-1.11-3.621-1.912-5.917-1.257c-3.229,0.918-6.322,2.147-9.858,2.227c-6.105,0.137-10.47-3.656-10.291-9.69
			c0.036-1.222,0.715-1.605,1.731-1.647c1.571-0.068,3.145-0.054,4.67-0.512c-4.172-1.775-4.85-2.911-4.245-7.489
			c0.138-1.04,0.114-2.082,0.289-3.119c0.733-4.35,0.077-5.172-4.182-5.979c-4.832-0.909-8.446-3.861-11.286-7.62
			C343.001,306.736,340.508,302.564,336.48,299.688z"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          fill="#FDF6EC"
          d="M378.92,438.861c1.116,5.614-0.786,10.226-4.717,14.155
			c-2.073,1.616-3.474,3.542-2.851,6.356c0.179,0.81,0.026,1.536-0.307,2.272c-1.255,2.789-7.735,7.879-12.313,6.923
			c-1.479-0.312-2.81-0.717-1.882-2.435c1.193-2.215,0.113-2.021-1.488-1.856c-4.272,0.438-4.281,0.4-3.333-4.057
			c0.165-0.771,0.446-1.894-0.54-1.912c-1.833-0.036-2.561-1.804-3.686-2.204c-4.973-1.773-5.389-6.877-8.191-10.167l0,0
			c-1.477-2.422-2.006-5.476-4.717-7.076c-0.264-0.262-0.526-0.523-0.789-0.787c0.09-1.809,2.284-3.534-0.572-5.425
			c-2.641-1.75-3.021,0.032-4.037,1.524c-0.448,0.65-1.392,0.526-1.484-0.244c-0.195-1.604-0.851-3.384,0.958-4.653
			c1.347-0.939,3.277-1.058,4.189-2.996c-1.683,0.384-3.19,0.729-4.699,1.072c0.463-1.84,1.094-3.596,2.433-5.016
			c0.356-0.378,0.681-0.8,0.952-1.244c1.689-2.784,3.362-5.576,5.04-8.365c-3.191-0.304-4.714-3.257-7.263-4.582
			c-1.603-0.832-3.506-1.464-3.351-3.8c0.083-1.244-0.661-2.898,0.715-3.614c1.675-0.877,1.999,1.042,2.871,1.887
			c3.324,3.208,4.915-1.365,7.447-1.865c0.955-0.188,1.742-1.862,2.367-2.991c2.406-4.337,6.096-5.294,10.056-2.274
			c1.045,0.796,1.733,0.783,2.787,0.374c1.454-0.566,4.904-3.052,1.689,1.558c-0.133,0.188,0.136,0.659,0.217,0.998
			c0.362-0.095,0.755-0.13,1.075-0.303c1.009-0.538,1.991-1.129,2.981-1.702c0,0.521,0,1.042,0,1.564
			c-0.202,3.312,2.346,2.041,3.935,2.364c1.555,0.099,1.583,1.212,1.57,2.354c0.238,1.056-0.982,2.477,0.786,3.148
			c-0.133,1.791-0.101,3.528,1.576,4.715c0.912,0.796,1.996,1.241,3.141,1.568c2.707-0.691,4.669-0.455,3.935,3.151
			c0.269,0.277,0.477,0.648,0.795,0.841c4.138,2.505,4.864,4.07,3.989,9.23c-0.408,2.409-1.011,4.717,0.971,6.845
			c0.827,0.886,0.483,2.191,0.042,3.327C378.796,436.595,378.276,437.684,378.92,438.861z"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          fill="#FDE2D1"
          d="M369.491,348.444c1.472,0.108,2.703,0.924,2.9,2.252
			c1.05,7.13,6.773,5.713,11.201,5.844c2.148,0.068,3.628,0.039,4.98,1.862c1.423,1.923,3.339,3.164,5.865,3.577
			c1.537,0.253,3.425,0.678,2.981,3.234c-0.298,1.727,1.299,1.303,2.2,1.283c1.948-0.038,3.645-0.022,3.949,2.566
			c0.115,0.988,1.145,1.507,2.137,1.209c0.923-0.275,2.24-0.464,1.937-1.955c-0.181-0.889-0.128-1.8,0.776-1.891
			c6.01-0.591,11.194-3.704,16.845-5.354c4.391-1.283,9.395-1.145,12.07-5.906c0.414-0.735,1.109-1.27,1.949-0.822
			c4.531,2.421,8.886-0.294,13.322-0.313c1.233-0.006,4.069-2.86,3.774,1.547c-0.045,0.679,1.146,0.43,1.456-0.185
			c3.896-7.67,12.042-10.723,17.773-16.391c1.955,1.356,1.993,3.224,1.406,5.255c-1.47,2.358-3.684,4.027-5.57,6.008
			c-2.192,2.31-1.791,3.727,1.243,4.504c3.964,1.011,4.801,4.895,6.992,7.499c0.75,0.889-0.617,1.862-1.599,2.235
			c-3.875,1.479-7.552,3.679-11.979,3.167c-4.72-0.547-8.924,1.529-13.28,2.796c-6.983,4.971-14.913,8.068-22.761,11.279
			c-2.906,1.188-5.413-1.4-8.21-1.759c-8.426-1.075-16.769-1.523-25.262,0.256c-4.218,0.883-8.745-0.483-12.956-1.919
			c0.246-2.796-5.49,0.074-3.636-3.429c1.596-3.02,9.838-4.252,12.156-2.604c1.115,0.79,2.03,3.208,3.732,1.347
			c1.862-2.035-1.283-2.207-1.929-3.106c-1.678-2.326-4.003-4.008-7.492-2.316c-3.259,1.581-6.974,0.471-10.481,0.644
			c-0.82,0.037-2.395,0.466-1.873-1.351c0.347-1.215,0.323-3.155,2.535-2.463c0.835,0.262,1.521,1.059,2.358,1.242
			c0.977,0.208,2.047,0.058,3.061-0.068c0.12-0.012,0.358-0.999,0.168-1.256c-0.671-0.91-1.257-1.994-2.74-1.84
			c-2.633,0.278-5.143-0.278-7.482-1.483c-1.523-0.788-1.939,0.387-2.201,1.318c-0.237,0.838-1.33,1.864,0.083,2.712
			c0.785,0.47,2.922-0.672,2.18,1.583c-0.565,1.714-2.112,1.046-3.367,0.841c-0.844-1.094-2.06-0.678-3.151-0.774l0.005,0.003
			C369.397,361.556,372.203,355.577,369.491,348.444z"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          fill="#F9ECD0"
          d="M332.554,278.479c-0.192,6.865,3.133,13.458,1.572,20.428
			c-2.585,0.551-2.882-1.759-3.92-3.142c-4.213-5.303-5.603-11.954-8.517-17.878c-0.751-1.523-1.34-3.135-2.491-4.412
			c-2.957-3.272-3.263-6.954-1.782-10.94c1-2.223,1.449-4.577,1.619-6.996c0.554-2.988,1.064-5.979,1.468-8.993
			c0.039-0.406,0.121-0.803,0.225-1.196c0.827-1.926,0.251-4.034,0.75-6.001c0.267-0.835,0.721-1.5,1.593-1.795
			c1.667-3.15,3.444-6.139,7.808-5.358c1.445,0.361,2.269,1.309,2.603,2.726c0.134,4.012,1.662,7.957,0.622,12.022
			c-2.386,1.859-2.665,4.252-2.405,7.168c0.23,2.614,1.756,5.198,0.51,7.908c-0.27,0.237-0.582,0.4-0.919,0.505
			c-2.898-0.259-2.348,1.471-1.815,3.189c0.527,2.971,1.312,5.854,2.43,8.659C332.418,275.664,333.245,276.969,332.554,278.479z"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          fill="#FDBD50"
          d="M302.636,426.191c-0.259,0.29-0.517,0.585-0.776,0.879
			c-2.838-0.116-5.51,0.473-8.132,1.597c-1.621,0.696-3.386,1.327-5.181,0.342c-0.876-0.774-0.945-1.824-0.844-2.859
			c0.403-4.213-2.375-7.594-2.962-11.543c1.397-5.924,2.1-6.562,7.727-6.855c0.827-0.041,1.561-0.083,1.794-1.066
			c0.123-0.518,0.173-1.055-0.158-1.49c-0.653-0.855-1.427-0.422-2.052-0.013c-3.159,2.08-5.056,0.877-6.245-2.319
			c-0.611-2.326-0.374-4.61,1.751-5.883c2.746-1.647,4.888,0.294,6.958,1.865c1.661,0.742,3.922-1.28,5.24,1.104
			c0.048,0.546-0.152,1.018-0.44,1.461c-0.766,0.765-0.932,1.476,0.19,2.073c0.009,0.764,0.021,1.532,0.031,2.297
			c1.26,2.044,1.941,4.397,4.059,5.962c1.476,1.091,1.305,2.879,0.792,4.637C303.455,419.578,302.584,422.806,302.636,426.191z"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          fill="#FDFDFD"
          d="M318.361,262.757c-3.387,7.14,3.588,11.037,5.309,16.58
			c1.742,5.611,4.322,10.963,6.536,16.429c-0.409,4.629-4.279,3.941-7.212,4.693c-5.117-9.908-9.624-19.99-9.249-31.51
			c-2.275-2.146-2.246-4.479-0.562-6.941c0.32-0.3,0.696-0.493,1.122-0.589C315.833,261.324,317.251,261.57,318.361,262.757z"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          fill="#FCE4D1"
          d="M336.48,299.688c2.994,0.854,4.514,3.381,6.306,5.583
			c4.582,5.634,8.833,11.494,16.906,12.764c2.356,0.368,3.6,3.048,2.601,5.812c-2.109,5.828-0.699,8.432,5.287,9.696
			c0.465,0.096,0.898,0.365,1.346,0.553c-0.358,0.455-0.629,1.062-1.095,1.328c-0.536,0.307-1.245,0.32-1.879,0.448
			c-2.05,0.41-4.101,0.819-6.152,1.226c1.645,4.088,2.607,8.442,8.413,9.124c4.149,0.486,7.406-1.692,10.587-3.007
			c4.41-1.827,6.025,2.035,9.043,2.7c1.129,0.25-0.399,0.716-1.041,1.162c-4.642,3.205-9.167,0.572-13.752,0.134
			c-1.155-0.112-3.397-1.99-3.559,1.238c-5.188,2.243-8.021-2.799-12.081-4.069c-2.296-0.719-1.046-3.323-0.237-4.782
			c2.802-5.051,3.126-10.598,3.661-16.12c0.213-2.204,0.143-4.142-2.853-4.443c-2.383-0.243-5.006-1.711-5.886,2.14
			c-0.107,0.464-0.829,1.104-1.252,1.097c-0.45-0.012-1.211-0.649-1.278-1.097c-1.24-8.294-6.946-13.889-11.934-19.919
			C337.22,300.754,336.863,300.21,336.48,299.688z"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          fill="#FD9D54"
          d="M302.636,426.191c-3.196-3.579,0.35-6.827,0.79-10.139
			c0.262-1.961,0.446-3.749-1.122-4.251c-3.464-1.104-2.726-3.628-2.767-6.02c4.318-1.037,7.343,4.021,11.764,2.499
			c-1.342,5.979-2.686,11.961-4.029,17.94C305.721,426.971,304.178,426.805,302.636,426.191z"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          fill="#FDF0BD"
          d="M298.682,401.089c0.288-0.25,0.576-0.5,0.863-0.749
			c1.13-2.566-0.489-4.76-0.969-7.108c0.667-0.854,1.595-0.822,2.534-0.748c2.877-0.017,3.523,5.671,7.539,2.901
			c1.15-0.793,2.627-1.143,2.661-2.901c1.714-1.063,3.623-0.758,5.486-0.778c-1.594,3.561,0.931,8.593-3.933,10.979
			c-0.723-0.508-1.338-1.136-1.94-1.772c-2.42-1.036-4.614-0.08-6.718,0.896c-1.603,0.749-3.249,1.033-4.939,0.953
			C298.151,402.714,298.18,401.898,298.682,401.089z"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          fill="#CED8AB"
          d="M306.675,309.861c-5.015,0.524-7.583-2.684-9.502-6.526
			c-1.235-2.473-3.469-3.327-5.664-4.309c-1.413-0.633-2.994-0.908-3.806-2.443c4.667-7.534,8.197,0.201,10.247,1.871
			c3.267,2.662,7.094,5.285,9.436,9.127C307.148,308.341,306.912,309.104,306.675,309.861z"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          fill="#FDDA89"
          d="M298.682,401.089c1.691,2.296,3.143,0.326,4.765-0.177
			c2.815-0.867,5.595-3.198,8.669-0.598c-3.42,1.932-6.582,4.76-11.016,3.157c-0.53,0.002-1.062,0.009-1.593,0.013
			c-1.19-0.468-1.942,2.178-2.783,0.793C295.838,402.819,297.529,401.882,298.682,401.089z"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          fill="#FDBB4E"
          d="M301.1,403.472c4.286,1.088,6.893-3.682,11.016-3.157
			c0.248,0.79,0.498,1.58,0.747,2.37C309.064,404.771,305.161,405.309,301.1,403.472z"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          fill="#FDE2D1"
          d="M365.553,367.317c-0.118,1.088,0.339,2.316-0.72,3.161
			c-0.089,0.07-0.689-0.221-0.733-0.413c-0.281-1.293,0.301-2.185,1.458-2.745L365.553,367.317z"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          fill="#FDEABF"
          d="M271.277,465.621c-1.048-7.047-2.315-14.015-7.187-19.664
			c2.116-4.885,7.152-7.084,10.243-11.158c3.092-4.068,4.776-8.771,6.388-13.333c1.379-3.907-3.755-0.954-4.832-2.981
			c0.851-1.05,2.006-1.968,0.968-3.587c-0.635-0.992-0.17-1.932,0.527-2.763c1.948-1.731,3.324-1.082,4.194,1.09
			c1.307,3.259,2.185,6.669,4.111,9.703c1.245,1.962,0.16,3.752-1.427,5.171c-0.406,0.6-0.846,1.181-0.917,1.946
			c-0.411,3.809,0.923,6.892,3.733,9.458c1.355,1.235,2.534,2.663,2.82,4.596c-0.051,1.8-0.552,3.598,0.43,5.371
			c0.632,1.138,0.096,2.272-0.624,3.29c-1.814,2.558-3.404,5.263-5.659,7.513c-3.072,3.067-3.575,3.151-6.718,0.351
			c-1.875-1.667-3.618-3.415-4.495-5.688c-0.68,2.326,1.232,3.941,1.986,5.946C274.263,462.931,273.833,465.075,271.277,465.621z"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          fill="#FDCAA5"
          d="M277.522,412.976c-0.884,1.734,5.099,5.502-1.631,5.51
			c-2.154-2.358-4.357-4.677-6.444-7.096c-1.049-1.215-0.344-2.703,0.873-2.594c5.361,0.473,3.428-3.616,4.075-6.091
			c1.032-0.01,2.064-0.016,3.096-0.022c1.736-0.282,2.069,0.874,2.243,2.185C278.199,407.345,278.207,410.257,277.522,412.976z"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          fill="#FDC7AC"
          d="M269.965,417.032c1.613,1.417,3.644,2.524,4.219,4.802
			c0.238,0.937-0.44,2.043-1.497,1.727c-2.42-0.722-2.764-3.196-3.688-5.121C268.681,417.774,269.107,417.233,269.965,417.032z"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          fill="#FDE6AD"
          d="M279.083,405.042c-0.014-1.132-0.577-1.9-1.592-2.361
			c0.026-0.543,0.05-1.091,0.076-1.635c0.515-0.405,1.014-0.405,1.492,0.062c1.666,0.614,1.354-0.803,1.646-1.6
			c1.105-0.24,2.185-0.525,2.754-1.664c2.182-1.084,0.753-3.059,1.137-4.581c3.545-0.183,5.003-2.738,6.262-5.515
			c1.709,1.536,2.776,3.621,4.431,5.214c1.804,2.591,1.089,5.016-0.479,7.383c-2.084-1.337-3.808-4.207-6.711-2.659
			c-1.74,0.928-2.069,3.101-1.291,5.125c-0.358,0.762-0.384,1.772-1.493,1.967c-1.764,0.323-3.507,0.784-5.318,0.81
			C279.634,405.506,279.33,405.324,279.083,405.042z"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          fill="#FDFAF6"
          d="M294.86,393.159c-2.457-0.973-3.606-2.914-4.001-5.41
			c3.356-4.325,5.743-2.335,7.922,1.199c0.741,1.2,1.549,2.358,2.329,3.536c-0.802,0.284-1.603,0.566-2.406,0.851
			C297.367,394.5,296.099,394.156,294.86,393.159z"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          fill="#FCB182"
          d="M284.597,393.264c0.701,1.744,5.083,4.155-0.856,4.687
			c-0.711,0.122-1.053-0.099-0.795-0.867c-0.438-1.356-2.134-2.518-1.039-4.098C282.695,391.85,283.706,392.97,284.597,393.264z"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          fill="#FCAB86"
          d="M279.06,401.108c-0.497-0.019-0.995-0.042-1.492-0.062
			c-0.571-1.343-2.142-2.424-1.24-4.129c0.144-0.269,1.288-0.365,1.655-0.103C279.492,397.893,280.13,399.32,279.06,401.108z"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          fill="#FCB183"
          d="M282.946,397.084c0.266,0.291,0.532,0.579,0.795,0.87
			c-0.059,0.979,0.465,2.188-0.606,2.825c-1.315,0.783-1.636-0.793-2.43-1.271C280.448,397.771,281.227,396.994,282.946,397.084z"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          fill="#F9D8C4"
          d="M503.134,285.559c-0.885,0.172-1.826,0.208-2.646,0.541
			c-2.885,1.174-5.21,3.432-8.653-0.167c-1.648-1.721-6.129-2.121-7.979,2.528c-1.27,3.186-3.598-0.931-5.636-0.15
			c-0.646,0.25-0.762-0.266,0.564-2.47c1.643-2.726,3.682-5.835-1.517-6.983c-3.398-0.752-2.111-4.318-4.005-5.88
			c-0.409-1.167,0.363-1.906,1.214-2.073c7.021-1.385,10.861-7.578,16.61-10.876c2.225-1.279,1.331-2.412-0.516-3.237
			c-3.181-1.423-6.467-1.769-9.943-1.433c-3.853,0.374-7.712,0.784-11.589,0.726c-0.306-0.189-0.542-0.451-0.643-0.819
			c0.017,0.378,0.32,0.592,0.513,0.896c0.179,1.088-0.602,2.352-1.203,2.131c-4.146-1.52-9.482,3.433-12.869-2.511
			c-0.915-1.606-5.548,0.044-6.867,2.335c-1.118,1.945-2.098,4.19-1.951,6.292c0.229,3.313-1.201,4.795-4.557,5.326
			c2.638,2.016,3.731-2.418,5.859-0.681c-1.453,3.733-2.938,6.952-8.055,4.171c-1.599-0.87-4.035-0.205-6.089-0.24
			c0.033-2.841,0.507-5.653,0.517-8.494c0.233-0.313,0.548-0.509,0.923-0.601c0.529-0.032,1.014,0.118,1.502,0.333
			c1.125,0.499,2.156,2.073,3.457,1.027c1.216-0.982-0.209-2.108-0.488-3.161c-0.524-1.996,1.409-4.439-1.356-6.132
			c-0.665-0.407,0.476-1.664,1.4-1.846c3.155-0.617,5.137-2.949,7.446-4.84c3.979-3.253,8.398-5.841,12.909-8.279
			c0.596-0.32,0.994-0.835,1.328-1.401c1.549-2.633,3.728-4.658,6.19-6.405c0.665-1.167,2.261-2.226,0.904-3.735
			c-1.423-1.587-2.563-3.762-5.277-3.446c-2.634,0.307-5.694,1.279-3.506-3.538c0.744-1.638-1.452-3.89,0.49-5.812
			c0.396-0.394-0.632-0.512-1.071-0.535c-1.135-0.054-2.389,0.339-3.158-0.95c-0.954-4.712,1.723-8.688,2.595-13.026
			c0.409-2.962-0.192-5.928,0.015-8.88c0.028-0.646,0.024-1.305,0.227-1.929c0.237-0.723,0.378-1.667,1.376-1.619
			c0.864,0.039,1.299,0.829,1.573,1.555c0.91,2.384,0.461,4.898,0.612,7.355c0.083,1.4-0.184,3.244,1.592,3.589
			c1.742,0.335,3.757,2.249,5.427-0.535c0.848-1.415,2.539-0.985,3.964-0.659c2.843,1.583,3.946,3.912,3.358,7.175
			c-0.461,2.56-0.182,5.211-0.798,7.771c-0.149,0.617-0.234,1.369,0.471,1.743c0.681,0.358,1.264-0.063,1.734-0.515
			c2.614-2.505,5.548-4.699,5.214-9.069c-0.197-2.589,1.576-4.949,1.859-7.556c0.151-1.404,0.557-3.055-0.736-4.031
			c-1.192-0.896-2.088,0.563-3.099,1.005c-2.711,1.189-5.516,1.356-8.397,0.921c-1.365-3.983-0.658-6.366,2.838-8.887
			c-1.525-0.787-3.941,0.432-4.429-2.112c-0.288-1.503-0.276-2.831,0.787-4.033c1.581-1.785,3.343-1.772,5.241-0.569
			c0.755-1.846,1.896-2.93,4.129-2.738c1.65,0.14,1.622,1.714,2.61,2.684c-0.643-4.54-2.519-5.615-6.452-3.964
			c-2.125,0.896-3.883,1.034-4.101-2.002c-0.058-0.797-0.317-1.545-0.82-2.169c-0.647-0.799-1.522-2.053-2.485-1.234
			c-1.245,1.062,0.633,1.19,1.01,1.801c1.007,1.638,0.141,3.215-2.102,3.394c-2.624,0.211-4.843-0.793-6.553-2.84
			c-0.144-0.346-0.196-0.704-0.168-1.075c2.13-3.129,1.097-5.72-1.095-8.41c-2.764-3.391-1.891-5.625,2.242-6.52
			c1.843,1.305,3.688,2.61,5.533,3.912c5.984,4.06,6.903-1.849,9.349-4.754c4.758,1.238,9.564,2.163,14.506,2.173
			c0.168,0.07,0.333,0.147,0.501,0.22c0.789,1.833,1.576,3.663,2.364,5.496c2.564,1.616,1.823,4.11,1.577,6.379
			c-0.202,1.871-0.377,3.617,0.945,5.201c1.4,1.676,2.882,2.399,5.139,1.517c3.622-1.421,5.043-0.781,6.676,2.862
			c1.068,2.38,2.237,4.738,2.819,7.313c0.35,1.552,0.794,2.399,2.91,2.022c2.593-0.461,4.151,2.242,2.846,4.521
			c-0.391,0.688-1.14,1.279-0.706,2.063c1.962,3.544,1.072,7.984,3.798,11.347c1.869,2.306,1.84,5.137,0.561,7.789
			c-0.744,1.545-1.063,3.173-1.574,4.76c-1.361,4.226-1.798,8.1-0.053,12.479c1.299,3.26-1.309,6.83-4.647,8.148
			c-1.438,0.566-3.33,0.908-1.062,3.045c1.902,1.788,0.34,3.317-0.997,4.625c-3.286,3.208-4.738,8.813-5.723,13.052
			c-1.034,4.446-1.029,7.284,3.424,9.507c1.657,0.826,1.39,3.074-0.763,3.426c-3.682,0.601-5.419,2.47-5.601,6.149
			C504.8,284.004,504.117,284.996,503.134,285.559z"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          fill="#FDFDFD"
          d="M540.113,160.515c12.119,4.498,21.903,14.222,35.335,15.774
			c9.408,1.363,18.828,2.614,28.314,3.304c0.063,0.007,0.2,0.051,0.392,0.026c14.214-1.964,28.802-0.003,42.825-4.072
			c1.812-0.528,3.72-1.564,5.593-0.051c-2.162,4.305-6.161,6.906-9.449,10.169c-3.939,3.912-7.884,7.815-11.827,11.724
			c-1.773-1.123-3.67-0.63-5.555-0.406c-2.534,0.025-4.616-0.483-5.491-3.356c-0.549-1.804-2.407-1.951-3.958-1.919
			c-6.438,0.131-12.784-0.717-19.142-1.564c-3.147-0.419-6.271-0.01-9.228,1.295c-4.548,2.006-9.56,2.741-13.975,5.105
			c-1.945,1.12-4.091,1.733-6.192,2.47c-4.094,1.429-8.108,2.991-11.372,6.014c-1.062,0.985-2.318,1.712-3.81,1.919
			c-0.864,0.048-1.662-0.083-2.247-0.806c-10.356-7.124-11.485-20.489-19.677-29.078c1.762-3.826,3.904-7.53,5.147-11.516
			C536.596,162.991,537.811,161.456,540.113,160.515z"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          fill="#FDE1CD"
          d="M503.134,285.559c1.082-4.236,0.266-9.93,7.37-9.559
			c0.286,0.016,0.892-0.918,0.869-0.944c-0.7-0.717-1.368-1.782-2.224-1.974c-4.885-1.084-4.162-4.091-2.731-7.402
			c0.257-0.592,0.926-1.139,0.912-1.692c-0.157-6.017,1.474-9.958,5.785-14.021c0.94-0.886,2.635-1.811,0.682-3.41
			c-0.867-0.711-3.417,0.271-2.636-1.654c0.574-1.417,2.615-2.591,4.248-3.148c2.782-0.947,5.294-5.432,3.703-7.712
			c-2.276-3.253-0.728-6.273-0.469-9.645c0.395-5.147,6.377-10.102,0.331-16.052c-2.043-2.009-1.971-7.102-3.046-10.745
			c-0.454-1.545,1.201-1.264,1.889-1.849c1.144-0.976-0.431-1.414-0.798-2.079c-0.442-0.807-1.039-1.776-2-0.524
			c-2.635,3.432-2.67,0.499-3.269-1.277c-1.029-3.067-1.931-6.203-3.249-9.146c-0.95-2.127-2.203-4.484-5.164-1.702
			c-1.304,1.226-3.389,1.421-4.634,0.042c-1.646-1.82-4.312-3.372-3.236-6.606c0.854-2.562,0.983-5.108-0.188-7.623
			c1.13-0.115,1.601-0.803,1.546-1.868c-0.092-1.77,0.465-3.612,2.362-3.228c1.776,0.362,4.028,0.403,5.09,2.786
			c1.428,3.193,0.774,7.84,5.714,8.8c0.203,0.042,0.505,0.816,0.434,0.877c-4.476,3.967,1.146,4.513,2.301,6.433
			c0.922,1.539,1.837,2.508,0.607,4.476c-1.177,1.88,0.884,3.477,1.953,4.587c1.28,1.331,1.75-0.736,2.389-1.526
			c0.909-1.126,1.808-1.619,3.374-1.145c1.023,0.31,2.69,0.33,3.353-0.292c5.114-4.772,6.734-0.512,8.219,3.212
			c1.995,5,3.611,10.157,4.48,15.563c-1.32-3.363-2.265-6.952-4.091-10.01c-1.321-2.21-2.594-6.254-6.613-4.015
			c-3.898,2.175-2.486,5.64-1.456,8.932c0.864,2.758,1.91,4.491,5.425,4.59c1.432,0.038,2.121,3.196,0.765,5.179
			c-0.876,1.283-2.011,2.386-2.944,3.634c-0.856,1.145-0.058,2.652,0.814,2.175c4.377-2.393,1.478,2.121,2.663,2.623
			c-1.79,2.46,3.658,7.832-3.358,8.272c-0.846,0.051-0.927,1.437-0.112,1.827c2.788,1.337-0.467,7.08,4.887,6.494
			c5.121-0.56,7.921-3.173,7.722-9.578c3.968,13.653-7.53,46.161-18.784,55.204c-0.378,0.183-0.757,0.138-1.048-0.137
			c-3.721-3.528-3.96-3.468-7.085,0.563c-1.313,1.696-1.941,3.967-4.086,4.997c-0.774,0.295-1.569,0.413-2.391,0.397
			C505.867,286.732,504.411,286.493,503.134,285.559z"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          fill="#F0EFD0"
          d="M550.327,206.142c0.774,0,1.55,0.003,2.322,0.003
			c2.415,1.373,3.716,3.611,4.754,6.065c0.958,2.271,2.391,4.046,4.91,4.712c0.892,0.236,2.04,0.521,2.35,1.308
			c1.584,4.008,5.371,4.991,8.675,6.718c2.96,1.551,4.692,3.982,5.066,7.451c0.295,2.729,0.697,5.457,1.248,8.161
			c0.189,3.755,1.133,7.444,0.929,11.228c-0.03,8.045-2.586,15.678-3.831,23.525c-0.146,0.924-0.679,1.689-1.309,2.374
			c-4.972-0.262-9.941-0.525-14.913-0.784c-4.913-11.314-8.763-22.859-7.047-35.467c0.231-1.692-2.139-2.997-0.474-4.699
			c0.937-0.956-0.369-2.981,1.318-3.215c0.453-0.064,1.199,1.99,1.778,3.017c1.708-8.56-2.297-21.679-8.255-27.562
			c-0.811-0.796-1.587-2.821-1.157-3.455C547.896,203.739,548.923,206.68,550.327,206.142z"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          fill="#FD90A4"
          d="M555.742,278.524c9.002,0.991,14.512,7.879,21.293,12.62
			c3.243,2.284,5.191,4.914,2.955,9.066c-1.06,1.967,0.697,2.745,2.018,3.573c2.499,1.57,3.599,2.981,0.77,5.633
			c-4.063,3.807-4.132,11.833-0.519,16.353c1.825,2.281,1.02,5.221,2.121,7.709c0.347,0.781-1.114,0.899-1.806,0.788
			c-2.604-0.426-4.526,0.758-6.289,2.428c-2.256-0.64-3.196-2.08-2.701-4.412c0.152-0.713,0.753-1.637-0.171-2.073
			c-0.847-0.396-1.307,0.627-1.881,1.098c-0.823,0.672-1.657,1.289-2.787,1.238c-1.001-0.042-1.751-0.499-2.142-1.437
			c-0.355-0.848-0.059-1.603,0.676-2.028c3.62-2.102,3.86-4.888,1.856-8.27c-1.541-2.601-2.016-6.167-6.069-6.382
			c-0.515-0.029,0.118,0.045-0.393-0.019c-2.973-0.381-2.097-4.181-4.83-5.167c-2.281-0.819-3.559-2.917-1.792-5.723
			c1.129-1.792-0.868-1.392-1.583-1.472c-2.197-0.243-3.161-1.526-3.585-3.529c-0.395-1.868-0.818-3.663,1.518-4.619
			c0.246-0.102,0.754,0.167,0.738-0.416c-0.225,0.145-0.471,0.224-0.739,0.231c-2.254,0.061-1.929,2.104-2.618,3.378
			c-0.473,0.87-0.66,2.258-2.013,2.016c-1.493-0.266-1.509-1.67-1.421-2.835c0.149-1.964,0.523-3.89,2.014-5.38
			c0.712-0.713,1.829-1.424,0.646-2.55c-0.907-0.864-2.196-0.701-2.843,0.105c-2.69,3.356-6.27,3.902-10.202,3.659
			c-1.038-0.063-1.659,0.589-2.054,1.513c-1.036,2.419-2.557,2.243-4.305,0.771c-0.451-0.691-0.517-1.44-0.346-2.227
			c0.94-2.041,2.412-3.509,4.586-4.213C540.738,283.87,547.28,278.969,555.742,278.524z"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          fill="#FDBAC4"
          d="M529.92,291.902c-0.005,0.774-0.013,1.551-0.018,2.326
			c1.4,2.175,0.18,3.768-1.362,5.038c-1.889,1.552-3.061-0.237-4.27-1.305c-3.891-3.442-7.415-7.274-11.361-10.653
			c-0.825-0.726-2.112-0.764-2.737-1.797c1.103-1.75,2.097-3.583,3.329-5.237c3.979-5.345,5.301-5.233,8.516,0.566
			c0.007,1.049,0.01,2.096,0.016,3.142C523.646,287.632,525.288,291.259,529.92,291.902z"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          fill="#FCFAF7"
          d="M483.517,143.196c4.714,2.649,8.37,7.156,14.104,8.01
			c3.282,5.63-2.395,7.102-4.706,10.134l0,0c-2.562-1.347-2.602-4.059-3.263-6.347c-0.64-2.21-1.472-4.04-3.547-5.319
			c-1.803-1.11-2.945-2.927-3.517-4.994C482.409,144.028,482.16,143.03,483.517,143.196z"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          fill="#FDE1CD"
          d="M529.92,291.902c-4.778,1.059-7.811-1.987-7.888-7.921
			c1.5,1.155,3.397,0.547,4.328-0.211c5.283-4.296,5.64-0.006,6.594,3.503c0.076,0.285,0.583,0.457,0.891,0.681
			C532.536,289.27,531.229,290.584,529.92,291.902z"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          fill="#FDFDFD"
          d="M455.941,137.009c-0.751,3.902-1.5,7.805-2.248,11.708
			c-2.086-4.038-6.188-6.562-7.905-10.985c-1.009-2.604-4.52-2.856-6.47-4.821c-0.53-0.534-1.493,0.116-1.809,0.957
			c-0.339,0.902,0.035,1.59,0.933,1.811c5.566,1.369,7.533,6.363,10.313,10.426c1.375,2.012,3.43,3.205,4.859,5.063
			c-0.007,1.542-0.011,3.087-0.018,4.632c-0.393,0.471-0.835,0.876-1.403,1.133c-3.304,1.615-6.108-1.126-9.231-1.059
			c-1.231-0.403-2.569-0.534-3.727-1.135c-1.847-0.963-3.609-2.048-1.546-4.421c1.192-1.373,0.511-2.143-0.912-2.485
			c-3.79-0.912-5.84-4.418-9.139-6.084c-0.564-0.285-0.722-1.494-1.678-0.637c-2.379,2.14-5.094,1.363-7.812,1.062
			c-2.1-0.23-4.024-0.109-2.028,2.834c1.346,1.983,0.638,3.813-1.687,3.109c-6.01-1.814-10.286,1.51-14.875,4.162
			c-1.126,0.648-2.168,1.452-3.424,1.865c-4.093,0.71-8.16,1.836-12.362,0.668c-0.987-0.275-1.702-0.118-1.991,1.146
			c-0.874,3.816-3.708,5.374-7.257,6.023c-1.028-0.208-1.49-0.841-1.543-1.89c-0.043-0.842,0.742-2.441-1.061-2.06
			c-1.304,0.275-0.894,1.66-0.558,2.629c0.647,1.872,0.912,3.737,0.231,5.662c-1.484,2.188-2.288,5.301-6.014,4.434
			c0.16-7.175,0.235-14.354,0.524-21.526c0.104-2.607-0.707-3.093-2.88-1.925c-0.525-3.858,1.711-6.11,4.768-7.601
			c3.542-1.728,5.542-4.172,6.308-8.209c1.171-6.165,5.628-9.12,13.106-9.419c2.174-0.086,3.972-0.349,5.358-2.451
			c4.866-7.374,12.287-9.059,19.611-4.296c2.657,1.727,4.419,2.196,7.162-0.049c3.957-3.24,8.619-2.203,11.737,2.5
			c2.196,3.311,4.672,3.944,8.322,3.083c6.369-1.499,12.339,5.389,10.786,11.723C449.237,137.252,453.698,135.986,455.941,137.009z"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          fill="#F9ECD0"
          d="M365.582,170.755c2.799-0.426,3.727-3.02,5.445-4.696
			c1.1-1.536,1.607-3.477,3.194-4.68c2.743-1.741,7.799-1.526,5.855-7.217c-0.313-0.918,0.96-1.545,1.945-1.046
			c4.688,2.383,9.448,0.316,14.17,0.33c0.072,0.169,0.144,0.335,0.217,0.505c-0.33,1.872-1.773,2.438-3.345,2.847
			c-2.763,0.723-2.575,2.159-0.932,3.916c0.716,0.765,1.652,1.279,2.415,2c1.174,1.107,1.236,2.258-0.564,2.345
			c-2.768,0.127-3.086,2.083-3.354,3.989c-0.77,5.458-4.392,8.644-8.984,9.357c-1.65,0.256-2.775,0.918-3.05,2.518
			c-0.507,2.949-2.391,3.976-5.123,4.51c-2.078,0.406-4.511,0.49-5.811,2.732c-0.507,0.818-1.143,1.506-1.99,1.983
			c-0.339,0.166-0.694,0.294-1.059,0.387c-4.604,1.03-8.761,7.396-7.838,11.97c0.205,1.018,0.922,1.532,1.682,2.048
			c0.799,0.543,1.317,1.208,1.316,2.249c-0.008,9.133,3.988,15.773,12.118,20.338c1.953,1.097,4.253,2.444,3.434,5.048
			c-0.752,2.393-3.104,3.73-5.608,3.237c-2.537-0.499-2.683,1.507-3.711,2.652c-0.153,0.102-0.311,0.201-0.467,0.301
			c-3.22,2.658-1.861,5.281,0.263,7.651c1.861,2.08,1.505,3.423-0.902,4.469c-3.047,1.327-3.042,3.563-1.825,6.12
			c0.459,0.969,2.078,1.558,1.077,2.966c-0.8,1.122-2.163,0.959-3.266,0.767c-2.137-0.367-4.229-0.994-6.34-1.513
			c5.049-1.193,4.669-5.8,5.835-9.389c0.539-1.654-0.509-2.78-1.902-3.458c-1.743-0.848-2.657-2.243-3.623-3.899
			c-2.163-3.708-6.207-3.877-9.901-4.197c-3.221-0.279-6.58,0.416-9.358-1.916c0.332-1.801-0.757-3.612,0.02-5.416
			c2.708-1.855,4.395-4.6,6.26-7.188c0.987-0.838,2.09-0.832,3.312-0.636c0.78,0.124,1.823,1.548,2.252,0.287
			c0.405-1.187,0.557-2.991-1.119-3.769c-1.437-0.665-2.911-1.187-3.456-2.882c-0.053-1.168-0.139-2.313-1.633-2.562
			c-0.094-2.424,2.052-4.328,1.582-6.839c0.377-8.989,3.911-16.615,10.061-23.081C357.072,179.481,361.343,175.131,365.582,170.755z
			"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          fill="#ECF2EA"
          d="M342.812,206.973c1.924,2.847,0.174,4.847-1.582,6.839
			c-5.279,4.37-10.689,8.532-17.372,10.611c3.413-6.699,11.492-8.272,15.63-14.213C340.355,208.963,341.69,208.045,342.812,206.973z
			"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          fill="#D7D7A5"
          d="M220.898,219.475c-0.946,0.659-2.664,1.267-2.711,1.986
			c-0.311,4.709-2.686,3.733-5.45,2.3c-0.363-0.186-0.947,0.054-1.43,0.099c0.269,0.576,0.528,1.159,0.805,1.728
			c3.383,6.977,8.372,12.626,14.369,17.447c-5.759,0.192-10.834-0.812-13.701-6.737c-0.678-1.404-2.417-2.296-3.668-3.426
			c-1.587-8.26-6.053-15.237-10.141-22.351c-0.862-1.5-0.288-2.479,0.157-3.595c0.358-0.899,1.283-0.797,1.996-0.333
			C207.723,210.873,214.309,215.179,220.898,219.475z"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          fill="#C6C593"
          d="M206.015,623.78c1.046-0.519,2.092-1.033,3.138-1.552
			c0.765,0.403,1.257,1.631,2.253,1.21c2.544-1.075,4.32-0.032,6.278,1.528c1.651,1.314,2.981,0.585,3.798-1.483
			c0.925-2.348,0.667-5.169,2.76-7.047c0.568-0.064,0.819,0.646,1.391,0.569c-0.401-0.074-0.7-0.321-0.992-0.581
			c-1.347-1.816,2.615-3.035,0.782-4.354c-1.262-0.907-3.443,0.058-5.206-0.239c0.702-2.908,3.617-3.679,5.378-5.563
			c2.72-2.459,5.226-2.184,7.553,0.576c0.253,0.297,0.776,0.663,0.989,0.51c2.714-1.977,6.79-0.766,8.999-3.705
			c1.475-1.961,2.974-3.516,5.657-3.387c1.472,0.07,1.775-2.352,3.555-1.824c3.621-0.919,7.313-0.841,11.005-0.762
			c-1.038,2.902,0.122,4.536,2.951,5.379c0.966,0.283,1.958,0.725,2.665,1.557c-0.01,2.194-2.542,0.955-3.161,2.326
			c1.104,3.56-1.257,2.601-2.95,2.143c-1.327-0.362-3.544-3.337-2.655,1.197c0.022,0.117-1.301,0.516-2.004,0.755
			c-3.09,1.052-6.746,0.738-9.018,3.79c-0.237,0.32-1.141,0.612-1.252,0.485c-3.875-4.354-8.223,0.017-12.267-0.712
			c-1.149-0.207-1.377,0.482-1.399,1.311c-0.055,1.971-2.271,3.286-1.598,5.416c0.053,0.167,1.124,0.291,1.24,0.114
			c3.314-4.956,7.681-0.306,11.48-1.022c0.467-0.086,3.701-2.306,3.28,0.333c-0.489,3.073,4.754,4.277,1.961,7.127
			c-2.262,2.307-3.814,5.221-6.643,7.095c-1.633,1.081-2.846-2.312-3.932-0.048c-2.119,4.418,2.151,2.685,3.647,3.628
			c0.854,1.129,0.054,1.635-0.823,2.019c-0.971,0.425-1.794,1.018-2.483,1.813c-3.845,3.309-8.354,5.826-11.587,9.825
			c0.472,1.858,2.902,3.124,1.422,5.61c-0.477,0.803,0.497,0.308,0.726,0.14c0.935-0.688,1.797-1.132,2.508,0.247
			c-1.679,2.105-2.88,4.843-6.236,4.75c-1.558-2.337-7.218-2.159-4.135-7.67c-1.359,0.848-2.291,1.466-3.254,2.021
			c-0.546,0.317-1.456,0.915-1.667,0.742c-1.048-0.869,0.528-0.905,0.625-1.517c0.496-3.121-3.055-3.276-4.326-4.567
			c-2.251-2.288-0.047-2.78,0.125-4.012c1.973-2.375,2.953-5.35,4.735-7.838c-0.498-2.175-1.9-4.422,0.529-6.366
			c0.253-0.202,0.378-0.563,0.562-0.85c-0.334-0.091-0.68-0.292-1-0.254c-1.411,0.159-2.82,0.354-4.219,0.605
			c-1.307,0.229-2.599,0.539-3.896,0.818c0.431-1.293,0.653-2.738,2.37-3.059c0.075-0.017,0.046-0.591,0.065-0.906
			c-0.953,0.312-2.158,0.354-2.816,0.975c-2.191,2.075-4.427,0.598-5.795-0.494c-1.361-1.087-1.24-3.364,0.473-4.945
			C205.986,625.283,205.89,624.414,206.015,623.78z"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          fill="#B5BA7C"
          d="M220.218,611.832c2.087-2.054,4.869-0.358,7.271-1.321
			c1.243-0.499,1.009,0.918,0.416,1.741c-1.032,1.422-2.054,2.855-3.081,4.289l0.009-0.01c-0.872,2.542-1.658,5.121-2.66,7.613
			c-0.387,0.967,0.375,2.96-1.898,2.61c-1.532-0.235-3.396,0.72-4.279-1.635c-0.603-1.608-2.153-2.094-3.469-0.857
			c-3.792,3.562-3.177,0.161-3.375-2.034c0.768-0.618,1.537-1.231,2.304-1.849c0.531-0.49,1.061-0.977,1.592-1.462
			C215.439,616.557,217.829,614.196,220.218,611.832z"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          fill="#D7D3B4"
          d="M252.35,598.438c-1.308,2.015-2.436,4.119-5.59,2.928
			c-1.22-0.461-1.465,1.19-1.977,2.021c-1.291,2.098-3.018,3.767-5.566,3.539c-1.454-0.131-2.329-0.31-3.055,1.239
			c-0.261,0.557-2.171,1.009-2.24,0.883c-1.974-3.455-5.031-3.436-8.326-2.78c0.788-1.867,1.788-3.263,4.21-3.144
			c2.511,0.12,3.688-1.398,3.65-3.878C239.755,598.978,246.051,598.706,252.35,598.438z"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          fill="#E8EFE5"
          d="M269.62,172.37c0.288,0.857,0.598,1.708,0.856,2.576
			c0.388,1.302,2.192,3.372,1.124,3.63c-1.542,0.368-3.127-1.753-3.751-3.378c-5.653-14.725-15.606-26.494-25.949-37.987
			c-0.961-1.069-2.548-2.614-3.994-1.283c-1.269,1.168,0.307,2.652,0.968,3.801c1.466,2.549,3.146,4.974,5.372,8.436
			c-1.903-1.257-2.812-1.859-3.722-2.457c-0.497-0.573-0.993-1.145-1.489-1.718c-1.255-3.743-3.152-7.159-5.248-10.486
			c-0.437-0.694-1.409-1.561-0.517-2.412c0.868-0.829,1.625,0.169,2.419,0.55c12.898,6.154,22.417,15.47,27.764,28.944
			C265.08,164.684,267.54,168.452,269.62,172.37z"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          fill="#FCEDDD"
          d="M483.517,143.196c-0.983,1.926,0.727,3.42,1.631,3.903
			c5.821,3.096,5.482,9.356,7.768,14.241c-1.886,1.548-3.731,2.204-5.896,0.31c-0.694-0.607-2.371-1.189-2.798-0.831
			c-3.199,2.684-4.439-0.554-6.313-1.869c0.479-2.489-0.674-4.555-1.672-6.679c-1.163-2.476-0.866-4.892,0.907-6.929
			C478.79,143.452,480.747,141.987,483.517,143.196z"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          fill="#FDF6C1"
          d="M485.844,116.538c-2.729,6.098-3.701,12.619-4.664,19.147
			c-0.081,0.541-0.503,1.433-0.826,1.462c-0.912,0.073-0.847-0.864-0.776-1.427c0.849-6.795,1.776-13.576,6.256-19.192
			L485.844,116.538z"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          fill="#A7C694"
          d="M289.303,220.294c-0.692-0.23-1.998-0.493-1.988-0.685
			c0.302-5.602-3.697-3.944-6.659-4.021c-2.029-1.562-4.054-3.122-6.088-4.674c-0.093-0.073-0.365-0.058-0.365-0.08
			c0.232-6.634-8.204-1.654-9.016-6.689c-0.628-3.887-3.866-4.335-6.406-6.097c-1.29-0.896-3.004-3.244-4.083-5.247
			c3.116-1.791,6.214-2.415,7.789,1.687c1.036,2.703,4.635,1.1,5.484,3.969c0.222,0.739,1.069,0.56,0.274,1.36
			c-1.489,1.503-0.246,3.042,0.188,4.29c0.582,1.669,1.648,0.358,2.36-0.279c1.371-1.225,3.033,0.771,4.627-0.607
			c0.713-0.614,3.327-0.976,3.71,0.509c0.338,1.302-1.847,0.541-2.781,1.087c-0.885,0.522-2.081,0.778-1.524,2.233
			c0.465,1.219,0.082,2.911,2.273,3.068c3.577,0.259,5.8,4.968,10.073,3.177C287.882,215.626,288.593,217.962,289.303,220.294z"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          fill="#7CA970"
          d="M234.268,182.552c-0.264-0.006-0.526-0.012-0.79-0.022
			c-0.518-4.248,0.611-6.5,5.443-4.763c1.018,0.365,2.294,0.247,3.263-0.749c1.656-1.705,2.388,0.691,3.623,0.934
			c0.545,0.108,0.814,1.091,0.354,1.059c-2.638-0.173-2.79,3.487-5.794,3.295C238.464,182.185,236.061,179.136,234.268,182.552z"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          fill="#FAFAEC"
          d="M178.536,541.062c1.544-3.67,3.087-7.335,4.629-11.001
			c1.668-0.495,2.911-2.118,4.872-1.833c-0.493,2.2-0.876,4.435-1.52,6.586c-0.401,1.338-1.198,2.554-1.816,3.823
			C182.738,539.681,181.298,542.05,178.536,541.062z"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          fill="#F8F8F7"
          d="M457.614,131.478c-0.013,1.526-0.426,2.889-1.604,3.938
			c-2.92-1.155-1.963-3.772-1.079-5.138C456.138,128.417,456.816,130.724,457.614,131.478z"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          fill="#B9BC84"
          d="M268.975,604.819c-2.713,0.49-4.698-1.678-7.087-2.345
			c-2.021-0.563,0.262-3.282,1.467-4.798c4.386,0.099,8.516-0.589,12.041-3.529c0.453-0.376,1.745-0.444,2.11-0.091
			c1.129,1.086-0.339,2.379-0.04,3.614c-0.937,3.774-6.179,3.192-7.084,6.999C269.962,605.191,269.49,605.214,268.975,604.819z"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          fill="#A6C694"
          d="M274.362,218.732c-2.399,1.942-1.636,4.584-1.562,7.073
			c-4.531-2.13-8.878-4.728-13.944-5.499c-0.896-0.134-1.874-0.617-0.56-1.209c2.078-0.934,2.057-1.762,0.902-3.497
			c-1.203-1.804-2.167-3.032-4.107-0.902c-0.152,0.167-0.634,0.032-0.961,0.035c1.893-4.744-6.625-3.362-4.763-8.093
			c-6.445-2.515-8.477-9.872-14.323-13.045c2.066-0.826,2.983-0.832,4.725,1.59c1.385,1.929,3.873,6.362,8.339,4.155
			c-0.299,4.453,10.077-3.071,6.31,5.636c-0.448,1.037,0.915-0.083,1.336-0.278c2.285-1.062,4.28-1.004,4.024,2.048
			c-0.159,1.894,1.153,1.884,1.932,2.39C266.167,212.028,270.485,215.083,274.362,218.732z"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          fill="#91B77E"
          d="M313.739,262.117c-1.297,2.278-0.646,4.556,0.006,6.833
			c-0.158,1.373-0.316,2.745-0.475,4.117c-1.948-0.279-2.096-2.102-2.788-3.381c-4.242-7.85-8.406-15.745-12.608-23.618
			c-1.67-3.125-1.657-6.478-1.399-9.872c0.833-2.598,2.149-3.749,5.145-2.62c4.01,1.51,6.454-2.246,9.56-3.634
			c1.68-0.751,3.111-0.761,3.644,0.471c1.647,3.794,8.38,1.516,8.247,7.143c-0.24,0.538-0.479,1.072-0.719,1.609
			c-0.949,2.063-0.109,4.325-0.72,6.427c0.032,0.227,0.013,0.447-0.06,0.668c-1.716,1.27-3.245-0.164-4.838-0.39
			c-5.821-0.826-9.6,1.782-9.719,6.714c-0.08,3.353,1.967,4.907,4.68,5.985C313.337,259.222,315.137,259.734,313.739,262.117z"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          fill="#F6D99F"
          d="M342.862,216.375c1.152,0.857,2.257,1.619,3.857,1.664
			c1.38,0.039,3.348,6.068,2.402,7.085c-0.756,0.812-1.749,0.851-2.326-0.112c-1.211-2.025-3.166-1.458-4.922-1.638
			C342.202,221.042,342.533,218.71,342.862,216.375z"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          fill="#F8CB84"
          d="M335.612,230.562c-0.657,1.561,4.069,3.045,0.065,4.654
			c-1.417,1.11-2.25,0.061-3.123-0.845c-0.558-0.726-1.116-1.452-1.674-2.175C332.458,231.653,334.035,231.106,335.612,230.562z"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          fill="#D9CBD4"
          d="M453.615,151.168c-2.959-0.541-4.361-2.735-6.083-4.971
			c-2.809-3.65-4.133-9.159-10.203-9.37c-0.978-0.032-1.73-0.791-1.665-1.836c0.096-1.504,0.852-2.719,2.196-3.471
			c0.776-0.436,1.516-0.938,2.495-0.237c5.114,3.66,8.049,9.238,12.147,13.788c0.783,0.87,0.813,2.415,1.19,3.647
			C453.668,149.533,453.642,150.349,453.615,151.168z"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          fill="#F5CCB6"
          d="M452.061,155.88c0.512-0.029,1.024-0.055,1.537-0.08
			c3.144,1.331,6.285,2.662,9.429,3.989c-3.506,1.836-3.843,3.752-0.868,6.736c2.321,2.333,4.159,5.42,0.1,8.183
			c-2.646-0.147-4.482-1.055-3.309-4.133c0.646-1.692-0.284-2.569-1.4-3.208c-2.195-1.257-4.236-2.406-4.346-5.429
			c-0.048-1.289-1.884-1.743-2.379-3.017C450.406,157.569,451.46,156.817,452.061,155.88z"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          fill="#FDE2E3"
          d="M590.402,605.527c-2.402-0.355-6.323,3.347-5.718,5.515
			c0.394,1.417,1.487,1.65,2.744,0.938c0.852-0.485,1.881-1.643,2.522-0.185c0.273,0.629-0.465,2.208-1.18,2.656
			c-1.842,1.152-3.864,2.125-5.936,2.78c-2.397,0.761-4.703,1.396-6.155,3.73c-0.746,1.203-2.423,1.842-3.402,1.195
			c-2.255-1.494-4.249-3.896-3.256,1.516c0.163,0.881-0.95,0.145-1.308-0.182c-2.745-2.499-4.297-5.433-4.38-9.334
			c-0.028-1.363-1.861-2.691-2.87-4.031c-1.446,2.066-3.979,3.9-3.796,6.442c0.394,5.555-2.43,10.263-3.269,15.452
			c-0.02,0.127-0.06,0.351-0.115,0.358c-4.548,0.603-7.474,2.837-9.309,7.303c-0.537,1.31-1.604-0.938-1.754-2.183
			c-0.104-0.848,0.418-2.023-0.717-2.409c-1.062-0.358-1.61,0.667-2.104,1.387c-2.152,3.138-3.453,2.84-4.375-0.852
			c-0.631-2.521-1.882-1.351-2.959-0.202c-2.373-3.08-4.763-0.44-7.145-0.024l0.017-0.008c-1.97,1.107-3.792,2.618-6.275,2.377
			c-2.063,0.686-3.802,2.652-6.304,1.581c1.137-2.566,3.896-3.745,5.189-6.267c1.427-2.791,3.104-5.295,2.989-8.721
			c-0.075-2.312,1.01-4.602,3.664-5.458c2.182-3.37,4.447-6.692,6.502-10.137c0.812-1.366,1.134-3.217-0.446-4.254
			c-1.519-0.993-2.258,0.799-3.123,1.611c-2.196,2.058-4.229,4.294-6.433,6.342c-1.068,0.991-2.003,3.144-3.734,2.081
			c-1.729-1.062,0.162-2.84,0.092-4.321c-0.297-3.922,2.413-6.513,4.473-9.206c2.369-3.097,5.412-5.663,8.283-8.344
			c2.854-2.664,6.246-4.2,9.872-5.381c2.271-0.863,3.842-2.562,5.449-4.308c2.293-2.495,3.925-2.317,5.243,0.754
			c1.02,2.377,2.284,2.946,4.494,1.824c1.477-0.525,2.757-1.462,4.287-1.882c4.994-1.372,8.426-0.419,10.361,4.312
			c2.723,6.66,9.078,8.63,14.105,12.34c0.875,0.647,2.029,0.285,3.06,0.366C591.134,600.975,591.708,602.014,590.402,605.527z"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          fill="#FDCCD8"
          d="M389.14,658.204c0.785,0,1.573-0.004,2.357-0.008
			c2.424-1.146,4.741,0.264,7.096,0.271c3.269,0.008,6.626-0.136,9.386-2.375c0.547-0.116,1.085-0.089,1.617,0.09
			c2.022,2.223,4.36,1.766,6.764,0.774c4.987,2.313,9.693,0.099,14.455-0.935c2.11-0.458,2.893-2.538,4.195-3.951
			c4.045-4.389,4.772-4.725,9.224-0.822c7.15,6.275,16.13,6.897,24.729,8.808c0.76,0.169,1.573,0.058,2.322-0.209
			c0.875-0.31,1.801-0.681,1.709-1.829c-0.089-1.096-0.945-1.539-1.915-1.612c-6.27-0.46-12.346-2.131-18.55-2.972
			c-3.064-0.416-5.845-1.384-8.264-3.274c-3.797-2.966-3.534-6.103,0.645-8.456c0.684-0.384,1.321-0.845,2.009-1.215
			c1.604-0.576,3.212-1.248,4.806,0.043c0.568,0.465,1.283,0.519,1.844,0.177c2.706-1.657,5.945-1.014,8.681-1.941
			c3.979-1.347,8.047-0.47,11.974-1.469c2.73-0.692,4.532,1.699,6.787,2.594c1.391,0.555,2.328,1.21,3.792-0.082
			c2.493-2.198,5.538-0.605,8.331-0.59c1.133,0.008,1.401,1.341,0.886,2.134c-1.165,1.788-1.692,3.772-2.406,5.725
			c-0.429,1.175-2.175,2.056-2.688,0.677c-1.042-2.814-1.903-1.294-3.303-0.499c-3.026,1.726-6.699,0.807-10.178,2.244
			c3.06,1.49,6.984,0.53,8.983,3.943c0.354,0.604,1.401-0.247,2.051-0.647c1.11-0.684,2.073-1.611,3.328-2.056
			c1.072-0.38,2.082-0.271,2.883,0.598c0.86,0.927,0.85,2.134-0.032,2.812c-4.974,3.822-3.527,7.709-1.062,12.41
			c1.523,2.912,3.055,5.798,4.73,8.636c2.365,3.999,2.123,4.434-1.717,7.027c-0.308,0.209-0.63,0.4-0.796,0.755
			c0.191,0.048,0.404-0.179,0.647-0.186c3.99-0.092,4.121,3.504,5.446,5.884c0.403,0.729,0.218,1.801,0.862,2.537
			c0.341,4.485-0.893,8.783-1.568,13.15c-0.123,0.799-0.22,1.746-1.272,1.545c-0.921-0.177-0.525-1.104-0.324-1.609
			c0.724-1.808-1.986-3.229-0.302-5.371c0.822-1.043-0.762-1.266-1.641-0.836c-4.006,1.965-8.118,0.308-12.174,0.42
			c-0.674,0.021-1.745,0.869-1.907-0.277c-0.082-0.592,0.841-1.543,1.532-1.991c3.297-2.136,2.826-1.829,1.239-4.816
			c-0.429-0.807-4.699-5.073,1.271-6.782c4.93-1.414-2.018-0.738-0.735-2.168c2.155-2.403,0.224-4.889-0.017-7.35
			c-0.061-0.619,0.253-1.471-0.619-1.48c-0.406-0.007-1.076,0.784-1.185,1.303c-0.349,1.651-0.87,3.396-0.631,5.003
			c0.566,3.807-2.018,4.276-4.607,4.833c-0.425,0.093-1.056-0.153-1.399-0.458c-0.666-0.594-0.102-1.116,0.272-1.659
			c1.733-2.514,5.452-5.112-0.281-7.406c-0.338-0.135-0.593-1.235-0.427-1.727c0.375-1.098,1.136-0.617,1.885-0.237
			c0.358,0.18,0.897,0,1.354-0.016c-0.091-0.458-0.078-1.267-0.283-1.327c-4.252-1.245-2.008-2.406-0.25-4.205
			c1.161-1.187,2.428-4.025,1.864-4.762c-1.072-1.401-3.004,0.248-4.449,0.905c-1.448,0.651-2.763,0.602-4.158,0.083
			c-0.663,2.442-7.376-0.666-4.656,5.243c0.61,1.327-2.156,2.253-3.615,2.953c-0.542,0.261-1.883,0.008-2.111-0.413
			c-1.061-1.964,1.395-1.645,1.956-2.643c0.377-0.667,1.257-1.792,0.621-1.952c-3.554-0.891-5.025-5.988-9.528-4.708
			c-1.265,0.362-2.027,0.051-2.478-1.126c-0.284-0.739-0.527-1.661-1.554-1.309c-1.129,0.388-0.244,1.295-0.322,1.97
			c-0.355,3.031-2.846,0.214-3.916,1.38c-1.685-1.796-2.81-0.454-3.742,0.807c-1.091,1.471-1.838,4.461-3.346,0.291
			c-1.11,1.353-2.616,1.949-4.285,2.162c-3.209,0.402-6.478,3.553-9.632-0.27c-0.403-0.49-1.363-0.076-1.434,0.717
			c-0.107,1.202,0.623,3.01-1.526,2.738c-1.732-0.22-3.501-0.691-4.467-2.687c-1.147-2.375-2.227-4.543-4.612-0.391
			c-1.021,1.778-3.639-0.824-5.602-1.197c-5.406-1.022-10.687-2.808-16.121-3.544c-1.419-0.192-3-0.346-4.502-0.575
			C387.77,660.354,388.456,659.279,389.14,658.204z"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          fill="#FDCFBF"
          d="M540.871,588.233c-10.71,4.108-17.69,12.087-22.811,21.997
			c-1.289,0.748-2.854,1.866-2.207,3.298c2.728,6.01-2.897,7.472-5.74,9.647c-1.883,1.441-5.413,0.198-8.419,1.219
			c-3.896,1.321-3.182,3.711-3.303,6.289c-1.997-0.166-2.624-2.17-4.091-3.048c-0.396-2.274,1.477-4.44,0.178-6.85
			c-0.38-0.704,0.739-1.586,1.58-1.997c1.276-0.623,2.547-1.249,3.704-2.079c-2.101,1.518-4.613,1.968-7.024,2.623
			c-1.464,0.399-3.233,0.963-3.997-1.091c-0.545-1.46,1.779-4.161,3.572-4.424c0.563-0.079,1.228,0.832,1.562,0.004
			c0.1-0.249-0.984-0.718-1.37-1.27c-1.374-3.286,1.499-4.984,2.703-7.307c2.143-3.666,6.312-4.127,9.594-5.992
			c0.376-0.21,0.985,0.007,1.488-0.029c-0.522,0-1.05-0.095-1.562-0.061c-5.115,0.354-9.806-3.6-10.19-8.758
			c-0.09-1.194-0.184-2.318-0.767-3.38c-0.289-0.729-0.542-1.458-0.116-2.22c2.155-1.887,4.874-2.35,7.527-2.971
			c2.725,0.284,5.392,0.754,7.613,2.569c1.053,0.861,2.286,1.105,3.642,1.145c3.573,0.112,4.764,1.455,4.686,5.019
			c-0.007,0.346-0.792,0.729-0.045,0.982c0.219,0.074,0.616-0.419,0.888-0.694c0.994-1.007,0.176-1.805-0.193-2.78
			c-1.602-4.243-4.988-7.578-6.1-12.06c-1.909-3.263-4.638-5.832-7.253-8.48c-1.384-1.404-2.718-2.764-2.76-4.897
			c1.798-3.139,2.13-3.192,5.479-0.88c0.412,3.449,3.498,5.048,5.405,7.4c1.893,2.331,3.614,4.679,5.121,7.281
			c3.527,6.083,6.348,6.962,13.038,4.555c0.5-0.185,1.02-0.2,1.537-0.048c1.028,0.963,1.859,2.21,3.631,1.815
			c1.788-0.396,3.011-0.378,1.854-3.093c-1.412-3.316-2.32-6.813-4.273-9.955c-0.706-1.133-1.57-1.129-2.449-1.52
			c-1.908-1.986-3.216-4.229-3.014-7.104c0.817-1.124,2.43-0.838,3.316-1.91c1.609-1.941,2.066-3.873,0.226-5.933
			c-2.652-2.966-2.626-3.06,1.208-4.566c1.09,0.515,2.263,1.301,2.294-0.911c0.032-2.229-0.144-4.448-0.259-6.668
			c-0.154-2.896,0.336-5.561,3.773-6.433c1.478-0.375,1.962-1.567,2.357-2.872c0.528-1.74,1.711-2.672,3.554-2.289
			c1.981,0.417,3.276,1.589,3.353,3.767c0.016,0.394,0.061,0.835-0.112,1.164c-3.627,6.952-0.536,14.393-1.417,21.609
			c-0.675,5.522,0.506,11.552,2.664,17.031c1.162,2.949,0.726,5.365-1.999,7.211c-1.349,0.911-2.232,2.185-2.708,3.745
			C543.675,585.88,542.453,587.199,540.871,588.233z"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          fill="#FDC5CA"
          d="M496.059,605.533c-0.731,2.495-3.824,3.941-3.142,7.066
			c-1.686,1.667-4.856,2.857-4.426,5.333c0.499,2.878,0.257,5.976,1.646,8.698c0.354,0.691,0.771,1.355,0.86,2.153
			c-0.013,0.697-0.306,1.261-0.902,1.631c-1.233,0.768-2.705,1.421-1.646,3.304c0.87,1.549,1.955,2.707,3.97,2.719
			c3.048,0.017,5.116-1.202,6.011-4.189c2.675,5.49-0.283,10.808-1.09,15.976c-0.985,6.306,3.729,7.242,6.916,8.931
			c2.513,1.338,3.773,2.375,3.59,5.054c-0.465,6.791-1.038,13.567-5.513,19.179c-2.409,3.024-1.266,6.521-1.564,9.828
			c-2.045-0.596-1.311-2.893-2.575-4.14c-1.793-1.766-2.547-6.107-5.799-1.367c-0.132,0.193-1.088,0.133-1.266-0.106
			c-0.236-0.312-0.291-1.092-0.061-1.363c0.832-1,1.687-2.109,2.782-2.723c1.41-0.787,4.244-1.457,2.768-3.027
			c-1.437-1.524-0.119-5.284-3.815-4.888c-0.35,0.039-1.188-0.879-1.138-1.27c0.542-4.357-1.398-7.665-4.245-10.646
			c-0.991-1.037-0.892-2.112,0.07-3.129c0.811-0.854,2.056-1.578,2.349-2.58c0.479-1.634,3.928-2.276,2.114-4.201
			c-1.816-1.93-3.107,0.932-4.503,1.767c-2.948,1.763-2.461,1.451-4.723-0.657c-1.468-1.368-4.329-1.289-6.589-1.736
			c-1.269-0.253-3.073-0.139-3.147-1.645c-0.091-1.85,1.851-1.192,3.004-1.555c3.978-1.247,9.244,0.931,11.747-4.36
			c0.135-0.288,1.168-0.508,1.228-0.412c0.314,0.509,0.604,1.123,0.62,1.704c0.021,0.853-0.975,1.681-0.135,2.545
			c0.172,0.174,0.987,0.075,1.002,0.005c0.497-2.629,1.065-5.211,3.074-7.195c-0.46-0.034-0.943-0.185-1.376-0.091
			c-2.932,0.63-7.293-2.607-8.416,3.242c-0.15,0.774-0.771-0.927-1.274-1.141c-3.194-1.367-5.238-4.325-9.993-3.529
			c-3.813,0.642-7.979,1.415-12.036,1.388c-2.122-0.012-4.192-0.066-5.902,1.49c-2.644,2.417-4.624-2.327-7.207-0.684
			c-1.951-0.197-1.099-1.321-0.579-2.088c0.731-1.079,1.966-1.667,2.913-2.545c5.633-0.7,10.226-5.066,16.312-4.457
			c2.647,0.264,5.062-0.446,5.761-3.945c0.622-3.109,3.654-4.522,5.754-6.628c1.78-1.782,0.591-5.444,3.473-7.245
			c1.779-1.111,3.571-2.154,5.412-3.146c2.28-1.231,4.665-2.315,5.972-4.869C493.212,604.29,494.592,603.818,496.059,605.533z"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          fill="#D5D4AA"
          d="M513.289,692.852c-4.098,2.841-4.5,7.422-5.563,11.712
			c-0.295,1.184,0.168,1.857,1.169,1.995c0.675,0.094,2.044-0.339,2.047-0.55c0.047-4.756,2.995,0.195,3.975-1.219
			c1.821,1.677-0.285,2.413-0.853,3.503c-0.761,1.465,1.663,4.235,0.261,3.957c-3.86-0.762-7.495,3.19-11.183,0.205
			c2.268-4.411,3.389-9.136,3.987-14.033C507.694,693.786,509.321,692.389,513.289,692.852z"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          fill="#FDC8BB"
          d="M525.203,618.904c-4.225,3.974-1.603,10.499-5.674,14.754
			c-1.963,2.054-3.469,4.469-6.171,5.691c-0.874,0.227-1.74,0.54-2.632,0.659c-1.51,0.193-3.184-0.215-3.728-1.521
			c-0.667-1.595,1.188-2.265,2.239-2.578c6.7-1.992,9.09-7.468,11.351-13.255C521.323,620.773,521.966,618.039,525.203,618.904z"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          fill="#FDC8C2"
          d="M525.923,635.399c1.525-1.952,3.546-3.171,5.994-2.917
			c1.56,0.158,0.937,1.87,1.149,2.941c-2.189,6.495-7.289,11.258-10.272,17.282c-1.017,0.099-1.806,2.286-2.846,0.692
			c-0.7-1.074-0.616-2.601,0.604-3.884c3.4-3.571,5.28-7.945,6.011-12.802C526.623,636.317,526.148,635.84,525.923,635.399z"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          fill="#9AA95C"
          d="M327.064,629.172c0.517-0.571,1.034-1.137,1.55-1.704
			c5.63,0.119,6.959,4.197,7.923,8.61c0.415,1.524,0.918,3.15-0.998,4.001c-1.747,0.777-3.577,1.782-5.406,0.053
			c-0.656-0.619-1.312-1.326-2.206-1.561c-3.308-0.856-3.886-2.641-1.966-5.392C326.796,631.985,326.427,630.433,327.064,629.172z"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          fill="#FDB3BB"
          d="M498.43,632.248c-0.697,1.722,1.568,4.923-1.513,5.165
			c-4.019,0.321-8.973,1.111-10.386-4.716c-0.828-3.423,2.984-2.046,4.023-3.596c1.026-1.227,2.149-2.208,3.922-1.581
			c2.55-0.482,2.883,1.771,3.915,3.163C498.404,631.205,498.416,631.726,498.43,632.248z"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          fill="#AFB476"
          d="M327.064,629.172c0.095,0.503,0.451,1.189,0.245,1.49
			c-3.348,4.902,0.45,7.27,3.76,8.854c2.28,1.09,5.555,0.742,5.469-3.438c1.491,1.417,0.562,3.213,0.763,4.836
			c-2.121,1.586-3.405,3.711-3.982,6.28c-1.4,1.195-2.473,0.536-3.81-0.354c-3.422-2.281-5.396-5.613-7.234-9.098
			C323.87,634.884,325.468,632.027,327.064,629.172z"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          fill="#FDE5E8"
          d="M422.083,689.44c-0.382-2.632-4.936-2.747-3.877-3.674
			c1.927-1.686,2.596-4.889,5.217-5.305c1.593-0.252,3.274,1.5,3.265,3.302C426.674,686.693,422.272,686.23,422.083,689.44z"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          fill="#FDC8C2"
          d="M519.664,637.769c2.093-0.792,4.185-1.586,6.275-2.377
			C524.224,637.174,522.558,639.094,519.664,637.769z"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          fill="#FDE6D9"
          d="M590.402,605.527c0.347-3.282-1.226-3.004-4.087-3.819
			c-5.545-1.584-11.375-4.303-14.737-9.624c-5.946-9.411-5.804-9.499-15.787-5.434c-2.154-0.596-3.465-1.334-1.977-4.008
			c1.577-2.836,2.385-6.073,4.114-8.871c0.092-0.148,1.102-0.89-0.021-0.909c-2.865-0.051-2.904-2.072-3.075-4.065
			c-0.057-0.634,0.222-1.722-0.344-1.808c-3.46-0.521-2.953-2.661-2.69-5.219c0.241-2.376,0.211-4.67,1.929-6.573
			c0.43-0.474,1.061-1.27,0.838-1.682c-0.458-0.856-1.262,0.092-1.954,0.045c-1.52-0.105-3.345,0.561-4.247-1.174
			c-0.895-1.716,0.132-2.75,1.587-3.834c1.172-0.873-0.245-2.1-0.584-3.121c-0.647-1.952-0.854-3.592,1.572-4.428
			c1.785-0.614,2.521-2.172,3.177-3.773c0.853-2.091,2.043-3.957,3.779-5.423c1.994-1.682,2.515-1.686,4.855,0.227
			c0.414,0.34,0.422-0.025,0.47-0.076c0.556-0.604,0.934-1.309,0.605-2.134c-1.116-2.799-1.711-5.142,2.726-5.192
			c1.404-0.016,0.685-1.441,0.305-2.162c-1.181-2.224-0.386-3.686,1.559-4.921c1.547-0.98,2.735-2.452,4.311-3.396
			c2.827-1.696,5.166-3.171,3.378-7.381c-1.06-2.491,2.209-3.752,4.352-6.078c-2.42,1.733-4.498,1.57-6.46,1.948
			c-2.341,0.452-4.75,1.126-4.202-2.771c0.024-0.179-0.076-0.283-0.473-0.124c-2.736,1.098-4.499-0.383-6.093-2.274
			c-0.774-0.918-1.456-1.919-2.902-1.424c-0.372,0.126-0.813,0.243-0.98,0.597c-0.236,0.503,0.328,0.549,0.581,0.792
			c3.516,3.359,3.376,4.987-0.597,7.747c-0.917,0.638-3.402,0.872-0.744,2.628c0.839,0.551,0.379,1.403-0.027,2.092
			c-0.775,1.312-7.358,3.146-8.64,2.377c-1.807-1.088-0.528-2.713-0.651-4.095c-1.323,0.649-2.387,2.277-4.19,1.074
			c-2.534-1.695-2.792-4.881-0.486-6.276c0.148-0.09,0.422,0.128,0.63-0.028c-0.256,0.016-0.526-0.051-0.775-0.006
			c-2.054,0.36-4.086,0.837-6.2,0.754c-2.085-0.08-3.481-1.17-4.143-3.046c-0.677-1.919-0.141-3.584,1.558-4.845
			c0.137-0.103,1.837,0.428,0.438-1.03c-2.307-2.405-1.404-6.101,1.81-7.26c4.515-1.631,7.983-4.276,10.702-8.172
			c1.283-1.842,3.114-2.838,5.409-1.414c4.229,0.934,7.343-2.041,11.08-2.829c-3.663,0.807-6.831,3.189-10.784,3.106
			c-1.441-1.566-1.295-3.275,0.248-4.432c2.102-1.572,3.752-3.966,6.72-4.182c-3.209,0.459-5.296,2.834-7.714,4.594
			c-1.789,1.301-3.414,2.862-5.382,3.888c-3.283,1.717-5.703,0.1-5.58-3.633c0.077-2.364,1.291-4.159,2.816-5.857
			c2.313-2.582,1.46-6.977-1.606-8.538c-0.815-0.415-1.591-0.931-2.442-1.238c-2.355-0.85-3.197-2.072-1.444-4.324
			c1.569-2.022,0.649-4.927,2.591-7.039c0.82-0.891,1.206-2.477,2.256-3.488c1.042-1.002,1.717-2.061,0.159-3.218
			c-2.69-2.274-4.986-4.841-6.104-8.261c-0.485-1.48-0.79-2.994,0.111-4.458c1.48-1.021,2.61,0.062,3.391,0.927
			c2.698,2.984,6.234,4.635,9.658,6.483c0.696,0.938,0.439,1.997,0.356,3.03c-0.085,1.062-0.225,2.377,0.64,2.962
			c4.264,2.898,7.161,7.6,12.562,9.037c4.926,1.31,9.071,4.469,13.642,6.635c2.405,1.142,3.648,4.533,3.791,7.665
			c0.191,4.279,1.7,8.028,4.324,11.409c1.618,2.079,2.301,4.682,3.479,7.01c0.95,1.882,1.942,3.326,4.275,3.442
			c2.778,0.141,2.862,1.653,2.351,4.062c-0.731,3.438-1.935,6.89,1.156,10.308c1.299,1.437,0.107,4.659-0.68,6.578
			c-0.696,1.697-0.198,1.771,0.821,2.301c0.577,0.302,1.096,0.812,1.725,0.919c3.36,0.553,4.714,1.593,2.129,4.977
			c-1.832,2.403,0.351,4.322,2.219,5.395c2.43,1.392,3.594,2.998,2.613,5.7c-0.525,1.45,0.132,1.778,1.358,2.067
			c1.288,0.302,2.2,1.377,3.339,2.021c1.875,1.056,2.343,2.335,1.337,4.526c-1.919,4.185-1.698,4.408,2.641,6.596
			c0.962,0.486,2.022,1.069,2.297,2.042c1.161,4.11,4.2,7.536,4.327,12.005c0.028,1.018-0.113,2.595,1.433,2.095
			c4.312-1.397,5.428,1.869,7.126,4.443c0.733,1.111,1.222,2.302,1.58,3.563c3.511,9.664,2.61,19.912,3.958,29.862
			c-0.321,5.435-3.216,8.826-8.626,8.478c-3.471-0.223-4.186,4.139-7.441,3.314c-7.891-1.997-15.406,2.352-23.309,1.336
			c-2.84-0.36-1.421-1.742-1.513-2.896c-0.79-0.756-1.611-0.585-2.462-0.11C592.23,606.891,591.229,606.577,590.402,605.527z"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          fill="#E7DD99"
          d="M603.68,676.175c1.012,3.681,2.631,7.673-3.875,7.435
			c1.912,5.378-3.395,3.853-5.126,5.702c-4.752,5.086-5.659,5.466-12.126,0.351C590.389,686.412,598.017,682.833,603.68,676.175z"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          fill="#DACED5"
          d="M639.143,601.602c1.532-0.131,2.428-2.709,4.25-1.265
			c0.488,0.388,0.587,1.755,0.34,2.496c-0.852,2.537-3.043,2.742-5.306,2.646C638.666,604.188,638.905,602.895,639.143,601.602z"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          fill="#FDE2E3"
          d="M590.402,605.527c1.647,1.548,4.081-2.339,5.499,0.794
			c-1.923,0.173-3.22,1.435-4.653,2.536c-0.953,0.733-2.323,2.037-3.366,0.904c-1.061-1.15,0.816-1.81,1.379-2.694
			C589.604,606.531,590.02,606.038,590.402,605.527z"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          fill="#CFCFA2"
          d="M322.274,637.743c1.761,1.041,4.822,1.116,3.372,4.543
			c2.865,1.152,4.244,4.633,7.672,4.908c-8.073,9.433,1.639,15.882,3.956,23.572c-1.207,0.115-1.491,0.691-1.149,1.861
			c0.371,1.273-1.429,1.841-1.513,1.73c-3.508-4.465-8.552,0.79-12.26-1.979c-1.758-2.174-3.614-4.3-3.266-7.413
			c0.49-4.221,1.847-8.203,3.191-12.19c-4.821-2.803,0.032-8.757-3.865-11.862C319.701,639.858,320.987,638.801,322.274,637.743z"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          fill="#A6AA6E"
          d="M344.462,680.923c-6.626-1.318-7.93,4.373-10.688,8.138
			c-0.131,0.179-0.174,0.541-0.088,0.742c0.611,1.425,2.527-0.651,2.792,0.926c0.081,0.466-1.641,1.055-2.103,1.846
			c-0.573,0.977-0.693,2.216-1.008,3.34c-1.059,0.271-2.12,0.544-3.18,0.815c-0.273-3.178-1.747-6.767,0.812-9.3
			c1.878-1.858,0.979-6.112,5.1-5.88c0.703,0.041,1.628-0.409,1.219-0.919c-2.828-3.493,1.614-4.416,2.222-6.656
			C341.18,676.29,342.821,678.607,344.462,680.923z"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          fill="#FCFBFB"
          d="M406.145,726.307c1.435-1.104,2.368-2.843,4.445-2.829
			c1.179,0.011,2.73,0.057,2.54,1.601c-0.255,2.052-2.062,2.381-3.856,2.303C408.173,727.333,406.931,727.657,406.145,726.307z"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          fill="#E1E4BC"
          d="M630.484,702.234c1.805,0.275,2.801-1.062,4.087-1.925
			c1.247-0.836,3.026-2.176,4.178-0.577c1.633,2.269,1.546,1.494,2.769-0.366c1.515-2.306,4.657-1.346,6.806-0.8
			c2.121,0.536,4.551,1.541,6.155,2.763c1.286,0.979,7.082,1.305,3,5.768c-0.319,0.35,0.641,2.112,1.271,3.036
			c1.006,1.479,2.784,2.858-0.435,3.785c1.199,1.996-0.992,2.974-1.241,4.263c-0.447,2.317-2.338,3.405-3.492,4.093
			c-2.575,1.538-2.415,3.63-2.627,5.857c-4.246-1.166-5.902-6.817-3.2-10.928c-0.346-5.029-5.162-3.76-7.812-5.544
			c-3.369-1.251-4.446-4.526-6.287-7.128C632.789,703.501,630.952,703.814,630.484,702.234z"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          fill="#D2CB80"
          d="M647.754,717.203c-0.961,4.235-0.427,8.036,3.2,10.928
			c1.131,1.368,1.481,3.007,1.619,4.717c-2.702-0.495-3.424-3.259-4.907-4.906c-1.738-1.928-2.202-5.128-2.872-7.862
			C644.253,717.867,645.696,717.068,647.754,717.203z"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          fill="#D0C77B"
          d="M633.654,704.531c3.164,1.434,3.841,5.062,6.287,7.128
			c-0.074,2.396,2.302,2.085,3.44,3.137c0.537,0.497,1.331,1.057,0.735,1.911c-0.188,0.268-1.093,0.398-1.355,0.191
			c-4.162-3.276-7.512-7.265-10.098-11.89C632.993,704.849,633.323,704.688,633.654,704.531z"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          fill="#E2D581"
          d="M617.931,688.847c2.089,2.364,4.18,4.732,6.268,7.098
			C621.616,694.016,616.218,694.568,617.931,688.847z"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          fill="#FCFAF9"
          d="M322.277,652.776c1.004,4.744-2.383,8.714-2.336,13.287
			c-0.149,4.921-0.976,9.694-3.031,14.204c-0.032,0.236-0.072,0.47-0.119,0.706c-3.141,3.958-4.442,8.714-5.959,13.404
			c-1.39,4.3-2,8.929-5.001,12.592c-2.722,4.391-2.113,9.703-4.702,14.503c-2.622,4.859-5.312,10.323-6.35,16.106
			c-0.98,0.659-0.708,2.835-2.663,2.274c-1.83-0.521-1.211-2.11-1.209-3.297c0.006-4.185,0.206-8.379-1.631-12.313
			c-1.323-1.98-0.349-3.842,0.504-5.548c4.781-9.557,9.271-19.254,14.245-28.725c2.505-4.77,4.376-10.004,6.157-15.177
			c0.256-0.745,0.499-1.685-0.079-2.249c-1.91-1.869-1.745-3.506-0.477-5.839c1.035-1.899,1.784-3.696,3.93-4.51
			c1.159-0.438,1.803-1.468,2.534-2.383C318.151,657.468,320.214,655.123,322.277,652.776z"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          fill="#FAF8F6"
          d="M328.506,725.907c2.165,5.534,0.593,10.485-2.248,15.31
			c-1.233,2.096-2.185,4.357-3.266,6.545c-1.918,1.9-3.836,3.801-5.755,5.7c-0.483-3.062,1.686-5.258,2.586-7.865
			C322.172,738.795,323.729,731.64,328.506,725.907z"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          fill="#E7E5CC"
          d="M305.83,706.97c2.851-8.465,4.683-17.274,8.75-25.323
			c0.592-1.169,0.925-2.227,2.21-0.673c-0.538,8.913-2.949,17.198-8.405,24.398C307.818,706.121,306.695,706.447,305.83,706.97z"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          fill="#E7E5CC"
          d="M316.91,680.268c0.307-4.885,1.499-9.581,3.031-14.204
			c-0.459,2.589,3.683,3.418,2.412,6.316c-0.043,0.239-0.077,0.479-0.097,0.718C321.776,676.462,318.717,677.896,316.91,680.268z"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          fill="#F2F0E3"
          d="M271.99,768.288c-0.875-2.92,1.84-5.088,1.65-7.864
			c1.105-1.36,2.019-1.951,2.564,0.431c0.333,1.461,0.891,2.87,1.348,4.303C276.384,767.415,274.096,767.691,271.99,768.288z"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          fill="#F1EEE1"
          d="M299.418,765.73c-0.422-0.26-0.921-0.499-0.899-0.565
			c0.711-2.109,1.646-4.089,3.721-5.202c0.143-0.073,0.921,0.693,0.905,0.718C301.963,762.35,300.729,763.984,299.418,765.73z"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          fill="#F9FAF4"
          d="M524.323,735.254c6.936,3.594,10.897,10.142,14.659,16.337
			c6.345,10.457,11.859,21.483,14.912,33.468c1.663,6.528-1.664,16.282-5.917,18.56c2.321-4.001,1.267-7.653-0.95-11.336
			c-4.571-7.591-6.2-16.688-12.141-23.606c-2.058-2.393-5.312-3.898-5.764-7.526C527.454,752.528,523.261,744.38,524.323,735.254z"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          fill="#F6F6D7"
          d="M488.203,764.453c0.367-1.108-0.812-3.455,0.888-3.489
			c1.426-0.032,3.392,1.565,4.255,2.971c2.979,4.851-0.917,9.447-1.047,14.233c-0.009,0.252-1.084,0.617-1.674,0.647
			c-0.253,0.008-0.724-0.692-0.78-1.112C489.267,773.346,488.754,768.98,488.203,764.453z"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          fill="#EBE8BA"
          d="M546.746,800.266c-0.316,1.226-1.213,0.976-1.927,0.992
			c-1.044,0.025-2.15,0.032-2.285-1.43c-0.064-0.704,0.197-1.532,0.932-1.297C544.634,798.91,545.678,799.677,546.746,800.266z"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          fill="#EBE8BA"
          d="M499.51,784.877c-0.617,0.498-1.409,1.409-2.41,1.846
			c-0.687,0.297-1.005-0.577-0.948-1.271c0.116-1.458,1.257-1.861,2.407-2.085C499.244,783.231,499.837,783.545,499.51,784.877z"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          fill="#EBE8BA"
          d="M481.469,788.065c-0.351-0.428-0.559-0.681-0.766-0.938
			c0.274-0.144,0.541-0.37,0.827-0.398c0.158-0.019,0.519,0.323,0.495,0.444C481.973,787.44,481.731,787.67,481.469,788.065z"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          fill="#D5D3AF"
          d="M233.463,658.204c-1.656-0.947-1.747,0.96-2.629,1.42
			c-0.645,0.336-1.369,0.52-2.059,0.771c-0.173-0.819-0.77-2.089-0.454-2.384c3.148-2.93,0.42-3.89-1.56-5.173
			c-0.058-0.038,0.235-0.615,0.366-0.945c5.205-2.136,9.645-5.234,12.692-10.087c0.221-2.363,3.109-1.548,3.88-3.257
			c3.069,0.709,4.362-4.44,7.84-2.376c-0.198,0.477-0.704,1.121-0.547,1.404c3.433,6.119-2.623,8.253-4.963,11.931
			c-0.211,0.292-0.453,0.336-0.731,0.097c-4.979-1.781-6.825-0.516-7.422,4.615C237.608,656.548,234.943,656.865,233.463,658.204z"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          fill="#F9F6F2"
          d="M268.975,604.819c0.469-0.047,0.938-0.099,1.406-0.149
			c2.476-0.038,5.982-2.78,6.451,2.456c-4.777,5.83-9.035,12.234-16.635,14.917c-0.873-3.398,1.924-4.174,3.936-5.516
			c1.945-2.265,3.947-4.488,5.81-6.819c0.76-0.949,2.228-2.027,1.326-3.337c-0.969-1.407-2.356,0.083-3.565,0.193
			c-0.638,0.061-1.264,0.246-1.894,0.374C265.867,604.74,268.252,606.023,268.975,604.819z"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          fill="#E2DFC5"
          d="M264.133,616.527c-0.914,2.125-3.718,2.899-3.936,5.516
			c-0.929-0.384-1.097-5.021-3.094-0.44c-0.37,0.847-1.802,3.96-4.402,2.428c-1.341-0.79-2-2.512-1.17-3.669
			c1.529-2.134,1.944-5.832,5.812-5.32C259.631,615.341,261.87,616.021,264.133,616.527z"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          fill="#FBF8F6"
          d="M219.331,640.114c-0.403,3.324-1.03,6.509-4.735,7.838
			c-1.582-0.188-4.177,0.659-4.281-1.438c-0.114-2.286,1.002-4.752,2.015-6.942c0.824-1.781,2.703-1.13,4.174-0.743
			C217.491,639.09,218.392,639.675,219.331,640.114z"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          fill="#F0ECDE"
          d="M216.156,660.123c-1.588,1.269-3.293,3.983-5.203,2.899
			c-1.973-1.12-0.165-2.179,1.409-2.413C213.624,660.424,214.89,660.283,216.156,660.123z"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          fill="#FCFAF9"
          d="M245.3,649.605c0.243-0.034,0.486-0.066,0.731-0.097
			c-0.013,2.662-2.041,4.275-3.411,6.137c-4.619,6.289-8.544,13.31-14.624,18.208c-3.098,2.494-4.13,7.181-8.674,7.934
			c-0.038-0.484-0.076-0.964-0.115-1.446c-0.197-1.008-0.755-2.107-0.52-3.005c0.711-2.698,0.191-3.869-2.777-2.903
			c-0.627,0.205-1.335,0.353-1.55-0.467c-0.037-0.141,0.893-0.704,1.423-0.8c9.618-1.748,15.402-9.082,21.988-15.177
			C240.519,655.449,242.805,652.414,245.3,649.605z"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          fill="#E8E7CB"
          d="M316.088,659.813c-0.368,1.978-2.479,2.752-3.18,4.232
			c-1.06,2.242-6.597,3.774-1.504,7.552c1.723,1.28-0.014,3.465-0.723,4.997c-6.29,13.586-12.677,27.128-19.064,40.666
			c-1.058,2.241-2.193,4.441-2.343,6.982c-2.708-3.562-4.332-1.066-5.934,1.111c-0.684,0.923-0.436,3.002-2.312,2.376
			c-1.687-0.561-0.846-2.038-0.62-3.306c0.141-0.788-0.346-1.692-0.55-2.546c-1.025-7.526,3.2-13.049,7.124-18.688
			c0.216-0.312,0.452-0.991,0.52-0.972c7.564,2.245,4.251-5.472,6.99-7.615c0.102-0.076,0.197-0.169,0.294-0.251
			c6.979-0.053,9.328-4.803,10.675-10.548c0.143-0.609,0.278-1.771,0.13-1.82c-4.453-1.498-2.531-3.237-0.544-5.54
			c1.774-2.059,0.011-5.497,2.329-7.627c0.543-0.499,1.361-1.468,1.195-1.858c-1.987-4.661,1.597-4.77,4.395-5.643
			C314.056,660.976,315.049,660.325,316.088,659.813z"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          fill="#FCFCF3"
          d="M180.851,687.254c-0.048,0.256-0.085,0.518-0.146,0.771
			c-0.497,2.063-3.333,2.516-3.375,4.896c2.129-0.032,3.461-1.523,5.006-2.659c0.281-0.207,0.883,0.022,1.335,0.052
			c-0.104,0.604-0.118,1.241-0.324,1.81c-1.678,4.648-6.01,5.284-9.944,6.35c-2.583,0.701-5.285,0.964-7.935,1.422
			c0.732-2.838,3.57-3.587,5.371-5.362c1.108-1.091,2.413-1.982,3.618-2.978c2.591-2.144,5.05-4.361,4.029-8.267
			C180.735,683.739,182.618,684.411,180.851,687.254z"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          fill="#FDEEAF"
          d="M652.572,175.496c-13.986,5.339-28.862,3.925-43.314,5.093
			c-11.265,0.914-22.911,0.032-33.81-4.3c1.781-0.103,3.392-0.339,0.941-2.182c-0.679-0.512-2.051-0.544-1.052-1.798
			c0.812-1.014,1.788-0.851,2.563,0.253c0.834,1.183,2.338,2.969,3.237,2.201c1.576-1.344-0.636-2.364-1.478-3.263
			c-1.317-1.407,0.866-1.983,0.506-3.071l0.021-0.02c1.894-1.256,3.493,0.762,5.303,0.471c3.846,0.656,7.753,0.845,11.574,1.619
			c3.136,0.633,6.102,1.052,8.812-1.44c1.365-1.254,3.26-0.749,4.848-0.061c8.754,3.784,17.494,3.4,26.264,0.073
			c1.722-0.652,3.527-1.065,5.369-0.592c5.409,1.398,10.573,0.566,15.634-1.544c0.794-0.33,1.675-0.883,2.524-0.032
			C657.869,169.767,655.221,172.633,652.572,175.496z"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          fill="#FAFBF7"
          d="M660.517,166.904c-10.941,4.92-23.207,2.021-34.457,5.975
			c-5.118,1.798-10.775-0.912-15.923-2.882c-1.946-0.742-3.439-1.401-4.889,1.13c-1.447,2.53-4.253,1.183-6.408,0.943
			c-4.648-0.515-9.26-1.381-13.885-2.104c0.79-2.681,3.146-2.054,4.829-1.894c9.491,0.898,17.477-3.551,25.774-6.933
			c0.482-0.198,0.991-0.422,1.358-0.781c9.062-8.857,19.72-4.859,29.977-3.608c2.067,0.253,4.047,0.937,6.17,0.438
			c1.399-0.33,2.829-0.666,4.192,0.179c0.847,0.573,2.368,1.103,2.405,1.724c0.077,1.324-1.581,1.359-2.534,1.871
			c-2.293,1.232-4.702,2.255-6.933,3.583c-1.122,0.665-3.148,1.378-2.426,2.997c0.762,1.708,2.768,0.941,4.261,0.662
			c4.798-0.902,8.91-3.561,13.38-5.313C663.781,164.229,662.147,165.566,660.517,166.904z"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          fill="#FDF4BF"
          d="M579.398,251.749c-0.259-3.932-0.517-7.866-0.777-11.801
			c1.43-1.248,3.905-0.918,4.713-3.125c0.062-0.164,0.301-0.176,0.324-0.195c0.981-0.768,3.899,2.809,3.47-1.801
			c-0.15-1.603,2.69-3.186,3.401-5.451c0.771-2.463,1.764-4.942,3.447-6.996c0.843-1.027,0.764-2.115,0.704-3.32
			c-0.125-2.541,0.659-4.856,2.141-6.936c0.383-0.15,0.772-0.211,1.182-0.192c3.346,0.211,6.694,0.557,9.987-0.441
			c3.271-0.292,6.572-2.255,9.787,0.173c-8.038,8.263-10.775,19.472-16.233,29.171c-3.892,2.063-7.385,4.753-11.106,7.069
			C587.106,249.98,583.742,252.396,579.398,251.749z"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          fill="#FDFDFD"
          d="M579.398,251.749c6.637-1.789,12.104-5.71,17.677-9.498
			c1.216-0.828,2.534-2.405,4.469-1.417c-1.223,3.931-4.053,7.277-4.235,11.59c0.396,1.301,0.169,2.559-0.219,3.819
			c-1.642,5.336-2.87,10.774-4.068,16.218c-0.308,1.383-0.539,2.831-1.896,3.689c-3.308,3.173-7.374,0.918-11.046,1.506
			c-1.51,0.24-3.09,0.032-4.638,0.029C575.834,268.899,579.445,260.604,579.398,251.749z"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          fill="#E1DFB8"
          d="M628.12,248.566c1.759,0.618,3.301,2.143,4.783-0.998
			c0.729-1.545,2.778,1.756,4.657,1.048c-0.132,1.328,0.403,2.224,1.633,2.803c0.602,0.284,1.65,1.471,0.265,1.298
			c-4.282-0.538-2.956,3.129-3.124,4.523c-0.343,2.831,1.876,4.043,4.715,3.996c0.676-0.01,1.359,0.553,2.039,0.851
			c0.144,0.538,0.104,1.203-0.34,1.414c-3.898,1.849-5.611,6.344-9.805,7.915c-2.063,0.774-3.28,2.872-4.906,4.348
			c-0.893,0.809-1.405,2.296-3.056,1.932c-1.062,0.937-0.82,3.301-3.487,2.482c-3.632-1.116,0.527-1.97-0.06-3.208
			c-1.231-2.604-1.661-5.575-3.493-7.939c-2.479-1.529-1.153-3.135-0.092-4.731c1.958-3.112,1.575-7.847,7.027-8.637
			c1.23-0.179,2.687-4.261,1.675-7.066C627.064,248.122,627.588,248.118,628.12,248.566z"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          fill="#FAE28A"
          d="M597.465,212.472h-0.375l-0.378-0.002
			c-3.376,1.896-6.769,3.672-8.604,7.466c-0.646,1.334-2.438,1.503-3.893,0.905c-1.645-0.674-1.978-2.364-1.483-3.649
			c1.49-3.893,2.153-8.045,3.723-11.861c0.834-2.029,1.214-3.574,0.042-5.455c-0.687-1.104-2.282-2.665-0.025-3.436
			c1.783-0.608,4.004-0.419,5.107,1.717c2.691,5.205,5.215,6.631,11.132,6.197c2.614-0.192,5.223-0.134,7.817,0.336
			c1.595,0.288,3.012,0.63,3.139-1.967c0.104-2.195,1.836-3.657,3.73-4.744c2.731-2.249,6.264-0.774,9.213-1.993
			c1.637,0.224,4.296-2.969,4.687,1.404c-4.506,4.757-9.011,9.517-13.517,14.274c-3.359,0.269-6.716,0.541-10.072,0.809
			C604.293,213.045,600.878,213.045,597.465,212.472z"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          fill="#B8CAA1"
          d="M617.85,264.298c0.393,1.571-1.249,3.18,0.09,4.731
			c-0.117,4.495,0.262,9.085-0.575,13.442c-0.395,2.05-1.207,4.776-3.421,6.266c-6.587-3.327-12.104-8.694-19.612-10.259
			c1.726-1.324,2.89-3.58,5.556-3.336c0.948,0.864,1.579,1.174,2.067-0.521c1.265-4.389,4.5-7.828,6.029-12.111
			c0.179-0.499,0.686-0.746,1.274-0.547C610.617,268.275,614.812,264.158,617.85,264.298z"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          fill="#88B482"
          d="M599.71,275.232c-2.098,0.576-2.373,4.175-5.379,3.247h0.002
			c-1.196-0.55-3.015-0.313-3.007-2.383l-0.201,0.055c1.593-8.033,3.069-16.091,6.184-23.724c1.48,0.732,2.198,3.052,4.814,1.247
			c1.555-1.071,2.88,0.986,3.242,2.832c0.51,1.049,0.177,2.073-0.225,3.017C602.966,264.634,601.875,270.121,599.71,275.232z"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          fill="#B9C393"
          d="M628.12,248.566c-0.524,0.01-1.046,0.019-1.568,0.029
			c-0.674-0.861-1.32-0.5-1.988,0.021c-0.623,0.487-1.445,1.095-2.049,0.349c-0.756-0.943,0.651-1.135,0.926-1.746
			c0.848-1.898,2.062-4.038-1.152-4.837c-1.475-0.368-2.887-1.071-2.285-2.399c0.737-1.622,2.451-0.841,3.69-0.083
			c1.045,0.64,2.306,1.433,3.12,0.285c0.732-1.033-0.714-2.351-1.369-2.371c-3.482-0.096-5.367-3.161-8.313-4.146v0.003
			c2.747-5.746,7.873-1.731,12.449-3.471c-1.774,1.711-4.77,1.001-4.202,3.266c0.437,1.734,2.438-0.202,3.784,0.16
			c1.455,0.391,3.158-0.486,4.48,0.806c-0.526,1.606-0.072,4.043-3.042,3.599c1.439,0.355,2.935,0.566,4.299,1.11
			c1.155,0.458,1.384,1.583,0.674,2.601c-0.487,0.694-1.432,0.819-2.051,0.221c-0.602-0.582-0.318-2.508-1.937-1.337
			c-0.825,0.595-1.306,1.667-1.941,2.524c1.059,0.371,2.122,0.742,3.229,1.129C630.96,245.271,629.317,246.679,628.12,248.566z"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          fill="#DDDEB5"
          d="M599.71,275.232c0.058-6.794,3.079-12.693,5.655-18.727
			c2.134-0.985,2.626-5.585,5.174-4.05c2.861,1.724-0.421,4.779-0.668,7.293c-0.073,0.752-0.401,1.475-0.613,2.214
			c-1.282,5.333-6.031,9.162-6.483,14.888c-0.044,0.528-1.052,1.417-1.573,1.391C599.154,278.144,600.097,276.314,599.71,275.232z"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          fill="#B9C393"
          d="M617.131,233.669c-0.176,1.347-0.889,1.849-2.107,1.167
			c-0.356-0.198-0.783-1.042-0.663-1.199c0.879-1.151,1.823,0.272,2.771,0.036V233.669z"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          fill="#F9FBF5"
          d="M624.981,277.696c3.16-2.962,5.79-6.254,9.791-8.622
			c2.782-1.647,5.562-4.593,8.315-6.986c3.25-2.572,6.456-5.198,9.772-7.681c1.095-0.822,2.557-1.875,3.797-0.275
			c1.23,1.583-0.124,2.713-1.179,3.631c-9.832,8.567-19.699,17.098-29.567,25.623c-0.37,0.317-0.871,0.477-1.313,0.714
			c-0.003-0.585-0.044-1.17-0.003-1.753C624.704,280.796,624.851,279.248,624.981,277.696z"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          fill="#FDABB6"
          d="M551.89,435.762c-5.673-0.299-8.528-5.063-12.574-7.943
			c-0.931-0.783-0.926-1.849-0.769-2.892c1.661-11.103-2.104-20.329-9.678-28.272c-1.064-1.117-2.352-2.153-2.377-3.916
			c4.62-0.035,8.153-2.882,12.161-4.405c4.023-1.532,7.225-3.861,10.692-6.014c1.162-1.196,1.15-3.097,2.514-4.168
			c2.18,0.418,4.156,1.411,6.196,2.22c-1.819,7.684,4.19,11.695,7.912,16.766c0.505,0.688,1.341,0.77,2.099,0.189
			c0.423-0.324,1.051-0.793,1.395-0.57c3.455,2.247,6.127-0.115,8.898-1.509c1.572-0.791,2.456-0.125,3.326,0.979
			c2.213,2.812,4.682,3.062,7.419,0.765c1.229-1.031,2.539-1.258,3.981-0.442c1.857,1.046,3.812,0.708,5.741,0.311
			c0.722-0.15,1.871-0.211,1.767-1.184c-0.393-3.682,2.093-4.056,4.699-4.216c2.201-0.131,2.989-0.912,1.574-2.87
			c-1.111-1.539-0.861-2.707,0.318-4.171c2.215-2.751,1.998-7.174-0.405-10.01c-1.413-1.666-2.445-3.436-2.423-5.626
			c0.039-3.657-1.956-5.467-5.293-6.174c-0.27-1.574-0.538-3.151-0.808-4.726c2.302-4.519,3.891-5.093,8.111-2.904
			c0.475,0.246,0.949,0.438,1.486,0.393c7.96-0.684,15.87-0.463,23.708,1.242c0.769,0.166,1.694,0.21,2.304-0.23
			c3.18-2.304,6.578-0.649,9.87-0.618c1.335,0.01,2.785,0.573,2.727,2.211c-0.063,1.82-1.644,2.571-3.084,2.127
			c-3.099-0.959-4.241,0.333-4.888,3.136c-0.569,2.459-2.206,2.309-3.787,0.879c-4.015-3.637-3.987-3.665-9.24-2.405
			c4.012-0.371,5.333-0.064,7.858,2.278c0.996,0.925,2.068,1.494,3.324,1.942c2.035,0.726,2.818,2.36,2.262,4.378
			c-0.538,1.942-2.105,1.517-3.556,1.162c-0.729-0.179-1.865-0.592-2.029,0.355c-0.209,1.206,1.009,1.391,1.917,1.226
			c2.101-0.391,4.167-0.17,6.243,0.093c1.055,0.13,2.193,0.271,2.381,1.58c0.15,1.042-0.63,1.958-1.458,2.083
			c-3.748,0.57-3.695,4.891-6.483,6.347c-0.296,0.154-0.735,0.512-0.163,0.755c4.407,1.893,2.614,6.708,4.598,9.824
			c1.35,2.115,1.683,4.891,3.271,7.041c0.725,0.982,1.154,2.121-0.183,3.064c-1.399,0.449-2.853,0.144-4.28,0.286
			c-2.75,0.262-5.445-0.474-8.174-0.65c-0.038,2.61-1.18,5.065-2.427,7.045c-1.649,2.616-4.782,2.168-7.318,1.656
			c0.762,0.906,2.033,1.325,3.57,0.915c0.504-0.131,1.001-0.553,1.482-0.445c1.962,0.441,4.823,0.34,5.072,2.668
			c0.224,2.121-2.527,1.597-4.033,2.063c-1.116,0.346-2.315-0.009-2.298,2.114c0.016,1.561-2.346,1.526-3.181,0.746
			c-2.916-2.729-6.794-3.068-10.169-4.615c-1.315-0.602-2.323-1.667-2.653-2.033c-1.089-0.988-1.252-0.185-0.979-0.632
			c0.051-0.084,0.2-0.058,0.358,0.145c1.074,1.376,3.799,2.229,1.818,4.663c-1.911,2.352-4.947,2.306-6.934-0.208
			c-1.521-1.922-3.81-2.712-5.462-4.372c-0.941-1.82-2.721-2.787-4.175-4.08c-0.83-0.742-1.731-1.314-2.769-0.416
			c-1.053,0.917-2.158,0.682-3.325,0.366c-1.798-0.49-3.418-0.979-4.641,1.343c-1.062,2.017-1.441,3.487,0.557,5.083
			c1.179,0.942,1.757,2.388,1.044,3.882c-0.826,1.732-2.463,1.076-3.837,0.984c-3.243-0.218-6.443-1.213-9.744-0.489
			c-0.495,0.108,0.017,0.239-0.371-0.02c-3.345-2.232-7.175-3.835-10.157-6.405c-1.308-1.128-0.646,0.141-0.716,0.237
			c-1.34,1.842-2.811,1.325-2.928-0.481c-0.198-3.023-2.391-5.407-2.235-8.438c0.052-1.02-0.099-1.664-1.43-1.777
			c-2.32-0.206-4.097-2.137-6.604-2.15c-1.557-0.009-2.052-1.363-1.896-2.873c0.13-1.266-0.034-2.492-1.63-2.588
			c-1.205-0.073-2.574-1.385-3.61,0.083c-0.847,1.203-0.356,2.553,0.339,3.704c3.084,5.09,6.026,10.061,10.855,14.069
			c4.637,3.852,7.152,9.77,9.055,15.666c-0.035,1.19-0.201,2.357-0.25,3.531c-0.116,2.764-1.286,4.477-4.292,4.43
			C553.872,436.904,552.8,436.568,551.89,435.762z"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          fill="#FD7793"
          d="M513.358,286.336c3.665,3.413,7.538,6.634,10.937,10.294
			c4.555,4.901,4.898,1.078,5.607-2.402c0.952-0.246,2.728,1.129,2.734-0.042c0.032-5.535,4.084-3.279,6.203-2.962
			c4.229,0.634,5.078-3.698,8.203-4.696c2.806-0.898,3.146,1.043,4.062,2.342c1.301,1.846-0.51,1.721-1.536,2.441
			c-2.479,1.74-2.446,4.446-1.824,7.255c0.589-3.627,3.254-5.375,5.964-7.137c0.647-0.419,1.209-0.903,1.209,0.201
			c0,0.947,1,2.47-0.966,2.802c-1.396,0.237-3.349,0.32-2.43,2.387c0.711,1.603-0.394,5.23,3.773,4.059
			c1.096-0.307,3.379,0.496,3.751,1.373c0.902,2.121-1.794,0.928-2.446,1.932c-1.85,2.844,0.617,4.091,2.283,4.639
			c1.822,0.601,3.034,0.768,2.483,3.068c-0.214,0.902,0.156,2.066,1.49,1.509c2.746-1.148,4.035,0.829,4.942,2.556
			c1.98,3.768,5.324,7.166,2.999,12.262c-0.789,1.727-5.09,0.742-2.961,2.937c2.056,2.12,3.523-1.062,4.556-2.931
			c0.417-0.755,1.062-0.953,1.518-0.339c0.71,0.956,2.444,2.124,1.406,3.195c-2.502,2.578-0.057,3.884,0.967,5.614
			c-0.055,0.224-0.092,0.451-0.108,0.678c-2.618,5.246-5.021,6.66-10.64,5.17c-7.134-1.894-14.216-4.089-20.404-8.289
			c2.068,1.532,3.278,3.42,2.263,6.136c-2.283,1.146-3.342-0.675-4.518-1.967c-1.25-1.369-2.537-2.255-4.461-1.513
			c-2.47,0.956-4.061-0.074-4.993-2.346c-1.118-2.715-3.041-4.142-6.035-4.155c-1.335-0.006-2.932-0.269-2.977-1.894
			c-0.052-1.868,1.527-2.917,3.165-2.767c2.742,0.256,2.445-1.116,1.978-2.851c-0.96-3.544-3.644-5.979-5.749-8.761
			c-0.966-1.277-2.511-0.4-3.596,0.163c-1.591,0.832-1.74,2.786-0.845,3.743c2.423,2.584,0.572,4.936,0.026,7.303
			c-0.549,2.396-2.878,3.436-5.219,2.546c-2.702-1.023-2.539-2.975-1.501-5.132c0.398-0.825,0.751-1.733,1.546-2.235
			c2.008-1.273-0.099-2.223-0.401-3.078c-0.414-1.167-1.703-0.969-2.575-0.07c-0.869,0.896-1.117,2.911-3.123,1.619
			c-0.835-3.366-2.018-6.622-2.924-9.961c-0.345-2.15-1.146-4.245-0.666-6.475c2.107-3.922,0.98-8.478,2.527-12.543
			C508.53,286.131,509.058,285.684,513.358,286.336z"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          fill="#FDB5C1"
          d="M643.074,403.485c0.034-3.685-3.345-5.982-3.78-9.636
			c-0.275-2.293-2.215-4.331-1.81-6.983c0.079-0.515-0.448-1.599-0.772-1.631c-6.596-0.646-3.371-1.219-0.574-3.842
			c2.171-2.041,3.229-5.201,6.756-5.896c0.729-0.141,0.404-1.539-0.151-1.308c-2.343,0.969-4.669-1.664-6.822,0.026
			c-2.366,1.858-3.153-0.73-4.561-1.472c-0.977-0.519-0.536-1.334,0.047-1.942c1.247-1.306,2.737-1.539,4.252-0.605
			c0.886,0.541,1.749,1.673,2.463,0.119c0.605-1.315,0.01-3.071-1.176-3.388c-4.87-1.305-8.611-6.392-14.491-4.386
			c-0.406,0.141-1.053-0.425-1.589-0.662c0.358-0.272,0.684-0.711,1.079-0.791c2.923-0.583,5.845-1.322,8.801-1.545
			c1.836-0.138,4.568-0.627,3.976,3.003c-0.177,1.069,0.886,1.622,1.798,1.728c1.644,0.186,0.893-1.228,1.082-1.948
			c0.737-2.786,0.908-6.302,5.278-3.155c0.511,0.366,1.707-0.099,2.522-0.367c0.217-0.074,0.434-1.034,0.247-1.283
			c-0.564-0.755-1.378-0.999-2.411-0.883c-10.491,1.165-20.92-0.013-31.365-0.838c-1.334-0.105-2.733,0.624-4.104,0.966
			c-0.58,0.144-1.637,0.486-1.676,0.393c-3.393-7.888-5.248,0.48-7.836,0.723c-0.544-2.744,1.255-5.009,5.09-5.364
			c7.747-0.717,15.578-2.892,23.227,1.152c0.833,0.441,3.105-1.836,4.71-2.85c3.109-0.861,6.816,1.83,9.494-1.6
			c2.169,2.956,5.28,3.429,8.63,3.266c3.147,4.028,4.698,8.483,4.756,13.699c0.015,1.211,3.828,3.144,4.808,2.268
			c5.553-4.965,7.919,1.267,11.571,2.834c1.054,2.354,2.104,4.709,3.159,7.061c0.034,2.639-0.153,5.3,0.837,7.834
			c-1.273,1.487-3.285,0.25-4.817,1.176c1.666,1.095,4.894-0.53,4.897,1.213c0.003,2.675-3.435,1.936-5.102,2.061
			c-5.981,0.454-12.016,0.214-18.031,0.274c-0.724,0.007-1.444,0.259-2.167,0.4c0.377,0.627,0.64,1.375,1.15,1.868
			c3.215,3.081,4.401,7.406,6.781,11.002c1.128,1.705,1.112,3.729-0.037,5.587c-4.107,1.923-8.106,2.886-11.561-1.246
			C644.654,406.913,641.842,406.233,643.074,403.485z"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          fill="#FDA592"
          d="M599.064,362.609c0.545-0.653,1.09-1.305,1.634-1.958
			c0.707,2.489,4.439,2.658,4.524,5.3c0.117,3.686,1.158,6.337,3.688,9.335c1.339,1.586,3.271,7.057-0.934,9.91
			c-1.513,1.027-1.983,1.699-0.089,2.658c1.566,0.793,1.396,2.783,1.338,3.695c-0.093,1.459-2.121,0.867-3.351,0.905
			c-4.214,0.134-4.264,0.118-2.935,3.698c0.453,1.219-0.401,0.975-0.937,1.026c-3.354,0.314-6.712,3.049-10.073-0.214
			c-0.972-0.938-1.453,0.515-2.098,1.046c-3.433,2.819-6.201,2.419-9.158-1.113c-1.924-2.3-3.838,0.825-5.773,1.34
			c-1.661,0.444-3.217,3.599-4.473-0.394c-0.07-0.227-1.176-0.428-1.246-0.313c-2.692,4.473-3.485,0.323-5.188-0.959
			c-4.063-3.055-5.186-8.052-8.198-11.807c-1.856-2.316,1.442-2.69,2.413-3.947c0.997-1.654,2.766-1.923,4.371-2.035
			c5.102-0.362,9.466-2.063,12.674-6.209c1.636-0.582,2.703,0.17,3.521,1.494c-0.052,1.785,0.273,3.116,2.363,3.829
			c2.886,0.986,5.297,2.854,6.982,5.477c0.818,1.273,2.064,1.88,3.341,2.492c-1.303-1.849-5.922-2.747-1.733-6.206
			c0.402-0.33-0.515-1.097-0.589-1.747c-0.155-1.375,0.271-2.636,1.787-2.335c3.213,0.64,3.771-2.939,6.136-3.525
			c0.311-0.076,0.472-0.227,0.49-0.422c-0.19-0.013-0.459,0.193-0.677,0.135c-3.591-0.945-3.598-0.963-1.415-3.903
			c0.325-0.436,0.642-0.807,0.385-1.375C597.235,365.456,598.477,364.304,599.064,362.609z"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          fill="#FDAEB0"
          d="M547.211,340.545c-0.436-2.712-0.755-5.432-4.013-6.503
			c-0.573-0.188-1.318-1.842-1.087-2.149c0.897-1.194,1.471,0.185,2.1,0.627c7.241,5.095,15.585,7.45,23.997,9.564
			c4.017,1.008,5.287-3.167,7.969-4.709c0.574,2.493,2.571,4.092,3.838,6.149c0.784,1.279,1.695,2.738,0.13,4.171
			c-2.176,1.555-3.946,3.253-3.247,6.353c0.194,0.863-0.371,1.769-1.587,1.715c-0.637-0.202-1.258-0.523-1.586-1.073
			c-3.603-6.046-8.79-6.135-14.61-3.966c-0.487,0.179-1.029,0.109-1.546,0.019c-2.073-0.674-2.974-2.687-4.519-3.966
			c-1.886-1.887-3.265-4.267-5.567-5.761C547.389,340.859,547.301,340.702,547.211,340.545z"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          fill="#FDA372"
          d="M595.846,366.486c1.952,0.095,2.8,0.453,0.528,1.829
			c-0.849,0.515-2.231,1.312-1.484,2.345c0.883,1.219,2.039,0.441,2.848-0.636c0.566-0.749,1.487-1.594,2.255-0.714
			c1.016,1.171-0.439,1.884-1.125,2.438c-2.025,1.638-4.295,2.988-5.591,5.393c-0.299,0.557-0.788,1.146-1.559,0.685
			c-0.631-0.377,0.077-2.84-1.641-1.333c-1.181,1.032,0.352,2.015,1.004,2.929c0.505,0.704,1.42,1.958,1.23,2.179
			c-1.129,1.324-1.652-0.058-2.353-0.739c-0.175-0.169-0.613-0.064-0.932-0.085c-0.013,0.509-0.15,1.049-0.02,1.516
			c0.406,1.462,1.955,1.602,2.94,2.405c1.195,0.972,2.29,2.723,1.278,3.672c-1.623,1.526-1.052-1.263-2.033-1.631
			c-5.011-1.891-6.859-8.291-13.165-8.804c-2.236-0.182-0.334-2.386,0.569-3.528c2.808-1.616,4.821-4.13,7.136-6.315
			c2.216-1.391,4.68-0.073,6.969-0.649C593.75,367.123,594.798,366.806,595.846,366.486z"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          fill="#FDBD7C"
          d="M592.702,367.442c-2.341,0.483-4.679,0.966-7.019,1.45
			c0.622-3.161-1.494-6.405,0.606-9.633c0.982-1.506-0.385-3.55-0.303-5.387c0.244-1.577,0.901-2.997,1.625-4.398
			c3.463-1.6,4.346-1.207,6.703,2.991c1.125,5.227,3.021,10.624-3.073,14.3C591.729,366.991,592.216,367.215,592.702,367.442z"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          fill="#FCECE9"
          d="M575.561,355.609c2.47-1.753-1.945-3.884,0.344-5.733
			c1.289-1.04,2.304-2.459,4.242-2.181c0.007,0.508,0.015,1.017,0.024,1.522c0.792,1.181,2.234,1.21,3.327,1.861
			c1.346,0.8,2.274,1.725,1.743,3.443c-1.248,2.527-0.286,5.879-2.952,7.875c-3.015,1.325-5.192-1.483-7.923-1.596
			c-0.575-0.34-0.962-0.826-1.19-1.449C572.875,357.407,573.025,355.747,575.561,355.609z"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          fill="#FCF0ED"
          d="M594.315,352.465c-2.05-0.947-2.973-4.907-6.327-2.367
			c-3.051,1.839-3.433,1.76-3.919-0.816c3.177-3.585,8.653-3.803,11.506-8.189c0.924-1.42,3.396,0.397,4.459,1.862
			c1.381,1.906-0.175,2.866-1.695,3.432C595.467,347.458,593.699,349.141,594.315,352.465z"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          fill="#EF7A83"
          d="M584.069,349.281c0.726,3.068,2.767-0.192,3.921,0.816
			c0.406,1.628-0.851,2.598-1.509,3.803c-0.528,0.465-1.064,0.465-1.611,0.032c0.146-3.278-5.334-0.949-4.699-4.715
			C581.473,349.106,582.787,348.227,584.069,349.281z"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          fill="#B8CAA1"
          d="M591.326,276.096c1.004,0.793,2.007,1.59,3.007,2.383
			C592.668,278.521,590.84,278.767,591.326,276.096z"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          fill="#86B483"
          d="M588.082,440.404c4.312,0.533,7.837,4.009,12.473,3.333
			c2.008,0.358,4.571-0.651,5.558,2.242c3.254,1.923,6.866,0.323,10.313,0.995c2.271,0.444,3.967,3.056,6.654,1.784
			c0.076-0.034,0.762,0.736,0.773,1.144c0.027,0.931-0.725,1.352-1.544,1.451c-1.633,0.198-3.044,0.703-3.929,2.214
			c-0.688,1.174-2.552,1.944-1.741,3.625c1.159,2.401,2.375-0.513,3.495-0.135c0.688,0.229,4.552-1.709,1.564,1.91
			c-0.952,1.148,0.61,1.961,1.554,1.795c1.99-0.346,4.713,2.177,5.871-0.905c0.727-1.927-1.514-1.363-2.364-1.271
			c-2.198,0.241-2.47-1.458-3.264-2.719c-0.407-0.648-0.532-1.321,0.186-1.631c0.473-0.207,1.237-0.084,1.725,0.173
			c0.746,0.391,0.017,2.31,1.314,1.897c1.124-0.356,0.753-1.862,0.378-2.764c-0.646-1.562,0.262-2.347,1.354-2.733
			c1.632-0.576,2.834,0.74,4.036,1.543c1.329,0.89,0.476,1.76-0.363,2.493c-0.54,0.478-1.562,0.82-0.725,1.792
			c3.913,4.566-1.761,4.97-3.187,7.161c-0.477,0.734-2.109-0.296-2.685,0.947c-0.056,0.116,0.457,0.729,0.648,0.7
			c1.337-0.189,2.918,1.323,3.963-0.26c0.745-1.129,1.014-2.561,2.791-1.383c1.38,0.912,1.736,2.374,1.607,3.792
			c-0.199,2.193-2.128,1.187-3.077,1.052c-2.562-0.362-6.151,1.989-7.397-0.26c-1.846-3.336-3.353-5.367-7.421-4.354
			c-0.684,0.169-1.543-1.074-1.334-2.072c0.845-4.06,0.535-4.325-4.373-3.496c-0.509,0.086-1.042,0.021-1.566,0.024
			c-2.16-0.988-4.377-1.915-4.836-4.666c0.282-0.429,0.538-0.877,0.854-1.28c0.772-0.994,0.926-2.076,0.54-3.25
			c-0.63,0.432-1.212,1.116-1.901,1.251c-3.22,0.639-6.471,1.126-9.711,1.665c-3.338,0.617-7.662-2.574-8.191-6.187
			c-0.134-0.902,0.364-1.88-1.246-1.451c-1.518,0.405-3.167,0.33-3.199-1.657c-0.032-1.971,1.452-3.055,3.376-3.036
			C586.042,439.891,587.15,439.812,588.082,440.404z"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          fill="#A4C399"
          d="M588.082,440.404c-1.286,0.134-2.616,0.111-3.846,0.45
			c-1.152,0.317-2.368,1.197-1.831,2.524c0.626,1.549,1.664,0.403,2.521-0.086c1.082-0.623,2.415-0.928,3.286-0.062
			c1.264,1.257-0.272,1.586-1.039,2.182c-0.854,0.659-0.375,1.73,0.128,2.031c2.433,1.459,3.771,4.54,7.016,4.766
			c3.977-0.936,4.647,2.55,6.311,4.711c3.057,3.835,7.043,6.697,10.566,10.047c0.975,0.925,2.512,1.526,1.999,3.347
			c-1.653-0.32-2.705,0.877-3.953,1.574c-1.316-1.021-2.634-2.043-3.948-3.061c-4.06,1.06-6.154-2.449-9.128-3.911
			c-4.342-3.064-6.502-8.046-10.345-11.632c-5.34-4.983-11.118-9.318-17.091-13.447c-0.296-2.892,0.919-3.922,3.636-2.986
			c1.873,0.65,3.265,2.08,4.832,3.224c0.306,0.224,0.754,0.467,1.045,0.207c0.542-0.479,0.134-1.001-0.258-1.295
			c-2.251-1.69-4.858-2.393-7.622-2.467c-1.668-0.044-3.165-0.159-3.474-2.247c0.742-4.852,1.357-5.3,6.932-5.075
			c2.515,0.45,4.069,2.355,5.562,4.103c1.712,2.001,3.57,3.814,5.52,5.561C585.96,439.376,587.021,439.891,588.082,440.404z"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          fill="#81AF7D"
          d="M616.309,475.048c-0.12-1.647,4.012-2.023,1.314-4.568
			c-0.431-0.408,0.755-1.106,1.264-0.707c6.358,4.962,12.347-0.591,18.518-0.664c1.648-0.021,2.807,0.474,2.922,1.775
			c0.166,1.829-1.558,2.072-2.786,1.593c-1.896-0.74-2.94,0.16-4.012,1.381c1.781,0.358,2.8,3.126,4.623,2.289
			c3.763-1.732,7.089,0.828,10.646,0.482c0.903-0.091,2.484,0.499,1.859,1.223c-2.034,2.345,1.166,6.013-2.23,7.788
			c-0.938,0.492-1.784,0.364-2.468-0.422c-1.323-1.521-1.394-2.954,0.239-4.389c0.641-0.561,0.82-1.487-0.332-1.749
			c-1.321-0.306-2.622-0.215-3.37,1.24c-0.444,0.859,0.222,2.623-1.912,2.124c-1.521-0.354-1.619-0.864-1.255-2.351
			c0.255-1.044-1.366-1.476-2.323-1.629c-1.082-0.173-1.633,0.615-1.516,1.737c0.326,3.153-2.126,2.144-2.845,1.35
			c-4.202-4.647-9.439-3.996-14.741-3.362c-1.228,0.758-1.966,0.017-2.496-0.927C614.83,476.242,615.486,475.575,616.309,475.048z"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          fill="#FCDBDB"
          d="M606.112,445.979c-1.436-1.785-4.314,0.016-5.558-2.242
			c0.154-1.268,0.648-2.385,2.068-2.425c3.554-0.1,5.183-1.38,3.619-5.108c-0.721-1.715,0.568-0.679,1.116-0.724
			c6.412-0.533,12.258,1.896,18.278,3.436c1.37,0.353,2.787,0.525,4.183,0.781c-4.349,6.663-11.111,6.355-17.836,6.202
			C610.027,445.854,608.069,445.948,606.112,445.979z"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          fill="#7DB07E"
          d="M658.302,496.382c-0.288,0.35-0.605,0.732-0.922,1.113
			c-1.876-2.995-4.932,0.201-7.074-1.349c-0.503-0.365-1.396-0.83-1.368-1.188c0.463-5.962-2.518-5.546-6.461-3.63
			c-0.746,0.364-1.779,0.119-1.944-0.726c-0.175-0.906,0.062-2.71,0.322-2.749c1.48-0.215,0.693-3.058,2.577-2.278
			c5.527,2.305,11.231,4.412,14.474,10.033C658.026,495.813,658.124,496.032,658.302,496.382z"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          fill="#DFE4C3"
          d="M613.192,470.314c-3.701-3.586-7.443-7.132-11.064-10.795
			c-0.659-0.667-2.326-0.991-1.501-2.599c4.354,3.457,8.76,6.851,13.026,10.415c1.024,0.854,2.872,1.904,1.123,3.778
			c-0.903,0.479-1.135-0.378-1.582-0.8H613.192z"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          fill="#D8D9B5"
          d="M656.414,508.059c-0.054,0.247-0.116,0.717-0.16,0.713
			c-3.666-0.15-10.449-8.633-10.039-12.514C650.563,499.373,652.431,504.629,656.414,508.059z"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          fill="#D3D8B0"
          d="M638.823,490.608c0.106,0.598-0.361,0.863-1.146,0.81
			c-0.126-0.007-0.274-0.032-0.37-0.103c-3.182-2.355-5.479-5.461-7.473-8.82c-0.015-0.025,0.662-0.533,0.694-0.503
			C633.257,484.787,635.956,487.61,638.823,490.608z"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          fill="#C2D4AD"
          d="M604.535,453.824c1.611,1.557,3.223,3.113,4.835,4.666
			c-3.126,0.409-4.351-2.508-6.513-3.781c-0.223-0.135-0.094-0.864-0.127-1.313C603.332,453.539,603.934,453.68,604.535,453.824z"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          fill="#A4C399"
          d="M616.309,475.048c-0.985,1.816-0.525,2.902,1.595,3.142
			c-0.005,0.272-0.012,0.547-0.018,0.82c-2.49,0.289-3.216-1.99-4.672-3.205C614.245,475.551,615.276,475.298,616.309,475.048z"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          fill="#A4C399"
          d="M613.194,470.314c0.527,0.265,1.055,0.531,1.582,0.796
			c-0.254,0.881,0.446,2.673-0.781,2.476C612.447,473.334,613.497,471.443,613.194,470.314z"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          fill="#FDECED"
          d="M569.294,439.557c9.029,4.545,16.166,11.392,22.554,19.021
			c1.578,1.887,3,3.896,4.832,5.565c3.026,3.964,6.685,7.549,7.402,12.87c0.095,0.712,0.792,1.325,1.289,1.927
			c6.751,9.794,12.862,19.96,17.939,30.73c1.635,1.085,2.61,2.708,3.547,4.356c-0.106,1.504-1.069,2.804-1.06,4.342
			c-1.282,3.957,0.804,5.844,4.164,7.207c0.564,0.231,3.647,0.024,1.634,2.021c-3.918,3.897,0.688,3.032,2.003,4.021
			c1.025,0.596,1.841,1.398,2.417,2.438c-0.853,1.783-4.732-0.006-4.161,3.606c0.136,0.866-2.521,1.274-1.088,2.642
			c1.235,1.178,2.585,2.401,4.812,1.885c2.241-0.518,6.025-2.663,6.24,1.333c0.154,2.857-0.836,7.103-5.415,7.845
			c-1.522,0.247-2.953,1.033-4.472,1.363c-3.046,0.655-2.572,2.126-1.256,4.148c1.834,2.821,2.868,5.904,2.156,9.35
			c-1.22-1.66-2.596-3.23-3.616-5.006c-1.195-2.079-2.301-3.417-4.863-1.754c-2.765,1.803-3.187-0.277-3.024-2.27
			c0.457-5.613-3.539-12.483-8.692-14.926c-3.754-1.783-0.346-4.593-0.776-6.882c-0.198-1.056,2.607-1.41,1.04-2.707
			c-1.509-1.248-3.443-2.07-5.309-2.744c-0.508-0.186-1.49,1.261-2.313,0.183c-0.7-0.923,0.167-1.693,0.573-2.467
			c1.212-2.313,2.518-3.938-1.673-5.305c-2.693-0.877-5.764-4.21-2.188-8.069c0.821-0.888,3.603-2.191-0.562-1.833
			c-2.449,0.211-3.822-3.261-7.015-2.486c-0.84,0.205,0.752-0.845,0.93-1.369c0.332-0.979,5-1.143,0.688-3.109
			c-0.265-0.122-0.369-0.596-0.548-0.906c5.85-2.61-0.956-4.545-0.338-6.666c0.891-3.054-0.193-6.468,1.784-9.3
			c0.046-0.063-0.646-0.931-0.924-0.892c-6.57,0.883-5.96-5.196-8.219-8.451c-2.713-3.909-5.587-7.845-5.898-12.78
			c-0.712-11.24-10.366-12.07-17.786-14.904c-5.281-2.019-13.464-11.279-12.215-15.824c1.045-0.014,2.091-0.029,3.137-0.046
			c3.113,0.638,6.354,0.577,9.381,1.688C565.986,438.231,567.709,438.74,569.294,439.557z"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          fill="#FCE6D9"
          d="M605.371,478.939c-2.897-4.934-5.794-9.867-8.691-14.797
			c2.799,1.691,5.311,3.912,8.611,4.686C607.95,472.177,603.281,475.587,605.371,478.939z"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          fill="#B7C6A5"
          d="M626.557,514.335c-1.373-1.354-3.059-2.488-3.246-4.665
			c2.376-0.29,7.782,3.937,7.961,6.223c-0.515,0.023-1.029,0.041-1.544,0.061C628.433,515.88,627.247,515.589,626.557,514.335z"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          fill="#FDB6C1"
          d="M643.074,403.485c-0.003,2.153,2.071,2.646,3.141,3.938
			c-1.024,2.158-2.652,3.097-5.069,2.606c-3.225-0.655-6.284,0.849-9.512,0.722c1.971,1.375,4.208,1.572,6.349,1.296
			c4.615-0.593,9.098,0.812,13.668,0.63c0.925-0.036,1.811,0.269,2.469,1.005c1.574,0.316,4.432-0.659,4.514,0.642
			c0.141,2.236-2.778,1.936-4.367,2.236c-5.4,1.021-10.795,0.86-16.114-0.898c-1.778-0.589-3.836-1.088-5.475,0.552
			c-0.081,0.08,0.615,0.935,0.947,1.43c-1.236,1.552,0.115,4.609-3.192,5.083c-1.069,0.151-0.489,1.46-0.007,2.281
			c0.604,1.034,2.038,2.059,0.511,3.333c-1.453,1.213-3.213,1.418-4.629,0.103c-2.628-2.439-5.034-3.012-7.593,0.044
			c-1.275,1.527-2.24-0.178-3.368-0.457c-4.909-1.2-6.771-6.738-11.577-8.045c-2.516-1.831-5.217-3.513-5.504-7.074
			c2.273,0.744,4.293,1.85,5.737,3.846c0.841,1.161,1.359,3.224,3.423,1.251c0.796-0.758,3.132-0.218,2.725-1.482
			c-0.445-1.375-1.493-3.368-3.852-2.986c-0.805,0.131-1.798,0.042-1.31-1.148c0.393-0.951,1.445-2.142,2.352-0.911
			c3.456,4.68,10.313,2.884,13.829,7.465c0.503,0.657,2.339,1.651,3.125,0.583c0.946-1.289-1.107-0.857-1.484-1.526
			c-0.234-0.42-0.368-0.892-0.55-1.34c0.479-0.032,0.962-0.052,1.441-0.097c1.268-0.127,2.542-0.215,3.8-0.412
			c1.104-0.173,2.19-0.466,3.285-0.704c-0.188-0.273-0.377-0.545-0.566-0.818c-1.721,1.821-2.605-2.547-4.483-0.563
			c-0.928,0.979-8.064-2.009-8.013-3.353c0.057-1.469,1.306-0.939,2.078-0.666c5.978,2.128,9.075,0.711,10.26-4.763
			c0.028-0.127,0.042-0.259,0.091-0.381c0.629-1.58-4.15-1.41-1.231-3.381c0.855-0.575,2.687,0.976,4.251,1.295
			C636.481,403.498,639.877,402.033,643.074,403.485z"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          fill="#FDC4CA"
          d="M654.119,413.684c-6.98-0.256-13.957-0.539-20.94-0.75
			c-1.998-0.061-4.231-0.523-3.924-2.715c0.323-2.303,2.794-0.856,4.309-1.132c4.192-0.758,8.708,0.956,12.651-1.663
			c2.889,4.44,7.075,2.771,10.998,2.34C655.784,410.756,656.673,413.577,654.119,413.684z"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          fill="#FDC1C6"
          d="M598.265,412.914c1.834,2.359,3.668,4.715,5.504,7.074
			c-4.203-0.02-7.693,0.812-9.76,5.269c-1.138,2.451-4.277,0.688-6.478,1.264c-3.613,0.943-7.342,3.381-11.171,0.387
			c-0.263-0.205-0.867,0.029-1.312,0.058c0.181,0.457,0.227,1.161,0.556,1.339c3.055,1.635,4.533,6.433,9.339,4.814
			c0.043-0.012,2.67,1.837,0.92,3.395c-0.834,0.745-0.925,1.453-0.962,2.348c-5.455-0.547-6.073-6.759-10.279-8.768
			c-2.89-1.142-6.076-1.295-8.883-2.782c-1.132-0.601-2.379-0.746-3.331,0.812c-1.12,1.826-2.373,0.886-3.449-0.266
			c-4.293-7.662-8.999-14.887-15.97-20.604c-2.453-2.012-3.539-5.809-4.944-8.931c-0.674-1.497-0.889-3.439,0.755-4.668
			c0.785-0.585,1.477-0.988,2.969-0.21c2.106,1.094,5.294,0.857,4.062,4.382c-0.897,2.566-0.058,2.479,1.795,1.996
			c2.523-0.66,4.312,3.414,7.256,1.088c0.35-0.275,0.85,1.616,0.941,2.658c0.154,1.741-2.179,4.633,2.238,4.502
			c0.913-0.025,0.106,1.212-0.506,1.653c-1.846,1.333,0.995,2.508,0.112,3.795c0.274,0.159,0.549,0.319,0.824,0.48
			c0.41-0.922,0.873-1.824,1.217-2.771c0.27-0.748-0.221-2.208,1.281-1.772c1.09,0.316,0.343,1.181,0.09,1.907
			c-0.229,0.655-0.129,2.08-0.084,2.083c4.134,0.338,6.616,4.843,9.719,5.162c3.292,0.34,6.894,0.889,10.364,0.612
			c0.988-0.081,2.377,1.468,2.914-0.522c0.352-1.301-0.561-2.2-1.512-2.968c-1.106-0.899-3.687-2.438-2.362-3.055
			c2.644-1.234,0.821-7.968,6.46-5.042c1.342,0.698,3.51-0.553,4.512-0.562C594.358,407.032,597.315,409.173,598.265,412.914z"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          fill="#FDF4C4"
          d="M657.256,157.368c-4.295,1.475-8.459,0.3-12.773,0.054
			c-8.646-0.493-17.474-4.085-26.292,3.167c-6.893,5.668-16.248,8.982-26.036,8.685c-2.376-0.07-4.938-0.896-7.199,0.691
			c-1.926,0.512-3.03-1.491-4.767-1.555c0.173-1.372-0.688-3.378,0.821-3.931c1.329-0.486,2.95,1.855,4.688,0.298
			c2.059,3.461,2.57-2.463,4.597-0.845c0.215,0.172,1.836-2.127,1.541-3.608c-0.396-1.98-1.903-1.267-3.131-0.893
			c-1.8,0.551-3.018-0.208-3.971-1.657c-0.949-1.442,0.726-2.418,0.746-3.256c0.126-5.637,8.726-6.091,7.358-12.403
			c5.226-1.228,0.083-5.771,2.523-7.888c-1.864-1.692,3.595-2.53,0.509-4.363c2.994-3.371,7.143-2.722,10.688-2.227
			c3.881,0.544,7.711,1.248,11.644,1.193c4.172-0.058,6.577,2.735,9.007,5.611c-0.265-2.463-1.575-4.341-2.915-6.225
			c-0.804-1.129-2.093-2.076-1.712-3.759c3.162,1.819,6.325,3.64,9.487,5.458c1.722,3.093,2.396,6.714,4.775,9.479
			c1.75,6.356,6.98,9.302,12.124,12.3C651.874,153.385,655.184,154.466,657.256,157.368z"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          fill="#FBFBF6"
          d="M622.583,124.457c2.761,3.125,5.453,6.305,6.306,10.55
			c0.15,0.749,1.279,1.737-0.018,2.3c-0.392,0.169-1.38-0.56-1.843-1.097c-8.765-10.134-20.108-7.504-31.157-6.347
			c-4.521-0.272-8.344,2.156-12.518,3.228c-2.929-0.943-1.246-2.345-0.195-3.368c8.349-8.149,17.308-14.679,30.08-12.522
			c2.816,0.475,3.476,2.302,4.675,4.048C619.469,122.318,621.028,123.388,622.583,124.457z"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          fill="#FCFCF5"
          d="M633.66,62.284c0.982-0.848,2.023-1.642,2.929-2.566
			c0.472-0.479,0.708-1.19,1.05-1.796c-0.735,0.162-1.482,0.496-2.206,0.451c-1.533-0.096-2.657,3.446-3.746,1.144
			c-0.63-1.333,1.161-4.057,3.389-5.05c4.303-1.918,8.931-2.439,13.53-3.04c0.533-0.069,1.118,0.247,1.676,0.385
			c-0.345,0.519-0.586,1.174-1.056,1.531c-1.66,1.26-3.498,2.298-5.083,3.639c-3.449,2.919-8.02,4.881-8.954,10.051
			C632.064,66.29,634.798,63.664,633.66,62.284z"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          fill="#FAFAF3"
          d="M636.846,139.393c-3.313-2.294-4.386-5.713-4.775-9.479
			c2.445,0.169,2.413,1.913,2.391,3.698c-0.02,1.461,0.74,2.908,2.071,2.885c1.688-0.029,0.579-1.472,0.565-2.374
			c-0.025-1.801,0.922-1.539,2.001-0.826c9.444,6.238,19.879,10.455,30.224,14.859c0.451,0.191,1.178,0.607,1.158,0.876
			c-0.07,1.024-0.979,0.508-1.495,0.519c-3.666,0.067-7.332,0.061-10.998,0.079c-6.508-1.212-11.71-4.811-16.498-9.11
			C640.086,139.265,638.876,137.812,636.846,139.393z"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          fill="#D9CBD5"
          d="M646.941,533.163c1.215,2.907,5.691,2.981,6.459,5.058
			c1.999,5.411,7.655,8.5,8.443,14.427c0.062,0.469,0.111,0.938,0.165,1.409c-0.479-0.101-0.986-0.125-1.427-0.313
			c-2.08-0.887-3.746-2.204-5.029-4.156c-2.846-4.32-4.419-10.005-11.07-10.421c-1.386-0.087-1.679-1.647-1.255-2.832
			C643.771,534.81,644.939,533.759,646.941,533.163z"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          fill="#DACDD5"
          d="M665.009,567.45c-0.294,0.872,0.88,2.972-0.528,3.448
			c-1.763,0.596-2.86-1.462-3.929-2.681c-0.939-1.068-2.594-2.457-0.73-3.928c1.547-1.219,0.862-2.202,0.191-3.318
			c-0.666-1.109-1.812-2.277,0.152-3.131c1.604-0.695,2.755,0.107,3.48,1.586C664.818,561.816,665.344,564.337,665.009,567.45z"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          fill="#D5C6D3"
          d="M657.073,596.062c-1.219-0.135-2.331-0.535-2.969-1.643
			c-0.916-1.589,0.407-1.806,1.414-2.493c1.883-1.289,0.583-3.381,0.853-5.099c0.158-1.025-0.044-2.178,1.363-2.467
			c1.785-0.369,2.983,0.597,4.091,1.797c1.041,1.127,0.36,1.744-0.553,2.504c-1.427,1.185-1.658,2.887-1.647,4.64
			C659.637,595.006,658.905,596.046,657.073,596.062z"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          fill="#D7C6D2"
          d="M666.501,577.668c-2.602,1.046-0.98,5.162-2.65,5.124
			c-3.965-0.092-4.07-4.491-5.61-7.166c-0.655-1.134,0.569-2.395,1.945-2.098C662.625,574.052,664.764,575.274,666.501,577.668z"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          fill="#D6C5D2"
          d="M636.016,534.056c-1.699,0.074-2.544-0.7-2.415-2.438
			c2.29-1.146,2.572-7.273,7.072-2.885c0.823,0.8,3.216,0.156,2.508,2.171c-0.507,1.45-1.981,2.285-3.759,2.369
			C638.276,533.323,637.149,533.78,636.016,534.056z"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          fill="#DDD1D9"
          d="M654.728,598.388c-1.2,2.332-3.955,2.069-5.775,3.329
			c-0.792,0.551-1.212-0.178-1.011-0.918c0.584-2.139,1.194-4.536,3.61-5.115C653.063,595.321,653.85,597.043,654.728,598.388z"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          fill="#D9CCD7"
          d="M644.817,548.935c1.471,0.135,2.715,1.011,2.789,2.84
			c0.051,1.219-0.919,1.942-2.109,1.688c-1.555-0.33-3.072-0.958-3.124-2.931C642.333,549.081,643.383,548.912,644.817,548.935z"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          fill="#D9CBD5"
          d="M648.561,583.167c-0.043,1.164-0.341,1.931-1.618,1.503
			c-1.44-0.482-3.201-0.749-3.515-2.54c-0.223-1.279,0.93-1.722,2.044-1.679C647.255,580.521,648.223,581.58,648.561,583.167z"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          fill="#D6C9D2"
          d="M652.351,567.124c-0.019,1.458-0.995,1.496-1.919,1.449
			c-1.745-0.086-3.048-0.914-3.279-2.727c-0.129-1.004,0.814-1.269,1.634-1.14C650.357,564.967,651.838,565.447,652.351,567.124z"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          fill="#DED6DC"
          d="M642.28,589.677c-0.084,1.13-0.448,2.164-1.708,2.271
			c-1.634,0.135-1.411-1.193-1.381-2.19c0.034-1.162,0.592-2.113,1.85-2.102C642.303,587.664,642.27,588.748,642.28,589.677z"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          fill="#E1DDBE"
          d="M626.557,514.335c1.252,0.152,2.389,0.54,3.17,1.618
			c-1.308,0.807-2.619,1.61-3.929,2.415C624.341,516.703,624.898,515.414,626.557,514.335z"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          fill="#DDD6DC"
          d="M650.803,559.999c0.042,1.075-0.529,1.454-1.414,1.44
			c-1.339-0.017-2.168-0.7-2.309-2.038c-0.105-1.01,0.249-1.878,1.465-1.618C649.729,558.035,650.521,558.857,650.803,559.999z"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          fill="#FDEDE5"
          d="M526.784,392.461c5.783,6.833,12.062,13.288,13.062,22.961
			c0.437,4.229-0.072,8.28-0.53,12.396c-0.646,5.56,3.216,8.875,6.264,12.595c-3.075,2.728-6.998,4.279-9.894,7.31
			c-1.032,1.077-2.806,0.646-4.12,0.03c-3.88-1.812-8.003-2.312-12.19-2.517c-2.78-2.646-3.515-6.312-3.167-9.671
			c0.324-3.102-1.104-4.571-2.891-6.282c-0.95-0.909-2.56-1.136-3.096-2.327c-0.804-1.788-1.751-1.273-2.634-0.374
			c-2.204,2.236-5.181,1.359-7.763,2.045c-1.22,0.322-2.288-0.506-2.981-1.507c-0.834-1.207-0.083-2.231,0.63-3.197
			c0.312-0.422,0.742-0.752,1.033-1.177c0.755-1.108,3.836-1.253,0.258-3.74c-2.305-1.599-3.551-2.269-6.078-1.653
			c-2.73,0.665-5.384-0.851-8.137-0.864c-0.5-0.003-1.064-0.304-1.05-0.946c0.017-0.778,0.658-1.108,1.244-1.443
			c-0.935,0.289-1.327,1.327-2.069,1.701c-3.502,1.763-7.419,2.213-11.197,2.908c-1.82,0.333-2.582-2.331-1.171-4.187
			c1.178-1.552,2.694-2.831,3.634-4.587c0.274-0.508,0.831-0.838,1.228-1.283c-0.381,0.362-0.707,0.819-1.157,1.056
			c-4.164,2.202-6.391,6.747-10.69,8.784c-1.109,0.524-1.086,0.577-2.637-0.054c-0.103-3.278,2.418-7.557-4.29-7.895
			c-1.822-0.093-0.401-4.828,0.935-5.557c2.073-1.133,3.392-2.54,4.443-4.398c0.434-0.765,5.516-1.586,0.122-2.703
			c-1.168-0.241-0.504-0.957-0.085-1.39c4.509-4.647,9.538-8.025,15.843-10.425c4.922-1.871,7.72,0.962,11.5,1.392
			c3.165,0.359,6.313,0.116,9.593,0.893c4.232,1.005,8.396,0.23,7.573-6.014c-0.15-1.142,0.551-1.977,1.59-2.473
			c0.853-0.751,1.736-0.406,2.514,0.051c4.68,2.754,9.615,5.102,13.891,8.503C525.572,389.428,527.153,390.388,526.784,392.461z"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          fill="#FBD5CE"
          d="M460.684,418.44c3.282-1.853,7.693-2.496,7.981-7.565
			c0.028-0.527,0.677-1.024,1.039-1.532c4.065,3.243,4.161-2.365,6.521-2.944c0.522-0.129,0.866-1.286,1.498-0.453
			c0.222,0.294-0.106,1.263-0.463,1.697c-1.944,2.403-3.916,4.786-6.007,7.058c-0.788,0.857-1.414,1.593-0.727,2.699
			c0.765,1.235,1.742,0.666,2.736,0.246c4.896-2.063,10.544-1.87,14.902-5.572c-0.605,2.435-2.979,1.959-4.448,3.493
			c4.599-0.447,8.988,2.562,13.115-0.734c0.176-0.141,1.75,0.8,1.727,1.183c-0.232,3.945,2.585,3.093,4.821,3.266
			c0.457,0.036,1.198,0.346,1.277,0.667c0.273,1.097-0.744,0.803-1.237,0.805c-2.157,0.016-3.173,1.709-4.365,3.014
			c-0.748,0.815-2.747,1.458-1.486,2.995c0.68,0.831,2.251,1.721,3.131-0.263c3.834,1.862,6.229-0.195,8.403-3.011
			c0.097-0.124,1.229,0.202,1.277,0.443c0.821,3.983,4.71,4.971,7.127,7.374c2.02,2.005-1.584,3.431-0.323,5.73
			c1.151,2.095,2.573,4.574,1.674,7.338c0.391,0.837,0.037,1.582-0.472,2.159c-4.968,5.623-6.6,12.585-7.965,19.663
			c-0.102,0.793-0.438,1.511-0.667,2.255c-2.368,7.724-3.578,8.601-11.662,8.498c-2.24-0.046-4.406,0.461-6.562,1
			c-0.828,0.092-1.568-0.095-2.168-0.7c-0.625-0.708-0.857-1.601-1.131-2.474c-1.548-4.923-1.52-4.971-5.884-7.435
			c-1.593-0.898-1.926-1.979-1.17-3.552c0.396-0.823,1.292-1.777,0.982-2.499c-1.242-2.892-0.395-4.523,1.791-6.841
			c1.038-1.093-1.649-5.128,0.636-7.687c-2.929-1.124-3.266-3.374-2.779-6.1c0.162-0.906-0.682-0.502-1.052-0.371
			c-2.785,1.005-5.96,1.122-8.153,3.493c-1.46,1.578-2.995,1.612-4.354,0.009c-1.417-1.669-0.032-2.748,1.22-3.406
			c3.634-1.912,5.185-5.771,8.058-8.472c-3.567,2.981-8.679,1.671-12.286,4.601c-0.658,0.538-2.208,0.605-2.504-0.234
			c-1.024-2.9-1.593-0.696-2.519,0.012c-2.812,2.149-5.648,4.229-7.629,7.261c-0.576,0.879-1.647,1.286-2.716,1.438
			c-0.923,0.135-1.944,0.171-2.419-0.792c-0.421-0.849,0.321-1.442,0.883-1.98c0.659-0.63,1.461-1.129,1.834-2.013
			c0.376-0.879,0.656-2.127-0.075-2.551c-0.854-0.494-0.931,0.923-1.433,1.426c-0.971,0.963-2.096,1.54-3.411,1.804
			c-1.424,0-2.882,0.266-4.225-0.478c-2.296,0.1-3.048-2.098-4.58-3.139c-0.581-0.392-0.906-1.723,0.237-1.789
			c4.858-0.288,2.636-3.038,1.973-5.275c0.066-5.98,5.89-13.048,12.176-14.869c0.619-0.181,1.028-0.255,1.111,0.421
			c0.163,1.359-0.4,2.422-1.812,2.544c-2.035,0.179-3.888,0.653-5.269,2.229c-0.48,0.547-0.741,1.285-1.104,1.939
			c0.669,0.352,1.409,1.1,1.991,0.991c4.166-0.78,9.244-0.092,10.458-6.061c0.204-1.012,0.756-1.985,2.068-2.029
			C460.491,415.292,459.396,417.798,460.684,418.44z"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          fill="#FDD8DD"
          d="M476.406,344.496c-0.267-1.833-0.533-3.663-0.8-5.496
			c-1.042-2.879-2.085-5.758-3.128-8.638c1.612-6.887,5.655-11.423,12.57-13.413c3.3-0.95,6.094,1.257,9.293,1.113
			c1.056-0.045,2.065,1.752,2.596,3.176c0.752,2.025-4.138,2.322-1.527,4.379c1.908,1.503,1.605,3.007,1.507,4.802
			c-0.037,0.704-0.314,1.731,0.873,1.769c1.402,0.045,1.059-0.841,1.013-1.839c-0.105-2.277,1.526-2.495,2.797-1.375
			c3.861,3.397,8.166,6.375,9.104,12.213c0.631,3.928-1.238,6.161-3.661,8.445c-0.286,0.27-0.618,0.497-0.992,0.614
			c-1.464,0.464-1.373,0.403-0.546,1.865c0.877,1.552,2.341,2.892,2.065,4.942c-0.382,1.388-1.347,2.275-2.528,2.978
			c-6.293,2.69-11.131,7.329-15.972,11.961c-3.328-2.361-7.341-2.262-11.09-3.102c-1.267-2.138,0.299-3.423,1.552-4.646
			c1.481-1.45,3.027-2.892,4.756-4.018c2.449-1.6,3.26-2.889,0.082-4.533c-1.665-0.863-2.896-2.284-3.323-4.149
			C480.368,348.594,477.081,347.413,476.406,344.496z"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          fill="#FD96AD"
          d="M489.069,371.994c0.094-0.87-0.097-2.313,0.325-2.524
			c5.508-2.738,8.784-8.836,15.233-10.105c1.576,0.294,2.629,1.088,2.772,2.809c0.156,1.9,1.11,2.55,2.921,1.798
			c1.474-0.611,2.757-1.194,4.12,0.566c1.673,2.162,0.304,4.084-0.486,5.62c-0.755,1.469-3.268,2.601-4.678,1.535
			c-1.982-1.497-1.964,0.49-1.934,0.915c0.126,1.73,0.89-0.432,1.63,0.167c1.59,1.289,2.986,0.505,4.366-0.835
			c1.222-1.184,2.953-1.647,4.627-2.016c1.063-0.229,2.826-0.159,2.831,0.611c0.023,4.716,5.349,4.024,6.971,7.134
			c0.655,1.254,2.134,2.146,3.845,0.381c1.984-2.047,3.484-0.47,4.639,1.308c0.276,0.43,0.08,1.06,0.27,1.53
			c0.691,1.73,0.527,4.245,2.875,4.667c2.207,0.394,2.889-1.841,3.992-3.24c2.444-3.093,4.468-2.873,6.915,0.726
			c-4.239,5.378-12.384,4.495-16.572,10.346c-0.641,0.892-4.552-0.556-6.947-0.924c-5.058-6.049-12.28-8.849-18.877-12.591
			c-3.883-5.154-10.191-5.013-15.492-7.06C491.354,372.397,490.187,372.257,489.069,371.994z"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          fill="#FDEFE6"
          d="M476.406,344.496c2.442,2.096,5.349,3.813,5.795,7.492
			c0.071,0.585,0.505,1.395,0.996,1.625c10.604,4.958,0.993,7.296-1.53,10.06c-1.498,1.641-3.365,2.809-3.688,5.218
			c-4.749,1.169-9.581-0.66-14.433,0.655c-3.408,0.922-7.138,0.653-10.727,0.918c3.528-2.674,7.449-4.495,11.92-3.871
			c4.827,0.672,8.788-1.642,13.021-3.071c0.522-0.179,0.922-0.713,1.379-1.084c-3.837-0.624-2.228-7.39-7.818-6.536
			c-4.093,0.627-3.146-2.844-2.037-4.674C470.98,348.431,473.532,346.214,476.406,344.496z"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          fill="#FAB6B9"
          d="M496.059,605.533c-4.192-1.919-2.789,3.123-5.081,4.187
			c-3.534,1.646-7.617,2.784-10.279,6.06c-0.57,0.703-1.777,1.831-1.636,2.037c3.577,5.248-2.908,4.412-4.06,6.755
			c-0.611,1.236-2.346,2.28-2.344,3.41c0.003,6.039-4.197,4.68-7.676,4.659c-0.497-0.002-1.131-0.458-1.47-0.273
			c-4.11,2.232-8.416,3.884-13.055,4.605c-1.056,0.543-2.361,1.206-3.333,0.839c-5.604-2.136-7.828,2.674-11.113,5.232
			c-1.141,0.889-1.641,2.867-3.728,1.631c-0.98-0.577-0.939,0.514-1.166,0.967c-0.715,1.439-1.766,2.599-2.956,3.628
			c-2.369,2.051-3.132,1.92-4.05-1.008c-0.489-1.564-1.078-1.166-1.852-0.464c-1.363,1.245-2.907,1.659-4.712,1.321
			c-1.299-0.24-2.207,0.527-3.127,1.312c-1.231,1.046-2.551,1.912-4.245,0.898c-0.645-0.667-0.851-1.615-1.446-2.302
			c-0.891-1.025-0.832-0.856-1.618,0.593c-1.15,2.118-2.978,3.36-5.481,2.278c-1.956-0.849-3.875-1.276-6.037-1.117
			c-4.692,0.346-6.271-1.01-7.307-5.851c-0.081-0.384-0.411-0.644-0.732-0.866c0.006-5.471-4.135-7.666-7.654-10.87
			c-5.731-5.214-9.629-11.98-11.224-19.799c0.171-0.068,0.344-0.135,0.515-0.201c4.661,1.49,6.824,5.227,9.152,9.21
			c2.07,3.545,5.417,6.294,7.938,9.65c0.885,1.181,3.684,2.55,4.829-0.879c0.684-2.045,2.267-2.848,4.368-1.927
			c2.215,0.97,4.507,1.182,6.894,1.124c2.239-0.054,3.138,1.163,2.409,3.297c-0.881,2.582-1.275,5.333-2.697,7.738
			c-0.283,0.48-0.751,1.259,0.037,1.677c0.469,0.249,0.956-0.272,1.342-0.623c3.659-3.324,3.764-3.455,7.51-0.391
			c2.633,2.158,4.624,0.188,6.859-0.509c2.396-0.931,0.929-1.975-0.022-2.751c-2.126-1.741-4.067-3.66-5.966-5.628
			c-0.988-1.026-2.065-2.036-1.957-3.671c1.426-2.787,2.579-5.886,6.556-5.766c5.142-0.082,10.285-0.034,15.408,0.572
			c0.668,0.081,1.347-0.062,1.872-0.447c4.911-3.638,8.935-1.032,12.684,1.999c5.316,4.3,9.773,3.26,13.041-2.801
			c1.777-3.299,5.656-2.108,8.5-2.894c-0.682-1.199-3.239,1.069-3.228-1.289c0.009-1.822,1.865-2.341,3.48-2.539
			c1.631-0.201,2.997-0.81,4.098-2.047c1.119-1.265,2.562-1.633,4.229-1.633c3.094-0.002,5.291-1.389,5.927-4.577
			c0.316-1.594,1.235-2.765,2.53-4.194c-3.453,2.451-5.951-0.953-8.859-0.703c-1.884-0.598-3.989-0.938-4.173-3.534
			c0.715-2.508,2.607-4.329,3.843-6.536c0.984-1.193,1.809-2.7,3.784-1.984c1.029,1.696,0.081,3.235-0.369,4.827
			c-0.254,0.91-1.096,2.272,0.517,2.509c1.814,0.266,3.687-0.317,4.885-1.821c0.847-1.055-0.088-1.887-0.931-2.564
			c-1.169-0.94-3.128-0.731-3.696-2.546c1.647-3.391,4.625-2.307,6.625-1.19c2.438,1.363,3.711,1.584,5.144-1.043
			c0.571-1.046,1.621-2.034,2.801-2.669c0.356,0.532,0.922,1.025,1.039,1.606c1.617,7.915,4.285,10.205,11.771,10.256
			c1.058,0.008,3.114-1.43,3.097,0.7c-0.014,1.453-2.188,0.704-3.389,0.79c-2.554,0.187-5.505-0.44-5.629,3.578
			c-0.024,0.758-1.098,1.323-1.321,0.916C497.282,599.305,497.729,606.541,496.059,605.533z"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          fill="#FDC2C2"
          d="M355.333,578.793c1.082,0.831,3.279,0.827,2.361,3.154
			c-0.719,1.888-2.636,1.897-4.076,2.688c-0.784,0.43-2.417-0.926-2.29,0.783c0.086,1.142,0.537,2.629,1.888,3.25
			c1.02,0.467,2.013,0.482,2.769-0.599c1.121-1.605,3.087-1.877,4.624-2.828c0.774-0.479,1.695-0.889,2.529-0.111
			c0.672,0.63,0.33,1.427,0.205,2.2c-0.616,3.826-0.6,3.83,3.568,3.801c5.208-0.033,6.112,0.506,6.937,4.147
			c0.098,0.663-1.576,1.175-0.365,1.824c3.009,1.61,1.668,3.127,0.159,4.848c-1.78,2.027-2.631,4.74-4.912,6.451
			c-2.056,1.539,0.737,3.318-0.016,4.994h-0.035c-2.984,0.515-5.479,1.303-3.213,5.144c1.771,3.008,3.474,6.056,5.169,9.111
			c0.554,0.994,1.771,2.424,0.779,3.131c-1.39,0.988-3.351,1.178-5.083,1.664c-0.04,0.012-0.428-0.691-0.337-0.807
			c2.887-3.626-0.425-4.209-2.583-5.502c-2.073-1.241-3.087-3.087-2.487-5.618c0.152-0.643,1.03-1.562-0.134-1.919
			c-0.375-0.117-1.118,0.801-1.627,1.309c-0.546,0.555-0.94,1.28-1.537,1.763c-0.98,0.8-1.879,2.854-3.393,0.947
			c-1.093-1.376,0.101-2.303,1.306-3.077c0.869-0.556,1.47-1.655,2.383-1.989c7.771-2.87,0.913-8.673,2.62-12.726
			c-5.474,2.178-4.096-4.519-7.119-5.451c-0.421-0.131-1.533,0.549-1.514,0.616c1.033,3.558-2.899,1.767-3.61,3.381
			c-2.167-1.014-1.324-3.052-1.662-4.717c-0.331-1.642-0.174-4.229-3.224-2.253c-0.512,0.33-1.443,0.038-2.177-0.007
			c-1.257-0.079-1.429-3.456-2.914-1.483c-0.91,1.209-1,3.867,1.289,5.111c-1.69,1.207-0.769,5.794-4.944,3.813
			c-0.249-0.119-0.646,0.074-0.976,0.119c3.431-2.64-0.69-7.076,1.947-9.444c2.799-2.508,1.331-6.091,3.192-8.641
			c0.578,0.036,2.03-0.778,1.595-0.02c-2.39,4.185,3.878,2.27,3.274,4.957c1.764-0.344,1.419-1.52,0.993-2.646
			c-1.522-4.033-0.447-7.14,3.223-9.388c0.679-0.416,1.358-1.025,2.105-0.4C351.887,579.938,351.856,579.974,355.333,578.793z"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          fill="#FDC8C4"
          d="M387.557,644.065c0.959-0.195,1.426-1.743,2.623-1.01
			c0.135,0.078-0.437,1.261-0.635,1.949c-1.208,4.194,2.238,4.924,4.621,4.859c2.91-0.074,5.554-0.512,8.144,1.325
			c1.662,1.176,3.767-1.005,3.83-1.383c0.37-2.194-1.193-0.121-1.769-0.565c-0.261-0.198-0.723-0.571-0.68-0.663
			c0.212-0.435,0.513-0.869,0.882-1.181c1.291-1.091,2.644-0.605,4.033-0.144c2.03,0.671,1.712,2.339,1.765,3.871
			c-0.438,1.985-2.65,3.208-2.362,5.505c-5.03,5.508-10.885,2.345-16.512,1.566c-0.764-2.722-2.578-3.843-5.209-5.062
			c-2.569-1.186-8.465-1.692-5.162-7.515c-2.73-0.418-1.867-4.025-4.2-4.754c-0.958-0.301-1.747-0.738-1.755-1.801
			c-0.002-0.378,0.656-1.123,0.8-1.074c0.819,0.279,1.671,0.627,2.332,1.17c2.114,1.737,4.187,3.533,6.198,5.388
			C385.813,645.757,386.762,645.647,387.557,644.065z"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          fill="#FDC2C2"
          d="M340.793,598.422c0.685,0.195,1.502-0.05,1.212,0.791
			c-0.122,0.354-0.772,0.623-1.23,0.732c-0.325,0.074-0.729-0.193-1.096-0.305C340.073,599.209,340.468,598.774,340.793,598.422z"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          fill="#F7A6B1"
          d="M373.401,595.297c-1.198-4.895-5.2-2.539-8.069-3.151
			c-1.397-0.301-3.829,1.347-3.765,0.275c0.098-1.628-0.941-3.646,0.61-5.197c0.359-0.362,0.361-1.084,0.529-1.639
			c-0.558,0.167-1.425,0.167-1.622,0.53c-1.043,1.923-5.227,0.388-4.516,4.135c-1.876-0.287-3.75-0.573-5.626-0.861
			c0.972-1.622-0.422-2.578-1.011-3.797c-0.447-0.926-0.787-1.968,0.301-2.821c1.499-1.174,1.717-0.267,1.736,1.022
			c1.91-0.617,3.816-1.231,5.726-1.846c3.459-1.395,0.217-4.072,1.076-6.108c0.456-1.083-1.286-1.102-2.497-1.322
			c-4.16-0.754-1.567-5.608-4.098-7.463c-2.708-1.984-0.963-7.508-6.3-7.573c-1.279-0.016-0.84-2.279-0.585-3.477
			c1.285-6.046,2.605-12.085,4.083-18.084c0.552-2.245-1.383-2.945-2.26-4.197c-1.852-2.645,0.079-5.294-0.452-7.88
			c5.165,0.712,6.647-4.297,7.684-6.886c2.216-5.536,5.597-10.087,8.848-14.843c0.255,0,0.509,0,0.763,0
			c3.051,4.839,2.751,6.238-1.726,9.409c-3.632,2.566-5.197,6.508-6.09,10.601c-0.648,2.968-3.095,6.014-0.363,9.187
			c0.215,0.254,0.106,0.979-0.17,1.091c-4.778,1.927-2.124,6.483-3.324,9.678c-0.847,2.245-1.11,4.639-2.494,6.816
			c-1.891,2.979,0.313,8.992,3.473,9.927c1.106,0.325,1.866-1.129,2.811-1.729c2.261-3.009,4.454-1.739,6.644,0.097
			c1.414,1.579,2.695,3.326,4.847,4.038c0.305,0.213,0.542,0.481,0.723,0.808c0.424,3.098,1.146,6.12,2.305,9.038
			c0.142,0.358,0.063,0.76-0.199,1.088c0.48-0.085,0.519-0.522,0.643-0.854c1.307-3.514,4.819-1.122,6.931-2.556
			c0.282-0.189,1.053,0.459,1.107,0.937c0.285,2.553,0.721,5.073,1.161,7.602c0.168,0.965-0.831,1.806-1.787,1.718
			c-3.165-0.301-4.069,1.492-4.361,4.16c-0.098,0.885-0.559,1.745-1.284,2.377c-0.074,0.516-2.283,0.283-0.779,0.894
			c3.285,1.327,4.704,5.021,8.556,5.745c2.059,0.388,1.569,3.517,1.008,5.132c-0.456,1.314,0.037,1.577,0.609,2.294
			c1.043,1.302,2.331,2.653,1.169,4.497c-1.222,1.94-2.958,2.36-5.032,1.381c0.4-4.325-1.172-7.772-4.578-10.446
			C373.232,596.631,373.107,595.963,373.401,595.297z"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          fill="#FDF0DD"
          d="M339.613,445.938c1.587,2.595,5.576,3.417,5.233,7.573
			c-0.094,1.15,1.799,1.198,2.991,0.833c0.988-0.302,2.115-0.467,1.792,0.96c-0.59,2.607,0.125,2.519,1.961,1.296
			c0.399-0.264,1.167,0.021,1.765,0.051c-0.146,1.601,0.971,3.302-0.533,4.802c-0.497,0.499-1.145,1.54-0.945,1.914
			c0.586,1.101,1.548,0.191,2.393,0.074c1.392-0.192,2.823-0.116,4.21-0.337c1.945-0.313,1.643,0.534,1.038,1.709
			c-0.52,1.006-3.252,1.198-1.79,2.558c0.905,0.841,3.765,0.656,5.032-0.169c3.575-2.331,8.669-3.628,7.596-9.888
			c-0.373-2.18,0.784-4.456,3.847-4.297c0,0.26,0,0.522,0,0.783c0.668,1.281-0.872,2.014-0.785,3.149
			c-0.588,6.711-3.8,11.887-9.16,15.856c-1.51,1.116-2.894,2.658-4.329-0.032c-0.35-0.659-0.898-0.939-1.599-0.575
			c-1.883,0.979-1.86,0.982-3.121-1.351c-0.603-1.116-1.04-2.98-2.458-2.712c-4.424,0.845-5.089-2.33-5.952-5.201
			C345.019,457,340.313,452.312,339.613,445.938z"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          fill="#FDF0DD"
          d="M378.92,438.861c-1.969-0.939-1.112-2.018-0.445-3.439
			c0.434-0.919,1.874-3.505-1.548-2.545c-0.214-1.968-1.179-4.258-0.496-5.838c1.787-4.143,1.233-7.137-2.414-9.312
			c-0.505-0.301-0.414-1.61-0.598-2.451c5.876,1.664,7.696,4.754,5.789,10.409c-1.075,3.184,1.326,5.782,1.021,8.717
			C380.071,435.919,379.372,437.377,378.92,438.861z"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          fill="#FDF0DD"
          d="M334.896,438.861c3.929,0.787,4.837,3.591,4.717,7.076
			C336.577,444.557,336.352,441.3,334.896,438.861z"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          fill="#FDF0DD"
          d="M362.412,400.34c-1.574,2.588-3.911-0.404-5.676,0.777
			c-0.173-1.465,0.976-2.198,1.742-3.142C359.056,399.985,361.415,399.032,362.412,400.34z"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          fill="#FDF0DD"
          d="M364.768,405.842c-0.817,0.122-1.634,0.243-2.452,0.365
			c0.555-1.171,1.11-2.341,1.667-3.513C365.092,403.533,364.676,404.751,364.768,405.842z"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          fill="#FDF0DD"
          d="M369.484,412.125c-1.82,1.03-2.909,0.589-3.141-1.568
			C368.126,409.605,368.763,410.951,369.484,412.125z"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          fill="#FDF6EC"
          d="M373.417,456.949c-1.581-1.51,0.68-2.06,0.785-3.149
			C375.702,455.289,374.678,456.149,373.417,456.949z"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          fill="#FBF7F7"
          d="M419.076,462.553c2.853-1.372,5.704-2.74,8.556-4.113
			c1.204-0.519,2.717-0.579,2.927,0.797c0.767,5.037,4.859,9.465,2.818,15.007c-0.378,1.835-1.798,2.782-3.277,3.566
			c-4.496,2.383-7.964,6.055-11.799,9.264c-0.653,0.646-1.261,1.334-1.762,2.114c-5.822,6.157-11.561,12.409-18.637,17.214
			c-7.818,3.923-15.178,8.411-19.979,16.085c-0.547,0.877-1.184,1.742-2.442,1.609c-2.207-1.428-1.43-3.367-0.765-5.16
			c1.13-3.053,3.025-5.688,4.871-8.335c1.414-2.02,2.532-4.186,3.412-6.488c0.524-0.9,1.23-1.61,2.151-2.1
			c4.039-0.807,8.104-1.096,11.966-3.122c4.883-2.57,8.813-5.943,12.032-10.309c5.515-6.186,7.586-13.745,8.675-21.701
			C418.026,465.381,417.66,463.708,419.076,462.553z"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          fill="#F8ABBB"
          d="M419.076,462.553c-0.268,9.913-2.18,19.252-9.39,26.725
			c-0.248,0.131-0.646,0.165-0.664,0.024c-0.237-1.768-1.558-1.691-2.831-1.717c-0.82-1.708-1.604-3.443-3.082-4.729
			c0.008-1.194,0.94-1.516,1.809-1.669c2.823-0.502,2.821-2.598,2.832-4.738c0.006-1.394-0.635-1.819-1.977-1.504
			c-1.331,0.315-2.679,1.201-4.048,0.085c2.115-5.251,5.776-9.543,9.064-14.011c2.244-3.048,5.354-5.396,3.581-9.857
			c-0.453-1.143,0.266-2.29,1.502-2.866c-2.087,2.851,1.694,3.214,2.25,5.066C419.044,456.443,416.44,459.776,419.076,462.553z"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          fill="#FCD3DF"
          d="M432.398,475.038c2.147-5.514-2.58-9.92-2.462-15.123
			c0.033-1.421-1.296-1.345-2.304-1.476c2.085-1.023,5.527-0.217,6.302-1.053c2.191-2.373-4.029,0.391-2.325-2.792
			c4.153-0.494,8.305-0.902,12.451-0.013c1.394,0.552,1.006,1.458,0.615,2.542c-1.477,4.085-1.041,4.814,3.269,5.852
			c0.255,0.062,0.52,0.073,0.772,0.14c0.893,0.243,1.873,0.469,2.036,1.562c0.186,1.238-0.608,2.182-1.656,2.322
			c-3.611,0.485-5.937,3.195-8.871,4.836C437.536,472.709,435.717,475.718,432.398,475.038z"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          fill="#FDF9F7"
          d="M444.931,455.333c-4.433-0.364-8.963,0.97-13.322-0.738
			c2.341-3.13,6.337-5.375,5.436-10.228c1.328-1.317,2.655-2.639,3.983-3.961c1.312-0.799,2.615-0.898,3.903,0.056
			c2.259,4.343,1.36,8.992,1.256,13.556C446.173,454.672,445.598,455.125,444.931,455.333z"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          fill="#F9BCC9"
          d="M406.33,487.562c1.443-1.145,3.871-3.487,4.171-3.24
			c2.156,1.769-3.026,2.757-0.817,4.955c-5.828,8.617-13.802,13.34-24.347,13.373c-0.725-2.735,2.458-3.402,2.762-5.667
			c-1.187,4.012-5.569,4.547-7.791,7.441c-1.921,1.122-3.161,3.611-5.95,3.012c-0.23-0.217-0.396-0.479-0.48-0.775
			c-0.133-0.267-0.062-0.817-0.678-0.448c-1.18,1.438-1.707,3.502-3.922,3.848c-2.883-0.86-3.132-2.767-2.078-5.198
			c1.049-2.425,2.451-4.601,4.498-6.318c0.285-0.249,0.597-0.466,0.917-0.664c2.287-1.2,4.619-2.313,7.081-3.126
			c2.096-0.563,4.064-1.629,6.305-1.638c3.86-1.545,5.515-5.52,8.65-7.859c3.368-2.71,6.422-5.642,7.073-10.23v0.004
			c0.256-0.044,0.512-0.11,0.769-0.115c2.278-0.082,5.736-3.312,6.318-0.823c0.643,2.752,1.934,8.745-4.947,8.311
			c-0.178-0.012-0.378,0.323-0.568,0.496c0.417,2.384-1.75,2.79-2.988,3.903c-0.473,0.425-1.16,0.857-0.822,1.537
			c0.309,0.625,1.101,0.473,1.739,0.49C403.048,488.878,404.422,487.149,406.33,487.562z"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          fill="#F58E9E"
          d="M372.659,498.624c-1.457,2.103-2.922,4.195-4.37,6.303
			c-1.374,1.999-1.074,3.589,1.182,4.68c0.876,0.951,0.333,1.798-0.175,2.656c-0.342,0.467-0.756,0.866-1.227,1.203
			c-2.322,0.916-5.297,0.931-5.806,4.333c-0.193,1.293-0.643,2.55,0.432,3.694c1.64,1.754,0.785,3.473-0.792,4.247
			c-4.487,2.196-3.447,6.333-3.458,9.819c-0.008,1.989,0.176,3.925-0.775,5.633c-1.528,2.745-1.554,5.65-0.526,8.317
			c0.956,2.479,0.363,4.354-1,6.174c-1.358,1.818-0.787,3.071,0.768,4.255c-2.036-0.207-2.904,4.197-4.307,2.247
			c-2.545-3.542-7.871-6.937-3.73-12.537c2.8-3.788,2.701-8.068,2.661-12.377c-0.013-1.325-0.817-2.854,1.75-2.358
			c0.781,0.15,2.746-0.618,1.657-1.759c-4.392-4.604,0.619-8.119,1.184-12.077c0.667-4.68,3.734-7.484,8.02-9.766
			c2.57-1.369-0.581-4.706-0.189-7.197c0.269-0.27,0.538-0.534,0.808-0.8c0.574-1.887,1.59-3.56,2.813-5.054
			C369.35,496.098,371.164,495.769,372.659,498.624z"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          fill="#F7A6B1"
          d="M372.659,498.624c-2.382-1.645-4.118-1.289-5.171,1.571
			c-0.499,1.353-1.34,2.496-2.724,3.119c-1.855-3.286-0.315-5.317,2.494-6.961c5.851-3.432,11.884-5.692,18.742-3.237
			c-1.761,1.656-3.982,2.096-6.268,2.363c-2.892-0.123-4.829,1.895-7.102,3.159L372.659,498.624z"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          fill="#F8ABBB"
          d="M401.723,475.026c0.993,4.667-3.058,10.523-7.073,10.23
			C396.916,481.782,398.217,477.643,401.723,475.026z"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          fill="#F8ABBB"
          d="M397.68,473.766c0.286,0.576,0.529,0.879,0.545,1.196
			c0.007,0.126-0.484,0.327-0.769,0.378c-0.08,0.016-0.352-0.404-0.325-0.596C397.17,474.476,397.405,474.232,397.68,473.766z"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          fill="#FDD88D"
          d="M279.083,405.042c0.269,0.013,0.538,0.022,0.807,0.032
			c0.283,0.269,0.572,0.614,0.527,0.978c-0.409,3.315,0.827,2.784,2.601,1.152c0.547-0.502,1.128-1.132,1.945-0.364
			c1.552,2.511-0.917,5.265,0.475,7.79c2.068,4.459,4.398,8.86,3.143,14.062c1.344,1.894,3.483,2.841,5.175,4.326
			c1.019,1.143,1.173,2.549,1.271,3.993c0.051,0.738-0.258,1.81,0.593,2.081c1.084,0.346,1.347-0.937,1.98-1.504
			c2.239-2.005,2.201-5.19,3.899-7.475c0.339-0.173,0.689-0.207,1.05-0.09c1.368,3.208-2.72,7.836,2.614,10.006
			c-2.913,3.328,0.414,9.741-5.759,11.537c-1.054,0.306-3.156,0.76-2.461,2.337c0.518,1.178,1.612-0.776,2.591-0.755
			c0.882,0.022,1.761,0.195,2.641,0.301c-0.239,0.691-0.269,1.628-0.748,2.027c-4.76,4.003-7.321,10.036-14.122,12.38
			c-5.402,1.862-10.134,4.925-16.045,2.566c-0.121-0.049-0.223-0.137-0.333-0.207c-0.867,2.015-1.913,4.657-4.442,2.694
			c-1.094-0.849-0.573-3.78-0.786-5.763c0.994-3.677,4.079,0.306,5.575-1.526c1.035-1.577,2.069-3.157,3.104-4.734
			c2.372-0.877,4.611,0.435,5.167,2.085c0.763,2.261,1.021,1.805,2.227,0.544c2.866-2.986,6.1-5.659,8.045-9.424
			c1.019-1.973,2.09-3.941,0.093-6.017c-1.007-1.046-0.174-2.508-0.602-3.715c-0.916-2.529-2.997-4.116-4.795-5.937
			c-2.534-2.565-2.337-5.812-2.23-9.037c0.573-0.354,1.222-0.139,1.827-0.243c-0.226-0.592-0.942-0.798-1.125-1.424
			c2.719-1.628,2.847-3.266,0.887-5.944c-1.607-2.198-2.545-5.09-3.085-7.809c-0.604-3.042-1.743-2.124-3.269-0.995
			C276.721,410.072,276.417,407.267,279.083,405.042z"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          fill="#F77442"
          d="M302.551,430.023c-0.234,0.064-0.466,0.141-0.692,0.233
			c-2.723,1.425-5.219,3.488-8.601,3.058c-1.879-1.216-5.067-1.109-4.679-4.623c2.482-0.642,5.248-0.807,7.369-2.06
			c2.383-1.412,4.042-1.088,5.912,0.438C302.09,428.053,302.321,429.038,302.551,430.023z"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          fill="#F5CBB6"
          d="M371.049,258.044c-2.673-2.386-6.556-4.111-2.614-8.845
			c1.593-1.917-0.385-4.683-1.43-6.9c-0.599-1.271-1.711-2.338-1.467-3.922c-0.002,0,0.008,0.003,0.008,0.003
			c2.35-2.649,6.057-2.409,8.88-4.13c1.089-0.665,2.564-0.857,2.956-2.223c1.654-5.771,6.881-6.385,11.387-8.065
			c10.521,0.864,18.687-4.219,26.382-10.477c1.229-0.847,2.125-2.39,3.984-1.916c0.262,0.291,0.493,0.624,0.56,1.011
			c0.897,5.15,5.491,7.626,9.789,4.753c5.739-3.833,11.519-7.776,14.741-14.322c0.666-1.353,1.206-3.954,3.341-2.87
			c2.334,1.178,1.725,3.794,0.527,5.531c-2.891,4.197-6.389,7.937-9.584,11.91c-1.81,2.252-3.831,4.29-6.436,5.665
			c-0.931,0.493-1.918,1.088-2.369,2.016c-2.209,4.549-7.454,6.523-9.442,11.241c-0.233,0.554-1.402,0.563-1.164,1.302
			c0.269,0.838,1.234,0.848,2.013,0.873c1.427,0.042,2.847-0.531,4.286-0.063c1.075,0.451,2.055,0.943,3.328,0.287
			c1.579-0.812,3.114-0.482,4.088,1.117c0.962,1.577,0.994,3.221-0.065,4.808c-0.515,0.774-1.175,1.427-1.948,1.916
			c-1.985,1.251-3.575,2.335-3.569,5.326c0.007,3.004-4.38,5.72-7.689,5.115c-4.032-0.736-7.442-0.02-10.572,2.69
			c-1.667,1.443-3.975,1.292-6.016,1.174c-2.107-0.125-2.654-1.615-2.392-3.672c0.791-6.181,0.746-6.162-5.096-3.849
			c-1.601,0.633-2.903,1.849-4.512,2.345c-2.212,0.681-4.75,0.745-5.972-1.453c-1.174-2.114,0.853-3.541,2.329-4.833
			c0.395-0.346,0.879-0.566,1.352-0.79c0.614-0.297,1.163-0.666,1.056-1.446c-0.075-0.56-0.47-0.943-1.012-1.059
			c-0.814-0.17-1.371,0.436-1.425,1.03c-0.294,3.313-4.077,3.352-5.36,5.816c-0.48,0.924-1.958,0.236-2.911-0.141
			c-3.623-1.433-5.274-4.434-6.197-8.452C371.708,249.292,376.205,254.321,371.049,258.044z"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          fill="#FDC1A8"
          d="M371.049,258.044c3.056-3.902,0.368-8.064,0.453-12.092
			c0.034-1.6-0.234-3.733,1.3-3.852c1.892-0.144,1.195,2.127,1.409,3.442c0.49,3.017,2.018,5.294,4.963,6.317
			c0.491,0.17,3.209,2.946,1.86-0.976c4.538,0.199,4.26-5.169,7.312-6.369c1.709-0.672,2.455,1.404,2.936,2.943
			c0.565,1.804-0.123,2.021-1.875,2.143c-1.883,0.128-3.011,1.926-3.903,3.525c-0.179,0.323,0.373,1.522,0.595,1.526
			c1.927,0.016,3.78,1.548,5.83-0.416c2.74-2.629,7.107-2.344,10.04-5.077c1.04-0.969,1.382,0.649,1,1.503
			c-0.651,1.453-1.234,2.783-0.616,4.456c0.608,1.648-3.019,4.13,0.075,4.828c1.894,0.428,4.795,0.601,7.102-2.086
			c1.817-2.121,5.619-2.821,9.101-1.971c5.322,1.299,8.285-1.756,6.751-6.817c-0.419-1.382,0.367-1.286,1.094-1.234
			c2.147,0.14,3.119-1.756,4.664-2.636c1.996-1.136,1.876-3.055,0.939-4.799c-0.968-1.804-2.273-1.139-3.596-0.204
			c-1.407,0.988-2.447,0.336-3.178-1.027c2.892-2.636,5.772-4.146,10.137-2.972c6.496,1.747,12.034-0.656,15.47-8.528
			c1.75-4.012,3.098-8.189,5.064-12.108c1.391-0.24,2.775-0.63,4.172-0.678c1.118-0.039,2.164,1.73,1.938,1.775
			c-5.549,1.059,1.521,6.529-2.825,7.984c-0.53,0.176-0.208,1.075,1.388,0.429c4.756-1.919,6.634,2.089,8.973,4.466
			c1.362,1.385-0.981,2.815-1.864,4.127c-1.247-0.694-2.271-1.389-3.69,0.105c-2.504,2.636-5.713,4.536-7.615,7.834
			c-1.408,2.44-4.112,3.183-6.656,3.784c-4.114,0.97-7.564,2.668-9.51,6.708c-0.963,1.996-3.052,2.751-4.832,3.838
			c-2.964,1.808-2.888,4.031,0.131,5.755c1.548,0.887,1.46,1.155-0.853,2.63c-0.262,0.009-0.526,0.019-0.788,0.029
			c-2.748,3.992-4.794,8.333-6.446,12.875c-0.232,0.637-0.732,1.065-1.409,1.251c-1.79-0.262-2.39-3.74-4.755-2.053
			c-2.337,1.666-5.079,3.429-3.866,7.098c0.816,2.476-0.052,4.19-2.132,5.412c0.976-9.069-6.101-15.624-15.657-15.143
			c-3.458,0.175-7.054-1.117-10.487-2.092c-1.895-0.541-1.715-1.721,0.079-2.543c0.595-0.275,1.868,0.112,1.516-1.104
			c-0.104-0.359-1.331-0.538-2.046-0.55c-3.504-0.055-7.01-0.025-10.548-0.598c6.257-0.848,12.514-1.683,18.77-2.546
			c1.191-0.163,2.896,0.243,2.601-1.916c-0.344-2.508-2.244-2.232-3.898-1.917c-8.949,1.711-17.916,3.266-27.074,2.981
			c-1.699-0.051-4.618,0.899-4.736-1.228c-0.115-2.073,3.262-0.228,4.083-1.242C368.883,259.875,373.833,262.706,371.049,258.044z"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          fill="#FDE4D4"
          d="M389.185,309.122c-4.384-1.96-9.795-0.636-13.983-4.59
			c-3.354-3.167-8.501-3.451-12.455-6.497c-3.193-2.457-6.475,2.005-9.388,4.076c-1.151,0.819-2.374,2.479-3.54,0.749
			c-0.909-1.347,1-2.617,1.948-2.793c4.768-0.879,4.216-7.137,8.621-8.253c0.264-0.067,0.405-0.617,0.602-0.94
			c-0.418-0.131-0.897-0.46-1.243-0.355c-1.146,0.352-2.752,0.992-3.02-0.46c-0.415-2.255,2.207-0.688,2.689-1.475
			c1.591-2.611,4.921-3.055,6.463-4.77c2.038-2.271,4.397-2.958,6.732-4.101c2.191-1.071,4.811-2.473,4.33,2.137
			c-0.157,1.494,1.034,1.522,1.802,1.184c1.72-0.758,4.386,0.022,5.067-2.175c0.366-1.181-1.542-1.363-2.669-1.577
			c-0.694-0.131-1.692,0.201-1.707-0.959c-0.001-0.115,1.075-0.269,1.658-0.362c2.115-0.335,2.791-3.33,5.568-2.69
			c7.177,1.654,14.765,1.28,21.664,4.312c1.247,0.546,1.388,1.615,0.212,2.421c-0.928,0.64-2.422,0.771-1.959,2.406
			c0.427,1.516,1.787,1.779,3.114,1.9c1.347,0.125,3.245,0.352,2.764,1.909c-0.717,2.313-1.851-0.24-2.854-0.262
			c-0.593-0.013-1.437-0.252-1.736,0.042c-0.841,0.834,0.13,1.229,0.638,1.798c2.428,2.722,4.229,4.917-0.979,7.201
			c-2.203,0.963-2.397,5.576-6.588,4.961c-0.656-0.096-0.524,1.193,0.223,1.798c0.437,0.355,1.081,1.475,0.177,1.28
			C396.422,303.981,394.484,311.266,389.185,309.122z"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          fill="#FDE1CD"
          d="M505.44,302.894c0.271,2.079,0.54,4.158,0.811,6.234
			c-0.859,0.774-1.901,0.672-2.922,0.576c-3.238-0.314-6.403-0.118-9.48,1.078c-1.058,0.413-2.208,0.812-3.273-0.09
			c-1.078-1.98-1.448-4.232-0.061-5.944c1.986-2.446-0.324-2.866-1.39-3.918c-1.819-1.798-2.582,0.496-3.324,1.199
			c-1.08,1.027-1.677,2.614-2.332,4.031c-4.162,8.966-11.087,15.217-20.523,17.309c-4.334,0.963-9.255,5.237-13.354,1.971
			c-4.753-3.788-9.114-0.931-13.611-1.325c-1.718-0.15-3.916,2.422-4.526,0.554c-0.518-1.583,2.773-1.628,4.24-2.578
			c6.108-3.957,12.968-6.478,19.026-10.697c4.798-3.346,10.884-4.92,16.509-6.98c9.439-3.458,17.411-9.44,25.761-14.741
			c3.74-2.374,5.696-1.581,6.981,2.329C506.124,295.344,505.145,299.206,505.44,302.894z"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          fill="#FDE2CD"
          d="M468.543,255.684c7.345-1.065,14.706-2.934,22.03-0.054
			c1.506,0.591,3.634,1.52,3.958,2.707c0.396,1.455-2.036,1.919-3.212,2.828c-1.808,1.395-3.331,3.282-5.783,3.682
			c3.745,6.395-2.224,1.184-2.775,2.332c-1.924,4.005-6.288,3.95-9.499,5.799c-6.749-1.184-7.594,6.632-12.529,8.222
			c-0.691,0.224-1.455,0.94-1.988,0.78c-1.187-0.358-1.017-1.251-0.318-2.13c0.171-0.217,0.14-0.947,0.013-0.998
			c-5.836-2.492-7.232-7.658-8.075-13.199c-0.052-0.352-0.619-0.63-0.946-0.94c-0.273,0.371-0.835,0.81-0.765,1.101
			c0.341,1.449,0.131,2.575-1.332,3.241c-2.418,0.032-3.063,5.304-6.556,2.201c-0.951-0.844-1.697-2.776-0.482-2.61
			c6.671,0.915,4.753-4.913,5.143-7.31c0.926-5.704,4.944-6.606,9.084-7.873c0.635-0.195,2.24-0.672,1.343,1.158
			c-0.49,0.998,0.158,1.437,0.693,1.983C458.285,258.377,467.414,257.674,468.543,255.684z"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          fill="#FDC5CC"
          d="M490.575,310.693c5.064-2.14,10.219-3.366,15.676-1.564
			c0.975,3.337,3.072,6.424,2.216,10.157c0.98,2.191-0.952,4.136-0.517,6.283c0.225,7.754,4.493,13.298,9.925,18.202
			c4.066,3.669,4.148,5.358,0.401,9.229c-2.623,1.725-5.82,2.281-8.365,4.197c-0.867,0.657-1.928,0.8-2.847-0.102
			c-1.335-2.761-2.596-5.537-5.301-7.361c-0.709-0.479-2.129-1.247-1.15-2.427c0.746-0.902,1.589,0.083,2.348,0.572
			c0.972,0.624,1.616,2.738,3.07,1.43c1.598-1.433,3.412-3.225,3.897-5.17c0.908-3.631-5.854-13.886-9.265-14.84
			c-0.358-0.103-0.839,0.234-1.263,0.365c0.192,0.441,0.266,1.069,0.6,1.283c0.83,0.521,2.4,0.607,1.857,1.967
			c-0.422,1.059-1.726-0.163-2.409,0.058c-6.017,1.933-3.598-2.143-3.213-4.539c0.306-1.904-0.879-1.872-2.061-1.98
			c-0.914-0.083-2.143-0.007-2.207-1.075c-0.032-0.537,1.178-1.132,1.79-1.737c0.843-0.832,3.026-1.225,1.763-2.851
			c-0.775-0.991-1.51-4.623-4.098-1.378c-0.237,0.301-1.352,0.346-1.674,0.064c-4.844-4.213-7.955-0.272-11.347,2.341
			c-2.88,2.217-4.183,5.538-5.925,8.547c0.051-7.718,3.71-12.975,10.768-16.02C485.749,313.265,488.136,311.915,490.575,310.693z"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          fill="#FDC7B0"
          d="M332.554,278.479c-1.973-4.258-4.034-8.487-3.981-13.346
			c-0.029-1.679,1.422-2.239,2.39-3.164c0.254-0.013,0.507-0.022,0.761-0.026c2.556-1.842,1.785-4.293,1.247-6.721
			c-0.532-2.406-0.854-4.757,2.315-5.671c3.32,0.86,5.309,3.169,6.674,6.151c-0.645,1.74-4.538,2.498-2.784,4.405
			c2.684,2.921,2.983,9.747,6.396,9.101c9.808-1.852,19.23,6.714,28.826,0.471c1.964,3.045,4.772,1.059,7.22,1.27
			c0.244,0.02,0.692,1.046,0.592,1.145c-0.619,0.611-1.31,1.42-2.086,1.564c-5.301,0.963-10.646,1.418-15.923-0.061
			c-4.976-1.395-8.533,1.286-12.163,3.91c-1.455,1.049-2.875,2.862-4.4,2.962c-4.063,0.258-7.228,1.103-9.165,5.22
			c-0.728,1.548-1.903-0.08-2.48-1.091C334.832,282.568,333.698,280.521,332.554,278.479z"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          fill="#FCF0E6"
          d="M341.96,255.703c-2.092-1.843-4.183-3.686-6.273-5.528
			c-0.876-0.698-2.264-0.883-2.37-2.354c-0.255-4.485-0.509-8.967-0.763-13.449c1.042,0.282,2.083,0.563,3.124,0.845
			c2.164,1.379,4.695,2.073,7.044,1.497c4.067-0.995,8.22,0.387,10.827,2.166c3.104,2.118,6.08,5.611,8.082,8.961
			c1.398,2.338-1.896,5.793-1.541,9.296c0.123,1.219-3.427,1.711-5.548,1.702C350.348,257.792,346.154,256.749,341.96,255.703z"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          fill="#FDE9D8"
          d="M426.088,278.479c2.112-4.99,2.646-10.86,7.855-14.126
			c1.301,3.013,1.336,5.918-0.767,8.631c-0.705,2.939-0.929,6.167-4.928,6.801C426.974,279.989,426.264,279.823,426.088,278.479z"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          fill="#FD98AF"
          d="M505.44,302.894c-1.681-3.503-1.501-7.258-1.471-10.992
			c0.245-0.806,0.489-1.612,0.734-2.415c-0.371-2.447,1.243-3.305,3.144-3.932c0.774-0.016,1.551-0.028,2.324-0.045
			c1.097,0.144,2.34-0.284,3.187,0.826c-7.134-0.109-5.102,5.953-6.122,9.628C506.606,298.236,506.97,300.802,505.44,302.894z"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          fill="#F9D8C4"
          d="M507.848,285.556c-1.049,1.308-2.097,2.62-3.144,3.932
			c-0.522-1.312-1.046-2.62-1.57-3.928C504.705,285.556,506.276,285.556,507.848,285.556z"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          fill="#9DC390"
          d="M313.739,262.117c-0.19-0.822,0.154-1.75-1.029-2.271
			c-7.275-3.199-8.649-5.592-6.338-10.755c1.907-4.258,7.67-5.709,13.336-3.381c0.597,0.247,1.242,0.369,1.863,0.551
			c-0.536,3.141-1.071,6.286-1.607,9.431c-3.411,0.713-4.239,3.691-5.511,6.282C314.219,262.037,313.981,262.085,313.739,262.117z"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          fill="#FDD3BE"
          d="M333.317,247.821c0.79,0.784,1.58,1.567,2.37,2.354
			c-3.532,1.187-2.04,3.65-1.368,5.771c0.896,2.835,1.08,5.211-2.596,5.998c-0.032-4.481-2.179-8.796-0.826-13.422
			C331.482,246.525,332.073,246.554,333.317,247.821z"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          fill="#C6DBB4"
          d="M314.453,261.973c0.063-3.65,0.019-7.396,5.511-6.282
			c0.281,2.54-0.187,4.91-1.603,7.066C317.058,262.495,315.756,262.232,314.453,261.973z"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          fill="#FDD3BE"
          d="M330.963,261.97c-0.249,1.468-0.616,2.847-2.39,3.164
			c-1.012-1.052-1.114-2.348-0.312-3.404C329.062,260.674,330.07,261.657,330.963,261.97z"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          fill="#9DC390"
          d="M321.631,245.591c-0.521-2.227-0.472-4.392,0.72-6.427
			C322.234,241.321,322.781,243.55,321.631,245.591z"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          fill="#FCF5EB"
          d="M285.438,414.63c-3.937-2.233,0.467-5.497-0.996-8.036
			c-0.641-1.209-0.041-1.853,1.041-2.274c0.443-0.506,0.884-1.008,1.326-1.507c0.969,2.972,2.38,3.943,4.923,1.296
			c0.848-0.883,2.442-2.243,3.332-1.079c1.158,1.517,0.94,3.82,0.252,5.65c-0.604,1.613-1.922,0.682-2.979,0.221
			C286.256,406.253,287.308,412.2,285.438,414.63z"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          fill="#FDC58B"
          d="M294.86,393.159c1.282,0.058,2.563,0.118,3.844,0.176
			c2.129,2.114,3.966,4.261,0.841,7.006c-1.58-1.782-3.155-0.198-4.734,0.006C294.619,397.951,296.155,395.564,294.86,393.159z"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          fill="#FDDEA1"
          d="M289.312,444.36c3.356,8.059,1.957,13.146-5.141,18.055
			c-0.95,0.656-1.583,1.384-1.823,2.385c-0.6,2.5-3.207,0.111-4.108,1.763c-1.046-1.296,0.925-1.536,0.856-2.323
			c-0.251-2.866-3.408-1.844-4.715-3.353c-4.353-1.609-1.401-6.389-3.816-8.85c-0.082-0.085,0.129-0.457,0.203-0.689
			c0.385,0.243,0.893,0.403,1.137,0.747c1.124,1.591,2.138,3.265,3.262,4.856c3.997,5.679,7.41,4.584,11.909-1.377
			C290.021,451.671,287.449,447.803,289.312,444.36z"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          fill="#F3774F"
          d="M282.988,427.72c1.482,0.237,3.134,0.185,3.757,1.984
			c0.092,0.271-0.112,0.895-0.33,0.984c-1.7,0.685-2.786-0.74-4.128-1.301C282.521,428.833,282.754,428.277,282.988,427.72z"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          fill="#FDBE52"
          d="M285.483,404.32c-0.345,0.757-0.693,1.516-1.04,2.274
			c-0.835,2.814-3.098,4.276-5.43,2.996c-2.075-1.138,1.844-2.687,0.875-4.516C281.674,404.24,283.495,403.661,285.483,404.32z"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          fill="#F1C1A7"
          d="M455.976,215.566c-0.28,7.159-4.077,13.033-7.742,18.656
			c-2.001,3.071-6.69,4.488-10.235,3.541c-4.696-1.254-8.617-0.419-12.694,1.411c-2.395,0.055-4.789,1.68-7.192-0.058
			c-0.785-0.566-1.52-1.145-1.322-2.188c0.216-1.136,0.861-1.507,1.997-0.883c0.245,0.131,0.988-0.167,1.169-0.467
			c6.546-10.873,18.307-16.996,25.16-27.588c0.489-0.758,1.336-1.282,1.824-2.038c1.017-1.57,2.164-4.084,0.599-4.923
			c-1.979-1.065-2.024,1.903-2.658,3.113c-3.557,6.788-9.737,10.844-15.796,14.763c-4.796,3.1-10.46-1.283-10.084-7.245
			c-1.113-1.654,0.202-2.662,1.091-3.663c1.372-1.539,2.794-3.036,4.134-4.6c3.739-4.783,8.083-8.963,8.978-15.704
			c0.453-3.417,2.251-6.593,0.752-10.176c-0.176-0.419,0.243-0.985,0.577-1.398c0.565-0.403,1.396-0.732,1.817-0.297
			c3.154,3.244,6.974,6.407,6.764,11.331c-0.147,3.49,1.511,6.298,2.02,9.488c0.283,1.763,1.878,1.532,3.17,1.469
			c1.471-0.074,2.653-0.762,2.567-2.358c-0.125-2.277,0.934-3.26,3.009-2.946c2.184,0.329,4.65,0.755,4.773,3.669
			c0.08,1.929,0.268,3.839,0.468,5.751C458.139,206.689,455.913,210.857,455.976,215.566z"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          fill="#F5CBB6"
          d="M472.486,201.423c-1.688,0.31-3.862-0.278-3.63,2.86
			c0.07,0.963-1.447,2.495-2.593,0.269c-0.06-0.116-0.075-0.355-0.119-0.358c-8.752-0.384-4.458-7.118-5.466-11.257
			c-0.225-0.921-0.099-1.986-1.271-2.386c-0.1,0.745-0.199,1.49-0.302,2.239c-2.711,0.963-3.74-0.86-3.849-2.873
			c-0.151-2.901,0.438-5.751,3.696-7.002c2.395,0.397,1.34,2.409,1.72,3.743c0.186,0.653,0.403,1.248,1.157,1.363
			c0.465,0.073,0.63-0.349,0.857-0.659c2.958-4.021,2.24-6.286-2.406-7.543c-0.37-0.141-0.645-0.387-0.834-0.736
			c-0.44-2.281,0.839-3.214,2.821-3.595c1.409,0.694,2.773,1.51,4.239,2.044c1.353,0.489,3.294,0.889,3.795-0.733
			c0.623-2.021-1.537-0.943-2.396-1.372c-0.724-0.361-1.633-0.697-0.997-1.647c0.882-1.321,2.14-2.822,3.824-2.012
			c1.945,0.941,3.385,2.691,3.718,5.003c0.084,0.583-1.347,1.353,0.062,1.769c0.78,0.228,1.822,0.416,2.381-0.71
			c1.838-3.705,3.931-0.308,5.693,0.054c2.338,0.477,1.79,3.794,3.64,5.093c-1.716,0.538-3.581,1.763-5.106,1.427
			c-1.665-0.364-0.156-1.474,0.51-2.159c0.363-0.374,0.729-0.953-0.079-1.136c-0.744-0.166-1.568-0.112-2.334-0.003
			c-0.969,0.141-1.281,0.851-1.002,1.734c0.334,1.072,0.623,2.201-0.794,2.546c-1.322,0.32-1.186-0.873-1.504-1.724
			c-0.464-1.241-1.556-1.276-2.597-0.63c-1.606,1.001-2.222,2.329-1.657,4.299c0.5,1.747,1.191,1.174,2.232,0.599
			c1.899-1.053,2.821,0.431,3.063,1.788c0.27,1.523-0.933,1.28-2.19,1.715c-2.723,0.947-2.223,4.29-2.278,6.884
			C473.418,199.354,473.413,200.39,472.486,201.423z"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          fill="#F5CBB6"
          d="M459.89,183.355c-3.366,0.708-3.247,3.762-3.771,6.104
			c-0.422,1.894,1.061,3.039,2.987,3.33c0.003,3.145,0.008,6.292,0.013,9.437c-1.882-2.409,1.008-7.021-3.705-8.097
			c-1.203-0.275-3.258-2.156-2.618,1.571c0.187,1.095-1.724,4.6-3.468,3.599c-1.708-0.979-5.139,3.244-5.673-2.748
			c-0.607-6.826-1.488-14.421-7.091-19.827c-0.39-0.375-1.207-0.308-1.826-0.445c-1.729-2.441-1.208-4.389,1.425-5.026
			c1.241-0.301,2.566-0.112,3.812-0.454c6.314-2.39,10.495,1.586,14.176,7.229c1.409,2.163,1.979,3.468,4.279,1.302
			c0.353-0.333,0.985-0.202,1.46,0.089l0.025,0.016C461.095,180.752,461.036,182.057,459.89,183.355z"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          fill="#F8C09B"
          d="M459.89,179.421c-3.418,1.948-4.082,1.612-7.058-1.836
			c-3.264-3.781-6.461-8.071-12.586-6.027c-0.951-0.668-1.646-1.657-2.829-2.044c-1.215-0.396-2.771-0.694-2.753-2.329
			c0.017-1.581,1.575-1.916,2.739-2.425c0.868-0.38,1.364-1.212,2.196-1.606c2.389-0.982,5.11-0.72,7.408-2.016
			c1.287-0.96,2.081-2.885,4.221-2.172c2.014,0.992,4.108,1.491,3.424,4.888c-0.416,2.063,3.349,1.203,4.271,3.147
			c0.473,0.998,1.342,1.811,2.031,2.706c-3.689,2.745-0.556,3.705,1.303,5c-0.004,0.259-0.001,0.519,0.011,0.778
			C461.667,176.913,459.401,177.335,459.89,179.421z"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          fill="#FDD2BE"
          d="M434.731,264.324c-0.349-0.637,2.605-1.126,0.224-1.596
			c-1.452-0.285-3.822-1.004-3.431-1.504c1.422-1.82-0.823-5.061,2.371-5.873c3.434-0.87,5.381-2.901,6.812-6.25
			c0.798-1.872,3.647-4.204,5.433-4.092c8.4,0.525,10.589-7.46,16.011-10.873c0.651-0.41,1.074-1.359,1.737-1.526
			c1.67-0.422,2.043-3.336,3.945-2.479c1.655,0.746-1.919,2.255-0.076,3.539c-2.869,2.578-4.808,7.108-7.392,8.442
			c-4.296,2.216-8.177,5.042-12.346,7.39c-3.081,1.733-4.798,5.707-9.076,5.569c-0.175-0.002-0.367,0.544-0.55,0.835
			c0.588,0.192,1.165,0.426,1.764,0.573c2.445,0.598,3.461,1.273,0.387,2.748c-1.31,0.63-1.259,1.849-0.396,2.981
			c0.982,1.283,2.821,2.594,0.818,4.229c-1.942,1.583-3.6,0.477-4.977-1.206C435.674,264.842,435.155,264.622,434.731,264.324z"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          fill="#FCF3E8"
          d="M472.486,201.423c0.001-1.037,0.004-2.073,0.005-3.11
			c3.464-1.522,7.975,0.02,10.697-3.864c0.787-1.123,1.569,0.128,1.948,0.938c0.327,0.697,0.775,1.529,0.655,2.21
			c-1.34,7.557-0.741,15.848-8.113,21.152c-1.105,0.797-1.754,0.88-2.968,0.455c-1.724-0.608-0.776-1.641-0.67-2.527
			c0.411-3.35,0.807-6.701,1.329-10.032C475.771,204.091,473.491,203.135,472.486,201.423z"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          fill="#FDE2CD"
          d="M466.761,254.558c1.125-0.787,1.888-1.401,2.736-1.846
			c0.171-0.089,0.679,0.471,1.03,0.73c-0.787,0.726-1.549,1.484-2.396,2.133C468.039,255.645,467.465,255.092,466.761,254.558z"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          fill="#FDECA6"
          d="M617.947,199.03c-1.766,1.59-5.418,2.271-2.309,6.005
			c0.917,1.1-0.996,1.142-1.697,1.078c-5.166-0.482-10.281-1.148-15.516-0.214c-1.395,0.247-3.815-0.262-4.07-2.786
			c-0.117-1.167-0.59-1.123-1.545-0.675c-2.748,1.295-2.606-0.832-2.262-2.31c0.426-1.826,0.267-2.76-1.841-2.658
			c-0.839,0.042-1.685-0.058-2.527-0.096c0.45,0.585,1.009,1.117,1.329,1.763c1.196,2.402,1.347,4.057-0.201,7.102
			c-1.738,3.417-2.69,7.844-3.747,11.894c-0.137,0.521-0.258,1.98,1.124,2.131c1.378,0.15,2.832,0.016,3.271-1.491
			c1.297-4.443,4.559-5.979,8.756-6.301c-0.34,8.425-3.303,15.821-8.787,22.2c-1.143,1.327-0.045,2.086,0.476,3.058
			c0.522,0.976,0.813,2.3-0.131,2.863c-1.526,0.911-1.249-1.068-1.91-1.625c-0.682-0.572-1.263-1.634-1.975-1.714
			c-1.375-0.157-0.905,1.213-1.076,2.012c-0.945,4.45-3.417-1.536-4.688,0.685c-0.486-2.39-1.452-4.802-1.355-7.168
			c0.171-4.063-2.359-6.926-5.269-7.211c-6.162-0.604-7.26-7.246-12.689-9.069c-3.035-1.02-4.491-6.75-6.658-10.354
			c6.08-4.882,13.043-7.991,20.448-10.221c5.126-2.191,10.551-2.293,15.992-2.332c1.066-0.006,2.062,0.179,3.032,0.617
			c7.048,3.196,14.396,4.271,22.075,3.225C615.655,197.235,617.403,196.852,617.947,199.03z"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          fill="#FDF9D1"
          d="M617.947,199.03c-2.278-1.913-4.416,0.464-6.547,0.234
			c-6.847-0.743-13.684-0.899-20.261-4.056c-3.29-1.581-8.004-0.695-11.97-0.154c-1.997,0.272-3.982,0.959-6.071,0.87
			c10.122-4.546,20.235-9.181,31.81-6.034c4.001,1.091,8.085,0.135,12.088,0.602c2.119,0.247,4.776-0.255,4.66,3.785
			c-0.065,2.258,3.144,1.392,4.953,1.708C624.143,198.198,620.353,196.644,617.947,199.03z"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          fill="#F9D8C4"
          d="M520.451,199.306c-0.646,0.096-1.039,0.278-1.271,0.157
			c-0.225-0.119-0.285-0.554-0.416-0.849c0.229-0.063,0.513-0.246,0.681-0.169C519.736,198.573,519.957,198.864,520.451,199.306z"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          fill="#FAFAF3"
          d="M562.92,235.546c1.05-1.369-0.182-4.648,3.266-4.168
			c1.975,0.272,4.743,1.359,4.165,3.237c-0.785,2.553-4.672,1.433-6.72,3.266C563.171,238.294,563.057,236.503,562.92,235.546z"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          fill="#F9ECD0"
          d="M396.191,153.446c5.755-3.468,10.74-9.322,18.697-6.021
			c0.298,0.122,0.829-0.32,1.251-0.5c-0.317-0.531-0.615-1.075-0.955-1.593c-0.68-1.037-2.611-1.629-1.967-3.052
			c0.724-1.603,2.646-1.485,4.195-1.331c3.229,0.319,6.719,2.143,9.008-1.984c0.146-0.266,1.177-0.038,1.798-0.038
			c-1.152,4.945,5.991,2.624,5.921,6.714c-0.007,0.342,0.848,0.905,1.374,1.014c5.059,1.062,5.064,1.04,2.287,4.77
			c-0.892,1.197,0.129,1.654,0.904,2.022c1.271,0.602,2.618,1.04,3.933,1.551c-0.105,0.17-0.209,0.343-0.312,0.515
			c-3.948,2.357-6.201,1.974-9.895-1.683c-4.483-4.44-6.513-4.782-11.705-1.068c-2.884,2.06-5.299,2.639-8.493,0.636
			c-2.936-1.836-6.227-1.388-9.396-0.041c-2.14,0.905-4.347,1.967-6.588,0.169L396.191,153.446z"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          fill="#DDCFD7"
          d="M408.657,122.162c-1.619,2.405-3.625,3.843-6.004,4.608
			c-2.689,0.86-5.578,1.116-7.78,3.202c-1.014,0.959-1.913,0.32-2.647-0.553c-0.923-1.097-0.336-1.974,0.533-2.659
			c4.303-3.4,9.188-5.191,14.699-5.139C407.824,121.624,408.188,121.94,408.657,122.162z"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          fill="#DAC8D0"
          d="M385.421,129.146c2.118-0.541,2.348,0.259,1.854,1.299
			c-1.977,4.148-5.024,7.31-9.209,9.238c-1.793,0.829-2.868-0.429-2.078-1.839C378.266,133.769,380.242,129.098,385.421,129.146z"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          fill="#DACCD5"
          d="M425.084,124.641c-1.507,1.208-2.843,2.48-4.376,3.431
			c-0.854,0.527-2.535,0.63-2.847-0.336c-0.843-2.622-2.657-1.874-4.403-1.762c-0.934,0.061-1.922,0.008-2.17-1.173
			c-0.243-1.161,0.176-2.244,1.313-2.533c4.083-1.042,8.006-0.312,11.761,1.46C424.642,123.858,424.794,124.261,425.084,124.641z"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          fill="#D7C6D3"
          d="M436.368,128.257c-2.161,3.314-5.754,2.518-8.447,3.733
			c-0.914,0.413-1.997-0.889-1.63-2.038c0.808-2.524,2.264-5.212,4.994-5.211C432.948,124.743,434.608,126.963,436.368,128.257z"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          fill="#D5C7D2"
          d="M374.221,161.379c0.165,2.399-1.424,3.602-3.194,4.68
			c-0.021-1.679,0.43-3.413-0.659-4.956c-1.011-1.433-1.392-2.892,0.233-4.133c0.952-0.726,2.019-1.206,3.26-0.473
			c2.402,1.424,0.12,2.604-0.125,3.906C373.682,160.688,374.048,161.053,374.221,161.379z"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          fill="#FCE5CD"
          d="M451.229,158.967c-1.55,0.557-2.519,1.689-3.136,3.167
			c-3.293,1.226-3.107-1.321-3.516-3.225c-0.112-0.317-0.249-0.63-0.422-0.925c-0.377-1.04-1.956-1.436-1.571-2.892l0.052-0.093
			c3.271-1.094,6.061,2.972,9.423,0.876C451.784,156.907,451.505,157.937,451.229,158.967z"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          fill="#DAC3CB"
          d="M370.305,150.81c-0.124-2.118,0.285-3.583,2.637-3.615
			c1.488-0.023,2.192,0.607,2.067,2.092c-0.183,2.188-1.594,3.935-3.407,4.261C369.718,153.89,370.574,151.702,370.305,150.81z"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          fill="#DCD0D8"
          d="M386.363,144.434c1.774,0.595,3.268,1.373,3.513,3.301
			c0.164,1.295-0.454,2.489-1.842,2.405c-1.82-0.105-3.058-1.404-3.433-3.134C384.324,145.729,385.335,144.956,386.363,144.434z"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          fill="#D8CCD6"
          d="M400.449,135.417c1.425,0,2.564,0.252,2.517,1.577
			c-0.062,1.798-1.337,2.754-3.026,2.988c-1.267,0.176-2.032-0.653-1.974-1.833C398.046,136.504,399.225,135.723,400.449,135.417z"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          fill="#D6C9D2"
          d="M418.889,137.067c-0.759,1.369-1.695,2.77-3.583,2.898
			c-0.872,0.061-1.522-0.557-1.225-1.491c0.536-1.686,1.527-3,3.515-3.036C418.542,135.419,419.002,135.944,418.889,137.067z"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          fill="#DFD8DD"
          d="M408.862,138.308c-0.957-0.198-1.638-0.807-1.3-1.606
			c0.543-1.292,1.425-2.604,3.042-2.524c1.014,0.051,1.487,1.024,1.161,2.028C411.312,137.598,410.06,137.995,408.862,138.308z"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          fill="#F5DBAA"
          d="M424.538,203.8c-0.602,3.497-4.96,4.344-5.539,7.86
			c-1.042,0.787-2.084,1.57-3.125,2.358c-1.432,0.252-1.381-0.868-1.491-1.708c-0.12-0.918,0.452-1.792,0.435-2.684
			c-0.046-2.416-1.342-4.779-3.375-5.163c-2.279-0.429-3.68,2.015-3.731,4.168c-0.215,8.938-6.147,12.287-13.541,14.292
			c-1.782,0.483-3.452,1.155-5.029,2.092c-4.392,1.695-9.261,2.085-11.076,8.125c-0.995,3.308-6.187,2.569-9.162,4.405
			c-0.936,0.579-2.229,0.576-3.357,0.835c1.288-1.581-0.9-7.735,4.91-3.65c0.547,0.384,3.35-1.427,4.205-2.786
			c0.867-1.375-0.94-2.784-2.107-3.247c-5.322-2.131-8.724-5.372-11.08-11.021c-0.826-1.974-0.703-6.309-4.314-7.297
			c2.059-2.927,0.023-4.396-1.409-6.967c-2.793-5.01,3.55-13.717,9.088-13.711c1.251,2.949-1.513,4.715-2.229,7.076
			c-1.63,5.365-1.533,6.254,3.533,8.807c0.297,0.23,0.521,0.515,0.682,0.854c0.137,0.516,0.28,1.318-0.083,1.484
			c-6.169,2.809-0.382,7.127-1.264,10.633c-0.256,1.021,1.607,1.878,2.888,2.278c3.244,1.011,6.409,2.258,9.494,3.663
			c2.957,1.347,5.527,1.114,7.873-1.18c1.316-1.29,2.927-2.182,4.427-3.225c0.309-0.205,0.64-0.364,0.983-0.492
			c2.638-0.483,5.366-0.336,7.713-2.23c2.511-2.031,4.46-4.024,3.277-7.553c-0.458-1.366-0.051-2.613,0.902-3.608
			c1.043-1.087,1.564-2.367,1.786-3.81c0.155-0.515,0.383-1.001,0.636-1.471c1.185-1.296-0.242-3.148,0.939-4.444
			c1.999-0.799,3.539,0.167,4.972,1.357c1.303,1.081,1.787,0.652,1.859-0.852c0.099-2.073-0.358-3.871-2.279-5.044
			c-2.788-1.702-5.333-3.733-7.767-5.902c-1.546-1.379-3.152-1.772-4.886-0.311c-2.492,1.129-4.375,0.928-4.899-2.226
			c1.229-1.571,1.497-2.959,0.613-5.093c-1.603-3.865-0.239-7.403,2.812-9.396c0.802,0.423,0.895,1.209,1.039,1.984
			c1.478,7.927,6.833,12.854,14.902,13.602c1.958,0.179,3.884,0.521,5.815,0.831c3.519,0.569,4.049,1.625,2.506,4.693
			c-0.176,0.349-0.422,0.659-0.601,1.008c-2.375,4.657-2.121,5.902,1.728,9.44C423.188,201.475,424.935,201.884,424.538,203.8z"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          fill="#F6DDAF"
          d="M397.011,169.21c-2.974,3.736-4.309,7.431-0.003,11.218
			c1.261,1.11,1.116,1.271-3.153,2.934c-0.181,0.192-0.408,0.512-0.59,0.479c-5.616-1.014-9.69,2.832-14.489,4.405
			c-3.701,1.216-7.798,1.283-11.665-0.188c-0.404-5.074,4.201-2.761,6.173-3.557c2.422-0.976,4.113-1.212,4.24-4.271
			c0.205-4.907,5.281-1.846,7.503-4.287c2.116-2.326,4.715-3.522,4.198-7.588c-0.174-1.372,0.654-6.644,5.491-4.257
			c-1.194-0.912-2.46-1.744-3.563-2.754c-1.024-0.938-2.321-1.827-1.73-3.615c0.565-1.728,1.869-1.974,3.421-1.916
			c1.663,0.063,2.592-1.04,3.405-2.285c2.537,0.154,4.84-0.444,7.147-1.596c2.807-1.401,6.132-1.67,8.623,0.154
			c3.468,2.537,5.771,1.427,8.832-0.707c4.025-2.802,8.298-4.431,11.986,1.229c2.23,3.423,5.754,4.565,9.747,2.485
			c0.793,0.774,1.586,1.548,2.381,2.323c-1.541,1.404-3.623,1.292-5.455,2.108c3.086,2.671,3.785-1.056,5.444-2.117
			c1.812,1.067-0.71,5.009,3.139,4.728c-2.774,1.097-5.672,1.542-8.638,1.593c-2.946,1.04-4.644-1.366-6.286-2.806
			c-1.996-1.753-4.456-2.617-6.417-4.296c-0.924-0.79-1.891-0.751-2.865-0.131c-3.842,2.441-7.562,1.631-11.353-0.233
			c-3.083-1.517-5.749-0.678-7.574,2.102c-0.331,0.217-0.716,0.435-1.087,0.365c-3.215-0.611-3.407,0.23-1.46,2.629
			c1.313,1.619,0.548,3.483-0.172,5.202c-1.4,1.049-2.678,2.277-4.279,3.052C397.59,169.604,397.272,169.476,397.011,169.21z"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          fill="#EEB967"
          d="M366.363,206.113c-5.88-0.966-7.064-3.935-5.018-9.088
			c1.016-2.559,3.089-4.549,3.494-7.322c0.214-0.061,0.434-0.106,0.654-0.138c2.477-0.236,4.841,0.045,7.413-1.132
			c2.429-1.113,4.71,0.701,4.154,4.907c2.078-3.506,5.153-4.724,6.818-7.459c0.849-1.395,2.847-1.136,4.358-1.276
			c2.911-0.272,5.357,1.612,8.138,2.085c0.371,0.064,0.323,0.918,0.215,1.443c-1.566,3.522-2.108,7.425-4.361,10.726
			c-1.572,2.303-2.332,5.339-6.123,4.424c-0.558-3.25-3.29-2.208-5.251-2.594c-1.284-0.252-1.875,0.637-2.14,1.782
			c-0.474,2.035,0.061,3.558,2.034,4.555c1.215,0.611,2.195,1.517,1.545,3.135c-0.843,0.928-2.116,0.95-3.143,1.494
			c-1.43,0.758-2.939,2.368-4.485,0.524c-1.371-1.631-2.18-3.723-1.067-5.809c1.584-2.968,0.67-4.587-2.337-5.617
			c-3.386-1.159-5.16,0.179-4.815,3.835C366.495,205.108,366.601,205.614,366.363,206.113z"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          fill="#F3D294"
          d="M396.135,188.17c-3.592-3.442-10.04-2.866-12.81,0.588
			c-1.645,2.048-4.933,2.697-5.308,6.011c-0.026,0.237-0.959,0.662-1.264,0.528c-1.204-0.528-1.014-1.715-0.932-2.732
			c0.109-1.321,1.286-2.936-0.917-3.55c-1.641-0.454-2.952-0.032-3.986,1.599c-2.559,4.047-3.343-2.018-5.426-1.049
			c0.54-0.501,1.08-1.004,1.619-1.506c6.238-0.633,12.801,0.547,18.116-4.488c2.395-2.265,5.729,0.115,8.628-0.208
			c0.782,2.892,2.778,2.144,4.713,1.574c1.524,1.18,2.681,2.508,1.878,4.635c-1.117,1.062-2.27,0.562-3.425,0.08
			C396.479,189.306,396.075,188.877,396.135,188.17z"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          fill="#F7F5ED"
          d="M239.82,641.807c-0.24,4.107-8.903,10.994-12.692,10.087
			C231.359,648.53,235.589,645.169,239.82,641.807z"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          fill="#B5BA7C"
          d="M224.825,616.541c0.835,0.691,2.127-1.952,2.703,0.353
			c0.166,0.668,0.617,1.701-0.59,1.833c-1.491,0.162-1.98-0.906-2.104-2.195L224.825,616.541z"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          fill="#FDD2C6"
          d="M509.429,465.603c1.399-7.854,3.429-15.424,9.43-21.231
			c4.344-0.256,8.547,0.074,12.522,2.168c1.545,0.813,3.622,1.737,4.883-0.396c2.137-3.615,5.681-4.688,9.315-5.729
			c0.988,0.12,2.924,0.576,2.088,1.339c-2.54,2.326-2.625,6.146-5.444,8.104c-0.459,0.318-0.703,0.335-0.624,1.055
			c0.846,7.652,0.821,7.656,2.978,8.365c4.053,1.342,5.229,6.355,2.251,9.428c-0.988,1.017-2.039,2.072-2.68,3.307
			c-0.909,1.76-1.164,3.941,0.245,5.396c1.802,1.86,3.41-0.156,4.773-1.117c3.709-2.618,6.911-5.993,11.286-7.583
			c0.703-0.256,1.424-1.68,2.254-0.485c0.353,0.507,0.142,1.537-0.058,2.265c-0.218,0.796-0.884,0.976-1.78,1.112
			c-3.726,0.573-6.709,2.079-6.632,6.57l0,0c-3.196-0.771-4.842,1.318-6.244,3.564c-1.984,3.17-4.777,5.262-8.329,6.043
			c-2.895,0.636-4.012,2.556-3.926,4.983c0.108,2.998,2.325,1.38,3.825,1.303c0.293-0.017,0.617,0.602,0.927,0.927
			c-0.608,0.407-1.227,1.175-1.824,1.161c-2.529-0.057-4.315,0.845-3.943,3.545c0.371,2.682,2.287,3.353,4.938,2.914
			c2.173-0.358,4.438-0.149,6.661-0.195c0.682-0.016,1.552-0.976,1.965,0.167c0.14,0.39-0.432,1.155-0.845,1.589
			c-0.448,0.471-1.041,1.549-1.771,0.469c-0.574-0.849-1.161-1.844-1.973-0.763c-1.186,1.578,0.869,2.468,1.323,3.729
			c0.239,0.664,0.469,2.067,1.84,1.084c0.423-0.306,0.75-0.759,1.195-1.015c1.332-0.762,2.701-1.459,4.056-2.183
			c0.562,1.796-1.607,2.105-1.898,3.484c-0.181,0.85-1.065,1.696-0.218,2.514c0.877,0.852,1.696,0.16,2.388-0.458
			c0.905-0.803,1.574-1.512,2.979-0.45c1.303,0.993,2.312-0.56,2.676-1.448c0.653-1.606-0.916-0.932-1.59-1.079
			c-0.723-0.16-1.832,0.1-1.774-0.969c0.033-0.621,0.735-1.449,1.348-1.743c1.374-0.665,3.094-0.566,4.12-2.017
			c0.706-1.001,2.288-1.603,1.456-3.258c-0.704-1.401-1.709-2.26-3.446-1.858c-0.572,0.129-1.616,0.175-1.745-0.087
			c-0.513-1.033,0.658-1.127,1.173-1.597c2.218-2.03,4.128-3.843,6.409,0.272c1.669,3.002,4.339,3.678,6.932,0.511
			c0.55-0.668,1.323-1.454,2.28-0.611c0.079,0.07-0.75,1.175-1.164,1.798c-0.661,0.999-2.563,1.629-1.501,3.165
			c1.08,1.558,2.323-0.312,3.468-0.271c2.369,0.072,4.445-0.33,6.377-1.824c0.553-0.425,1.922-0.567,2.362,0.474
			c0.132,0.307-0.764,1.149-1.296,1.624c-2.112,1.889-6.235,2.55-3.391,7.149c0.773,1.248,0.086,3.678-2.443,4.838
			c-2.891,1.327-5.327,3.653-7.948,5.562c-1.518,1.107-0.488,2.066,0.291,2.905c0.988,1.064,1.455,2.086,0.668,3.51
			c-0.97,1.752-1.85,0.527-2.875,0.12c-0.478-0.188-1.605,0.017-1.663,0.239c-0.173,0.672-0.231,1.668,0.154,2.132
			c0.812,0.976,2.42,1.705,0.89,3.111c-1.418,1.31-3.113,2.32-4.726,3.408c-0.116,0.076-0.541-0.308-0.82-0.477
			c0.474-0.41,1.001-0.771,1.404-1.242c0.459-0.533,0.806-1.163,1.203-1.752c-0.726,0.111-1.526,0.066-2.158,0.37
			c-2.51,1.196-3.811,3.478-4.92,5.87c-0.322,0.695-0.243,1.844-0.739,2.146c-3.874,2.369-5.467,5.219-2.573,9.423
			c0.178,0.26-0.296,1.378-0.41,1.368c-1.228-0.095-3.451-1.553-2.435,1.459c0.762,2.259,3.035-0.289,4.514,0.761
			c0.814,0.582,1.392-2.021,2.193-0.758c0.588,0.928,0.592,2.938-0.617,3.612c-3.917,2.184-0.633,6.408-2.795,9.114
			c-0.529,0.664,0.723,1.877,1.676,1.26c5.421-3.516,1.522,1.439,1.795,2.146c0.483,1.244-0.431,2.998,0.966,3.988
			c1.384,0.983,1.977-0.702,2.95-1.154c0.531-0.243,1.515-0.502,1.729-0.255c0.896,1.02-0.369,1.164-0.86,1.603
			c-3.714,3.322-3.762,8.611-6.583,12.45c-0.349,0.473,3.417-0.65,1.868,2.079c-2.743,1.603-4.4,0.598-5.332-2.202
			c-0.298-0.895,0.077-2.476-1.46-2.262c-0.862,0.122-1.842,0.973-2.355,1.763c-1.415,2.187-3.323,3.565-5.772,4.284
			c2.463-3.502,3.688-7.578,7.153-10.789c2.15-1.993-0.408-7.091-2.149-10.208c-0.945-1.693-1.936-2.471-0.197-3.996
			c0.313-0.275,0.534-1.067,0.366-1.411c-3.109-6.314,1.299-13.151-1.517-19.718c-0.887-2.07,1.465-5.522,2.323-8.348
			c0.729-2.401-1.095-3.099-2.704-3.464c-1.867-0.425-2.579,0.729-2.268,2.577c0.307,1.822-1.249,3.188-2.317,3.073
			c-4.922-0.527-3.428,3.077-3.639,5.378c-0.189,2.061,0.271,4.17,0.188,6.245c-0.065,1.595,0.94,3.998-0.994,4.56
			c-1.688,0.494-1.471-2.468-2.893-3.208c-3.338-1.98-4.007-5.739-5.428-8.815c-4.755-10.297-10.982-19.609-17.833-28.563
			c-1.031-1.351-2.145-2.655-2.955-4.159c-0.425-0.787-1.035-1.68-0.338-2.54c0.627-0.778,1.64-0.787,2.558-0.614
			c2.063,0.387,4.052,1.059,6.017,1.788c1.232,0.461,2.097,0.412,1.872-1.267c-0.166-1.243,0.297-2.367,0.918-3.351
			c0.994-1.57,1.218-2.282-1.176-2.337c-1.631-0.036-1.771-1.667-1.553-3.059c0.216-1.369,1.508-3.004,0.154-4.046
			c-2.181-1.687-2.663-4.716-5.527-5.909c-4.408-1.841-5.019-4.366-2.543-8.295C508.733,474.034,508.919,469.779,509.429,465.603z"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          fill="#FDBEC4"
          d="M408.009,656.63c-3.438-3.647,1.462-3.717,2.361-5.505
			c3.402-3.225,9.181-1.295,12.124-5.624c0.68-1,1.682,0.006,2.491,0.215c1.101,0.277,0.763,1.068,0.525,1.862
			c-0.232,0.767-1.392,1.659-0.237,2.303c0.37,0.205,1.564-0.685,2.129-1.303c1.248-1.366,3.165-2.248,3.068-4.678
			c-0.024-0.601,1.495-1.8,2.287-1.784c1.761,0.032,0.287,1.129,0.348,1.792c0.126,1.367,1.026,0.374,1.097,0.07
			c0.819-3.555,4.584-4.255,6.552-6.82c2.678-3.493,6.434,1.197,9.707-0.186c-0.65,1.626-3.898,1.183-3.133,3.938
			c-0.063,0.116-0.134,0.228-0.184,0.346c-1.007,2.332-6.664,1.955-4.201,5.952c2.104,3.415,5.852,4.736,10.191,4.976
			c4.482,0.243,8.899,1.688,13.347,2.61c0.896,0.183,1.849,0.649,2.678,0.486c2.809-0.547,5.824-0.617,5.45,3.059
			c-0.375,3.688-4.023,3.374-6.607,2.831c-9.595-2.015-19.367-3.448-26.912-10.939c-2.795-2.781-4.064,1.557-5.429,2.924
			c-5.756,5.771-12.167,7.349-19.791,4.264c-2.22,0.732-4.467,1.669-6.29-0.784C409.055,656.634,408.532,656.63,408.009,656.63z"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          fill="#FDC8C4"
          d="M409.579,656.634c1.943,1.491,4.288-0.241,6.29,0.784
			C413.432,659.888,411.295,659.941,409.579,656.634z"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          fill="#FCC5AC"
          d="M509.429,465.603c0.189,4.522,0.544,9.196-2.418,12.999
			c-2.728,3.501-0.919,5.564,2.092,6.414c2.244,0.634,2.637,2.092,3.87,3.417c2.557,2.744,4.535,4.878,2.09,8.642
			c-0.403,0.617,0.467,1.623,1.377,1.329c4.276-1.392,4.596-0.678,1.879,2.827c-0.794,1.028-2.911,3.216-0.222,4.999
			c0.173,0.115-0.023,1.231-0.126,1.243c-1.13,0.141-2.485,0.569-3.374,0.121c-2.665-1.348-5.317-2.497-8.366-2.405
			c0.788,3.005,3.116,4.981,4.908,7.303c8.647,11.214,15.311,23.565,21.085,36.434c-3.431,2.479,0.362,3.586,1.206,4.796
			c1.799,2.568-0.096,4.012-1.001,5.874c-0.987,2.033-2.362,2.196-4.132,1.118c-0.165-0.083-0.327-0.167-0.492-0.249
			c-0.431-4.309-1.342-4.983-6.373-4.699c-0.391,0.021-0.949,0.179-1.138-0.049c-2.166-2.616-5.744-1.486-8.222-3.144
			c-1.318-0.884-2.683-1.375-3.945-0.388c-1.462,1.143-0.898,2.806-0.456,4.297c0.58,1.964,2.775,3.797,0.428,5.969
			c-0.37,0.139-0.713,0.095-1.027-0.148c-1.704-0.404-3.881-3.406-4.73,0.864c-2.609-0.144-3.983-1.481-4.007-4.126
			c0.39-0.354,0.849-0.577,1.379-0.647c0.793-0.103,1.556,0.018,2.303,0.271c-0.604-0.48-1.426-0.633-1.921-1.274
			c-1.096-1.422-1.745-3.036-0.777-4.684c0.979-1.676,2.08,0,3.199,0.264c-0.634-1.462-1.904-2.214-2.845-3.254
			c-1.988-2.204-1.318-4.366,1.521-5.358c1.303-0.453,3.855,0.141,3.744-1.157c-0.177-2.113,0.556-4.181-0.237-6.404
			c-1.499-4.214-2.239-8.653-4.983-12.435c-0.984-1.354-1.657-3.283-0.986-5.208c0.159-0.34,0.369-0.644,0.614-0.925
			c1.486-1.486,1.8-2.067-0.706-3.298c-2.772-1.365-5.398-2.991-8.389-3.985c-1.966-0.656-2.92-2.621-3.715-4.373
			c-0.921-2.038,0.529-3.177,2.215-3.862c1.507-0.613,3.082-0.316,4.631,0.02c1.52,0.334,3.505,1.555,2.706-1.865
			c-0.319-1.372,1.256-1.376,2.289-1.24c1.056,0.138,1.793,1.043,2.803,1.353c3.976,1.223,5.321-0.243,5.438-4.392
			c0.184-6.525-4.503-10.679-6.98-15.886c-0.761-1.598-2.298-2.825-2-4.853C506.527,475.72,506.527,475.72,509.429,465.603z"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          fill="#FDFCFC"
          d="M498.551,559.122c0.849,1.737,2.147,3.051,3.79,4.044
			c2.747,4.732,7.886,7.495,10.299,12.501c0.867,2.161-0.218,3.386-2.027,4.274c-2.874,1.414-5.877,2.405-9.069,2.777
			c-2.578,0.756-5.156,1.51-7.735,2.269c-2.256-0.552-2.4-3.027-3.901-4.268c-1.205-1.846-2.758-3.406-4.168-5.087
			c-0.86-1.885-1.781-3.582-4.165-4.027c-1.786-0.333-2.881-1.581-2.818-3.566c0.718-2.872-1.116-6.203,1.549-8.731
			c1.436-0.491,2.839,0.078,4.261,0.139c0.909,0.042,2.043,0.183,2.331-0.922c0.947-3.637,3.493-2.772,5.919-2.207
			c1.646,0.384,3.271,0.87,4.892,1.383C498.239,558.026,498.625,558.438,498.551,559.122z"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          fill="#FDDEC7"
          d="M507.071,562.302c0.259-0.009,0.516-0.014,0.772-0.021
			c1.662-1.446,3.613-2.529,5-4.329c1.336-1.739,3.28-1.366,5.061-0.907c1.806,0.467,1.108,1.925,1.196,3.201
			c0.16,2.297,2.164,4.082,2.075,6.561c-0.014,0.362,0.266-0.239,0.336-0.349c0.362-0.562,0.784-1.046,1.394-1.331
			c0.907-0.42,1.713-0.058,2.615,0.197c2.35,0.66,0.436-0.959,0.442-1.22c0.021-0.902-0.202-1.837,0.205-2.688
			c0.432-0.919,1.071-1.529,2.127-0.704h0.002c1.064,2.361,2.128,4.722,3.193,7.083c4.562,4.456,4.81,8.934,0.742,13.368
			c-8.338,4.444-13.273,2.053-16.968-7.31c-1.064-2.703-3.971-4.847-6.396-6.791C507.127,565.666,505.925,564.565,507.071,562.302z"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          fill="#FDDBDC"
          d="M494.477,627.521c-1.307,0.527-2.615,1.051-3.922,1.581
			c-5.179-6.755-4.165-13.834,2.362-16.502c1.348-0.029,2.679,0.159,3.458,1.35c0.193,0.296-0.251,1.229-0.643,1.639
			c-1.432,1.504-2.149,0.131-3.096-0.781c-0.772-0.747-1.463-0.121-1.823,0.63c-0.472,0.988-0.813,2.038-1.21,3.063
			c0.745,0.04,1.604,0.342,2.217,0.072c2.821-1.229,6.239-0.989,8.543-3.52c0.614-0.675,1.645-1.305,2.341-0.306
			c0.926,1.331-0.436,2.085-1.306,2.192C494.865,617.767,496.785,624.015,494.477,627.521z"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          fill="#FDDEC7"
          d="M501.543,582.719c2.836-1.257,5.615-2.675,8.532-3.708
			c1.756-0.623,2.639-1.511,2.564-3.344c1.804,4.739,4.417,9.039,7.28,13.184c0.691,1.001,1.324,1.833-0.151,2.419
			c-1.875,0.742-3.424,2.725-5.621,2.028c-0.599-0.192,0.509-1.248,1.073-1.622c1.572-1.05,1.569-2.287,0.593-3.67
			c-0.787-1.12-1.189-1.912-3.282-1.27c-3.523,1.08-5.578-3.462-9.094-3.665C502.803,583.031,502.175,582.841,501.543,582.719z"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          fill="#F5A08E"
          d="M490.67,580.239c1.045,1.584,2.092,3.163,3.138,4.748
			c0.25,0.551,0.498,1.104,0.747,1.655c-0.012,2.957-3.277,3.996-3.946,6.702c-0.22,0.893-1.801,0.391-2.478-0.575
			c-2.135-3.055-4.896-2.643-7.807-1.432c-0.218,0.404-0.471,0.428-0.755,0.062c-1.768-0.189-2.126,1.473-3.084,2.351
			c-2.102-1.23-0.658-2.286,0.182-3.349c1.835-2.33,4.825-2.899,7.06-4.621C486.013,583.899,487.657,581.209,490.67,580.239z"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          fill="#FDB19D"
          d="M532.232,581.164c2.36-4.604,2.099-9.057-0.744-13.368
			c4.442,0.824,4.814,4.896,6.111,8.043c0.553,1.336,0.43,2.995,1.274,4.382c0.64,1.042,2.641,2.542,2.001,2.789
			c-2.222,0.845-4.762,1.042-7.189,1.109C531.605,584.177,532.474,582.286,532.232,581.164z"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          fill="#FDD2C6"
          d="M554.236,478.17c3.611-1.545,7.219-3.102,10.838-4.626
			c0.561-0.235,1.182-0.329,1.774-0.485c-0.197,0.688-0.181,1.605-0.629,2.025C562.812,478.28,558.669,478.829,554.236,478.17
			L554.236,478.17z"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          fill="#E7E5D3"
          d="M302.861,706.533c-0.371-0.848-0.99-1.595-0.814-1.964
			c1.896-3.967,1.31-8.668,3.725-12.456c0.253-0.396,0.702-0.668,1.059-0.998c0.158,0.422,0.536,0.904,0.439,1.253
			c-1.203,4.357-2.468,8.699-3.736,13.037C303.445,705.705,303.208,705.965,302.861,706.533z"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          fill="#FDECA6"
          d="M597.465,212.472c3.413,0,6.828,0,10.242,0
			C604.293,213.713,600.878,213.713,597.465,212.472z"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          fill="#FD819C"
          d="M507.064,357.094c3.65-1.612,7.3-3.222,10.95-4.833
			c1.033,0.288,1.146,1.721,2.391,1.823c2.263,0.189,2.918,1.849,3.28,3.769c0.247,1.299,0.401,2.898,2.387,1.497
			c2.591-0.713,5.464,2.104,4.945,5.534c-0.292,1.939,0.428,3.286,1.05,4.76c0.699,1.67,1.13,4.399,3.859,1.298
			c1.027-1.164,3.254-0.828,3.809,1.075c0.851,2.914,3.586,3.207,5.571,4.434c1.823,1.132,2.935-0.015,3.058-2.012
			c0.122-1.977-0.498-3.864-0.816-5.787c-0.326-1.973-0.07-3.633,1.867-4.667c3.472,2.364,5.029,6.826,3.561,10.349
			c-0.553,1.328-1.3,2.582-1.157,4.098c0.039,1.714-0.384,3.278-1.515,4.609c-2.966-2.579-5.415-2.923-7.048,1.427
			c-0.895,2.377-3.068,3.151-5.156,2.326c-2.487-0.981-3.081-4.12-2.801-5.998c0.35-2.352-1.19-2.774-2.062-2.873
			c-2.11-0.241-0.857,1.03-0.571,1.913c0.085,0.262-0.497,0.739-0.773,1.117c-1.691-1.347-4.924-0.34-5.575-2.812
			c-0.729-2.767-0.98-3.404-3.579-1.315c-2.009,1.619-1.34-0.908-1.84-1.865c-0.738-1.411,1.352-4.097-1.309-4.209
			c-2.477-0.105-5.498,0.521-6.639,3.128c-0.788,1.797-1.216,2.016-2.669,0.992c-0.735-0.519-2.398-3.394-1.757,0.573
			c0.032,0.192-0.405,0.463-0.623,0.698c-0.636-0.634-1.489-1.159-1.864-1.919c-0.845-1.715,2.353-3.593-0.114-5.352
			c0.829-1.04,1.413-0.109,1.809,0.367c1.167,1.401,1.972,1.389,4.006,0.957c3.403-0.72,1.278-0.857,0.259-1.664
			c-0.791-0.63-0.843-1.356,0.399-1.494c0.733-0.078,1.572,1.72,2.113,0.195c0.243-0.688-0.674-1.881-1.228-2.735
			c-0.912-1.401-1.662,0.08-2.195,0.409c-3.534,2.185-4.826,0.663-4.665-2.828c0.074-1.573-1.726-1.485-1.794-2.712
			C505.44,358.61,506.254,357.852,507.064,357.094z"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          fill="#FCF0EC"
          d="M551.818,378.431c-0.913-0.266-2.104-0.873-1.268-1.775
			c4.285-4.623,0.956-8.452-1.046-12.454c-0.19-0.371-0.164-0.726,0.071-1.068c1.286-1.05,3.017-0.602,4.398-1.325
			c1.291-0.441,2.572-0.972,3.983-0.569c0.929,0.33,1.643,0.976,2.355,1.619c2.278,2.063,4.96,3.803,5.813,7.041
			c1.685,1.849,4.008,0.685,5.963,1.257c1.095,0.224,2.157,0.525,3.085,1.181c0.116,0.144,0.23,0.294,0.345,0.441
			c-1.872,4.468-5.147,6.525-10.07,6.532c-2.414,0.004-4.828,0.972-7.241,1.507C555.727,380.964,553.554,380.282,551.818,378.431z"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          fill="#FDD1C6"
          d="M558.958,427.857c0.586-0.746,3.569,2.622,2.284-1.333
			c1.254-0.304,2.987-1.289,3.682-0.801c2.988,2.104,7.111,1.552,9.697,4.374c-3.86-0.49-6.985,0.103-7.216,4.93
			c-0.217,1.763-0.141,3.806-2.955,3.081c-3.363,0.078-6.41-1.091-9.424-2.394c2.641,0.352,3.284-0.995,3.198-3.385
			C558.17,430.849,558.692,429.347,558.958,427.857z"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          fill="#FD5C80"
          d="M507.025,324.877c0.48-1.861,0.962-3.727,1.441-5.588
			c2.659-0.147,0.241-3.366,2.182-3.717c1.366-0.246,3.756-0.429,4.081,0.198c0.909,1.747,1.018,3.916,2.45,5.509
			c-1.971-0.854-3.006-0.326-3.55,1.878c-0.29,1.189-1.647,2.2-0.271,3.436c1.09,0.976,2.487,0.739,3.744,0.489
			c1.507-0.297,0.89-1.916,1.322-2.591c1.892-2.959-0.021-5.505-0.566-8.032c-0.565-2.636,0.912-3.132,2.535-3.919
			c0.688,2.841,4.441-4.327,4.714,1.465c0.044,0.922,1.781,3.66,3.484,4.601c3.18,1.756,1.057,5.774,3.601,7.786
			c0.169,0.137-2.087,0.732-2.94,0.419c-2.038-0.756-4.053-0.023-4.059,1.577c-0.008,2.028,2.105,0.736,3.396,0.78
			c3.076,0.106,4.757,2.144,5.758,4.815c0.515,1.375,1.146,3.4,2.761,2.344c3.697-2.418,5.367-0.409,7.236,2.316
			c0.604,0.876,1.891,1.283,2.866,1.903l0.003-0.003c0.902,3.177,2.745,5.295,6.252,5.535c1.315,1.327,2.634,2.655,3.952,3.983
			c1.767,0.937,3.148,2.172,3.363,4.318c-0.118,0.383-0.359,0.655-0.737,0.799c-2.896,0.5-4.467-3.052-7.408-2.415
			c-3.397-0.458-6.718,0.848-10.104,0.432c-2.814-1.25-4.413-4.027-6.97-5.62c-0.42-0.266,0.029,0.153-0.267-0.279
			c-2.176-3.202-2.176-3.202-6.205-3.784c-0.711,0.048-1.318,0.547-2.06,0.48c-2.024-1.66-4.358-2.732-6.71-3.865
			c-4.662-2.242-7.962-6.244-11.576-9.795C507.263,328.876,507.807,326.637,507.025,324.877z"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          fill="#FDABB6"
          d="M643.95,394.217c1.862,2.646,4.932,2.639,7.481,3.833
			c0.993,0.464,0.304,1.081-0.117,1.497c-2.161,2.146-0.764,4.341-0.187,6.554c0.222,0.841,1.985,1.75,0.52,2.409
			c-0.785,0.355-3.29-0.997-3.244-1.211c0.899-4.3-4.101-4.735-4.941-7.771c-0.4-1.436-0.72-2.901-0.963-4.373
			C642.47,394.992,643.175,394.707,643.95,394.217z"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          fill="#F16161"
          d="M584.87,353.933c0.536-0.009,1.075-0.019,1.611-0.028
			c0.047,2.498,3.903,5.262-0.515,7.469c-0.188,0.096-0.258,0.422-0.384,0.64c5.564,2.214-0.796,4.597,0.101,6.878
			c-1.507,2.936-3.282,5.53-7.086,5.514c-1.026-0.543-2.054-1.085-3.078-1.628l-0.032,0.003c-1.294-0.707-1.305-2.079-0.228-2.399
			c3.582-1.062,4.208-4.133,5.41-6.913c0.241-0.558,0.665-1.11,1.084-1.606C584.719,359.976,582.011,355.862,584.87,353.933z"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          fill="#FD9D79"
          d="M560.505,354.726c-1.029-1.555-2.06-3.112-3.087-4.667
			c4.271-2.544,8.888-1.789,13.301-0.912c3.089,0.611,2.416,4.872,4.841,6.462c-1.473,0.886-1.892,2.224-1.664,3.842
			c-1.13,0.458-1.728-0.358-2.256-1.062c-1.894-2.521-4.026-3.48-7.097-1.836C562.738,357.519,561.271,356.626,560.505,354.726z"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          fill="#FD7793"
          d="M553.466,346.077c-1.164,0.364-2.642,2.617-3.403,0.56
			c-0.794-2.146-5.835-2.351-2.849-6.094C550.894,340.583,551.44,344.164,553.466,346.077z"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          fill="#FDC188"
          d="M581.754,361.864c-1.047,3.618-0.157,8.476-6.006,8.991
			c-1.033,0.09-0.476,1.226-0.261,1.926c-1.044-0.256-2.087-0.512-3.129-0.768c-0.711-1.171,0.875-1.6,0.824-2.556
			c-0.886,0.156-1.734,0.447-2.581,0.723c-1.876,0.607-3.774,0.546-5.688,0.323c0.124-3.695-3.562-4.639-5.129-7.108
			c0.913-0.931,2.083-0.828,2.819-0.09c3.077,3.091,5.768,1.884,8.431-0.521c1.196-0.86,2.941-0.931,3.639-2.523
			C577.455,358.92,579.123,362.525,581.754,361.864z"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          fill="#FDA653"
          d="M574.673,360.261c2.232,4.212-2.882,1.298-3.067,3.167
			c-2.701-0.46-5.271-1.414-7.174-3.349c-1.479-1.507-3.676-1.007-5.082-2.386c-0.576-1.376,0.01-2.287,1.17-2.975l-0.015,0.009
			c1.743,1.038,3.124,1.299,5.309,0.15c4.448-2.338,5.735,2.358,8.082,4.578C574.155,359.723,574.413,359.992,574.673,360.261z"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          fill="#E78EA0"
          d="M583.113,357.132c-1.461,0.448-2.012,0.788-2.518,0.726
			c-0.527-0.061-1.011-0.502-1.515-0.777c0.497-0.266,0.981-0.701,1.498-0.742C581.099,356.297,581.648,356.646,583.113,357.132z"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          fill="#EBECD1"
          d="M564.45,438.107c1.936-0.119,1.843-2.183,2.957-3.085
			c3.166,1.261,6.771,0.228,9.885,1.968c1.522,0.851,3.221,1.41,2.923,3.596c-0.094,0.697-0.397,1.812-0.836,1.939
			c-1.729,0.502-2.344-1.283-3.469-2.062c-1.396-0.962-2.654-2.232-4.185-2.862c-1.384-0.572-3.542-1.062-2.432,1.955
			C567.533,439.562,565.578,440.212,564.45,438.107z"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          fill="#FDEEB0"
          d="M605.57,158.929c-1.354,1.737-0.273,4.657-2.332,5.787
			c-1.618,0.889-2.313-1.734-3.862-2.035c-1.232-0.236-2.308-2.06-3.727-0.153c-0.583,0.784-1.353,2.587-2.585,1.116
			c-0.849-1.017,0.59-2.703,1.318-2.889c3.369-0.851,6.311-3.225,10.016-2.863C605.109,157.959,605.715,157.972,605.57,158.929z"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          fill="#FCC7C5"
          d="M444.931,455.333c1.057-4.957-0.424-9.916,0-14.871
			c1.666-1.21,4.205-1.568,3.809-4.67c-0.101-0.804,1.278-1.931,2.041-0.126c1.098,2.589,2.188,5.242-1.255,7.045
			c-0.613,0.318-1.755,0.214-1.347,1.311c0.085,0.23,1.702,0.324,2.089-0.055c3.424-3.352,6.756-6.799,10.053-10.283
			c0.754-0.798,0.931-2.569,2.284-2.253c1.423,0.337,1.423,1.673,0.627,2.857c-0.367,0.54-0.892,1.413-0.063,1.57
			c0.915,0.173,2.307-0.038,2.899-0.651c3.426-3.562,9.472-1.128,12.471-5.546c0.095-0.141,0.693,0.061,1.058,0.106
			c-0.3,1.084-0.247,2.652-0.957,3.17c-3.358,2.451-4.869,6.801-8.916,8.502c-0.604,0.255-1.777,0.48-1.199,1.527
			c0.329,0.59,1.161,1.084,1.854,1.247c0.781,0.186,1.355-0.27,1.675-1.206c0.421-1.247,10.79-5.227,11.896-4.666
			c1.113,0.561,0.069,0.978-0.181,1.388c-1.82,2.969-2.772,5.589,2.213,6.087c0.428,0.045,1.155,0.215,1.202,0.438
			c0.231,1.112-0.671,1.465-1.445,2.009c-1.909,1.347-4.214,2.857,0.178,4.015c0.427,0.112,1.134,0.619,1.095,0.816
			c-0.314,1.532-1.613,2.236-2.77,3.089c-1,0.738-4.371,0.845-0.74,3.072c1.57,0.961-0.437,2.5-0.71,3.799
			c-0.222,1.05-2.253,2.393-0.92,2.8c1.412,0.429,2.836,3.196,4.917,0.754c0.754-0.882,1.277-0.672,1.655,1.146
			c0.607,2.928,0.891,5.923,1.312,8.888c-2.145,1.199-3.448-0.407-4.752-1.569c-1.57-1.399-2.783-2.99-5.396-2.056
			c-2.744,0.981-1.044,3.341-1.925,4.837c-3.015,0.9-6.082,1.124-9.22,0.913c-10.955-0.736-20.504,3.727-30.031,8.229
			c-0.947,0.452-0.877,1.155-0.436,1.936c0.426,1.615-0.597,2.546-1.775,3.103c-2.72,1.287-5.29,2.845-7.993,4.149
			c-0.937,0.45-2.064,0.925-2.89-0.048c-0.87-1.026,0.039-1.899,0.573-2.792c0.803-1.337,3.29-2.601,1.798-4.124
			c-1.548-1.581-3.241,0.604-4.757,1.458c-3.404,1.924-5.637,1.223-7.012-2.225c0.493-0.551,0.988-1.104,1.483-1.655
			c3.128,0.234,5.876-0.613,8.143-2.85c0.181-0.179,0.429-0.292,0.63-0.454c0.328-0.27,0.003-0.333-0.043-0.477
			c-0.915-2.889-0.886-2.852,1.619-3.702c1.771-0.602,3.635-0.243,5.374-0.589c2.249-0.446,5.684-0.101,4.402-4.388
			c-0.449-1.507,1.392-2.044,1.878-3.208c3.196-1.528,5.154-5.4,9.436-4.953c0.342,0.035,1.139-0.874,1.102-1.29
			c-0.095-1.064-1.153-0.914-1.862-0.841c-2.632,0.275-4.422-0.47-5.559-3.142C441.289,457.959,443.863,457.058,444.931,455.333z"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          fill="#F6B3A7"
          d="M497.636,475.846c1.989,3.305,3.829,7.196,6.029,9.75
			c2.897,3.359,3.303,6.558,3.939,10.222c0.428,2.439,0.648,5.371-1.786,6.186c-1.946,0.652-5.018,1.965-6.854-1.11
			c-0.445-0.749-1.463-1.026-2.017-0.412c-0.722,0.803,0.585,1.206,0.779,1.861c0.245,0.828,0.725,1.948,0.388,2.517
			c-0.716,1.211-1.493-0.088-2.264-0.348c-1.109-0.368-2.24-0.698-3.382-0.931c-2.111-0.432-5-0.749-5.184,1.705
			c-0.117,1.538,1.306,4.581,3.87,4.997c2.926,0.473,5.369,1.5,7.377,3.674c0.385,0.42,1.217,0.874,1.636,0.725
			c1.038-0.371,0.56-2.997,2.401-1.946c0.346,0.2-0.711,2.746-1.017,4.228c-0.204,0.99-0.683,1.669-1.594,2.062
			c-2.287-2.391-4.942-3.976-8.239-4.82c-3.99-1.021-7.426-3.558-11.526-4.535c-3.04-0.728-6.278,0.318-9.142-1.292
			c0.875-2.419,2.958-3.548,5.855-3.053c1.406,0.239,2.757,0.751,4.151,1.062c1.129,0.248,2.413,0.771,2.803-0.979
			c0.346-1.562,0.028-2.858-1.733-3.377c-2.254-0.66-4.595-0.857-6.894-1.277c-3.706-0.676-7.439-1.196-11.14-1.906
			c-0.821-0.31-2.339-1.098-1.703-1.542c2.032-1.416,2.792-4.11,5.284-4.958c1.355-0.465,2.487-0.124,2.851,1.203
			c0.911,3.307,3.22,3.322,5.869,2.779c0.896-0.182,1.813-0.243,2.716-0.391c1.121-0.183,2.693,0.049,3.048-1.225
			c0.283-1.018-1.318-0.971-1.95-1.58c-0.939-0.902-1.66-1.768-0.276-2.88c5.804,0.025,11.279,1.564,16.661,3.529
			c0.627,3.976,4.476,2.99,6.805,4.346c0.406,0.239,0.572,0.05,0.225-0.131c-0.377-0.194-0.749-0.613-1.142-0.919
			c-2.468-2.206-4.494-4.843-7.778-6.178c-2.17-0.887-2.061-2.833-0.144-4.25c1.363-1.007,2.429-1.974,1.418-3.794
			c-0.714-2.491-4.484-2.307-4.648-5.28C493.076,475.701,495.366,475.81,497.636,475.846z"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          fill="#EB455D"
          d="M491.33,477.576c1.32,2.113,4.62,1.846,5.459,4.539
			c-2.261,2.093-4.324,4.629-8.011,3.422c-1.026-0.332-1.632,0.358-2.317,1.011c-2.9,2.774-5.426,3.075-8.94,1.037
			c-2.707-1.57-0.155-2.674,0.425-3.944c2.796-2.085,1.431-4.158-0.026-6.232c-0.961-1.22-3.623-2.199-0.862-4.065
			c2.208-1.496,4.765-3.628,7.17-0.655c1.556,1.923,3.106,3.435,5.528,3.954C490.281,476.951,490.805,477.265,491.33,477.576z"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          fill="#FDE3F0"
          d="M441.504,430.842c-0.539-1.595-1.003-2.307-0.825-2.549
			c1.326-1.817,3.035-3.184,5.331-3.539c0.487-0.077,1.776-0.09,1.321,0.667C446.118,427.432,442.915,426.719,441.504,430.842z"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          fill="#F99BA2"
          d="M378.134,607.104c1.609-0.156,3.428,0.528,4.575-1.355
			c1.238-2.034-1.641-2.367-1.541-3.947c0.048-0.78-2.551-1.382-0.173-2.381c-0.961-1.679,1.348-4.978-3.055-5.067
			c-3.538-0.07-4.039-5.534-8.043-5.709c-0.074-0.004-0.432-2.502-0.032-2.726c1.347-0.758,1.825,0.976,2.772,1.522
			c3.064-2.137,5.983-1.959,8.906,0.375c2.8,2.229,6.442,3.361,8.557,6.5c2.949,3.365,7.81,4.356,10.323,8.266
			c0.257,1.492-1.798,2.571-0.761,4.215c0.691,0.983,1.117,1.862,0.376,3.149c-1.645,2.854-1.127,3.765,2.113,4.202
			c1.287,0.177,2.567,0.177,3.677-0.677c0.439-0.34,0.922-0.585,1.468-0.698c3.821,1.366,7.631,2.246,11.061,5.068
			c4.88,4.012,10.873,4.627,16.859,1.52c3.382-1.758,6.582-1.918,9.581,1.158c1.114,1.137,2.922,1.663,4.433,2.438
			c3.015,1.547,5.252,1.562,7.027-1.959c1.033-2.047,3.165-3.414,5.313-4.456c0.884-0.429,2.472-0.418,2.162-1.771
			c-0.363-1.581-2.008-1.686-3.243-1.245c-3.241,1.161-6.182-0.131-9.191-0.854c-0.905-0.215-2.244-0.384-1.488-1.977
			c1.046-0.793,2.67-1.018,0.659-2.979c-2.707-2.638-3.81-6.147-1.845-9.869c0.92-1.739-0.13-4.42,2.547-5.301
			c1.72,0.181,2.214,1.255,2.063,2.804c0.255,3.908,0.564,7.753,4.812,9.729c0.818,0.376,1.18,1.352,1.552,2.191
			c0.213,0.475,0.691,1.269,0.923,1.108c3.123-2.166,5.938,0.621,8.929,0.47c6.127-0.31,6.43-0.53,6.952-6.451
			c3.344-0.086,6.861,3.502,10.062-0.464c0.062-0.078,1.012,0.297,1.217,0.653c0.58,1.001-0.312,1.417-0.998,1.705
			c-2.326,0.978-2.942,2.703-3.381,5.158c-0.554,3.109-3.488,4.756-6.407,4.352c-2.319-0.323-3.545,0.22-4.429,2.006
			c-1.547,3.118-5.951-0.186-7.36,3.585c0.997-0.179,1.844-0.44,2.692-0.45c0.981-0.011,2.284-0.47,2.769,0.758
			c0.204,0.521-0.228,1.484-0.65,2.031c-0.964,1.239-1.983,1.014-2.838-0.227c-1.583,2.266-6.149-2.257-6.669,3.196
			c-0.27,2.798-3.869,3.901-6.026,4.744c-1.718,0.668-4.801,1.545-7.106-1.016c-3.695-4.097-7.796-8.038-13.717-3.093
			c-2.241,1.872-4.342-1.056-7.218-0.179c-2.336,0.716-6.507,0.399-9.7-0.753c-0.926-0.643-2.319-0.553-2.823-1.871
			c-0.279-0.729-0.735-0.86-1.529-0.502c-4.022,1.816-8.136,1.298-12.234,0.278c-1.05-0.259-2.048-0.205-3.095-0.019
			c-5.621,1.008-6.875,0.193-8.166-5.202c-0.118,0.055-0.271,0.081-0.352,0.169c-1.243,1.342-0.765,4.319-3.434,4.181
			c-2.583-0.129-7.628-5.932-7.409-8.246c0.243-2.566,2.023-2.227,3.793-1.774c0.555,0.14,1.437,0.767,1.592-0.177
			c0.155-0.935-0.84-1.187-1.643-1.19c-1.559-0.002-2.723,0.852-3.675,1.971c-0.794,0.936-1.564,1.855-2.941,1.341
			c-1.046-0.392-1.706-1.208-1.847-2.34c-0.192-1.517,0.655-2.464,1.892-3.121C375.816,607.351,376.895,606.864,378.134,607.104z"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          fill="#FDB0B2"
          d="M378.134,607.104c-0.403,0.322-0.793,0.66-1.212,0.96
			c-1.174,0.842-3.426,0.438-3.333,2.682c0.026,0.658,0.628,1.672,1.188,1.858c2.061,0.682,2.452-1.365,3.408-2.435
			c2.137-2.397,4.303-2.038,6.387-0.029c0.897,0.865,0.67,1.932-0.16,2.8c-0.633,0.661-1.371,1.49-2.26,0.425
			c-1.014-1.215-2.096-2.188-3.593-0.972c-0.702,0.572-0.31,1.766,0.349,2.031c1.938,0.78,0.782,4.975,3.874,3.867
			c2.145-0.767,2.682-0.911,2.057,1.494c-0.077,0.293,0.172,0.672,0.27,1.01c0.375-0.301,0.959-0.533,1.081-0.914
			c0.354-1.104,0.422-2.304,0.786-3.403c0.449-1.359,1.611-1.688,2.871-1.379c1.478,0.367,1.248,1.384,0.753,2.456
			c-1.52,3.298,1.742,4.796,3.063,4.217c4.649-2.045,8.874,0.889,13.28,0.288c2.055-0.277,4.327,0.707,5.603-2.212
			c0.649-1.486,2.548-0.565,2.166,0.778c-0.635,2.22,1.019,2.681,1.93,3.777c-3.81-0.104-4.237,3.641-6.295,5.531
			c-0.2,4.303,0.994,7.784,5.324,9.642c1.199,0.514,2.798,0.983,2.56,2.902c-3.911,2.744-7.136,2.69-9.948-1.779
			c-0.806-1.283-2.773-0.797-3.119,1.149c-0.165,0.911,0.829,2.22-1.209,1.867c-1.387-0.24-2.164,2.646-3.664,0.986
			c-1.177-1.305,0.243-3.03,0.897-3.826c1.942-2.367,0.629-5.842,3.05-7.894c0.137-0.116,0.136-0.507,0.07-0.731
			c-0.267-0.921-1.018-1.433-1.764-0.859c-3.508,2.683-7.585-4.972-10.84,0.643c-0.617,1.063,0.418,2.719-1.188,3.464
			c-1.116-2.783-4.896-0.448-5.798-2.656c-2.041-5.001-8.268-7.397-9.229-11.893c-1.008-4.706-5.001-4.728-6.775-7.553
			c-1.954-2.399-4.062-4.446,0.478-6.808c2.475-1.285,2.553-5.205,5.185-7.013c0.807-0.557-0.455-1.303-1.093-1.518
			c-3.289-1.104-0.958-1.891,0.117-2.762c1.889,2.396,4.234,4.315,5.771,7.153C380.485,604.875,379.91,605.865,378.134,607.104z"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          fill="#E24E63"
          d="M452.089,595.321c1.45-1.327,0.087-1.784-0.742-2.405
			c-1.286-0.981-0.912-2.267-0.557-3.482c0.407-1.39,0.662-2.726-0.27-4.01c-0.82-1.291-0.856-2.88-1.882-4.151
			c-2.707-3.35-2.005-4.712,2.54-5.297c1.998,0.968,2.838,2.702,2.696,4.776c-0.287,4.169,2.102,7.188,4.418,10.089
			c1.642,2.058,3.967,3.483,6.791,1.531c0.31-0.21,0.74-0.224,1.122-0.306c6.735-1.437,7.38-2.792,4.296-8.979
			c-0.171-0.344-0.115-0.762-0.059-1.152c1.436-3.489,3.165-6.857,4.484-10.406c3.841,2.104,5.969,5.88,8.85,8.929
			c1.989,2.104,2.273,3.961,0.449,6.161c-2.257,1.44-4.505,2.888-6.772,4.312c-1.103,0.691-1.765,1.498-0.969,2.821
			c-1.331,1.838-1.968,4.179-3.995,5.512c-1.729,1.718-3.943,0.283-5.838,0.915c-2.224,0.736-4.512,1.233-6.772,1.856
			C455.277,602.286,454.268,601.417,452.089,595.321z"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          fill="#F49193"
          d="M466.19,599.247c2.102-1.143,4.198,0.432,6.3,0.016
			c0.047,2.614,2.551,2.16,3.912,3.133c0.752,1.995,2.557,4.415,0.021,5.907c-3.066,1.808-6.694,2.248-10.26,1.128
			c-1.471-0.461-2.928-2.375-4.183,0.374c-0.161,0.355-2.062,0.358-2.083,0.271c-1.085-4.627-7.937-5.509-7.297-11.021
			c0.166-1.437-2.336-2.28-0.513-3.734c2.243,2.363,2.673,7.292,7.827,5.532c1.168-0.388,1.166,0.575,1.483,1.238
			c0.769,1.597,4.137,2.542,5.539,1.635c0.839-0.548,0.394-1.084,0.103-1.647C466.58,601.186,465.635,600.44,466.19,599.247z"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          fill="#FD9096"
          d="M418.232,642.479c-4.448-2.636-8.871-5.02-9.511-11.219
			c-0.266-2.566,0.155-1.984,1.627-1.325c2.588,3.062,5.267,6.014,8.61,8.314c1.171,0.807,2.344,2.003,1.992,3.502
			C420.653,643.016,419.178,642.295,418.232,642.479z"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          fill="#FADAD6"
          d="M479.569,591.4c0.252-0.032,0.503-0.051,0.755-0.062
			c0.429,0.26,0.828,0.651,1.294,0.77c2.478,0.618,5.665,1.414,4.707,4.463c-0.985,3.145-4.782,3.166-7.665,3.298
			c-1.954,0.093-1.494-1.669-1.054-2.949C478.246,595.072,478.914,593.24,479.569,591.4z"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          fill="#F7A6B0"
          d="M375.802,523.838c5.148-8.691,12.65-14.541,21.98-18.18
			c1.941,2.004,1.95,3.409-1.195,3.785c-1.056,0.127-2.329,0.167-2.837,1.091c-1.952,3.545-6.04,4.556-8.507,7.31
			c-3.169,3.539-5.159,7.953-6.897,12.361c-0.511,1.298-0.624,3.426,1.566,3.775c1.846,0.29,2.236-1.429,2.701-2.791
			c0.17-0.492,0.373-1.001,0.394-1.512c0.339-8.39,6.954-11.907,12.572-16.171c1.402-1.066,2.839-0.6,4.403,0.104
			c2.667,1.198,4.441,0.139,4.663-2.817c0.182-2.443,2.454-3.212,3.415-5.048c2.193-4.181,5.782-6.765,10.425-7.868
			c1.228-0.296,2.387-0.584,3.043,0.79c0.652,1.369-0.236,2.234-1.298,2.875c-3.587,2.159-4.254,6.756-7.634,9.116
			c-0.591,0.416-0.896,1.466,0.547,1.438c4.486-0.096,2.573,2.21,1.591,4.184c-0.854,1.713-2.14,2.943-3.96,3.591
			c-3.081,1.627-5.579,3.208-7.841,6.566c-3.192,4.744-5.643,10.173-9.817,14.292c-2.345-0.009-3.412,2.141-4.299,3.484
			c-1.639,2.476-3.899,4.057-5.993,5.935c-1.493,1.337-2.794,2.587-4.248-0.042c-0.338-0.61-0.515-0.489-0.891,0.19
			c-0.745,1.352-0.435,2.759-0.317,4.18c0.157,1.878,0.102,3.716-2.247,4.309c-2.193,0.057-0.533,1.449-0.62,2.117
			c-0.247,1.881,0.441,3.769,0.231,5.684c-0.92,1.747-2.214,1.965-3.055,0.193c-0.824-1.741-2.203-2.331-3.75-2.912
			c-0.271-0.003-0.541-0.005-0.812-0.008c-0.76-1.557-0.146-3.047,0.292-4.501c1.49-4.976,0.598-9.63-1.732-14.124
			c-0.357-1.244-0.275-2.575-0.791-3.788c-0.147-0.355-0.268-0.717-0.386-1.084c-1.436-3.448-1.224-7.134-1.536-10.738
			c-0.261-3.014,1.909-4.998,3.151-7.375c0.902-1.723,1.766-3.47,2.944-5.03c0.627-0.522,1.273-0.976,2.137-0.539
			c0.982,3.954-0.868,7.607-1.252,11.421c-0.213,2.093-1.836,5.288,0.517,5.89c2.377,0.605,1.881-2.99,2.572-4.758
			C373.776,527.329,373.851,525.115,375.802,523.838z"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          fill="#F7B4B6"
          d="M367.925,563.866c2.36-1.257,4.154-0.79,4.36,2.029
			c0.129,1.798,0.729,1.612,1.868,1.104c1.217-0.249,2.435-0.769,3.648,0.022c0.812,0.675,1.449,1.511,2.102,2.322
			c0.931,1.161,1.365,1.129,2.113-0.323c1.999-3.877,2.621-3.938,6.199-1.686c1.25,0.786,2.218,1.8,3.092,2.956
			c0.724,0.96,1.115,2.411,2.915,0.937c1.332-1.09,2.202,0.164,2.24,1.501c0.064,2.182-0.563,4.137-2.596,5.367
			c-0.401,0.239-0.945,0.093-1.257,0.519c0.475,0.191,0.971,0.004,1.459,0.087c0.921,0.153,1.818-0.083,2.722-0.226
			c0.648-0.104,1.764-0.07,1.68,0.494c-0.557,3.727,2.866,5.279,4.131,7.981c0.099,0.207-0.077,0.485-0.251,0.7
			c-5.069,1.042-7.903,5.713-12.421,7.652c-2.733-0.742-3.91-3.48-6.329-4.93c-3.625-2.166-6.743-3.941-10.963-2.934
			c-0.334-1.91,1.587-3.509,0.716-5.576c-0.16-0.378,0.934-1.842,1.252-1.778c8.832,1.712,1.294-5.64,4.034-7.232
			c-0.307-0.512-0.509-1.315-0.944-1.471c-0.807-0.293-0.958,0.491-1.134,1.146c-0.627,2.333-1.312,3.252-2.137,0.052
			c-0.078-0.304-0.75-0.737-1.021-0.663c-1.464,0.404-1.75,1.788-2.225,2.986c-0.344,0.869,0.129,2.61-1.272,2.335
			c-1.075-0.211-0.884-1.655-0.803-2.94c0.136-2.141,0.109-4.959-1.131-6.354C366.361,566.132,368.011,565.172,367.925,563.866z"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          fill="#F37586"
          d="M369.531,517.506c-1.19,2.772-2.35,5.491-4.235,7.967
			c-2.775,3.648-0.49,7.883-0.536,11.853c-0.015,1.245,0.512,2.496,0.792,3.743c-1.853,1.104-3.271,2.565-4.651,4.284
			c-1.529,1.899-1.827,3.489-1.06,5.66c0.78,2.215,1.831,4.291,2.812,6.412c0.149,0.349,0.246,0.708,0.303,1.078
			c0.024,0.591-0.112,1.116-0.608,1.496c-1.799-1.1-3.584-3.529-5.434-0.061c-3.397,0.036-5.224-0.738-2-3.995
			c2.164-2.185,1.71-4.801,1.111-7.328c-0.606-2.562-1.569-4.765,0.645-7.364c1.481-1.743,0.086-4.876,0.547-6.899
			c0.72-3.18-1.691-8.372,4.26-9.403c0.426-0.073,3.313-1.263,0.047-2.84c-1.609-0.778-1.043-3.971,0.219-5.964
			c0.934-1.475,0.899-4.189,3.965-3.007c0.593,0.23,1.458-0.235,2.197-0.384c1.459,2.73-0.849,4.464-1.617,6.628
			C367.269,518.686,367.752,516.894,369.531,517.506z"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          fill="#F15069"
          d="M362.346,559.999c0.011-0.536,0.024-1.074,0.035-1.611
			c1.222-0.9,2.144,1.558,2.996,0.491c0.725-0.903-0.055-2.273-0.542-3.311c-1.089-2.307-1.073-4.755-0.9-7.983
			c-1.744,2.566-2.262,4.374-0.87,6.561c0.924,1.455,0.576,2.994-0.69,4.242c-2.164-2.704-3.057-5.976-4.211-9.149
			c-0.602-1.649-0.255-2.88,1.101-3.717c1.44-0.886,1.819-1.804,2.005-3.633c0.328-3.252,3.048,0.846,4.282-0.823c0,0.003,0,0,0,0
			c0.947,1.165-0.195,2.764,0.814,3.916c4.272,6.133,2.958,12.488,0.749,18.877C364.558,563.764,363.628,561.662,362.346,559.999z"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          fill="#F8ABBB"
          d="M363.938,508.864c-0.394,0.413-0.633,0.665-0.875,0.919
			c-0.176-0.257-0.437-0.493-0.495-0.772c-0.037-0.175,0.253-0.595,0.368-0.581C363.229,508.454,363.505,508.659,363.938,508.864z"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          fill="#F7B0AE"
          d="M410.316,519.072c2.785-0.491,3.066-3.292,4.555-4.979
			c0.208-0.235,0.191-0.671,0.277-1.013c-0.739,0.016-1.537-0.141-2.201,0.094c-0.908,0.316-1.702,1.606-2.709,0.412
			c-1.158-1.375,0.208-2.339,1.03-2.863c4.026-2.582,3.876-8.854,9.175-10.291c0.287-0.079,0.622-1.22,0.423-1.6
			c-0.486-0.93-1.295-0.246-2.036-0.137c-6.61,0.963-8.587,7.412-12.916,11.062c-1.062,0.899,2.202,4.549-2.198,4.892
			c-1.815,0.141-3.76,2.998-5.042-0.742c-0.078-0.228-1.745-0.349-2.108,0.029c-4.515,4.674-12.079,6.739-12.382,15.103
			c-0.079,2.238-0.054,5.532-3.381,6.435c-1.328,0.358-3.037,0.083-3.406-1.236c-0.783-2.806,0.316-5.312,1.223-8.13
			c2.483-7.715,9.818-10.729,14.204-16.478c0.617-0.809,2.226-1.619,3.064-1.372c3.551,1.053,2.043-1.19,1.894-2.599
			c6.31-5.463,12.648-10.895,18.155-17.205c2.113,2.711,4.308,3.431,7.132,0.76c1.136-1.075,2.639-2.338,4.395-1.944
			c1.039,0.233,2.783,1.691,2.694,1.881c-0.753,1.59-1.78,3.081-2.892,4.466c-0.527,0.655-2.081,1.054-1.497,1.913
			c0.782,1.158,1.968-0.011,2.641-0.583c2.867-2.435,7.104-2.546,9.511-5.704c2.815-3.237,6.596-3.794,10.566-3.824
			c0.491,0.146,0.945,0.393,1.388,0.668c1.626,1.011,4.858-0.349,5.068,1.801c0.225,2.286-2.741,3.562-3.999,5.239
			c2.147-0.486,4.98-1.871,5.486,2.42c-0.433,1.62-1.827,2.117-3.169,2.564c-3.085,1.031-5.928,2.595-8.894,3.89
			c-1.916,0.836-3.041,2.016-3.674,4.114c-1.191,3.958-3.627,6.843-8.112,7.29c-0.398-0.064-0.751-0.224-1.062-0.48
			c-0.496-1.022-0.082-1.883,0.479-2.711c3.12-3.22,5.526-6.787,5.48-11.501c-1.435-1.597-1.723,0.533-2.576,0.803
			c-0.232,0.263-0.48,0.502-0.731,0.739c-1.987,1.523-3.215,3.572-4.072,5.883c-0.155,0.32-0.339,0.625-0.538,0.918
			c-0.956,1.298-1.847,2.655-3.213,3.583c-5.509,4.616-6.268,8.167-3.163,14.836c-1.311,2.405-2.95,1.437-4.394,0.275
			c-0.96-0.771-1.428-2.121-3.131-1.225c-1.593,0.837-0.953,2.021-1.047,3.264c-0.158,2.066-1.496,3.768-2.47,5.564
			c-0.449,0.292-1.025,0.555-1.461,0.308c-1.793-1.021-3.05-1.424-4.679,0.755c-1.531,2.044-3.17,0.733-4.031-1.306
			C402.133,526.872,406.731,523.272,410.316,519.072z"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          fill="#F9C4CD"
          d="M375.802,523.838c-0.787,2.874-1.769,5.711-2.297,8.629
			c-0.396,2.187-1.131,4.196-3.487,3.652c-2.208-0.506-2.308-3.032-1.974-4.901c0.862-4.832,2.018-9.612,3.058-14.41
			c3.255-4.059,7.21-7.505,10.245-11.76c0.707-0.506,1.255-1.471,2.364-0.837c-0.567,4.346-3.935,7.199-5.753,10.865
			C376.621,517.777,373.52,520.199,375.802,523.838z"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          fill="#F7B2B0"
          d="M439.425,471.131c-1.246,1.915-1.033,3.526,0.996,4.756
			c-1.934,2.037-3.982,3.886-6.997,4.05c-1.666,0.09-3.381-0.066-4.983,0.292c-0.997,0.219-3.079-0.123-2.831,1.301
			c0.337,1.937,1.358-0.374,2.214-0.163c0.963,0.233,1.097,0.708,0.46,1.228c-3.153,2.573-5.414,7.548-10.862,4.204
			c4.272-4.836,9.558-8.381,14.974-11.76C434.739,473.733,437.082,472.432,439.425,471.131z"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          fill="#F48493"
          d="M383.711,504.211c-0.561,0.473-1.125,0.943-1.687,1.416
			c-0.675-0.72-1.777-0.799-2.399-1.6c2.681-3.526,7.271-5.096,9.424-9.187c0.124-0.234,0.904-0.122,1.377-0.171
			c-0.129,0.587-0.094,1.291-0.415,1.737c-1.505,2.117-3.108,4.168-4.675,6.243C384.796,503.17,384.254,503.688,383.711,504.211z"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          fill="#EC2B4F"
          d="M406.33,487.562c-0.288,0.257-0.562,0.532-0.865,0.768
			c-2.43,1.891-6.382,2.998-7.729,1.175c-1.661-2.242,2.208-3.49,3.975-4.943c0.587-0.482,1.061-1.104,1.585-1.663
			C406.976,482.716,406.63,485.154,406.33,487.562z"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          fill="#F0546B"
          d="M379.625,504.027c1.184-0.042,2.807-0.745,2.399,1.6
			c-3.283,4.075-5.919,8.782-10.921,11.181c-0.525,0.233-1.047,0.464-1.572,0.698c-0.604,0.635-1.198,1.278-1.813,1.902
			c-1.19,1.204-2.898,2.882-4.185,1.45c-1.349-1.505,1.729-0.58,2.035-2.05c0.435-2.088,1.526-4.042,2.336-6.055
			c0.247-0.28,0.493-0.563,0.739-0.845c1.7,1.152,3.324,1.331,4.826-0.349c0.536-0.599,0.996-0.862-0.091-1.662
			c-1.933-1.424-1.216-2.693,0.814-3.43C376.954,507.774,377.526,504.199,379.625,504.027z"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          fill="#EC2A4F"
          d="M374.191,506.469c-0.484,1.154-2.962,3.472,1.158,1.97
			c0.247-0.089,1.059,0.502,1.027,0.657c-0.52,2.488-2.407,3.997-4.447,5c-1.717,0.844-3.37,0.322-3.287-2.187
			c0.275-0.767,0.552-1.535,0.829-2.303c1.302-1.58,2.605-3.159,3.907-4.74c1.453-0.07,1.334,0.662,0.814,1.595L374.191,506.469z"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          fill="#F58E9E"
          d="M372.631,498.639c2.13-1.585,3.546-4.777,7.102-3.159
			C377.365,496.533,374.998,497.585,372.631,498.639z"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          fill="#F0546B"
          d="M374.193,506.461c-0.271-0.53-0.543-1.063-0.814-1.595
			c0.168-0.597,0.415-1.613,1.093-1.069C375.393,504.533,375.04,505.641,374.193,506.461z"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          fill="#FDC155"
          d="M293.258,433.314c2.8-1.205,5.384-3.02,8.601-3.061
			c-0.128,4.149-2.633,7.122-5.072,10.095c-0.522,0.638-1.159,1.732-2.161,1.114c-0.45-0.275-0.521-1.338-0.576-2.057
			C293.895,437.358,294.383,435.237,293.258,433.314z"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          fill="#F6DDAF"
          d="M389.142,225.016c1.208-2.051,2.847-2.975,5.372-3.25
			c6.941-0.761,11.66-6.03,11.873-13.036c0.097-3.154,2.369-4.485,4.309-6.135c0.803-0.682,2.301-0.218,2.291,0.579
			c-0.04,2.933,5.528,3.842,2.646,7.68c-0.762,1.014-0.861,2.188,0.243,3.165C408.257,220.831,399.938,225.931,389.142,225.016z"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          fill="#FD7693"
          d="M507.025,324.877c3.609,7.771,9.544,13.055,17.64,15.617
			c2.011,0.637,1.07,2.943,2.843,3.221c0.604,0.342,0.988,0.887,1.166,1.517c0.641,2.268,1.229,4.373,4.314,4.492
			c1.235,0.047,2.115,1.448,2.85,2.543c1.243,1.846,0.418,3.55-1.426,3.275c-3.747-0.559-8.242,1.936-11.264-2.492
			c1.958,2.131,1.068,5.509,3.582,7.211c0.062,0.511,0.657,1.609-0.433,1.209c-1.251-0.458-3.74,0.058-3.612-1.523
			c0.259-3.266-0.381-5.095-3.965-5.236c-0.266-0.01-0.471-1.597-0.706-2.451c2.794-2.985,2.53-4.99-0.931-7.815
			C511.068,339.538,506.5,333.386,507.025,324.877z"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          fill="#F3CB92"
          d="M404.826,158.231c-0.771-1.328-0.736-2.975,0.721-3.244
			c2.499-0.46,5.139-1.298,7.689,0.192c3.527,2.063,7.215,2.911,10.64-0.025c1.673-1.437,2.052-0.739,3.524,0.46
			c3.741,3.042,8.001,5.451,12.055,8.113c-3.443,2.537-4.386,4.706,0.842,6.081c0.127,0.035-0.029,1.142-0.051,1.75
			c-1.368,2.121-7.899-1.781-5.508,4.722c3.853,5.425,0.464,10.985-1.245,15.476c-1.636,4.303-3.446,9.965-8.955,12.044
			c-0.798-1.958-2.99-2.303-4.415-3.528c-4.526-3.899-2.041-7.14,0.086-10.618c0.468-0.764,1.361-1.366,0.838-2.399
			c-0.526-1.033-1.591-0.646-2.458-0.755c-6.928-0.886-13.962-1.174-18.78-7.655c-2.252-3.029-3.189-6.04-2.799-9.635
			c0.261-0.006,0.521-0.003,0.784,0c1.846,2.616,6.065,3.538,5.546,7.879c-0.076,0.624,0.734,1.036,1.338,1.295
			c4.607,1.958,9.219,3.896,14.33,1.743c1.398-0.588,2.751-0.335,4.197,0.003c2.148,0.502,4.311,0.643,2.831-2.758
			c-0.212-0.489-0.264-0.988-0.137-1.519c0.955-3.995,0.411-4.926-3.667-6.03c-1.493-0.403-3.229-0.319-2.69-2.792
			c0.529-2.422,1.822-3.656,4.326-3.532c1.16,0.058,1.976,1.248,3.495,1.056c-1.409-1.082-2.743-1.487-4.272-1.641
			c-2.337-0.243-4.611-0.81-6.717-1.964c-2.532-1.392-4.723-1.619-5.623,1.929c-0.125,0.499-0.63,0.812-1.11,1.056
			C406.757,163.112,405.257,161.123,404.826,158.231z"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          fill="#F9D8C4"
          d="M459.89,183.355c0.008-1.305,0.017-2.613,0.025-3.918
			c2.887-2.083,4.347,0.826,5.999,2.025c1.714,1.244-0.818,1.983-1.098,3.09c-0.062,0.249-0.175,0.531-0.357,0.691
			c-1.45,1.299,0.593,5.272-2.646,4.625C458.383,189.184,459.466,185.882,459.89,183.355z"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          fill="#EB9440"
          d="M396.135,188.17c0.264,0.253,0.528,0.502,0.792,0.752
			c3.01,1.423,6.655,2.312,5.825,6.948c-0.854,0.217-4.718,4.126-5.187,5.227c-0.808,1.9-2.37,2.674-4.309,2.942
			c-1.856,0.259-3.906,0.583-4.013,2.938c-0.092,2.005,1.223,3.417,3.109,4.088c2.084,0.739,3.939,0.352,5.091-1.68
			c2.329-4.101,6.399-7.466,5.56-12.946c-0.058-0.372,0.31-0.685,0.704-0.848c2.636,1.129,2.86,3.359,2.65,5.787
			c-0.235,0.281-0.472,0.562-0.707,0.844c-2.164,3.938-4.003,7.987-5.496,12.258c-1.569,4.485-2.866,4.744-7.839,3.618
			c-1.383-0.314-0.475,1.868-1.601,2.207l0,0c-2.546,1.491-5.11,2.94-7.473,4.731c-1.117,0.848-2.42,0.551-3.745,0.135
			c-3.999-1.261-7.862-2.828-11.516-4.862c-3.523-1.964-4.771-7.633-2.338-10.848c0.844-1.116,1.82-1.906,0.703-3.314l0.018-0.035
			c-1.255-1.708-2.57-3.416-0.841-5.566c3.103-3.861,6.227-0.998,8.869,0.422c2.289,1.231,1.927,3.717,0.264,5.701
			c-1.572,1.881-0.187,3.676,0.648,4.949c1.316,2.003,1.865-0.63,2.85-0.943c1.192-0.378,2.508-0.358,3.771-0.512
			c0.357,0.115,0.659,0.313,0.909,0.588c1.324,2.371,0.7,3.941-1.906,4.77c-0.451,0.147-1.679-0.275-1.114,0.863
			c0.292,0.595,1.182,0.192,1.735-0.186c1.789-1.216,2.054-3.222,2.604-5.077c0.472-1.551-0.524-2.981-0.396-4.472
			c0.131-1.533-0.087-3.41,2.244-3.634c3.271-0.371,3.692-3.416,5.403-5.227C394.188,194.846,392.475,190.224,396.135,188.17z"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          fill="#F6DDAF"
          d="M400.188,189.584c-0.539-1.548-1.078-3.1-1.617-4.648
			c0.448-3.241,2.344-1.753,3.589-0.896c3.73,2.569,7.529,5.102,10.896,8.106c2.406,2.144,1.414,5.378,0.825,8.101
			c-0.293,1.359-1.633,0.578-2.025-0.206c-1.139-2.274-3.181-2.885-5.385-3.327C403.11,195.454,402.422,191.839,400.188,189.584z"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          fill="#F0C177"
          d="M366.346,206.148c1.544,0.793,1.503,1.625,0.535,3.074
			c-3,4.488-1.972,8.228,2.735,10.793c1.593,0.87,3.292,1.583,4.776,2.607c6.193,4.284,10.995-0.787,16.322-2.316
			c-5.438,6.356-8.116,7.07-15.175,4.28c-2.649-1.046-5.434-1.75-8.085-2.79c-2.161-0.851-3.882-2.156-3.271-4.968
			c0.15-0.691,0.282-1.791-0.094-2.13c-4.301-3.886-2.035-5.688,2.069-7.073C366.363,207.556,366.291,206.66,366.346,206.148z"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          fill="#F0C177"
          d="M390.714,220.307c-0.176-1.145-0.321-2.297-0.541-3.436
			c-0.16-0.826-0.235-1.609,0.763-1.878c0.889-0.243,1.488,0.285,1.875,1.027c2.501,4.818,4.48,2.015,5.761-0.672
			c2.146-4.504,3.433-9.437,7.078-13.125c-3.522,5.108,0.475,13.352-7.376,16.868C395.655,220.262,393.251,220.066,390.714,220.307z
			"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          fill="#EFBD73"
          d="M400.188,189.584c3.727,0.94,3.663,5.217,6.283,7.13
			c-0.155,1.555,1.836,3.157-0.114,4.665c-1.118-1.747-0.6-4.431-3.079-5.403c-0.281,0.198-0.513,0.163-0.691-0.141
			c-0.67-3.302-3.133-5.131-5.66-6.913C398.012,189.142,399.101,189.364,400.188,189.584z"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          fill="#EEBD75"
          d="M409.491,163.791c0.847-6.699,4.261-5.905,8.632-3.1
			c2.735,1.756,6.438,0.467,9.181,2.591c0.581,0.448,1.519,0.432,2.292,0.63c-0.576,0.5-1.111,1.059-1.737,1.488
			c-0.862,0.595-2.398,1.27-2.676,0.18c-0.795-3.11-2.646,0.188-3.642-0.829c-0.487,1.104-1.417,2.24-1.347,3.308
			c0.086,1.363,2.265,0.701,2.542,0.675c4.466-0.416,4.13,4.373,6.671,5.873c-3.983,1.148,0.078,4.77-2.004,6.484
			c-0.17,0.138-0.521,0.205-0.725,0.132c-4.64-1.699-9.124,2.7-13.987,0.173c-1.616-0.842-4.328,0.976-6.201-0.867
			c-1.703-1.673-6.484-0.701-4.396-5.874c0.657-1.625-4.997-1.775-4.299-5.444c1.363-0.614,1.272-3.685,3.835-2.271
			C404.802,167.268,407.932,167.492,409.491,163.791z"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          fill="#FBE9D5"
          d="M409.491,163.791c-0.323,4.613-3.279,5.797-7.858,3.148
			c0.567-2.812,0.896-5.515-2.037-7.428c-0.495-0.323-0.933-1.427-0.754-1.955c0.456-1.356,1.5-0.339,2.263-0.208
			c1.255,0.211,2.483,0.579,3.722,0.883C406.381,160.086,407.936,161.938,409.491,163.791z"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          fill="#FCE5CC"
          d="M444.954,157.406c-0.97,4.657-2.969,6.333-6.966,2.446
			c-0.524-0.512-1.552-0.726-0.978-1.733c0.398-0.698,1.07-0.525,1.713-0.199c2.176,1.104,4.073-1.382,6.241-0.505L444.954,157.406z
			"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          fill="#F5DCAB"
          d="M382.112,210.799c-0.063-0.211-0.126-0.425-0.185-0.636
			c0.101-1.216-0.442-2.083-1.621-2.269c-4.355-0.69-2.839-3.755-2.613-6.302c0.323-3.669,2.466-2.399,4.523-1.743
			c0.823,0.262,1.798,0.029,2.702,0.044c1.019,0.02,2.338-0.252,2.778,0.86c0.514,1.306-1.358,1.312-1.696,2.262
			c-3.445,2.579,0.856,5.876-0.799,8.621C383.803,212.715,383.054,211.404,382.112,210.799z"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          fill="#EA963E"
          d="M385.725,198.278c-4.207,1.027-4.494-2.066-5.062-4.737
			c-0.195-0.915,0.126-2.115,1.387-1.68c2.821,0.979,6.628,1.123,7.149,4.895C389.522,199.094,386.937,197.94,385.725,198.278z"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          fill="#FAB6B9"
          d="M418.209,651.825c-0.089,1.824-1.172,2.49-2.847,2.422
			c-0.893-0.034-2.25-0.074-2.014-1.19c0.376-1.752,1.961-2.751,3.655-3.094C418.449,649.67,418.084,651.009,418.209,651.825z"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          fill="#FDCCD8"
          d="M440.752,644.288c-0.162-0.985-0.426-1.528-0.256-1.806
			c0.202-0.328,0.775-0.427,1.188-0.625c0.09,0.321,0.327,0.695,0.231,0.943C441.765,643.195,441.39,643.502,440.752,644.288z"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          fill="#FAB1A8"
          d="M498.551,559.122c-0.305-0.268-0.609-0.536-0.913-0.805
			c-2.88-2.602-6.927-1.572-10.1-3.237c-0.872-1.703,0.466-3.427-0.048-5.138c0.129-1.162-0.587-1.916-1.252-2.719
			c-0.767-0.928-0.517-1.647,0.566-2.114c1.979-1.088,3.974,1.774,5.941-0.016c1.832-1.669,0.187-3.757,0.66-5.599
			c0.594-2.316,1.192-4.555,3.958-5.291c0.799-0.211,1.695-0.644,1.748-1.875c-2.942,1.138-4.291,4.815-7.867,4.709
			c-0.363-0.064-0.71-0.183-1.048-0.336c-2.08-1.172-4.374-0.295-6.537-0.686c-1.874-0.335-4.036,0.062-5.175-2.088
			c-0.108-0.494-0.059-1.084-0.579-1.369c-2.784-1.53-3.087-2.665-1.369-5.234c1.599-2.389,0.963-4.205-1.904-4.356
			c-2.481-0.132-4.894-0.915-7.388-0.681c-2.007,0.19-3.908-0.036-5.644-1.156c-1.807-1.167-3.754-0.812-5.705-0.504
			c-1.367,0.216-2.185,0.795-1.93,2.331c0.489,2.97-1.201,3.33-3.525,2.93c-3.85-0.663-7.321-2.664-11.304-2.994
			c-1.93-0.16-2.068-1.743-1.085-3.246c0.852-1.302,2.127-2.315,2.604-3.875c0.467-0.631,1.068-1.035,1.859-1.121
			c2.366,0.647,4.251-0.157,5.877-1.883c1.898-2.013,4.185-3.36,6.942-3.847c0.575,0.012,1.095,0.175,1.522,0.574
			c0.289,0.645-1.454,2.621,1.384,1.622c2.274-0.799,4.461,0.436,6.825,1.425c3.534,1.481,7.812,1.066,11.188,3.685
			c0.575,0.447,1.935-0.496,2.965-0.671c1.164-0.2,2.237,0.047,2.375,1.353c0.29,2.755,1.942,3.396,4.349,3.208
			c0.972-0.077,1.66,0.531,2.367,1.165c3.025,2.706,3.33,2.646,6.128-0.468c1.265-1.405,2.387-3.358,4.78-1.743
			c0.988,3.084,1.857,5.918,4.072,8.765c2.463,3.167,3.521,8.36,3.093,12.454c-0.214,2.051,1.369,1.507,1.176,2.269
			c-0.534,2.13-1.96,3.062-4.183,2.629c-1.669-0.326-3.262-0.108-3.962,1.603c-0.688,1.676,1.038,2.029,1.909,2.918
			c1.16,1.183,2.08,2.629,2.962,4.05c0.488,0.787,0.055,1.532-0.755,1.916c-0.827,0.391-1.506,0.218-1.857-0.729
			c-0.322-0.877,0.125-2.271-1.484-2.278c-0.163,0-0.492,0.381-0.46,0.52c0.278,1.244,0.443,2.555,0.99,3.682
			c0.742,1.532,4.673,0.017,3.626,2.438C503.521,561.209,500.597,558.615,498.551,559.122z"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          fill="#F99C90"
          d="M499.219,519.072c-2.897-0.396-3.529,2.141-4.938,3.736
			c-2.178,2.474-4.408,3.241-6.353-0.29c-0.512-0.931-0.831-1.767-2.306-1.446c-3.109,0.674-5.479,0.364-4.898-3.969
			c0.226-1.678-1.229-0.654-1.922-0.4c-2.631,0.959-5.343,2.59-6.541-1.832c-0.287-1.062-1.695-1.828-2.518-0.951
			c-2.96,3.153-4.583,0.585-6.596-1.171c-2.088-1.821-4.567-0.935-6.697-0.187c-1.007,0.352-1.549,3.228-3.001,1.533
			c-1.404-1.639,1.403-1.895,2.129-2.929c0.371-0.526,0.789-1.025,1.184-1.538c0.185-0.495,0.437-1.076,0.92-1.192
			c4.367-1.095,8.757-1.844,13.207-0.416c7.079-0.556,13.69,0.973,19.794,4.572c2.407,1.416,5.6,0.671,7.689,3.172
			c0.88,1.053,1.722,1.833,1.587,3.259C499.711,519.04,499.465,519.056,499.219,519.072z"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          fill="#FDD0BF"
          d="M528.295,560.713c-2.858,1.276-1.477,3.01-0.523,4.715
			c0.602,1.079,1.044,2.64,0.077,3.234c-1.666,1.031-1.611-0.904-2.086-1.817c-0.459-0.886-1.11-1.475-2.178-1.228
			c-1.034,0.239-1.421,1.026-1.561,2.006c-0.19,1.325-0.674,3.101-2.107,2.643c-1.639-0.521,0.733-1.722,0.316-2.448
			c-0.957-1.66,1.135-4.914-2.77-5.108c-1.31-0.066-0.346-1.075-0.101-1.714c0.384-1.005,2.309-2.313,0.421-2.962
			c-1.093-0.379-3.52-1.803-4.508,1.159c-0.8,2.4-3.453,2.33-5.433,3.089c2.34-3.356-3.709-5.379-1.391-8.77
			c0.515-0.758-2.251-1.874-0.439-1.973c1.966-0.112,3.741-3.142,5.955-0.522c0.986,1.168,2.07,3.315,3.898,0.498
			c0.42-0.642,1.773,0.283,2.214,1.058c1.441,2.538,2.998,3.527,5.362,0.965c0.435-0.474,6.192,3.232,6.106,3.823
			C529.373,558.577,527.38,559.101,528.295,560.713z"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          fill="#EC7077"
          d="M484.227,586.618c2.365-4.466-2.241-6.639-4.013-8.736
			c-1.678-1.99-3.039-4.616-5.32-6.219c-3.182-1.167-3.593-3.43-2.296-6.194c0.728-1.55,1.244-3.022,1.046-4.811
			c-0.518-4.674,1.02-6.379,5.661-6.541c1.29-0.045,2.593-0.064,3.792,0.553c1.283,2.949-0.968,3.954-2.847,5.164
			c-0.175,2.683-0.35,5.367-0.525,8.052c-0.561,3.18,1.222,6.091,4.136,6.464c1.133,0.146,1.891,0.788,2.771,1.299
			c1.731,1.19,3.065,2.732,4.038,4.591C488.521,582.363,486.375,584.492,484.227,586.618z"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          fill="#F8CBBC"
          d="M480.25,559.834c1.093-1.526,2.186-3.056,3.278-4.585
			c0.521-0.854,1.385-1.116,2.279-1.333c0.563-0.062,1.081,0.028,1.496,0.45c3.198,1.962,8.232-0.879,10.334,3.951
			c-2.142-0.464-4.261-1.132-6.426-1.309c-1.031-0.083-4.282-2.096-2.551,2.124c0.811,1.974-1.574,1.536-2.651,1.483
			C484.082,560.525,482.169,560.111,480.25,559.834z"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          fill="#F5A19D"
          d="M486.632,575.648c-0.757-0.176-1.507-0.402-2.272-0.521
			c-1.308-0.201-3.254,2.471-3.51,0.544c-0.344-2.578-4.003-4.827-1.125-7.785c-0.485,1.57,0.259,2.834,1.695,2.673
			C485.449,570.105,486.106,572.725,486.632,575.648z"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          fill="#FD6883"
          d="M526.73,360.261c-1.794,0.016-3.69,0.054-2.946-2.639
			c0.422-1.532-0.883-2.354-1.195-3.577c-0.211-0.822-3.496,0.029-1.366-2.082c0.44-0.436,2.847-0.989,3.931,0.949
			c0.964,1.728,2.457,2.697,4.396,1.021c0.525-0.454,1.234-0.761,1.695-0.086c1.363,1.984,3.771,1.354,4.161,0.262
			c0.485-1.349-1.617-3.81-2.731-3.604c-6.656,1.234-3.867-4.264-5.167-6.789c0.262-0.518,0.523-1.033,0.785-1.551
			c1.908,2.47,5.548,2.655,7.211,5.422c1.133,1.888,2.416,3.622,4.222,4.924c2.254,2.463,5.214,4.142,7.406,6.67
			c1.069,1.234,2.517,2.264,2.331,4.197c0.015,0.274,0.029,0.55,0.042,0.825c-1.746,2.54-0.689,5.096-0.228,7.732
			c0.346,1.961,1.166,4.421-0.795,5.867c-2.116,1.557-3.941-0.026-5.471-1.526c-1.364-1.336-4.89-0.486-4.044-4.207
			c0.207-0.914-1.425-0.863-2.288-0.716c-1.28,0.22-0.774,0.886-0.38,1.667c0.698,1.382-0.01,2.027-1.379,1.788
			c-2.33-0.409-5.108-7.088-4.876-9.453c0.176-1.789-1.058-3.803-1.95-5.572C527.617,358.836,526.831,359.044,526.73,360.261z"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          fill="#FD9C98"
          d="M549.463,363.377c-2.663-4.14-7.123-6.482-10.205-10.202
			c-0.393-0.618-0.432-1.293,0.203-1.677c1.014-0.614,1.653,0.112,2.22,0.857c3.927-0.237,7.853-0.474,11.777-0.71
			c1.353,0.79,2.123,2.428,0.684,3.017c-4.283,1.752-0.929,4.197-0.877,6.292c0.014,0.562,0.752,1.023,0.963,1.65
			C552.839,364.1,551.036,363.027,549.463,363.377z"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          fill="#F25E5E"
          d="M559.755,357.858c5.292-1.001,7.715,4.101,11.851,5.566
			c-3.958,6.279-7.886,1.011-11.823-0.032c-0.582-0.454-1.162-0.911-1.742-1.369C556.129,359.109,556.365,358.536,559.755,357.858z"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          fill="#FD9C79"
          d="M559.755,357.858c-1.788,0.886-2.929,2.041-1.715,4.166
			c-1.272,0.194-2.541,0.386-3.812,0.582c-4.653-1.199-2.528-5.537-3.765-8.313c-0.271-0.614,0.672-0.982,1.379-0.614
			c4.058,2.111,2.07-0.57,1.616-2.032c3.007-0.476,4.176,3.272,7.062,3.071C560.265,355.766,560.009,356.812,559.755,357.858z"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          fill="#E35B6E"
          d="M564.913,370.504c2.93-0.78,5.847-1.632,8.801-2.293
			c0.917-0.208,2.463-0.602,2.766,0.505c0.387,1.415-1.483,0.848-2.136,1.517c-0.616,0.636-1.32,1.188-1.985,1.781
			C569.832,371.735,566.967,373.117,564.913,370.504z"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          fill="#FD7693"
          d="M541.681,352.356c-1.043-0.425-2.19-1.155-2.423,0.819
			c-3.655-3.67-7.31-7.342-10.965-11.011c1.608,0.662,3.651-0.387,5.015,1.388c0.483,0.634,1.161,0.682,0.852-0.501
			c-0.187-0.708-1.799-1.421-0.276-2.035c0.462-0.188,2.296,1.472,2.198,1.673c-2.509,5.31,3.729,5.316,4.947,8.295
			C541.221,351.454,541.462,351.898,541.681,352.356z"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          fill="#FBEAEC"
          d="M448.979,486.171c-3.971-0.003-7.433,1.834-11.059,3.07
			c-1.173-0.486-3.353-1.014-3.335-1.458c0.064-1.673,2.004-0.969,3.068-1.578c7.373-4.218,15.322-7.085,23.689-8.268
			c5.442-0.772,11.046-0.39,16.575-0.529c1.362,0.807,3.426,0.772,3.447,3.139c0.017,1.782-0.86,2.627-2.499,2.898
			c-7.411-0.703-14.535,0.854-21.613,2.787C454.494,486.155,451.725,487.671,448.979,486.171z"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          fill="#E72E4D"
          d="M457.51,485.195c5.805-2.434,12.104-2.331,18.158-3.489
			c1.266-0.243,2.521,0.445,3.199,1.739c0.696,1.33-2.612,2.595-0.679,3.174c2.307,0.693,5.612,3.727,7.507-0.883
			c0.338-0.822,0.732-2.423,1.451-1.884c3.979,2.983,6.419-1.387,9.643-1.737c1.54,2.273,2.224,4.188-1.45,5.115
			c-1.791,0.45-2.59,2.232-0.428,2.783c3.78,0.965,5.657,3.947,8.219,6.237c-2.088,1.845-4.558,1.276-6.367-1.462
			c-5.728-1.379-11.455-2.757-17.182-4.14c-2.645,0.922-5.189-0.515-7.803-0.31c-3.141-0.147-6.151-0.147-9.149,1.379
			c-2.503,1.277-5.967-0.803-6.994-3.261C454.733,486.296,455.661,485.575,457.51,485.195z"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          fill="#F8CEBD"
          d="M470.89,508.021c-4.709,0.536-9.418,1.071-14.127,1.608
			c-0.526-0.01-1.051-0.017-1.576-0.026c-0.079-0.099-0.229-0.201-0.228-0.3c0.016-4.127,5.058-10.634,8.87-11.468
			c6.512,1.052,13.049,1.966,19.524,3.198c4.554,0.867,1.382,4.336,1.428,6.329c0.062,2.521-1.84,0.674-3.059,0.338
			C478.103,506.704,474.413,505.146,470.89,508.021z"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          fill="#FCC5AC"
          d="M463.829,497.835c-3.856,3.209-6.744,7.123-8.643,11.768
			c-2.502,1.255-4.892,2.521-6.904,4.679c-1.309,1.404-3.471,3.527-5.777,0.779c0.264-3.576,3.541-3.628,5.745-4.93
			c5.589-4.478,9.656-10.058,12.129-16.791c3.281-2.749,7.241-3.596,11.344-4.046c2.74-0.247,5.319,0.432,7.858,1.355
			c0.021,1.504,1.179,1.891,2.286,2.322c1.006,0.391,2.821-0.406,2.912,1.215c0.1,1.779-1.874,2.147-3.112,2.442
			c-2.639,0.626-5.383,1.091-8.081,1.08c-1.729-0.002-4.24-0.092-3.794-3.118c0.134-0.905,0.018-1.82-0.898-1.709
			c-0.954,0.115-2.661,1.202-2.619,1.312c1.279,3.278-1.248,2.625-2.871,2.892c-0.111,0.017-0.212,0.093-0.315,0.141
			C463.335,497.432,463.58,497.634,463.829,497.835z"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          fill="#F3817F"
          d="M496.763,494.789c1.912,1.411,3.85,2.701,6.367,1.462
			c1.297,0.534,2.101,1.549,2.572,2.812c0.148,0.392,0.006,0.898-0.004,1.352c-0.698-0.318-1.847-0.481-2.021-0.984
			C502.514,496.078,495.418,501.663,496.763,494.789z"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          fill="#F7BFB9"
          d="M451.253,584.995c3.987,2.595-1.398,5.298,0.093,7.921
			c-1.29,4.076-3.655,8.021-1.614,12.515c0.245,0.541,0.574,1.344,0.997,1.443c1.542,0.358,3.297,0.383,2.797,2.701
			c-0.503,2.318-2.506,1.145-3.845,1.515c-0.425,0.458-0.958,0.678-1.566,0.742c-5.577-1-9.774-4.427-13.924-7.939
			c-0.57-0.541-0.934-1.207-1.156-1.952c-0.125-3.806,0.013-3.96,4.363-5.137c-2.428-0.288-4.401,1.82-6.928,2.124
			c-0.365-0.093-0.685-0.263-0.97-0.506c-1.238-2.472-4.227-3.794-4.22-7.179c0.007-2.574,0.266-4.635,2.909-5.732
			c1.335-0.556,2.277-1.718,3.496-2.476c0.877-0.544,1.969-0.573,2.781-1.237c-0.868-0.241-1.778-0.324-2.616-0.676
			c-1.876-0.793-1.738-2.099-0.747-3.451c1.509-2.062,3.371-3.739,4.431-6.288c0.826-1.99,2.562-0.086,3.765,0.732
			c0.179-0.33,0.586,2.542,0.742-0.095c0.112-1.906,0.987-3.755,2.073-5.419c1.005-0.918,1.769-0.461,2.461,0.42
			c-0.35,3.805-0.099,7.6,0.124,11.396c-1.797,1.233-2.973,2.772-2.914,5.129c0.042,1.696,0.809,2.704,2.335,3.2
			c0.878,0.284,1.842,0.444,2.604-0.23c0.792-0.7,0.07-1.43-0.16-2.126c-0.284-0.874-0.631-1.734-0.051-2.624
			C449.501,580.78,449.274,584.504,451.253,584.995z"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          fill="#F49193"
          d="M448.071,611.077c0.536,0.002,1.071,0.01,1.607,0.013
			c3.611,0.519,6.884,3.094,10.959,1.05c1.492-0.749,4.313,0.154,4.858,2.197c0.607,2.277-1.559,2.495-3.383,3.167
			c-1.977,0.729-4.451,2.297-5.089,4.076c-2.476,6.912-6.229,2.955-9.892,1.739c-2.678-0.89-3.868-4.069-7.256-3.995
			c-3.219,0.07-6.073,1.231-8.485,2.793c-1.917,1.243-3.182,0.877-4.957,0.453c-7.085-1.676-12.25-7.312-19.221-9.185
			c0.32-3,2.104-3.949,5.22-2.514c1.918,0.883,3.674,1.957,5.727,0.478c0.753-0.237,1.482-0.242,2.166,0.204
			c-0.104,4.294-0.104,4.29,5.211,4.888c0.516,0.062,1.011,0.222,1.489,0.429c1.32,0.781,2.629,0.58,4.111,0.349
			c4.92-0.765,9.916-2.516,14.742,0.522c0.894,0.562,2.013,0.644,2.946-0.097c1.126-0.89,0.194-1.907,0.094-2.867
			C448.786,613.513,447.592,612.483,448.071,611.077z"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          fill="#F8C1C2"
          d="M420.602,611.852c-0.803,0.002-1.604,0.006-2.406,0.013
			c-4.513-1.213-6.772-4.907-9.186-8.4c-0.508-0.734-0.649-1.715-1.152-2.489c-1.566-2.416-2.806-2.376-4.136,0.191
			c-0.774,1.494-1.167,3.479-3.565,1.988c-2.793-3.419-7.571-4.249-10.228-7.851c3.787-3.273,6.289-8.603,12.583-7.867
			c0.162,0.093,0.325,0.187,0.488,0.275c1.318,2.069,3.417,3.439,4.973,5.15c4.408,4.856,10.167,8.504,13.337,14.488
			c0.343,1.392,0.467,2.768-0.263,4.087C420.899,611.576,420.75,611.713,420.602,611.852z"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          fill="#F37683"
          d="M400.157,603.154c1.63,0.178,2.229-0.638,2.531-2.18
			c0.273-1.404,0.304-3.24,2.58-3.212c2.001,0.026,3.027,1.068,3.588,2.888c1.551,5.023,5.601,7.995,9.341,11.211
			c-1.676,3.302-3.705,1.541-5.519,0.268c-2.391-1.681-4.193-1.57-5.463,1.257c-0.262,0.26-0.524,0.517-0.785,0.774
			c-2.187-1.649-6.122-1.521-5.675-5.873c0.109-1.043-1.545-0.56-2.187-1.163C396.791,604.875,398.942,604.204,400.157,603.154z"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          fill="#F48E92"
          d="M398.567,607.124c1.772-0.145,4.358-1.45,2.951,2.472
			c-0.453,1.265,1.208,2.415,2.116,2.035c3.137-1.318,1.992,1.59,2.795,2.529c-2.974,2.966-6.208,0.913-8.588-0.416
			c-2.22-1.237-0.502-3.115,1.34-4.298C400.852,608.377,398.811,607.903,398.567,607.124z"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          fill="#9D2047"
          d="M470.995,581.951c2.332,2.734,4.257,5.536,1.301,9.024
			c-0.497,0.585-1.153,1.631-1.608,1.568c-3.432-0.494-6.689,3.294-9.343,1.78c-4.96-2.83-8.852-7.051-8.284-13.732
			c0.111-1.325-1.198-2.771-1.85-4.163c-5.137-3.641-5.116-6.663,0.207-9.914c3.482-2.126,4.72-6.218,7.851-8.613
			c0.437-0.41,0.909-0.762,1.42-1.065c1.606-0.893,3.315-1.187,5.124-0.877c1.735,0.724,3.926-1.265,5.438,0.915
			c1.001,1.44,1.912,2.803,0.481,4.549c-0.821,1.002-1.204,2.383-2.121,3.271c-3.403,3.287-1.397,7.255-1.493,10.943
			c-0.021,0.834,0.767,1.449,1.651,1.286c3.632-0.667,2.722,1.243,1.918,3.214C471.443,580.732,471.312,581.378,470.995,581.951z"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          fill="#D33A58"
          d="M470.995,581.951c-2.001-0.239,4.078-5.782-2.219-3.395
			c-0.386,0.145-2.146-1.586-2.12-2.412c0.13-4.357-1.451-8.995,2.524-12.834c1.042-1.005,2.988-2.658,1.536-5.582
			c-1.544-3.109-3.659,1.779-4.531-0.95c-1.128-1.75-0.327-2.959,1.404-2.929c2.337,0.042,4.205-1.05,6.258-1.735
			c4.277-0.674,8.361-0.349,11.991,2.323c-0.771,0.269-1.542,0.54-2.311,0.812c-1.812-0.01-3.646-0.209-5.424,0.022
			c-2.674,0.346-4.869,1.178-3.443,4.764c0.753,1.895,0.963,3.761-0.933,5.576c-2.376,2.278-0.054,4.177,1.165,6.052
			C475.84,575.942,472.78,578.707,470.995,581.951z"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          fill="#E76579"
          d="M459.914,558.368c-3.52,3.477-5.611,8.026-10.431,10.681
			c-3.747,2.066,0.978,4.859,1.728,7.38c-2.676,0.786-5.613,1.543-1.399,4.438c1.578,1.084,0.328,2.983,1.441,4.132
			c-2.508,0.499-2.562-2.93-4.739-2.962c-2.009-0.233-2.533-1.474-2.23-3.278c-1.01-3.9-2.128-7.796-0.145-11.733
			c-0.215-1.895-0.965-4.064,2.356-3.881c0.889,0.048,1.599-0.807,0.98-1.549c-3.171-3.816,0.465-4.008,2.571-5.041
			c1.181-0.56,2.27-1.271,3.386-1.936c0.855-0.511,2.462-0.42,2.339,0.269C455.096,558.723,459.021,556.695,459.914,558.368z"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          fill="#F07275"
          d="M466.19,599.247c1.01,1.773,2.936,2.969,3.247,5.18
			c-2.659,0.31-5.349,1.03-7.966,0.825c-2.864-0.228,0.329-3.366-1.556-4.398C462.008,600.32,464.098,599.781,466.19,599.247z"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          fill="#F7A2A4"
          d="M442.577,566.999c-0.528,1.603-1.107,3.189-1.569,4.812
			c-0.55,1.932-1.283,2.266-2.826,0.734c-0.596-0.591-2.498-2.612-1.962,0.791c0.225,1.428-1.354,1.773-2.134,2.574
			c-2.006,2.067-5.568,4.329,0.843,4.969c0.49,0.048,0.953,0.376,1.43,0.573c-0.437,0.444-0.803,1.01-1.324,1.306
			c-1.742,0.978-3.49,2.292-5.095,3.052c-6.366,3.003-4.266,6.125-1.06,9.816c0.63,0.729,0.747,1.901,1.1,2.866
			c-3.706-0.329-4.896-3.608-6.929-5.897c-1.589-2.797-3.49-5.424-4.593-8.49c-0.641-1.779-1.969-2.992-3.581-3.92
			c-0.451-0.321-0.85-0.703-1.202-1.132c-1.295-1.5-0.664-3.119,0.164-5.038c-5.024,3.525-5.512,5.009-3.647,10.005
			c0.493,1.318,1.558,2.908,0.205,3.966c-1.608,1.256-2.867-0.35-3.958-1.396c-1.564-1.5-2.606-1.653-2.955,0.853
			c-0.324,0.247-0.647,0.258-0.969-0.006h-0.003c-1.316-2.688-6.86-2.91-4.514-7.734c-1.907,0.091-4.288-0.497-5.603,0.433
			c-1.913,1.348-1.542-0.157-1.846-0.873c-0.349-0.82-0.894-1.892,0.893-1.655c3.096,0.416,3.706-1.688,4.032-4.044
			c0.098-0.704,0.801-1.673-0.271-2.011c-1.036-0.334-1.066,0.79-1.398,1.45c-0.273,0.552-0.692,1.408-1.097,1.438
			c-1.388,0.106-0.417-1.13-0.747-1.715c-1.508-2.696-4.066-4.27-6.449-5.927c-1.302-0.909-2.342,0.742-2.421,1.701
			c-0.54,6.538-2.596,3.538-4.601,0.793c-0.52-0.714-0.83-1.579-1.236-2.373c-2.799-2.315-3.423-5.205-2.241-8.563
			c2.086-1.197,1.799-3.032,1.095-4.782c-1.062-2.648,0.562-4.208,2.183-5.696c0.442-0.408,1.731-0.555,2.104-0.226
			c0.684,0.605-0.299,0.976-0.71,1.372c-0.579,0.561-0.617,1.146,0.037,1.622c0.269,0.195,1.021,0.228,1.024,0.214
			c0.744-3.336,5.098-2.826,6.312-6.234c0.662-1.862,2.292-5.348,6.026-4.312c0.617,3.965-2.188,7.474-1.292,11.172
			c2.374-4.834,4.318-10.271,7.586-15.032c0.865-1.264,1.435-2.83,3.177-3.241c0.806,0.499,2.041,2.229,2.393,1.207
			c1.731-5.058,4.31-2.904,6.975-1.207c0.323,1.223-0.66,1.599-1.372,2.157c-1.427,0.694-2.087,1.718-1.577,3.358
			c0.723,2.323-0.483,3.274-2.604,3.529c-0.258-0.025-0.728-0.046-0.738,0.032c-0.318,2.405,0.738,4.471,2.382,5.902
			c1.756,1.528,4.208,0.746,5.857-0.231c1.959-1.161-0.424-2.562-0.605-3.908c0.084-1.149,0.886-1.82,1.675-2.496
			c0.748-0.642,1.568-0.871,2.503-0.399c1.124,0.56,1.33,1.491,1.065,2.609c-0.235,1.008-0.59,1.987-0.702,3.022
			c-0.086,0.794,0.075,1.289,1.009,0.816c0.23-0.118,0.468-0.229,0.68-0.377c4.253-2.94,8.854-2.262,13.487-1.312
			c1.281,0.672,2.71,1.312,2.615,3.055c-0.058,1.076-0.21,2.359,0.379,3.031c0.557,0.631,0.86-1.207,1.881-0.979
			c1.622,0.361,1.771,1.646,1.384,2.734c-1.176,3.308-3.18,6.248-4.274,9.633c-0.567,1.752-2.133-0.298-3.026,0.219
			c-1.783,1.021-3.459,2.933-5.52,0.827c-1.756-1.795-0.233-3.614,0.409-5.41c0.204-0.562-0.286-1.236-0.215-1.92
			c0.078-0.741-0.459-1.479-1.038-1.429c-1.046,0.094-0.317,0.992-0.314,1.499c0.01,0.927,0.264,2.046-0.35,2.669
			c-2.395,2.431-2.052,5.02-0.923,7.847c0.399,0.999-0.461,2.97,0.935,2.988c1.388,0.018,3.133-0.18,4.41-1.388
			c2.936-2.776,7.082-2.204,10.532-3.624C440.801,565.282,441.8,566.267,442.577,566.999z"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          fill="#FAF4F2"
          d="M402.555,533.211c-3.358,6.297-6.695,12.604-10.101,18.875
			c-0.32,0.589-1.059,0.949-1.604,1.417c-0.059-0.633-0.123-1.268-0.178-1.901c-0.345-4.008,0.902-7.677,2.412-11.289
			c2.742-4.932,7.014-9.48,8.882-14.164c1.97-4.936,5.528-4.898,8.35-7.076C408.606,524.267,402.936,527.287,402.555,533.211z"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          fill="#FCE8E8"
          d="M375.014,558.355c0.747,2.857,1.495,5.71,2.243,8.563
			c-1.018,0.695-2.033,1.403-3.101,0.08c-0.327-2.7-0.634-5.406-0.987-8.106c-0.143-1.077-1.275-2.65,0.118-3.058
			C374.692,555.426,373.768,557.907,375.014,558.355z"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          fill="#F37586"
          d="M366.365,544.981c-1.298-1.092-0.662-2.586-0.814-3.916
			C366.834,542.162,366.157,543.664,366.365,544.981z"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          fill="#EC2349"
          d="M362.375,558.388c1.034-3.509-3.749-6.947-0.102-10.527
			c0.651-0.641-0.542-2.896,1.525-2.26c1.195,0.369,1.776,1.895,1.337,3.324c-0.651,2.128-0.365,4.076,0.652,6.045
			c1.102,2.134,2.193,5.033,0.126,6.463c-1.842,1.276-1.548-2.827-3.533-3.045H362.375z"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          fill="#FABBBD"
          d="M410.362,534.77c0.521-0.518,1.041-1.036,1.561-1.559
			c2.137-3.274-1.038-9.172,5.143-10.429c0.504-0.103,1.689-2.568,1.591,0.564c-0.08,2.582,1.825,3.544,4.317,2.052
			c1.781-1.409,3.056-2.994,2.435-5.52c-0.245-0.996,0.167-2.019,0.657-2.949c1.699-1.578,1.918-5.119,5.515-4.176
			c0.269,0.014,0.536,0.023,0.805,0.036c-0.039,3.626-1.465,6.964-2.198,10.444c-0.169,0.8-1.245,1.986-0.23,2.315
			c1.131,0.371,1.648-1.106,1.753-2.096c0.309-2.924,2.655-4.315,4.411-6.138c0.733-0.765,1.869-0.849,2.8-1.293
			c0.699-0.25,1.398-0.547,2.123-0.073c0.11,0.375,0.429,0.967,0.302,1.087c-6.131,5.798-0.12,5.136,3.13,5.846
			c1.126,0.245,2.08,0.518,3.137,1.173c1.387,0.86,3.604,1.441,5.021,0.922c1.776-0.646-0.455-2.129-0.313-3.273
			c0.09-0.726,0.644-2.039,0.824-2.011c2.793,0.421,5.873-1.952,8.258-0.011c3.804,3.1,8.191,1.176,12.221,1.897
			c2.53,0.454,4.367,1.396,5.567,3.007c1.347,1.815-0.311,2.838-2.108,3.583c-0.522,0.219-0.874,2.019-0.568,2.812
			c0.665,1.737,1.128,0.128,1.652-0.325c0.571-0.494,0.7-0.181,0.99,0.4c0.565,1.134,0.836,2.093-0.394,2.928
			c0.628,2.345,0.487,4.283-2.489,4.731c-0.932,0.01-1.77-0.291-2.513-0.844c-3.755-2.792-4.131-2.78-7.651,0.371
			c-0.812,0.738-1.704,1.376-2.578,2.04c-4.479,0.513-8.972,0.9-13.475,1.123c-0.892,0.045-1.819-0.221-2.695-0.486
			c-1.39-0.42-2.271-1.314-2.3-2.817c-0.031-1.629,0.821-2.419,2.452-2.294c0.762,0.062,1.652-0.461,1.428-0.894
			c-0.479-0.921-1.936-0.786-2.565-1.924c-0.299-0.543-0.583,0.401-0.83,0.677c-1.066,1.177-0.916,2.585-0.835,4.063
			c0.112,2.143-1.544,3.211-3.009,2.064c-3.113-2.434-3.534-0.354-4.21,1.904c-0.104,0.346-0.522,0.571-0.835,0.813
			c-1.768,1.244-3.418,0.854-5.024-0.353c-0.728-0.544-1.387-1.35-2.244-1.525c-3.075-0.625-6.2-1.059-2.117-4.812
			c0.421-0.387,0.235-0.979-0.336-1.259c-0.488-0.242-1.038-0.302-1.444,0.106c-0.55,0.548-0.784,1.359-1.398,1.866
			c-1.328,1.084-2.759,2.207-4.561,1.64c-1.588-0.502-1.211-2.079-1.275-3.349c-0.032-0.621,0.053-1.462-0.896-1.4
			c-0.81,0.051-0.91,0.787-0.938,1.452c-0.022,0.521,0.085,1.04,0.075,1.559c-0.032,1.607-0.737,2.858-2.328,3.184
			c-1.753,0.362-2.574-0.889-3.063-2.347C410.829,536.452,409.957,535.799,410.362,534.77z"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          fill="#F8CEBD"
          d="M439.461,516.717c-3.037,1.732-6.166,3.196-6.647,7.437
			c-0.207,1.826-1.682,4.193-3.971,3.288c-2.613-1.034-0.101-2.777,0.28-4.146c0.985-3.531,1.145-7.325,3.263-10.505
			c4.254-1.466,7.459-3.698,7.409-8.883c-0.013-1.348,1.61-1.768,2.841-2.311c4.48-1.984,8.892-4.12,13.328-6.199
			c1.272-1.45,2.631-2.631,4.723-1.503c1.208,0.943,0.393,2.149-0.077,2.76c-3.799,4.942-6.295,10.963-11.817,14.49
			c-3.064-0.511-5.673,1.061-8.41,1.934C438.45,513.697,439.34,515.385,439.461,516.717z"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          fill="#F18686"
          d="M460.686,493.895c-1.573,0.501-3.146,1-4.721,1.503
			c-1.644-4.179-4.573-0.873-6.884-1.165c-0.592-0.078-1.213,0.061-1.821,0.099c-0.067-1.108,0.848-1.244,1.523-1.261
			c2.906-0.061,2.158-4.312,5.323-4.456c0.961-0.045-0.211-0.831-0.875-1.022c-1.438-0.41-3.519,1.068-4.252-1.424
			c2.845-0.322,5.687-0.65,8.53-0.973c-2.082,0.816-1.495,3.263-0.938,3.73c1.564,1.315,3.761,3.271,5.77,1.954
			c3.052-2.006,6.164-1.648,9.381-1.586C467.982,490.682,464.061,491.629,460.686,493.895z"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          fill="#F59697"
          d="M426.742,517.354c-0.272,1.1-0.726,2.092-0.304,3.365
			c1.007,3.032-0.091,4.764-3.465,4.679c-3.62-0.619-1.13-5.92-4.913-6.417c4.358-1.665,3.308-8.02,8.082-9.326
			c1.04,1.347,0.241,2.664-0.178,3.909C425.467,515.044,425.688,516.255,426.742,517.354z"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          fill="#E93B55"
          d="M438.583,497.889c0.309,5.317-1.839,9.449-6.047,12.595
			c-0.85,0.689-1.84,2.041-2.841,0.906c-1.169-1.329-1.184-3.188-0.456-4.914h0.006c2.484-1.728,2.974-4.809,4.756-7.013l0.011-0.02
			C435.477,498.753,436.688,497.313,438.583,497.889z"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          fill="#EF5E6C"
          d="M429.239,506.476c1.832,0.735-1.446,5.668,3.297,4.008
			c-0.318,0.758-0.637,1.517-0.956,2.274c-2.623,0.466-2.919,3.385-4.836,4.597c-2.868,0.585-3.018-0.749-2.164-2.949
			c0.603-1.556,1.046-3.168,1.562-4.75C427.174,508.595,428.208,507.537,429.239,506.476z"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          fill="#EF5C6B"
          d="M434.001,499.463c-1.229,2.578,0.227,6.98-4.756,7.013
			C429.746,503.404,431.149,500.941,434.001,499.463z"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          fill="#EF5E6D"
          d="M438.583,497.889c-1.347,1.039-2.793,1.788-4.571,1.553
			C436.911,495.662,437,495.632,438.583,497.889z"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          fill="#E26737"
          d="M402.587,195.835c0.23,0.044,0.461,0.093,0.691,0.141
			c2.072,6.126-4.758,16.948-10.286,16.52c-3.524-0.272-4.891-2.927-5.187-5.394c-0.339-2.841,2.096-4.197,4.999-4.101
			c3.274,0.105,4.367-1.686,4.197-4.712c-0.08-1.423-1.689-3.032,0.219-4.271c1.916-1.245,2.188,1.321,3.443,1.638
			C401.28,195.812,401.944,195.781,402.587,195.835z"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          fill="#EEB664"
          d="M382.112,210.799c1.03,0.278,2.059,0.557,3.089,0.838
			c-1.032,1.939-1.9,3.992-3.168,5.762c-0.581,0.809-2.074,1.775-3.059,0.662c-0.782-0.886-1.197-2.354-1.229-3.577
			c-0.04-1.437,0.819-0.24,1.236-0.122C382.545,215.371,383.39,214.024,382.112,210.799z"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          fill="#F8E7D3"
          d="M415.901,179.936c-1.032-0.15-1.934-0.707-1.886-1.657
			c0.067-1.292,1.188-1.91,2.376-2.198c0.957-0.233,1.051,0.467,1.041,1.139C417.413,178.4,417.339,179.581,415.901,179.936z"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          fill="#F48E99"
          d="M476.42,537.937c2.753-0.15,1.114-2.897,2.342-3.951
			c3.029,1.363,6.36,1.504,9.395,0.896c1.996-0.401,1.8,0.69,2.36,1.506c-0.877,1.465-2.457,2.289-3.376,3.734
			c-0.408,0.643-1.178,1.404-1.925,0.931c-2.89-1.833-4.701,0.186-6.389,1.846c-2.546,2.501-5.393,0.436-7.971,0.547
			c-5.217,0.23-5.241-0.312-7.093,5.117c-1.104,2.496-2.664,4.428-5.659,4.64c-1.445,0.103-2.629-0.083-2.741-1.881
			c-0.825,0.966-0.499,2.546-1.918,3.25c-1.253,0.624-2.464,0.915-3.757,0.381c-1.086-0.444-1.065-1.357-0.753-2.313
			c0.115-0.354,0.419-0.646,0.351-1.058c-0.231,0.319-0.521,0.592-0.812,0.847c-0.989,0.873-1.476,2.172-2.538,2.979
			c-0.348,0.179-0.729,0.561-1.041,0.495c-5.802-1.215-5.675,3.816-7.314,6.945c-0.44,0.842-0.183,1.472,0.907,1.605
			c1.713,0.214,5.173-2.446,4.975-4.14c-0.271-2.303,0.768-3.658,2.569-4.721c1.719,0.107,4.394-1.929,4.413,1.968
			c-0.308,1.199-6.628-0.23-1.417,3.614c1.882,1.386-1.108,3.723-1.97,3.406c-2.978-1.097-1.934,1.737-2.919,2.445
			c-0.519-0.167-1.028-0.868-1.562-0.026c-4.173-1.432-7.513,2.265-11.518,1.872c-0.095,3.353-4.398,0.732-5.025,3.525
			c-0.099,0.44-1.563,1.145-1.934,0.226c-0.456-1.132-2.197-1.762-1.458-3.345c0.517-1.107,0.952-2.105-0.096-3.207
			c-1.825-1.919-2.012-3.611,0.576-5.219c1.705-1.063,1.47-2.781,0.888-4.575c-0.34-1.043-0.043-2.294-0.034-3.448
			c0.338,0.104,0.804,0.108,0.99,0.338c0.984,1.214,1.767,2.616,2.862,3.712c2.421,2.417-0.936,3.385-1.124,5.109
			c-0.092,0.825-0.56,1.899,0.638,2.392c1.072,0.435,1.841-0.072,2.823-0.62c0.833-0.468,1.547-4.633,3.319-0.593
			c0.273,0.622,0.908,0.384,0.956-0.37c0.208-3.283,1.938-5.866,3.748-8.439c0.549-0.78,0.872-1.97-0.014-2.463
			c-1.047-0.589-0.912,0.844-1.365,1.334c-0.987,1.071-3.132,0.425-2.664,0.141c3.097-1.871-1.904-2.409-0.397-3.346
			c2.808-1.745-0.484-2.284-0.559-3.437c0.445-1.388,1.571-1.538,2.78-1.547c2.49,0.104,3.269-2.307,4.947-3.397
			c1.162-0.759,2.277-2.303,3.694-1.38c1.228,0.8,0.042,2.415,0.188,3.661c0.168,1.422-2.063,3.115-0.189,4.065
			c1.24,0.632,2.751-1.018,3.808-2.048c3.837-3.746,7.996-6.469,13.724-5.316c0.641,0.128,1.296-0.205,0.959-1.14
			c0.767-0.519,1.532-1.033,2.299-1.55C469.072,536.746,472.745,539.184,476.42,537.937z"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          fill="#ED5D6D"
          d="M463.037,548.145c0.942-4.373,1.901-8.048,8.187-5.876
			c0.934,0.321,6.638,3.489,8.177-1.805c2.036,0.869,4.049-5.28,6.25-0.049c0.082,0.198,0.819-1.336,1.247-2.072
			c0.798-1.371,2.184-1.725,3.619-1.955l0.013-0.006c1.919,0.928,2.762,1.389,1.968,4.421c-1.005,3.839-3.216,4.2-5.926,4.929
			c-0.543,1.764,3.63,1.616,1.611,3.978c-1.626,0.731-2.591-0.563-3.765-1.226c-4.293-2.427-7.175-2.195-9.816,0.791
			c-1.11,0.987-2.73,1.86-3.733,0.814c-1.948-2.034-2.274,0.153-3.209,0.841c-0.244,0.264-0.54,0.448-0.877,0.564
			C465.023,550.95,464.729,548.764,463.037,548.145z"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          fill="#D71C42"
          d="M473.997,548.896c2.027-3.666,7.326-4.329,11.072-1.407
			c1.005,0.783,2.074,1.479,3.113,2.22c0.47,1.695,1.343,3.467-0.879,4.657c-0.488,0.022-0.977,0.048-1.465,0.071
			c-3.989-0.246-7.762-2.08-11.848-1.635c-0.266-0.312-0.477-0.654-0.638-1.03C473.042,550.694,473.483,549.789,473.997,548.896z"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          fill="#F48F9A"
          d="M486.571,545.731c3.754-0.507,3.883-4.267,5.663-6.503
			c1.181-1.483-1.597-1.581-1.705-2.85c3.692,0.44,4.681-3.401,7.32-4.592c0.975-0.44,1.269-3.131,2.644-1.401
			c0.915,1.156,0.754,3.798-1.319,4.263c-4.716,1.052-4.57,4.059-4.507,7.911c0.034,2.021,0.139,7.254-5.067,3.696
			C488.831,545.729,487.595,545.889,486.571,545.731z"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          fill="#FBF1ED"
          d="M439.461,516.717c-3.434-2.232-2.516-3.943,0.821-4.787
			c2.749-0.698,5.488-2.701,8.511-0.785c-2.448,0.74-4.777,1.671-6.286,3.916c-0.489,0.297-0.976,0.593-1.463,0.89
			C440.516,516.206,439.987,516.462,439.461,516.717z"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          fill="#ED596A"
          d="M423.708,592.182c2.09,2.104,4.181,4.206,6.271,6.311
			l0.008-0.005c0.672,0.392,1.294,0.812,0.945,1.751c-0.555,0.993-1.604,1.981-0.854,3.184c1.206,1.933,0.538,3.226-0.828,4.766
			c-1.091,1.235-0.786,2.694,0.943,3.459c0.597,0.264,1.279,0.595,1.859,0.207c0.843-0.562-0.01-1.108-0.224-1.603
			c-1.452-3.36,1.844-4.74,2.9-7.053c4.211,3.025,8.148,6.517,13.343,7.879c1.4,2.54,5.004,4.778,1.038,8.042
			c-0.771,0.634-1.198,0.854-2.266,0.209c-5.386-3.266-10.964-2.314-16.6-0.541c-1.164,0.368-2.987,0.731-3.393-1.432
			c-0.188-0.201-0.4-0.37-0.646-0.392c-6.088-0.503-6.786-1.302-4.118-7.108c1.647-3.587,0.8-6.697-1.694-8.385
			c-4.553-3.081-5.219-7.739-6.575-12.223c-0.795-2.626-0.843-5.415-1.451-8.1c-0.295-1.31-0.519-3.117,1.97-2.386
			c0.26,0.258,0.523,0.517,0.783,0.775c1.948,1.773,3.674,3.794,4.039,6.444C419.57,588.979,421.275,590.789,423.708,592.182z"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          fill="#E93D57"
          d="M434.729,603.198c0.063,1.603,0.134,3.186-1.713,3.983
			c-1.881,0.812,0.105,2.15-0.293,2.872c-1.129,2.054,4.56-1.389,1.51,2.047c-2.381,2.685-4.493,0.186-6.721,0.123
			c0.796-1.648-1.411-3.949,0.769-5.11c2.164-1.154,1.771-1.735,0.463-3.457c-1.608-2.115,0.241-3.181,2.199-3.734
			c1.565-0.389,2.285,0.65,2.961,1.758C434.18,602.187,434.455,602.693,434.729,603.198z"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          fill="#FAD8D3"
          d="M444.283,578.759c0.744,1.093,1.487,2.185,2.23,3.278
			c-0.16,2.121,3.955,3.177,1.984,5.189c-1.751,1.788-4.6,1.364-6.811-0.369c-2.827-2.22-0.332-4.836-0.399-7.226
			C441.236,577.805,443.008,578.225,444.283,578.759z"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          fill="#F7A5A6"
          d="M433.904,601.68c-0.986-0.585-1.975-1.172-2.961-1.755
			c-0.319-0.479-0.635-0.959-0.956-1.438c2.843-1.907,5.983-2.822,9.512-2.762C438.848,598.831,432.507,596.741,433.904,601.68z"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          fill="#F0737E"
          d="M414.335,578.763c-2.668,3.124,0.191,6.154,0.446,9.136
			c0.486,5.677,3.175,10.598,7.674,14.522c2.167,1.891,2.705,4.606,0.734,7.386c-3.254,4.595-1.291,6.841,3.662,7.549
			c-2.785,1.335-5.668-1.956-8.594,0.251c-0.626,0.474-0.791-1.069-0.438-1.664c0.841-1.413,1.844-2.735,2.783-4.091l0.014-0.022
			c0.617-2.057,0.624-4.114,0.003-6.171c-3.281-5.137-7.543-9.424-12.308-13.084c-1.997-1.528-3.777-2.987-4.951-5.178
			c0.269-1.315-2.479-2.512-0.501-3.65c1.597-0.922,3.039,0.64,4.09,1.788c0.873,0.956,1.783,2.693,2.895,1.929
			c1.264-0.87-0.329-2.295-0.811-3.382c-2.124-4.785-0.525-9.271,4.351-11.682c0.475-0.238,1.321-0.312,1.695-0.045
			c0.963,0.693,0.592,1.718-0.036,2.383C413.876,575.972,413.081,577.152,414.335,578.763z"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          fill="#F7B4B6"
          d="M403.361,587.396c4.789,3.451,9.699,6.865,13.147,11.701
			c1.576,2.211,5.814,2.443,4.898,6.468c-0.567,0.352-0.253,1.168-0.803,1.53c-5.411-4.995-9.627-11.243-16.065-15.168
			c-1.592-0.969-1.582-2.881-2.023-4.491C402.796,587.425,403.078,587.408,403.361,587.396z"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          fill="#F49193"
          d="M420.604,607.096c0.267-0.511,0.536-1.021,0.803-1.53
			c0.303,2.16,0.481,4.303-0.791,6.264C420.611,610.251,420.608,608.672,420.604,607.096z"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          fill="#F17C88"
          d="M463.037,548.145c3.646-1.782,2.373,2.387,3.871,3.201
			c-0.354,2.377-1.828,4.581-3.581,5.004c-1.021,0.247-1.916,0.535-2.558,1.392c-0.287,0.207-0.571,0.42-0.855,0.627
			c-2.142,0-4.283,0-6.435,0c0.099-1.136,3.351-1.475,1.988-2.727c-2.013-1.845-2.998,2.001-5.023,1.918
			c-0.907-2.506-3.376-0.317-4.702-1.564c-0.199-0.173-0.267-0.346-0.198-0.519c0.066-0.173,0.135-0.256,0.2-0.256
			c1.147-3.385,1.147-3.385,6.412-5.928c-0.038,2.012-4.184,3.317-2.048,4.824c2.51,1.768,3.575-1.363,4.6-3.427
			c0.395-0.794,1.403-2.128,2.455-1.338c1.688,1.264-0.821,0.951-0.844,1.655c-0.015,0.467,0.053,1.344,0.106,1.347
			c0.909,0.029,1.85-0.736,2.729,0.153C459.85,550.518,462.911,550.637,463.037,548.145z"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          fill="#EC5E6E"
          d="M460.77,557.741c2.048-2.134,4.094-4.266,6.139-6.399
			c0,0.004,0.092-0.078,0.092-0.078c2.463-0.778,4.892-1.293,6.999,0.853c0.004,0.229,0.002,0.458-0.007,0.687
			c-1.743,1.471-3.202,3.313-6.059,1.909c-1.557-0.764-1.665,0.921-1.748,2.066C464.381,557.101,462.575,557.421,460.77,557.741z"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          fill="#F7B0AE"
          d="M436.312,545.784c-1.042,0.269-2.084,0.533-3.124,0.802
			c-2.069-1.369-5.619,2.976-6.813-1.766c-1.414,2.646-4.319,2.354-6.582,3.3c-1.034,0.43-1.815,2.312-2.79,1.431
			c-0.68-0.616-0.065-2.497-0.379-3.725c-0.377-1.475,2.828-2.743,0.455-3.916c-1.564-0.771-2.243,1.669-3.57,2.293
			c-2.916,0.895-5.665,1.282-7.07-2.358c0.819-0.907,2.909-0.936,1.356-3.271c-1.55-2.333-0.161-3.782,2.567-3.805
			c0.083,0.103,0.178,0.298,0.249,0.287c2.888-0.348,0.481,4.955,3.768,3.724c3.074-1.146-0.086-3.612,0.643-5.372
			c0.59-1.428,1.686-1.878,3.138-1.734c1.708,0.17,1.689,1.289,1.442,2.555c-0.224,1.156-1.466,3.03,0.373,3.18
			c1.674,0.139,3.827-0.713,4.46-3.039c0.214-0.79-1.542-2.415,0.514-2.254c1.488,0.116,2.884,1.42,4.322,2.191
			c-0.966,0.706,0.078,2.299-1.362,2.9c-0.76,0.317-1.328,1.091-1.982,1.658c0.806-0.036,1.745-0.359,2.39-0.052
			c1.365,0.642,2.723,1.458,3.815,2.487c1.356,1.284,2.62,1.562,4.166,0.535C436.976,543.149,436.961,544.467,436.312,545.784z"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          fill="#F68B8D"
          d="M397.881,552.335c3.102-0.531,5.562-2.773,8.104,0.547
			c1.448,1.887,0.815,3.208,0.566,4.959c-0.285,1.998-1.813,2.685-2.962,1.973c-1.962-1.219-10.049,7.029-5.968-3.703
			C398.078,554.913,393.849,552.661,397.881,552.335z"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          fill="#F68B8D"
          d="M406.438,541.845c1.815,2.409,4.821,1.248,7.07,2.358
			c2.386,0.211,2.848,2.111,2.242,3.649c-0.86,2.187-3.562,1.764-5.385,2.244c-2.676,0.71-7.1-5.011-6.55-7.8
			C404.345,539.604,405.495,541.561,406.438,541.845z"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          fill="#F0606D"
          d="M404.148,570.263c0.15-1.124,0.088-3.332,2.937-3.267
			c1.749,0.037,3.289,0.973,3.096,2.772c-0.199,1.895-1.023,3.909-3.408,4.192C404.724,574.205,403.971,572.988,404.148,570.263z"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          fill="#F0737E"
          d="M423.708,592.182c-2.702-0.683-5.581-0.544-5.189-5.126
			c0.205-2.39-2.183-5.002-3.4-7.518C420.701,581.906,420.453,588.233,423.708,592.182z"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          fill="#F7B0AE"
          d="M427.282,549.924c-1.208,0.239-1.71,0.499-2.061,0.364
			c-0.344-0.133-0.531-0.665-0.787-1.023c0.348-0.093,0.72-0.312,1.044-0.254C425.875,549.079,426.235,549.373,427.282,549.924z"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          fill="#F7A3A5"
          d="M436.312,545.784c-0.004-1.315-0.009-2.631-0.015-3.948
			c-0.327-2.153,0.145-4.024,2.558-4.325c0.697-0.083,3.345-2.775,2.765,1.133c-0.143,0.95,0.826,0.88,1.404,0.736
			c1.231-0.315,1.202-1.185,0.723-2.131c-2.104-4.152,2.422-4.598,3.799-6.801c-0.579,3.66,2.899,1.894,4.152,3.105
			c-2.527-0.537-0.279,5.621-4.337,3.076c-0.816-0.511-1.485,0.197-1.529,1.137c-0.061,1.347,0.576,1.996,1.937,2.459
			c2.432,0.832,5.054-0.028,7.214-0.155c2.578-0.157,5.489,0.291,8.116-0.586c0.825,0.902,0.267,1.741-0.563,1.529
			c-4.036-1.032-7.171,1.447-10.423,2.825c-2.415,1.023-3.509,4.363-6.462,5.118c-0.747,0.188-1.871,1.43-2.146,0.192
			c-0.22-0.979-3.254-1.548-0.534-3.404c1.071-0.729,0.881-3.312,1.265-5.047c-1.714-0.053-2.158,2.312-3.271,2.262
			C438.562,542.854,439.874,548.363,436.312,545.784z"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          fill="#FAB1A9"
          d="M476.42,537.937c-3.675,3.091-7.35,0.518-11.023-0.002
			c2.457-3.744,5.093-5.52,8.591-1.016C474.454,537.524,475.594,537.61,476.42,537.937z"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          fill="#EF6D7D"
          d="M445.744,555.221c-0.002,0.256-0.002,0.519-0.002,0.778
			c0.581,1.627-3.882,3.102-0.152,4.828c-2.066,0.68-2.375,3.226-3.965,4.061c-1.547,0.812-3.834,1.836-5.435,0.465
			c-1.64-1.402-0.22-2.751,1.061-3.817c0.245-0.205,0.347-0.695,0.357-1.059C437.795,554.794,441.466,554.542,445.744,555.221z"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          fill="#D33A58"
          d="M473.999,552.116c-2.334-0.284-4.667-0.568-6.999-0.853
			c-0.184-1.869,0.493-3.077,2.521-3.135c0.907-0.025,3.623-1.858,1.553,1.421c0.976-0.219,1.95-0.437,2.924-0.653
			C473.997,549.967,473.999,551.042,473.999,552.116z"
        />
      </g>
    </g>
  </svg>
);
