import React from 'react';
import App from '../App/App';
import Invitation from '../Invitation/Invitation';
import Kiitos from '../Kiitos/Kiitos';
import Menu from '../Menu/Menu';

export default (props) => {
  const route = window.location.pathname.replace(/\/$/, '');
  switch (route) {
    case '/invitation/front':
      return <Invitation front />;
    case '/invitation/back':
      return <Invitation back />;
    case '/kiitos/front':
      return <Kiitos front />;
    case '/kiitos/back':
      return <Kiitos back />;
    case '/menu/menu':
      return <Menu content="menu" />;
    case '/menu/menuit':
      return <Menu content="menuit" />;
    case '/menu/v0':
      return <Menu content="vieraat" id={0} />;
    case '/menu/v1':
      return <Menu content="vieraat" id={1} />;
    case '/menu/v2':
      return <Menu content="vieraat" id={2} />;
    case '/menu/v3':
      return <Menu content="vieraat" id={3} />;
    case '/menu/v4':
      return <Menu content="vieraat" id={4} />;
    case '/menu/v5':
      return <Menu content="vieraat" id={5} />;
    case '/menu/v6':
      return <Menu content="vieraat" id={6} />;
    case '/menu/v7':
      return <Menu content="vieraat" id={7} />;
    case '/menu/v8':
      return <Menu content="vieraat" id={8} />;
    case '/menu/v9':
      return <Menu content="vieraat" id={9} />;
    case '/menu/v10':
      return <Menu content="vieraat" id={10} />;
    case '/menu/v11':
      return <Menu content="vieraat" id={11} />;
    case '/menu/v12':
      return <Menu content="vieraat" id={12} />;
    case '/menu/v13':
      return <Menu content="vieraat" id={13} />;
    case '/galleria':
      window.location.assign('https://galleria.wihersaari.fi/guestlogin/susannaandjuhani');
      return null;
    default:
      return <App />;
  }
};
